import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useInactiveAccountCheck } from '@copper/hooks';
import { Alert } from '@copper/ui-kit';
import s from './InactiveAccountAlert.module.css';
const InactiveAccountAlert = ({ portfolio, currencyId, mainCurrencyId, message }) => {
    const inactiveAccount = useInactiveAccountCheck(portfolio, currencyId, mainCurrencyId);
    if (!inactiveAccount)
        return null;
    return (_jsx("div", { className: s.wrapper, children: _jsx(Alert, { type: "warning", noBottomMargin: true, message: message }) }));
};
export { InactiveAccountAlert };
