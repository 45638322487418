import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const WFLR = {
    title: 'WFLR',
    id: 'wflr',
    qa: [
        {
            question: 'How do I stake WFLR?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the one-epoch bonding period (around 3.5 days)." })] }), _jsx(Alert, { type: "info", message: "Rewards expire every 90 days, so remember to claim yours periodically." })] }))
        },
        {
            question: 'How do I unstake WFLR?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm. " })] }), _jsx("p", { children: "Once signed, unstaked assets will be made available in your staking vault right away." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for WFLR?',
            answer: _jsx("p", { children: "No, you can choose up to 100% of your available balance." })
        },
        {
            question: 'How often will I earn WFLR staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "WFLR rewards are issued every epoch (around 3.5 days). You can also check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 3.5 days" })] }) }) }), "\u00A0", _jsx(Alert, { type: "info", message: "Rewards expire every 90 days, so remember to claim yours periodically." })] }))
        },
        {
            question: 'How can I claim my WFLR staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your WFLR rewards anytime by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your WFLR staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "Once signed, the assets will be made available in your staking vault right away." }), "\u00A0", _jsx(Alert, { type: "info", message: "Rewards expire every 90 days, so remember to claim yours periodically." })] }))
        }
    ]
};
