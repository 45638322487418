import { request } from '@copper/utils';
export const getActiveStakes = (params) => request.get(`/staking/active-stakes`, {
    params
});
export const getPendingStakes = (params) => request.get(`/staking/pending-stakes`, {
    params
});
export const getOutstandingStakes = (params) => request.get(`/staking/outstanding-stakes`, {
    params
});
export const getSuggestedPools = (params) => request.get(`/staking/suggested-pools`, { params });
export const getAggregatedActiveStakes = (params) => request.get('/calculations/active-stakes', { params });
export const getStakeCounters = (params) => request.get(`/staking/stake-summary`, {
    params
});
