import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { subscribeCurrencyRates, updateCurrencyRates } from '@copper/entities/currencyRates/currencyRates-reducer';
export const useCurrencyRatesSubscription = () => {
    const dispatch = useDispatch();
    const [subscription, setSubscription] = useState({
        unsubscribe: () => null
    });
    const subscribeToCurrencyRates = async (organizationId) => {
        const result = await dispatch(subscribeCurrencyRates({
            params: {
                organizationId
            },
            onUpdate: (onUpdateResponse) => {
                if (!onUpdateResponse.error) {
                    dispatch(updateCurrencyRates(onUpdateResponse?.data?.marketPrices ?? []));
                }
                else if (onUpdateResponse.error === 'connection-closed-from-worker') {
                    subscribeToCurrencyRates(organizationId);
                }
            }
        })).unwrap();
        setSubscription({
            unsubscribe: result?.unsubscribe
        });
    };
    useEffect(() => () => {
        subscription.unsubscribe?.();
    }, [subscription]);
    return subscribeToCurrencyRates;
};
