import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as accountsApi from '@copper/api/accounts';
export const fetchAccountsByOrganizationId = createAsyncThunk('accounts/getAll', async (organizationId, thunkAPI) => {
    try {
        const response = await accountsApi.getAccountsByOrganizationId(organizationId);
        return response.data.accounts[0];
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        accountId: ''
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchAccountsByOrganizationId.fulfilled, (state, { payload }) => {
            state.accountId = payload.accountId;
        });
    }
});
export const accounts = accountsSlice.reducer;
