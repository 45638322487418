import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ButtonIcon, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import { BANK_ROWS_MAP } from './constants';
import s from './BankAccount.module.css';
export const BankAccount = ({ bankAccount }) => {
    const { openSnackbar } = useSnackBar();
    const handleCopyAll = () => {
        const data = Object.entries(bankAccount)
            .filter(([key]) => BANK_ROWS_MAP.get(key))
            .map(([key, value]) => `${BANK_ROWS_MAP.get(key)}: ${value}`)
            .join('\n');
        copy(data, () => openSnackbar({ type: 'success', message: 'Copied' }));
    };
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.header, children: [_jsx("div", { className: s.title, children: "Deposit details" }), _jsx("button", { className: s.copyAllButton, type: "button", onClick: handleCopyAll, children: "Copy all details" })] }), Object.keys(bankAccount)
                .filter((fieldName) => BANK_ROWS_MAP.get(fieldName))
                .map((field) => {
                const value = String(bankAccount[field]);
                return (_jsxs("div", { className: s.item, children: [_jsx("div", { className: s.name, children: BANK_ROWS_MAP.get(field) }), _jsxs("div", { className: s.value, children: [value, _jsx("div", { className: s.copyButton, children: _jsx(ButtonIcon, { onClick: () => copy(value, () => openSnackbar({ type: 'success', message: 'Copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) }) })] })] }, `bank_acc_field_${field}`));
            })] }));
};
