import { request } from '@copper/utils';
export const createInvoiceReport = (invoiceId) => request.post('/reports/invoices', {
    invoiceId
}, {
    headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
});
export const createNetAssetValuesReport = (data) => request.post('/reports/wallets/net-asset-values', data);
export const createStakingOrdersReport = (data) => request.post('/reports/stake/orders', data);
export const createStakingRewardsReport = (data) => request.post('/reports/stake/rewards', data);
export const createTransactionsReport = (data) => request.post('/reports/orders/net', data);
export const createWalletSnapshot = (data) => request.post('/reports/wallets/wallets-snapshots', data);
export const getReportById = (id) => request.get(`/reports/${id}`);
export const retryReportById = (id, organizationId) => request.patch(`/reports/${id}`, {
    organizationId
}, {
    headers: {
        'Content-Type': 'application/vnd.retry-report+json'
    }
});
