import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectIsAgencyLendingLenderOrganization } from '@copper/entities/organizations/organizations-selector';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { Button, IconGradientLending, IconGradientSettle, IconGradientTransfer } from '@copper/ui-kit';
import s from './NetworkActionsController.module.css';
const NetworkActionsController = ({ counterparty, view = 'secondary', size = 'm', readOnly }) => {
    const { setRouteContext } = useRoutingContext();
    const { isAllowedInAnyPortfolio, checkPermissions } = useCheckPermissionsContext();
    const isAgencyLendingLender = useSelector(selectIsAgencyLendingLenderOrganization);
    const { counterpartyType, counterpartyId, _embedded, operations } = counterparty;
    const portfolioId = _embedded?.counterpartyConnection?.receiverPortfolioId ||
        _embedded?.counterpartyConnection?.requestorPortfolioId;
    const isCounterpartyAgencyOrganization = operations.includes('loan-broker');
    const isTransferAvailable = counterpartyType !== 'lite' &&
        checkPermissions(['create-network-transfer']) &&
        !isCounterpartyAgencyOrganization;
    const isSettleAvailable = isAllowedInAnyPortfolio('create-otc-trade') && !isCounterpartyAgencyOrganization;
    const isLendingAvailable = isAgencyLendingLender;
    if (counterpartyType === 'clearloop-exchange') {
        return;
    }
    if (isCounterpartyAgencyOrganization && isLendingAvailable)
        return (_jsx("div", { className: s.container, children: _jsx(Button, { testId: "button-network-lend-open-form", leftSection: _jsx(IconGradientLending, { height: 16, width: 16 }), size: size, text: "Lend", view: view, disabled: readOnly, onClick: (event) => {
                    event.stopPropagation();
                    if (readOnly)
                        return;
                    setRouteContext({
                        name: 'lend',
                        data: {
                            counterpartyId,
                            portfolioId
                        }
                    });
                } }) }));
    return (_jsxs("div", { className: s.container, children: [isTransferAvailable && (_jsx(Button, { testId: "button-network-transfer-open-form", leftSection: _jsx(IconGradientTransfer, { height: 16, width: 16 }), size: size, text: "Transfer", view: view, disabled: readOnly, onClick: (event) => {
                    event.stopPropagation();
                    if (readOnly)
                        return;
                    setRouteContext({
                        name: 'transfer',
                        data: {
                            counterpartyId,
                            portfolioId
                        }
                    });
                } })), isSettleAvailable && (_jsx(Button, { testId: "button-network-settle-open-form", leftSection: _jsx(IconGradientSettle, { height: 16, width: 16 }), size: size, text: "Settle", view: view, disabled: readOnly, onClick: (event) => {
                    event.stopPropagation();
                    if (readOnly)
                        return;
                    setRouteContext({
                        name: 'settle',
                        data: {
                            counterpartyId,
                            portfolioId
                        }
                    });
                } }))] }));
};
export { NetworkActionsController };
