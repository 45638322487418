import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { STANDARD_FOP_DISCLAIMER_ID, STANDARD_LITE_TRADING_DISCLAIMER_ID } from '@copper/constants/disclaimers';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { useDisclaimer } from '@copper/hooks/useDisclaimer';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { ButtonLink } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '../helpers';
import { SingleSettleForm } from './Form';
import { Result } from './Result';
import { Summary } from './Summary';
import s from './SettleSingle.module.css';
const stepMap = {
    form: '1',
    summary: '2',
    result: '3'
};
const DEFAULT_VALUES = {
    orderType: 'sell',
    transferBy: 'network',
    availableForSettlement: '0',
    deliveryType: 'payment-vs-payment',
    usePriceLimit: false,
    priceDeviationError: ''
};
const SettleSingle = () => {
    const dispatch = useDispatch();
    const counterpartiesEntities = useSelector(selectCounterpartiesEntities);
    const [resultData, setResultData] = useState();
    const { route } = useRoutingContext();
    const portfolios = useSelector(selectPortfolios);
    const currencies = useSelector(selectCurrenciesEntities);
    const disclaimer = useDisclaimer(STANDARD_LITE_TRADING_DISCLAIMER_ID);
    const fopDisclaimer = useDisclaimer(STANDARD_FOP_DISCLAIMER_ID);
    const { setRouteContext } = useRoutingContext();
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_VALUES
    });
    const [submitErrorMessage, setSubmitErrorMessage] = useState('');
    const [state, send] = useStateMachine({
        initial: 'form',
        states: {
            form: {
                on: { NEXT_STEP: 'summary' }
            },
            summary: {
                on: { NEXT_STEP: 'result', BACK_STEP: 'form', RESET: 'form' }
            },
            result: { on: { RESET: 'form' } }
        }
    });
    const { setTopPanel } = useTopPanel();
    useEffect(() => {
        setTopPanel({
            title: 'Settle',
            subTitle: 'Step 1 of 2'
        });
    }, []);
    useEffect(() => {
        let subTitle = `Step ${stepMap[state.value]} of ${Object.keys(stepMap).length}`;
        if (state.value === 'result') {
            subTitle = submitErrorMessage ? 'Failed' : 'Settlement instruction created';
        }
        setTopPanel({
            title: 'Settle',
            subTitle,
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [state, send, setTopPanel]);
    const handleFormSubmit = async () => {
        const { counterparty, deliveryType } = form.getValues();
        if (counterparty?.counterpartyType === 'lite') {
            await disclaimer.checkDisclaimer();
        }
        if (deliveryType === 'free-of-payment') {
            await fopDisclaimer.checkDisclaimer();
        }
        send({ type: 'NEXT_STEP' });
    };
    useEffect(() => {
        if (route?.name === 'settle') {
            send('RESET');
            const { counterpartyId = '', portfolioId = '', settleOrderType = 'sell', amount, priceLimit, currencyId, quoteCurrencyId = '', quoteMainCurrency = '', baseCurrencyId = '', deliveryType = 'payment-vs-payment', usePriceLimit = true } = route?.data || {};
            form.reset({
                counterparty: counterpartiesEntities[counterpartyId],
                orderType: settleOrderType,
                transferBy: 'network',
                amount: amount,
                priceOrQuoteAmount: priceLimit,
                portfolio: portfolios[portfolioId],
                baseCurrency: currencies[baseCurrencyId],
                mainCurrency: currencyId,
                quoteCurrency: currencies[quoteCurrencyId],
                quoteMainCurrency: quoteMainCurrency,
                deliveryType: deliveryType,
                usePriceLimit: usePriceLimit
            });
        }
    }, [route]);
    const handleFormSend = async () => {
        const formValues = form.getValues();
        const data = {
            externalOrderId: cuid(),
            orderType: formValues.orderType,
            limitType: 'otc',
            portfolioId: formValues?.portfolio?.portfolioId,
            amount: formValues?.amount,
            baseCurrency: formValues?.baseCurrency?.currency,
            mainCurrency: formValues?.mainCurrency,
            ...(formValues.usePriceLimit
                ? { priceLimit: formValues?.priceOrQuoteAmount }
                : { quoteAmount: formValues?.priceOrQuoteAmount }),
            quoteCurrency: formValues?.quoteCurrency?.currency,
            quoteMainCurrency: formValues?.quoteMainCurrency,
            daysToSettle: '0',
            description: formValues?.description,
            deliveryType: formValues.deliveryType
        };
        if (formValues?.counterparty) {
            data['toCounterpartyId'] = formValues?.counterparty?.counterpartyId;
        }
        try {
            const settlementInstruction = await dispatch(createOrder(data)).unwrap();
            setResultData(settlementInstruction);
        }
        catch (err) {
            setSubmitErrorMessage(getErrorData(err).message);
        }
        finally {
            send('NEXT_STEP');
        }
    };
    const handleDone = () => {
        form.reset(DEFAULT_VALUES);
        setSubmitErrorMessage('');
        send('RESET');
    };
    const handleRepeat = () => {
        if (!resultData)
            return;
        const { extra, portfolioId, orderType, amount, baseCurrency, mainCurrency, quoteCurrency, quoteMainCurrency } = resultData;
        const { priceOrQuoteAmount, usePriceLimit } = form.getValues();
        setRouteContext({
            name: 'settle',
            data: {
                counterpartyId: extra?.toCounterpartyId,
                portfolioId,
                settleOrderType: orderType === 'sell' ? 'sell' : 'buy',
                amount: amount,
                currencyId: mainCurrency,
                baseCurrencyId: baseCurrency,
                priceLimit: priceOrQuoteAmount,
                quoteCurrencyId: quoteCurrency,
                quoteMainCurrency: quoteMainCurrency,
                counterpartyPortfolioId: extra?.counterpartyPortfolioId,
                deliveryType: resultData.extra?.deliveryType || 'payment-vs-payment',
                usePriceLimit: usePriceLimit
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [state.value === 'form' && (_jsx(SingleSettleForm, { form: form, onSubmit: handleFormSubmit, isLoading: disclaimer.isDisclaimerLoading })), state.value === 'summary' && (_jsx(Summary, { data: { ...form.getValues() }, isSubmitting: form.formState.isSubmitting, onNext: form.handleSubmit(handleFormSend), disclaimer: disclaimer, fopDisclaimer: fopDisclaimer })), state.value === 'result' && (_jsx(Result, { errorMessage: submitErrorMessage, settlementInstruction: resultData, onDone: handleDone }))] }), state.value === 'result' && resultData && (_jsx("div", { className: s.repeatContainer, children: _jsx(ButtonLink, { text: "Repeat transaction", onClick: handleRepeat }) }))] }));
};
export { SettleSingle };
