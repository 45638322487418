import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const AVAX = {
    title: 'AVAX',
    id: 'avax',
    qa: [
        {
            question: 'How do I stake AVAX?',
            answer: (_jsxs(_Fragment, { children: [_jsx("i", { children: "Step 1 - Setup" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum amount: 25 AVAX)." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Step 2 - Transfer to the P-Chain" }) }), _jsx("p", { className: s.spaceAfter, children: "Avalanche features several built-in blockchains with different functions. In order to stake, AVAX must be moved from the Exchange Chain (X-Chain) to the Platform Chain (P-Chain)." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the staking dashboard, go to the Outstanding tab and select Deposit (TBC)." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Step 3 - Set staking parameters" }) }), _jsx("p", { className: s.spaceAfter, children: "Choose how much you want to stake, with which validators, and for how long." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the staking dashboard, go to the Outstanding tab and select Add validator." }), _jsx("li", { children: "Choose how much you want to stake with the preselected validator." }), _jsx("li", { children: "Select the staking duration (minimum duration: 24 hours)." }), _jsx(Alert, { type: "info", message: "When you stake AVAX, your assets are locked up for the duration of the staking term." }), _jsx("li", { children: "Sign using Copper Unlimited." }), _jsx(Alert, { type: "info", message: "You won\u2019t be able to change the stake amount, duration or validator after signing the transaction." })] })] }))
        },
        {
            question: 'How do I unstake AVAX?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Assets are automatically unstaked at the end of the staking term, at which time you can choose to withdraw or restake the assets \u2013 including any rewards." }), _jsx("h4", { children: "How to withdraw" }), _jsx("p", { className: s.spaceAfter, children: "Withdrawing assets effectively transfers the assets back to Avalanche\u2019s X-Chain, from where you\u2019ll be able to claim the assets." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "Wait out the predefined staking term." }), _jsx("li", { children: "At the end of the staking term, withdraw some or all of your unstaked AVAX, and sign the transaction." }), _jsx("li", { children: "Claim the withdrawn amount and sign one more time to confirm." })] }), _jsx("h4", { children: "How to restake" }), _jsx("p", { className: s.spaceAfter, children: "Alternatively, you can restake your assets \u2013 including any rewards \u2013 by repeating these steps." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the staking dashboard, go to the Outstanding tab and select Add validator." }), _jsx("li", { children: "Choose how much you want to stake with the preselected validator." }), _jsx("li", { children: "Select the staking duration (minimum duration: 24 hours)." }), _jsx(Alert, { type: "info", message: "Remember, your assets will be locked up for the duration of the staking term." }), _jsx("li", { children: "Sign using Copper Unlimited." }), _jsx(Alert, { type: "info", message: "You won\u2019t be able to change the stake amount, duration or validator after signing the transaction." })] })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for AVAX?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 25 AVAX. The maximum will depend on how much space is available in the validator node \u2013 this will be indicated to you at the time of staking." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "25 AVAX" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "Variable" })] })] }) })] }))
        },
        {
            question: 'When will I receive my AVAX staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Staking rewards for AVAX are automatically credited at the end of the staking term. To claim rewards you've accrued, you will first need to withdraw assets." }), _jsx("h4", { children: "How to withdraw" }), _jsx("p", { className: s.spaceAfter, children: "Withdrawing assets effectively transfers the assets back to Avalanche\u2019s X-Chain, from where you\u2019ll be able to claim the assets." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "Wait out the predefined staking term." }), _jsx("li", { children: "At the end of the staking term, withdraw some or all of your unstaked AVAX, and sign the transaction." }), _jsx("li", { children: "Claim the withdrawn amount and sign once more to confirm." })] })] }))
        },
        {
            question: 'Is there a minimum or maximum staking time?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking term is 14 days and the maximum is 1 year." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum term" }) }), _jsx("td", { className: s.td, children: "14 days" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum term" }) }), _jsx("td", { className: s.td, children: "12 months" })] })] }) })] }))
        },
        {
            question: 'What are the risks of staking AVAX?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "When staking AVAX, you should consider the following risks:" }), _jsxs("p", { children: [_jsx("b", { children: "Downtime." }), " If the validator is occasionally offline and responsive for less than 80% of the validation period, you will not receive any rewards. We recommend checking Avalanche\u2019s Validator Health dashboard before staking with a validator."] }), _jsxs("p", { children: [_jsx("b", { children: "Lockup period." }), " Your capital may be at risk if the market price of AVAX fluctuates during the 14-day lockup period"] })] }))
        },
        {
            question: 'How can I verify that a validator has over 80% uptime?',
            answer: (_jsxs("p", { children: ["You can check Avalanche\u2019s", ' ', _jsx("a", { target: "_blank", href: "https://stats.avax.network/dashboard/validator-health-check/", rel: "noreferrer", children: "Validator Health dashboard." })] }))
        }
    ]
};
