import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ButtonIcon, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import { QrCodeImage } from '../QrCodeImage';
import s from './AddressButtons.module.css';
const AddressButtons = ({ depositTargetAddress, view = 'primary' }) => {
    const { openSnackbar } = useSnackBar();
    return (_jsxs("div", { className: s.addressButtons, children: [_jsx(QrCodeImage, { view: view, address: depositTargetAddress }), _jsx(ButtonIcon, { onClick: () => copy(depositTargetAddress, () => openSnackbar({ type: 'success', message: 'Copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) })] }));
};
export { AddressButtons };
