import { fuzzySearch } from '@copper/ui-kit';
export const getOrganizationsToDisplay = (items, searchQuery) => {
    if (searchQuery === '')
        return items;
    return fuzzySearch({
        items,
        keys: ['organizationId', 'organizationName'],
        searchQuery
    });
};
