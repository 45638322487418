import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, FormProvider, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { AssetIcon } from '@copper/components';
import { getAvailableBalance } from '@copper/components/Forms/Amount/helpers';
import { FundsAmount } from '@copper/components/Forms/FundsAmount';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { Ratio } from '@copper/components/OxygenPledges/Ratio';
import { selectCurrenciesEntities, selectOxygenCollateralCurrencies } from '@copper/entities/currencies/currencies-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { usePledgeCalculation } from '@copper/hooks/oxygen/usePledgeCalculation';
import { useTotalCollateral } from '@copper/hooks/oxygen/useTotalCollateral';
import { Alert, ButtonIcon, ButtonLink, FormControl, FormLabel, IconAdd, IconDelete, Input, NumberInput } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
import { getCollateralItem } from '../helpers';
import s from './BorrowDCTForm.module.css';
export const BorrowDCTForm = ({ form, onSubmit }) => {
    const collateralCurrencies = useSelector(selectOxygenCollateralCurrencies);
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const { calculateTotalCollateral } = useTotalCollateral();
    const { fetchPledgeCalculation, pledgeCalculation } = usePledgeCalculation();
    const { control, watch, handleSubmit, setValue, trigger, formState: { errors } } = form;
    const { fields: collateralFields, append: appendCollateralItem, remove: removeCollateralItem } = useFieldArray({
        control,
        name: 'collaterals'
    });
    const { portfolio, amount, collaterals } = watch();
    const totalCollateralMarketValue = calculateTotalCollateral(getCollateralItem(collaterals));
    useEffect(() => {
        if (pledgeCalculation) {
            setValue('pledgeCalculation', pledgeCalculation);
            trigger(['amount', 'pledgeCalculation']);
        }
    }, [pledgeCalculation, setValue, trigger]);
    useEffect(() => {
        const collateralAvailableBalances = collaterals.reduce((acc, collateral) => {
            if (collateral?.currency?.currency) {
                acc[collateral.currency.currency] = getAvailableBalance({
                    walletsByPortfolioId,
                    currenciesEntities,
                    portfolio: portfolio,
                    currencyId: collateral?.currency?.currency
                });
            }
            return acc;
        }, {});
        if (Object.values(collateralAvailableBalances).length) {
            setValue('collateralAvailableBalances', collateralAvailableBalances, {
                shouldValidate: true
            });
        }
    }, [walletsByPortfolioId, currenciesEntities, portfolio, setValue, JSON.stringify(collaterals)]);
    useEffect(() => {
        const filteredCollaterals = getCollateralItem(collaterals);
        if (portfolio && new BigNumber(amount).gte(0) && filteredCollaterals.length > 0) {
            fetchPledgeCalculation({
                portfolioId: portfolio.portfolioId,
                debtAmount: amount,
                collaterals: filteredCollaterals
            });
        }
    }, [fetchPledgeCalculation, JSON.stringify(collaterals), portfolio, amount]);
    return (_jsx(FormProvider, { ...form, children: _jsxs("form", { className: s.formContainer, onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: s.formFields, children: [_jsx(PortfolioSelect, { name: "portfolio", label: "Account", hasUnavailableToggle: false, types: ['oxygen-vault'], control: control, errorMessage: errors?.portfolio?.message }), _jsxs(FormControl, { errorMessage: errors?.newPledgeName?.message, children: [_jsx(FormLabel, { view: "tool", children: "Pledge name" }), _jsx(Controller, { control: control, name: "newPledgeName", render: ({ field }) => (_jsx(Input, { size: "l", view: "tool", value: field.value ?? '', placeholder: "Name this position", onChange: (event) => field.onChange(event.target.value) })) })] }), _jsxs(FormControl, { children: [_jsx(FormLabel, { view: "tool", children: "Description (optional)" }), _jsx(Controller, { control: control, name: "newPledgeDescription", render: ({ field }) => (_jsx(Input, { view: "tool", value: field.value ?? '', size: "l", onChange: (event) => field.onChange(event.target.value) })) })] }), _jsxs("div", { className: s.collaterals, children: [_jsx(FormLabel, { view: "tool", children: "Collateral" }), _jsxs("div", { className: s.collateralContainer, children: [collateralFields.map((collateralItem, index) => (_jsx(FormControl, { indent: "none", errorMessage: errors?.collaterals?.[index]?.amount?.message ??
                                                errors?.collaterals?.[index]?.currency?.message, children: _jsxs("div", { className: s.collateralInputContainer, children: [_jsx(FundsAmount, { view: "tool", control: control, currencyItems: collateralCurrencies, portfolioId: portfolio?.portfolioId, inputName: `collaterals.${index}.amount`, selectName: `collaterals.${index}.currency` }), collateralFields.length > 1 && (_jsx("div", { className: s.removeCollateralItem, children: _jsx(ButtonIcon, { onClick: () => removeCollateralItem(index), testId: `remove-collateral-item-${index}`, children: _jsx(IconDelete, {}) }) }))] }) }, collateralItem.id))), errors.pledgeCalculation?.ratio?.type === 'ratio' && (_jsx(Alert, { noBottomMargin: true, type: "error", message: errors.pledgeCalculation?.ratio?.message })), _jsx("div", { className: s.addCollateral, children: _jsx(ButtonLink, { text: "Add another asset", leftSection: _jsx(IconAdd, {}), onClick: () => appendCollateralItem({}) }) })] })] }), _jsxs(FormControl, { errorMessage: errors?.amount?.message, children: [_jsx(FormLabel, { view: "tool", children: "DCT amount to create" }), _jsx(Controller, { control: control, name: "amount", render: ({ field }) => (_jsx(NumberInput, { decimalScale: 8, value: field.value, onValueChange: ({ value }) => field.onChange(value), size: "l", view: "tool", rightSection: _jsxs("div", { className: s.amountCurrency, children: [_jsx(AssetIcon, { size: "s", currency: "DCT" }), "DCT"] }) })) })] }), _jsxs("div", { children: [_jsxs("div", { className: s.ratio, children: [_jsxs("div", { className: s.ratioTitle, children: ["Collateral ratio", ' ', pledgeCalculation
                                                    ? `(min ${(Number(pledgeCalculation?.minRatio) * 100).toFixed()}%)`
                                                    : ''] }), _jsx(Ratio, { size: "l", value: pledgeCalculation?.ratio, minValue: pledgeCalculation?.minRatio })] }), _jsxs("div", { className: s.rowInfo, children: [_jsx("div", { children: "Total collateral value" }), _jsx("div", { className: s.rowInfoValue, children: formatFiat(totalCollateralMarketValue) })] })] })] }), _jsx(SwitchStateRow, { type: "submit" })] }) }));
};
