import BigNumber from 'bignumber.js';
import cuid from 'cuid';
import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    amount: Yup.string()
        .required('Enter an amount.')
        .test('amount', 'Amount must be greater than zero', (value) => new BigNumber(value ?? 0).gt(0))
        .test('amount', (value = '0', { parent, createError }) => {
        const { availableForSettlement = '0', baseCurrency, deliveryType } = parent;
        if (deliveryType === 'free-of-payment')
            return true;
        return new BigNumber(availableForSettlement).minus(value).gte(0)
            ? true
            : createError({
                message: `Insufficient ${baseCurrency?.currency ?? ''} balance.`
            });
    }),
    priceOrQuoteAmount: Yup.string().when('usePriceLimit', {
        is: true,
        then: (schema) => schema
            .required('Enter a price.')
            .test('priceOrQuoteAmount', 'Price must be greater than zero', (value) => new BigNumber(value ?? 0).gt(0)),
        otherwise: (schema) => schema
            .required('Enter a receiving amount.')
            .test('priceOrQuoteAmount', 'Receiving amount must be greater than zero', (value) => new BigNumber(value ?? 0).gt(0))
    }),
    baseCurrency: Yup.object().shape({
        currency: Yup.string().required('Select a currency.')
    }),
    mainCurrency: Yup.string().required('Select a network.'),
    quoteCurrency: Yup.object().shape({
        currency: Yup.string().required('Select a currency.')
    }),
    quoteMainCurrency: Yup.string().required('Select a network.'),
    portfolio: Yup.object().shape({
        portfolioId: Yup.string().required('Select an account.')
    }),
    counterparty: Yup.object().shape({
        counterpartyId: Yup.string().required(`Select an organisation from the network`)
    }),
    description: Yup.string().max(500, 'No more than 500 characters.'),
    priceDeviationError: Yup.string().test('priceDeviationError', (message) => message === '')
});
export const normalizeDryRunResult = (responseOrders) => responseOrders.reduce((acc, order) => {
    const orderId = cuid();
    return {
        ...acc,
        [orderId]: {
            orderId,
            toCounterpartyId: order?.extra?.toCounterpartyId,
            ...order
        }
    };
}, {});
