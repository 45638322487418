import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import s from './TextareaAutosize.module.css';
const getStyleValue = (element, property) => parseInt(getComputedStyle(element).getPropertyValue(property), 10) || 0;
export const TextareaAutosize = ({ value, onChange, size = 'm', minHeight, maxHeight = 120, view = 'primary', readOnly = false, testId = '', placeholder = '' }) => {
    const textareaRef = useRef(null);
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '0px';
            const scrollHeight = textareaRef.current.scrollHeight;
            const newValue = scrollHeight +
                getStyleValue(textareaRef.current, 'border-top-width') +
                getStyleValue(textareaRef.current, 'border-bottom-width');
            textareaRef.current.style.height = Math.min(maxHeight, newValue) + 'px';
        }
    }, [value]);
    return (_jsx("textarea", { placeholder: placeholder, readOnly: readOnly, value: value, style: { minHeight }, ref: textareaRef, onChange: onChange, className: cn(s.textarea, s[`size_${size}`], s[`view_${view}`]), "data-testid": testId }));
};
