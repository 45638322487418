import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { hasReachedMaturity, isBorrowerOrganization } from '@copper/components/Loans/helpers';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { cancelDefault, cancelLoan, defaultLoan, marginCallLoan, rebalanceLoan, repayLoan, writeOffLoan } from '@copper/entities/loans/loans-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Button, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './LoanControls.module.css';
const LoanControls = ({ loan }) => {
    const organization = useSelector(selectCurrentOrganization);
    const { checkPermissions } = useCheckPermissionsContext();
    const { openSnackbar } = useSnackBar();
    const [loading, setLoading] = useState(false);
    const { loanArranger, loanStatus, _embedded: embedded, terms, ltvPercentage } = loan;
    const { termType, marginCallPercentage, defaultPercentage, rebalancePercentage } = terms;
    const { isCancelable } = embedded || {};
    const isBorrower = isBorrowerOrganization(loan, organization);
    const isRepayable = loanStatus === 'open' &&
        (termType === 'open' || (termType === 'fixed' && hasReachedMaturity(loan)));
    const ltv = new BigNumber(ltvPercentage ?? 0);
    const isRebalancePossible = loanStatus === 'open' &&
        !!rebalancePercentage &&
        ltv.isLessThanOrEqualTo(new BigNumber(rebalancePercentage));
    const isMarginCallPossible = !isBorrower &&
        loanStatus === 'open' &&
        !!marginCallPercentage &&
        ltv.isGreaterThanOrEqualTo(new BigNumber(marginCallPercentage));
    const isDefaultPossible = ((loanStatus === 'margin-call' &&
        !!defaultPercentage &&
        ltv.isGreaterThanOrEqualTo(new BigNumber(defaultPercentage))) ||
        'repaying-loan' === loanStatus) &&
        !isBorrower;
    const isDefaultCancelPossible = loanStatus === 'defaulting' && !isBorrower;
    const isWriteOffPossible = ['defaulting', 'open', 'margin-call', 'repaying-loan'].includes(loanStatus) && !isBorrower;
    const hasAction = checkPermissions('manage-loan') &&
        (isCancelable ||
            isRepayable ||
            isRebalancePossible ||
            isMarginCallPossible ||
            isDefaultPossible ||
            isDefaultCancelPossible ||
            isWriteOffPossible) &&
        !loanArranger;
    const onCancel = async () => {
        setLoading(true);
        try {
            await cancelLoan(loan.loanId);
            openSnackbar({ type: 'success', message: 'Cancellation started.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    const onWriteOff = async () => {
        setLoading(true);
        try {
            await writeOffLoan(loan.loanId);
            openSnackbar({ type: 'success', message: 'Write off started.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    const onMarginCall = async () => {
        setLoading(true);
        try {
            await marginCallLoan(loan.loanId);
            openSnackbar({ type: 'success', message: 'Margin Call started.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    const onRepay = async () => {
        setLoading(true);
        try {
            await repayLoan(loan.loanId);
            openSnackbar({ type: 'success', message: 'Repayment started.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    const onDefault = async () => {
        setLoading(true);
        try {
            await defaultLoan(loan.loanId);
            openSnackbar({ type: 'success', message: 'Default started.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    const onRebalance = async () => {
        setLoading(true);
        try {
            await rebalanceLoan(loan.loanId);
            openSnackbar({ type: 'success', message: 'Rebalance started.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    const onDefaultCancelled = async () => {
        setLoading(true);
        try {
            await cancelDefault(loan.loanId);
            openSnackbar({ type: 'success', message: 'Default cancelled.' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setLoading(false);
        }
    };
    if (!hasAction)
        return null;
    return (_jsxs("div", { className: s.loanInfoContainer, children: [isCancelable && (_jsx(Button, { className: s.loanControl, view: "decline", text: "Cancel", loading: loading, onClick: onCancel })), isWriteOffPossible && (_jsx(Button, { className: s.loanControl, view: "decline", text: "Write Off", loading: loading, onClick: onWriteOff })), isRepayable && (_jsx(Button, { className: s.loanControl, view: "secondary", text: "Repay", loading: loading, onClick: onRepay })), isMarginCallPossible && (_jsx(Button, { className: s.loanControl, view: "secondary", text: "Margin Call", loading: loading, onClick: onMarginCall })), isDefaultPossible && (_jsx(Button, { className: s.loanControl, view: "secondary", text: "Default", loading: loading, onClick: onDefault })), isRebalancePossible && (_jsx(Button, { className: s.loanControl, view: "secondary", text: "Rebalance", loading: loading, onClick: onRebalance })), isDefaultCancelPossible && (_jsx(Button, { className: s.loanControl, view: "decline", text: "Cancel Default", loading: loading, onClick: onDefaultCancelled }))] }));
};
export { LoanControls };
