import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as cryptoAddressesApi from '@copper/api/cryptoAddresses';
import { normalizeCryptoAddresses } from '@copper/entities/addressBook/addressBook-normalize';
const mergeCryptoAddress = (newAddress, currentAddress) => ({
    ...newAddress,
    _embedded: { ...currentAddress?._embedded, ...newAddress?._embedded }
});
export const fetchCryptoAddresses = createAsyncThunk('cryptoAddresses/get', async (params, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.get(params);
        return response.data.cryptoAddresses;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchCryptoAddress = createAsyncThunk('cryptoAddress/get', async (cryptoAddressId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.getAddress(cryptoAddressId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createCryptoAddress = createAsyncThunk('cryptoAddresses/create', async ({ newAddress, twoFaCode }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.createNewAddress(newAddress, twoFaCode);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteCryptoAddress = createAsyncThunk('cryptoAddresses/delete', async ({ cryptoAddressId, twoFaCode }, thunkAPI) => {
    try {
        await cryptoAddressesApi.deleteAddress(cryptoAddressId, twoFaCode);
        return cryptoAddressId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const editCryptoAddress = createAsyncThunk('cryptoAddresses/edit', async ({ updatedAddress, twoFaCode }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.editAddress(updatedAddress, twoFaCode);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateTravelRules = createAsyncThunk('cryptoAddresses/updateTravelRules', async (travelRules, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.updateTravelRules(travelRules);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getAddressOwners = createAsyncThunk('cryptoAddresses/getOwners', async ({ organizationId }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.getAddressOwners(organizationId);
        return response.data.owners;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createAddressOwner = createAsyncThunk('cryptoAddresses/createOwner', async (data, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.createAddressOwner(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteAddressOwner = createAsyncThunk('cryptoAddresses/deleteOwner', async ({ ownerId, twoFaCode }, thunkAPI) => {
    try {
        await cryptoAddressesApi.deleteAddressOwner(ownerId, twoFaCode);
        return ownerId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const editAddressOwner = createAsyncThunk('cryptoAddresses/editOwner', async ({ ownerId, data }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.editAddressOwner(ownerId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const generateVerificationLink = createAsyncThunk('cryptoAddresses/generateVerificationLink', async (ownerId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.generateOwnerVerificationLink(ownerId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const verifyAddressWithVisualProof = createAsyncThunk('cryptoAddresses/verifyScreenshot', async (cryptoAddressId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.verifyAddressWithVisualProof(cryptoAddressId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const approveVisualProofTest = createAsyncThunk('cryptoAddresses/approveScreenshot', async (cryptoAddressId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.approveVisualProofTest(cryptoAddressId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rejectVisualProofTest = createAsyncThunk('cryptoAddresses/rejectScreenshot', async (cryptoAddressId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.rejectVisualProofTest(cryptoAddressId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const approveCryptoAddress = createAsyncThunk('cryptoAddresses/approve', async ({ cryptoAddressId, twoFaCode }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.approveAddress(cryptoAddressId, twoFaCode);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rejectCryptoAddress = createAsyncThunk('cryptoAddresses/reject', async ({ cryptoAddressId, twoFaCode, isNewAddress }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.rejectAddress(cryptoAddressId, twoFaCode);
        return { address: response.data, isNewAddress };
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addNonCustodialWallet = createAsyncThunk('cryptoAddress/addNonCustodialWallet', async ({ cryptoAddressId, data }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.addNonCustodialWallet(cryptoAddressId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const renameNonCustodialWallet = createAsyncThunk('cryptoAddress/renameNonCustodialWallet', async ({ cryptoAddressId, data }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.renameNonCustodialWallet(cryptoAddressId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const requestNonCustodialWalletPrivateKey = createAsyncThunk('cryptoAddress/requestNonCustodialWalletPrivateKey', async ({ cryptoAddressId, data }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.requestNonCustodialWalletPrivateKey(cryptoAddressId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const removeNonCustodialWallet = createAsyncThunk('cryptoAddress/removeNonCustodialWallet', async ({ cryptoAddressId, data }, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.removeNonCustodialWallet(cryptoAddressId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const verifyAddressWithSatoshiTest = createAsyncThunk('cryptoAddress/verifySatoshiTest', async (cryptoAddressId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.verifyAddressWithSatoshiTest(cryptoAddressId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const resetSatoshiTest = createAsyncThunk('cryptoAddress/resetSatoshiTest', async (cryptoAddressId, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.resetSatoshiTest(cryptoAddressId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const approveComplianceCheck = createAsyncThunk('cryptoAddress/approveComplianceCheck', async (address, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.approveComplianceCheck(address);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rejectComplianceCheck = createAsyncThunk('cryptoAddress/approveComplianceCheck', async (address, thunkAPI) => {
    try {
        const response = await cryptoAddressesApi.rejectComplianceCheck(address);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const addressBookSlice = createSlice({
    name: 'addressBook',
    initialState: {
        cryptoAddresses: {},
        openedCryptoAddresses: {},
        owners: []
    },
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchCryptoAddresses.fulfilled, (state, { payload }) => {
            state.cryptoAddresses = normalizeCryptoAddresses(payload);
        });
        addCase(getAddressOwners.fulfilled, (state, { payload }) => {
            state.owners = payload;
        });
        addCase(createAddressOwner.fulfilled, (state, { payload }) => {
            state.owners.push(payload);
        });
        addCase(deleteAddressOwner.fulfilled, (state, { payload }) => {
            state.owners = state.owners.filter((owner) => owner.ownerId !== payload);
        });
        addCase(editAddressOwner.fulfilled, (state, { payload }) => {
            state.owners = state.owners.map((owner) => {
                if (owner.ownerId === payload.ownerId) {
                    return payload;
                }
                return owner;
            });
        });
        addCase(generateVerificationLink.fulfilled, (state, { payload }) => {
            state.owners = state.owners.map((owner) => {
                if (owner.ownerId === payload.ownerId) {
                    return payload;
                }
                return owner;
            });
        });
        addCase(rejectCryptoAddress.fulfilled, (state, { payload }) => {
            if (payload.isNewAddress) {
                delete state.cryptoAddresses[payload.address.cryptoAddressId];
            }
            else {
                state.cryptoAddresses[payload.address.cryptoAddressId] = payload.address;
            }
            state.openedCryptoAddresses = {
                [payload.address.cryptoAddressId]: mergeCryptoAddress(payload.address, state.openedCryptoAddresses[payload.address.cryptoAddressId])
            };
        });
        addCase(deleteCryptoAddress.fulfilled, (state, { payload }) => {
            delete state.cryptoAddresses[payload];
        });
        addMatcher(isAnyOf(fetchCryptoAddress.fulfilled, createCryptoAddress.fulfilled, editCryptoAddress.fulfilled, updateTravelRules.fulfilled, approveCryptoAddress.fulfilled, verifyAddressWithSatoshiTest.fulfilled, resetSatoshiTest.fulfilled, rejectVisualProofTest.fulfilled, approveVisualProofTest.fulfilled, verifyAddressWithVisualProof.fulfilled, addNonCustodialWallet.fulfilled, renameNonCustodialWallet.fulfilled, removeNonCustodialWallet.fulfilled, requestNonCustodialWalletPrivateKey.fulfilled, approveComplianceCheck.fulfilled, rejectComplianceCheck.fulfilled), (state, { payload }) => {
            const existingCryptoAddress = state.cryptoAddresses[payload.cryptoAddressId];
            state.openedCryptoAddresses = {
                [payload.cryptoAddressId]: mergeCryptoAddress(payload, state.openedCryptoAddresses[payload.cryptoAddressId])
            };
            if (existingCryptoAddress) {
                state.cryptoAddresses[payload.cryptoAddressId] = mergeCryptoAddress(payload, existingCryptoAddress);
            }
        });
    }
});
const { reducer } = addressBookSlice;
export const addressBook = reducer;
