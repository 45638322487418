import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { hasClearloopAutoActivation, hasExchangeAutoActivation } from '@copper/helpers/exchanges';
import s from './AutoDiscoveryLabel.module.css';
const AutoActivationLabel = ({ exchange, type = 'exchange' }) => {
    const autoActivation = type === 'exchange'
        ? hasExchangeAutoActivation(exchange)
        : hasClearloopAutoActivation(exchange);
    if (!exchange || (type === 'exchange' && exchange?.exchangeAccounts?.length <= 1)) {
        return null;
    }
    return (_jsx("div", { className: cn(s.label, { [s.autoActivation]: autoActivation }), children: autoActivation
            ? 'Sub-accounts and wallets autodiscovery supported'
            : 'Sub-accounts and wallets autodiscovery not supported' }));
};
export { AutoActivationLabel };
