import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { Button, IconWarning } from '@copper/ui-kit';
import s from './ErrorBoundary.module.css';
class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false
        };
        this.handleOnClick = () => {
            this.setState({ hasError: false });
            location.replace('/');
        };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.error('Uncaught error:', error);
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.icon, children: _jsx(IconWarning, {}) }), _jsx("h1", { children: "Something went wrong" }), _jsx("div", { className: s.text, children: "There was an unexpected error. Please try again later. If the problem continues, contact us for support." }), _jsx(Button, { text: "Back to home", onClick: this.handleOnClick })] }));
        }
        return this.props.children;
    }
}
export { ErrorBoundary };
