import * as organizations from '@copper/api/organizations';
export const createOrganization = (data) => organizations.createOrganization(data).then((response) => response.data);
export const getOrganizationsByQuery = (params) => organizations.getByQuery(params).then((response) => response.data.organizations);
export const getOrganizationById = (organizationId) => organizations.get({ organizationId }).then((response) => response.data);
export const sendFiatDepositNotification = (organizationId, data) => organizations.sendFiatDepositNotification(organizationId, data).then((response) => response.data);
export const setupMasterPassword = (organizationId, data) => organizations.setupMasterPassword(organizationId, data).then((response) => response.data);
export const resendMemberActivationLink = (organizationId, userId) => organizations
    .resendMemberActivationLink(organizationId, userId)
    .then((response) => response.data);
export const getOrganizationDetails = (params) => organizations.getOrganizationDetails(params).then((response) => response.data);
export const getApiKeys = (organizationId) => organizations.getApiKeys(organizationId).then((response) => response.data);
export const inviteLiteOrganizationMember = (data) => organizations.inviteLiteOrganizationMember(data).then((response) => response.data);
export const getControllersSets = (organizationId) => organizations
    .getControllersSets(organizationId)
    .then((response) => response.data.controllersSets);
export const getControllersCreationSessions = (organizationId) => organizations
    .getControllersCreationSessions(organizationId)
    .then((response) => response.data.controllersCreations);
export const createControllersSession = (organizationId, createControllersSession) => organizations
    .createControllersSession(organizationId, createControllersSession)
    .then((response) => response.data);
export const cancelControllersSession = (organizationId, sessionId) => organizations
    .cancelControllersSession(organizationId, sessionId)
    .then((response) => response.data);
export const joinControllersCreation = (organizationId, sessionId, data) => organizations
    .joinControllersCreation(organizationId, sessionId, data)
    .then((response) => response.data);
