import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Popup } from '@copper/ui-kit';
import { LoanModalContent } from '../LoanModalContent';
const LoanModal = ({ showActions, loan, onClose }) => {
    if (!loan)
        return null;
    return (_jsx(Popup, { onClose: onClose, children: _jsx(LoanModalContent, { loan: loan, showActions: showActions }) }));
};
export { LoanModal };
