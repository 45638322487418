import Fuse from 'fuse.js';
function fuzzySearch({ items, searchQuery, keys, searchType = 'strong' }) {
    const options = {
        exact: {
            keys,
            threshold: 0,
            ignoreLocation: true
        },
        strong: {
            keys,
            threshold: 0.2
        },
        weak: {
            keys,
            threshold: 0.3,
            distance: 150
        }
    };
    const fuse = new Fuse(items, options[searchType]);
    return fuse.search(searchQuery).map((result) => result.item);
}
export { fuzzySearch };
