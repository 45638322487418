import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import s from './Switch.module.css';
const Switch = ({ label, onChange, id, checked = false, disabled = false, align = 'left', testId }) => {
    const handleChange = (e) => {
        if (onChange)
            onChange(!e.target.checked);
    };
    return (_jsxs("label", { className: cn(s.switch, s[`switch_${align}_side`]), children: [_jsx("input", { id: id, type: "checkbox", className: s.checkbox, onChange: handleChange, checked: checked, disabled: disabled, "data-testid": testId }), label && _jsx("span", { className: s.label, children: label })] }));
};
export { Switch };
