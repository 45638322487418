export const copy = (text, onSuccess, onError) => {
    if (!text) {
        onError && onError('No content to copy');
    }
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.all = 'unset';
    textarea.style.position = 'fixed';
    textarea.style.whiteSpace = 'pre';
    textarea.style.userSelect = 'text';
    textarea.style.visibility = 'hidden';
    document.body.appendChild(textarea);
    textarea.select();
    navigator.clipboard
        .writeText(textarea.value)
        .then(() => onSuccess && onSuccess())
        .catch(() => onError && onError('Error occured when copying'))
        .finally(() => document.body.removeChild(textarea));
};
