import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Icon } from '../../Icon';
const typeMap = {
    error: {
        background: '#e74c3c',
        sign: '#ffffff'
    },
    warning: {
        background: '#f5a623',
        sign: '#181a1d'
    }
};
const IconAlert = (props) => {
    const { type = 'error' } = props;
    return (_jsx(Icon, { ...props, path: _jsxs("g", { children: [_jsx("path", { d: "M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z", fill: typeMap[type].background }), _jsx("path", { d: "M13 8H11V13H13V8ZM13 14H11V16H13V14Z", fill: typeMap[type].sign })] }) }));
};
export { IconAlert };
