import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDownloadButton } from '@copper/components/AppDownloadButton';
import { useOrderContext } from '@copper/components/OrderPopup/context-hook';
import { StartSettlementButton } from '@copper/components/OrderPopup/ui/StartSettlementButton';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { createBoundedToken } from '@copper/entities/orders/orders-actions';
import { startCustodySettlement } from '@copper/entities/orders/orders-reducer';
import { selectUser } from '@copper/entities/user/user-selector';
import { generateCUDeepLink, getPlatformType } from '@copper/helpers/newCU';
import { isVaultPortfolio } from '@copper/helpers/portfolios';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
const StartSettlement = ({ order, disabled, type = 'complex' }) => {
    const dispatch = useDispatch();
    const { saveContext } = useOrderContext();
    const { openSnackbar } = useSnackBar();
    const { checkPermissions } = useCheckPermissionsContext();
    const { isAdmin } = useSelector(selectUser);
    const { portfolio } = order._embedded || {};
    const signingFlowType = portfolio?.extra?.signingFlowType;
    const isWalletMessageOrderType = order.orderType === 'wallet-message';
    const setSigningContext = (type) => {
        saveContext({
            popupContext: {
                header: {
                    returnCallback: () => {
                        saveContext({
                            popupContext: {}
                        });
                    },
                    title: `Sign ${isWalletMessageOrderType ? 'message' : 'transaction'}`,
                    subTitle: isWalletMessageOrderType ? 'Wallet message' : 'Asset withdrawal'
                },
                operation: type
            }
        });
    };
    const startHalfSigning = async () => {
        try {
            if (order.status === 'awaiting-settlement') {
                await dispatch(startCustodySettlement({ orderId: order.orderId })).unwrap();
            }
            setSigningContext('half-signing');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const startFullSigning = () => {
        setSigningContext('full-signing');
    };
    const startKryptonSigning = async () => {
        try {
            if (order.status === 'awaiting-settlement') {
                await dispatch(startCustodySettlement({ orderId: order.orderId })).unwrap();
            }
            const data = await createBoundedToken(order.orderId);
            window.open(generateCUDeepLink(data.token, order.orderId, getPlatformType(window.location.hostname), data.correlationId), '_self');
            let isBlurred = false;
            let timerId;
            window.onblur = () => {
                if (timerId) {
                    isBlurred = true;
                }
            };
            timerId = setTimeout(() => {
                if (!isBlurred) {
                    openSnackbar({
                        type: 'error',
                        autoClose: false,
                        message: `You need the new version of the Copper Unlimited app to sign this ${isWalletMessageOrderType ? 'message' : 'transaction'}. ` +
                            'Please download Copper Unlimited New and try again.',
                        action: _jsx(AppDownloadButton, { size: "s", type: "newCustody" })
                    });
                }
            }, 1000);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const needHalfSigning = isVaultPortfolio(order.portfolioType) &&
        !disabled &&
        checkPermissions(['start-custody-settlement'], order.portfolioId) &&
        (order.orderType === 'deposit'
            ? order.status === 'rejected'
            : order.status === 'awaiting-settlement' || order.status === 'working');
    const needFullSigning = isVaultPortfolio(order.portfolioType) &&
        !disabled &&
        !order.extra?.additionalPartSigned &&
        checkPermissions(['upload-order-part-signed-tx', 'upload-order-full-signed-tx'], order.portfolioId) &&
        ['part-signed-tx-added', 'rejected-part-signed-tx-added'].includes(order.status) &&
        isAdmin;
    const handleSignButtonClick = async () => {
        if (signingFlowType === 'krypton') {
            await startKryptonSigning();
        }
        else {
            needFullSigning ? startFullSigning() : await startHalfSigning();
        }
    };
    if (!needHalfSigning && !needFullSigning) {
        return null;
    }
    return _jsx(StartSettlementButton, { onSign: handleSignButtonClick, order: order, type: type });
};
export { StartSettlement };
