import { request } from '@copper/utils';
const normalizeApprovalRequestsParams = ({ actionNames, requestStatuses, ...params }) => ({
    ...params,
    actionNames: actionNames && actionNames?.join(','),
    requestStatuses: requestStatuses && requestStatuses?.join(',')
});
export const getApprovalRequests = (params) => request.get('/approval-requests', {
    params: normalizeApprovalRequestsParams(params)
});
export const updateApprovalRequests = (data) => request.patch('/approval-requests', data, {
    headers: {
        'Content-type': 'application/vnd.bulk-review-requests+json'
    }
});
export const updateApprovalRequest = (requestId, data) => request.patch(`/approval-requests/${requestId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.review-approval-request+json'
    }
});
export const getApprovalRequestDetails = (approvalRequestId) => request.get(`/approval-requests/${approvalRequestId}?includeApprovalAudits=true&includePolicyStages=true`);
export const updateApprovalRequestReview = (approvalRequestId, data) => request.patch(`/approval-requests/${approvalRequestId}`, data, {
    headers: {
        'Content-type': 'application/vnd.review-approval-request+json'
    }
});
export const cancelApprovalRequest = (approvalRequestId) => request.patch(`/approval-requests/${approvalRequestId}`, {}, {
    headers: {
        'Content-type': 'application/vnd.cancel-request+json'
    }
});
