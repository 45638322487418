export const LOAN_FORM_TITLES = {
    initial: 'Lend',
    bilateralPledge: 'Bilateral lending (old)',
    bilateral: 'Bilateral lending',
    agency: 'Agency lending',
    lender: 'Agency lending'
};
export const MATURITY_DATE_DEFINITION = 'The loaned funds may not be recalled for the duration of this term.';
export const LOAN_TYPES_MAP = {
    'agency-lending-collateralised-child-loan': {
        value: 'agency-lending-collateralised-child-loan',
        label: 'Collateralised',
        lendingType: 'Agency lending',
        isAgencyChildLoan: true,
        isBilateral: false,
        tooltipInfo: 'A collateralised loan is secured by collateral transferred by the borrower to Copper. There are no limitations on how the loan is used.'
    },
    'agency-lending-first-lien-child-loan': {
        value: 'agency-lending-first-lien-child-loan',
        label: 'First-lien',
        isAgencyChildLoan: true,
        isBilateral: false,
        lendingType: 'Agency lending',
        tooltipInfo: 'A first-lien loan is subordinated against the borrower’s ClearLoop holdings. The borrower receives the loan in a ClearLoop account and can only use the funds within ClearLoop.'
    },
    'agency-lending-parent-loan': {
        value: 'agency-lending-parent-loan',
        label: '',
        lendingType: 'Agency lending',
        isAgencyChildLoan: false,
        isBilateral: false
    },
    'bilateral-lending-title-transfer-collateralised-loan': {
        value: 'bilateral-lending-title-transfer-collateralised-loan',
        lendingType: 'Bilateral lending',
        label: 'Title transfer',
        isAgencyChildLoan: false,
        isBilateral: true
    }
};
