import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router';
import { FormResultFrame, FormResultHeading } from '@copper/components';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { Button } from '@copper/ui-kit';
import s from './Result.module.css';
export const Result = ({ transaction, onDone }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleButtonClick = () => {
        navigate({
            pathname,
            search: `orderId=${transaction.orderId}`
        });
    };
    return (_jsxs(FormResultFrame, { status: "signature", onDone: onDone, children: [_jsx(FormResultHeading, { status: "signature", children: "Pending signature" }), _jsxs("div", { className: s.container, children: [_jsx("h2", { className: s.title, children: "Almost there" }), _jsx("p", { className: s.description, children: "To confirm your operation, please sign this transaction." }), _jsx(Button, { size: "l", text: "Sign", fullWidth: true, onClick: handleButtonClick }), _jsx(CancelOrder, { order: transaction })] })] }));
};
