import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { InfoAccount, InfoRow, PortfolioIcon } from '@copper/components';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { getCurrencyName } from '@copper/helpers';
import { checkRequiredNetwork } from '@copper/helpers/validation';
import { formatRuleAmount, getCurrencyCode } from './helpers';
import s from '@copper/client/components/Rules/Rules.module.css';
export const Summary = ({ values, watch }) => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const { portfolio, amountThreshold, balanceType, currency, transferPortfolio, transferMinBalance, targetBalanceLimit, mainCurrency, amountBy } = values;
    const ruleType = watch('ruleType');
    const currencyCode = getCurrencyCode(currency, amountBy);
    const formattedAmountThreshold = formatRuleAmount(amountThreshold, currencyCode);
    const formattedTransferMinBalance = formatRuleAmount(transferMinBalance, currencyCode);
    const formattedTargetBalanceLimit = formatRuleAmount(targetBalanceLimit, currencyCode);
    return (_jsxs("div", { className: s.summary, children: [_jsx(InfoRow, { title: `If the ${currencyCode === 'USD' ? currency?.currency : ''} balance for`, titleColor: "ghost", children: portfolio && _jsx(InfoAccount, { account: portfolio }) }), _jsx(InfoRow, { title: ruleType === 'below' ? 'Is below' : 'Is above', titleColor: "ghost", children: _jsx("div", { className: s.rowData, children: _jsxs("div", { children: [_jsx("div", { className: s.subTitle, children: `${formattedAmountThreshold} ${currencyCode}` }), _jsx("div", { className: s.subTitleUnder, children: balanceType === 'available' ? 'Available balance' : 'Total balance' })] }) }) }), _jsx(InfoRow, { title: `Transfer ${ruleType === 'above' ? 'to' : 'from'}`, titleColor: "ghost", children: transferPortfolio && _jsx(InfoAccount, { account: transferPortfolio }) }), ruleType === 'below' && (_jsx(InfoRow, { title: "Leaving a min of", titleColor: "ghost", children: _jsx("div", { className: s.rowData, children: _jsx("div", { children: _jsx("div", { className: s.subTitle, children: `${formattedTransferMinBalance} ${currencyCode}` }) }) }) })), currency &&
                currency.mainCurrencies?.length > 1 &&
                checkRequiredNetwork({ origin: transferPortfolio, destination: portfolio }) && (_jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsx("div", { className: s.rowData, children: _jsxs("div", { children: [_jsx("div", { className: s.subTitle, children: mainCurrency }), _jsx("div", { className: s.subTitleUnder, children: mainCurrency && getCurrencyName(mainCurrency, currenciesEntities) })] }) }) })), _jsx(InfoRow, { titleColor: "ghost", className: s.summaryLastRow, title: _jsxs("div", { children: [_jsxs("div", { className: s.rowData, children: ["Until", _jsxs("div", { className: s.portfolioRow, children: [_jsx(PortfolioIcon, { portfolio: portfolio ? portfolio : 'all' }), _jsx("div", { className: s.subTitle, children: portfolio?.portfolioName || 'All accounts' })] })] }), "balance reaches"] }), children: _jsx("div", { className: s.rowData, children: _jsx("div", { children: _jsx("div", { className: s.subTitle, children: `${formattedTargetBalanceLimit} ${currencyCode}` }) }) }) })] }));
};
