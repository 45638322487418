import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { selectCounterpartiesEntities, selectCounterpartiesEntitiesByOrgIds } from '@copper/entities/counterparties/counterparties-selector';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { getCurrencyName, getMainCurrencies, getQuoteAmount, isMultiNetworkCurrencyOrToken } from '@copper/helpers';
import s from './SettlementInformation.module.css';
export const SettlementInformation = ({ order, viewByAdmin }) => {
    const { organizationName } = useSelector(selectCurrentOrganization);
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const counterparties = useSelector(selectCounterpartiesEntities);
    const counterpartiesEntitiesByOrgId = useSelector(selectCounterpartiesEntitiesByOrgIds);
    const recipientName = viewByAdmin
        ? counterpartiesEntitiesByOrgId[order?.organizationId ?? '']?.counterpartyName
        : organizationName;
    const fromCounterparty = counterparties[(order.portfolioType === 'settlement-lite'
        ? order?.extra?.fromCounterpartyId
        : order.extra?.toCounterpartyId) ?? ''];
    const initializerReceiveAmount = order.orderType === 'buy' ? getQuoteAmount(order) : order.amount;
    const initializerReceiveCurrency = order.orderType === 'buy' ? order.quoteCurrency : order.baseCurrency;
    const counterpartyReceiveAmount = order.orderType === 'buy' ? order.amount : getQuoteAmount(order);
    const counterpartyReceiveCurrency = order.orderType === 'buy' ? order.baseCurrency : order.quoteCurrency;
    const initializerReceiveMainCurrency = order.orderType === 'buy' ? order.quoteMainCurrency : order.mainCurrency;
    const counterpartyReceiveMainCurrency = order.orderType === 'buy' ? order.mainCurrency : order.quoteMainCurrency;
    return (_jsxs("div", { children: [_jsxs("div", { className: s.textContainer, children: [_jsx("div", { className: s.subtitle, children: "Settlement information:" }), _jsxs("div", { className: s.textContainer, children: [_jsxs("div", { className: s.text, children: [fromCounterparty?.counterpartyName ?? 'A counterparty', " to receive", ' ', initializerReceiveAmount, " ", initializerReceiveCurrency, initializerReceiveCurrency &&
                                        initializerReceiveMainCurrency &&
                                        isMultiNetworkCurrencyOrToken(initializerReceiveCurrency, initializerReceiveMainCurrency, getMainCurrencies(currenciesEntities, initializerReceiveCurrency)) &&
                                        ` on
            the ${getCurrencyName(initializerReceiveMainCurrency, currenciesEntities)} Network`] }), _jsxs("div", { className: s.text, children: [recipientName, " to receive ", counterpartyReceiveAmount, " ", counterpartyReceiveCurrency, counterpartyReceiveCurrency &&
                                        counterpartyReceiveMainCurrency &&
                                        isMultiNetworkCurrencyOrToken(counterpartyReceiveCurrency, counterpartyReceiveMainCurrency, getMainCurrencies(currenciesEntities, counterpartyReceiveCurrency)) &&
                                        ` on
            the ${getCurrencyName(counterpartyReceiveMainCurrency, currenciesEntities)} Network`] })] })] }), _jsxs("div", { className: s.textContainer, children: [_jsx("div", { className: s.subtitle, children: "Method" }), _jsx("div", { children: order.extra?.deliveryType === 'free-of-payment'
                            ? 'Free of Payment'
                            : 'Payment versus Payment' })] })] }));
};
