import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyFallback } from '@copper/components/EmptyFallback';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { getAmountInReporting } from '@copper/helpers';
import { isSettleCreatedByAdmin } from '@copper/helpers';
import { formatCurrency, formatFiat, toSignedOrderAmount } from '@copper/utils';
import s from './amount.module.css';
const TransferAmount = ({ order, title = 'Amount' }) => {
    const amount = order.amount;
    const baseCurrency = 'baseCurrency' in order ? order.baseCurrency : order.currency;
    const orderType = 'orderType' in order ? order.orderType : 'deposit';
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const rates = useSelector(selectRates);
    const formattedAmount = toSignedOrderAmount(currenciesEntities[baseCurrency]?.fiat
        ? formatFiat(amount, { currencyCode: baseCurrency })
        : formatCurrency(amount, { decimalPlaces: 8 }), orderType);
    const formattedFiat = isSettleCreatedByAdmin(order)
        ? ''
        : formatFiat(getAmountInReporting(order, rates[baseCurrency]));
    return (_jsxs("div", { children: [_jsxs("div", { className: s.description, children: [title, " (", _jsx(EmptyFallback, { children: baseCurrency }), ")"] }), _jsx("div", { className: s.amount, children: _jsx(EmptyFallback, { children: amount ? formattedAmount : '' }) }), !currenciesEntities[baseCurrency]?.fiat && (_jsx("div", { className: s.fiatAmount, children: _jsx(EmptyFallback, { children: amount ? formattedFiat : '' }) }))] }));
};
export { TransferAmount };
