export async function asyncPoll(requestFn, pollInterval = 3000, pollTimeout = 60000) {
    const endTime = Number(new Date()) + pollTimeout;
    const checkCondition = (resolve, reject) => {
        const request = requestFn();
        request
            .then((response) => {
            if (String(response.status).startsWith('2')) {
                resolve(response.data);
            }
        })
            .catch(() => {
            if (Number(new Date()) < endTime) {
                setTimeout(checkCondition, pollInterval, resolve, reject);
            }
            else {
                reject(new Error('Polling reached timeout'));
            }
        });
    };
    return new Promise(checkCondition);
}
