import { request } from '@copper/utils';
export const create = (params) => request.post('/orders', params);
export const createBoundedToken = (orderId) => request.post(`/orders/${orderId}/bounded-tokens`);
export const bulkCreate = (orders) => request.post('/orders', { orders }, {
    timeout: 300000,
    headers: {
        'Content-Type': 'application/vnd.create-orders+json'
    }
});
export const get = (reqParams) => request.get('/orders', { params: reqParams });
export const getById = (id) => request.get(`/orders/${id}`);
export const rejectOrder = (data, id) => request.patch(`/orders/${id}`, data, {
    headers: {
        'Content-type': 'application/vnd.reject-order+json'
    }
});
export const getChangesById = (id, queryString = '') => request.get(`/orders/${id}/changes${queryString}`);
export const startCustodySettlement = (id) => request.patch(`/orders/${id}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.start-custody-settlement+json'
    }
});
export const startOtcSettlement = (data) => request.patch(`/orders/${data.orderId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.start-settlement+json'
    }
});
export const fiatSettlementFundsSent = (data) => request.patch(`/orders/${data.orderId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.funds-sent+json'
    }
});
export const fiatSettlementFundsReceived = (data) => request.patch(`/orders/${data.orderId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.funds-received+json'
    }
});
export const sendHalfSignedTx = (order, data, reject = false) => request.patch(`/orders/${order.orderId}`, data, {
    headers: {
        'Content-Type': reject
            ? `application/vnd.upload-part-signed-reject-${order.orderType === 'wallet-message' ? 'wallet-message' : 'tx'}+json`
            : `application/vnd.upload-part-signed-${order.orderType === 'wallet-message' ? 'wallet-message' : 'tx'}+json`
    }
});
export const sendFullSignedTx = (order, data, reject = false) => request.patch(`/orders/${order.orderId}`, data, {
    headers: {
        'Content-Type': reject
            ? `application/vnd.upload-full-signed-reject-${order.orderType === 'wallet-message' ? 'wallet-message' : 'tx'}+json`
            : `application/vnd.upload-full-signed-${order.orderType === 'wallet-message' ? 'wallet-message' : 'tx'}+json`
    }
});
export const dryRun = (data) => request.post('/dry-run-orders', data);
export const cancel = (id) => request.patch(`/orders/${id}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel-order+json'
    }
});
export const reject = (id, data = {}) => request.patch(`/orders/${id}`, data, {
    headers: {
        'Content-Type': 'application/vnd.reject-order+json'
    }
});
export const cosign = (id, data) => request.patch(`/orders/${id}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-order-co-signers+json'
    }
});
export const approve = ({ orderId }) => request.patch(`/orders/${orderId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.approve-order+json'
    }
});
export const approveOtc = ({ orderId, status, portfolioIdOverride }) => request.patch(`/orders/${orderId}`, { ...(portfolioIdOverride && { portfolioIdOverride }) }, {
    headers: {
        'Content-Type': `application/vnd.approve-${status === 'require-initializer-approve' ? 'initializer' : 'counterparty'}-otc+json`
    }
});
export const setOrderAddresses = ({ orderId, addressData }) => request.patch(`/orders/${orderId}`, addressData, {
    headers: {
        'Content-Type': 'application/vnd.upload-order-addresses+json'
    }
});
export const acceptOrderAddresses = ({ orderId, acceptedData }) => request.patch(`/orders/${orderId}`, acceptedData, {
    headers: {
        'Content-Type': acceptedData.accepted
            ? 'application/vnd.accept-order-addresses+json'
            : 'application/vnd.decline-order-addresses+json'
    }
});
export const enterMasterPassword = ({ orderId, masterPassword }) => request.patch(`/orders/${orderId}`, { masterPassword }, {
    headers: {
        'Content-Type': 'application/vnd.enter-master-password+json'
    }
});
export const bulkSettleOperation = ({ orderIds, type = 'approve-settle' }) => request.patch('/orders/', { orderIds }, {
    headers: {
        'Content-Type': `application/vnd.${type}-orders+json`
    }
});
export const bulkCoSignOperation = ({ orderIds, masterPassword }) => request.patch('/orders/', { orderIds, masterPassword }, {
    headers: {
        'Content-Type': `application/vnd.co-sign-orders+json`
    }
});
export const chargeExecutionFees = (orderId) => request.patch(`/orders/${orderId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.charge-execution-fees+json'
    }
});
export const recreateOrder = (orderId) => request.patch(`/orders/${orderId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.recreate-transaction-request+json'
    }
});
export const getTransactionFlowSummary = (params) => request.get(`/calculations/orders-summaries`, { params });
export const replaceByFee = ({ orderId, ...data }) => request.patch(`/orders/${orderId}`, {
    ...data
}, {
    headers: {
        'Content-Type': 'application/vnd.replace-by-fee+json'
    }
});
