import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ExchangeAccountFields } from '@copper/components/Forms/ExchangeAccountFields';
import { selectExchangesEntities } from '@copper/entities/exchanges/exchanges-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { fetchPortfolios, updateExternalPortfolio } from '@copper/entities/portfolios/portfolios-reducer';
import { fetchWallets } from '@copper/entities/wallets/wallets-reducer';
import { hasClearloopAutoActivation, hasExchangeAutoActivation } from '@copper/helpers/exchanges';
import { Button, FormControl, FormLabel, Input, Popup, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './EditExchangeForm.module.css';
const EditExchangeForm = ({ portfolio, onClose }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const exchanges = useSelector(selectExchangesEntities);
    const { organizationId } = useSelector(selectCurrentOrganization);
    const exchange = exchanges[portfolio.extra?.exchange ?? ''];
    const exchangeAccount = exchange?.exchangeAccounts.find((item) => item.accountType === portfolio.extra?.exchangeAccountType);
    const { control, register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            name: portfolio.portfolioName
        }
    });
    const onSubmit = async (values) => {
        try {
            const additionalData = values.exchangeAccountFields.reduce((acc, field) => {
                const accKey = Object.keys(acc)[0];
                const fieldKey = Object.keys(field)[0];
                // This is to check if the key (field name) doesn't exist or has an empty value
                // to remove it from the request payload to only send the edited values.
                if (!acc.hasOwnProperty(accKey) || acc[accKey] === '') {
                    return { ...field };
                }
                if (!field.hasOwnProperty(fieldKey) || field[fieldKey] === '') {
                    return acc;
                }
                if (Object.keys(acc).length === 0 && Object.keys(field).length === 0) {
                    return {};
                }
                return { ...acc, ...field };
            }, {});
            const data = {
                portfolioId: portfolio.portfolioId,
                portfolioName: values.name ?? '',
                ...additionalData
            };
            await dispatch(updateExternalPortfolio(data)).unwrap();
            if (hasExchangeAutoActivation(exchange) || hasClearloopAutoActivation(exchange)) {
                // fetch changes for current and parent portfolios in auto-discovery
                await Promise.all([
                    dispatch(fetchPortfolios({ organizationId })).unwrap(),
                    dispatch(fetchWallets({ organizationId, nonEmpty: true })).unwrap()
                ]);
            }
            else {
                await dispatch(fetchPortfolios({ organizationId })).unwrap();
            }
            onClose();
            openSnackbar({ type: 'success', message: 'Your changes have been saved.' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    return (_jsxs(Popup, { onClose: onClose, children: [_jsx("header", { className: s.header, children: _jsxs("h2", { className: s.title, children: ["Edit ", portfolio.portfolioName, " account"] }) }), _jsxs("div", { className: s.content, children: [_jsxs("div", { className: s.description, children: ["Please provide additional information to connect ", portfolio.portfolioName, " account."] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(FormControl, { errorMessage: errors?.name?.message, children: [_jsx(FormLabel, { children: "Account name" }), _jsx(Input, { size: "l", ...register('name', { required: 'Enter an account name.' }), maxLength: 128 })] }), _jsx(ExchangeAccountFields, { control: control, name: "exchangeAccountFields", exchangeAccount: exchangeAccount, errors: errors.exchangeAccountFields, type: "edit" }), _jsx("div", { className: s.buttonContainer, children: _jsx(Button, { type: "submit", text: "Next" }) })] })] })] }));
};
export { EditExchangeForm };
