import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const TON = {
    title: 'TON',
    id: 'ton',
    qa: [
        {
            question: 'How do I stake TON?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake TON?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the unbonding period." }), _jsx("li", { children: "At the end of the unbonding period, claim your unstaked assets from the Pending tab on your dashboard, and sign once more to confirm." })] }) }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for TON?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "The minimum staking amount varies based on the pool you are staking to. This amount will be indicated at the time of staking. There is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "Variable" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn TON staking rewards?',
            answer: (_jsx(_Fragment, { children: _jsx("p", { children: "TON rewards are usually issued every 36 to 48 hours. However, the exact reward frequency depends on the smart contract you are staking to." }) }))
        },
        {
            question: 'How can I claim my TON staking rewards?',
            answer: (_jsx("p", { children: "Rewards for TON are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }))
        }
    ]
};
