import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ButtonIcon, IconArrowBack } from '@copper/ui-kit';
import s from './PageSwitcher.module.css';
const PageSwitcher = ({ hasMorePages, isFirstPage, onClickNext, onClickPrev }) => {
    if (isFirstPage && !hasMorePages) {
        return null;
    }
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.arrowLeft, children: _jsx(ButtonIcon, { disabled: isFirstPage, onClick: onClickPrev, tooltipLabel: "Previous page", children: _jsx(IconArrowBack, {}) }) }), _jsx("div", { className: s.arrowRight, children: _jsx(ButtonIcon, { disabled: !hasMorePages, onClick: onClickNext, tooltipLabel: "Next page", children: _jsx(IconArrowBack, {}) }) })] }));
};
export { PageSwitcher };
