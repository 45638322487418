import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { upperFirst } from 'lodash-es';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { AssetInfo } from '@copper/components/AssetInfo';
import { EmptyFallback } from '@copper/components/EmptyFallback';
import { Popup, PopupHeader } from '@copper/ui-kit';
import { SettlementDryOrderEditForm } from './EditForm';
import { SettlementDryOrderPreview } from './Preview';
import s from './Popup.module.css';
const BulkSettlementPopup = () => {
    const { modalContext, setModalContext, modalOrderData } = useBulkOrder();
    return (_jsxs(Popup, { onClose: () => setModalContext(), children: [_jsxs(PopupHeader, { rightSection: modalContext?.mode === 'read' && (_jsx(AssetInfo, { currency: modalOrderData?.baseCurrency ?? '', secondCurrency: modalOrderData?.quoteCurrency ?? '', align: "right" })), children: [modalContext?.mode === 'read' && (_jsxs("div", { children: [_jsx("div", { className: s.headerTitle, children: _jsx(EmptyFallback, { children: upperFirst(modalOrderData?.orderType) }) }), _jsx("div", { className: s.headerSubTitle, children: "Settle" })] })), modalContext?.mode === 'edit' && _jsx("div", { className: s.headerTitle, children: "Edit settle" })] }), modalOrderData && modalContext?.mode === 'read' && _jsx(SettlementDryOrderPreview, {}), modalOrderData && modalContext?.mode === 'edit' && _jsx(SettlementDryOrderEditForm, {})] }));
};
export { BulkSettlementPopup };
