import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const ICP = {
    title: 'ICP',
    id: 'icp',
    qa: [
        {
            question: 'How do I stake ICP?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: _jsx("i", { children: "Step 1 \u2013 Creating a neuron" }) }), _jsx("p", { className: s.spaceAfter, children: "From the dashboard, select Stake in the side toolbar." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 1 ICP)." }), _jsx("li", { children: "Select the end date of your staking term (minimum term: 6 months)." }), _jsx(Alert, { type: "info", message: "Your assets will be locked up for the duration of the staking term." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Step 2 \u2013 Choosing validators" }) }), _jsx("p", { className: s.spaceAfter, children: "Next, choose one or more validators to stake to \u2013 or neurons to follow, as they are referred to in the ICP governance system." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On your dashboard, go to the Outstanding tab and select Add validator." }), _jsx("li", { children: "Enter the neuron ID of the neuron you\u2019d like to follow in the Validator address field." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Once broadcast, your stake will appear in the Active tab. " }) })] }))
        },
        {
            question: 'How do I unstake ICP?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "At the end of your chosen staking term, follow these steps to unstake your ICP:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Go to the Outstanding tab to claim the available amount, and sign one last time to confirm." })] })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for ICP?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, there is a minimum staking amount of 1 ICP, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "1 ICP" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'Is there a minimum or maximum staking time?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking term is 6 months and the maximum is 8 years." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum term" }) }), _jsx("td", { className: s.td, children: "6 months" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum term" }) }), _jsx("td", { className: s.td, children: "8 years" })] })] }) })] }))
        },
        {
            question: 'How often will I earn ICP staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "ICP rewards are issued daily." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my ICP staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your ICP rewards at the end of the staking period by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your ICP staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the 7-day unbonding period." }), _jsx("li", { children: "Go to the Outstanding tab to claim the available amount, and sign one last time to confirm." })] }), _jsx("p", { className: s.spaceBefore, children: "Your assets will then be released into your staking vault." })] }))
        }
    ]
};
