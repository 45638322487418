import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ActiveLoanListItem } from '@copper/components/Loans/LoanList/ActiveLoanList/ActiveLoanListItem';
import { Table, TableEmptyRow, Tbody, Th, Thead } from '@copper/ui-kit';
import s from '@copper/components/Loans/LoanList/LoanList.module.css';
const ActiveLoanList = ({ group, loans, onLoanSelected, view }) => {
    const isBorrowingTab = group === 'borrowing';
    const isRequestsTab = group === 'requests';
    const getEmptyText = () => {
        switch (group) {
            case 'requests':
                return 'No loans need attention';
            default:
                return 'No active loans';
        }
    };
    return (_jsxs(Table, { withTabs: !isRequestsTab, noBottomRounding: true, children: [_jsxs(Thead, { children: [_jsx(Th, { width: "10%", className: s.contentAlign, children: "Asset" }), _jsx(Th, { align: "right", width: "10%", children: "Amount" }), _jsx(Th, { align: "right", width: "10%", className: s.contentAlign, children: "Collateral" }), _jsx(Th, { width: "10%", children: "Term" }), _jsx(Th, { align: "left", children: isRequestsTab ? 'Counterparty' : isBorrowingTab ? 'Lender' : 'Borrower' }), _jsx(Th, { align: "right", width: "10%", children: "Health" }), _jsx(Th, { align: "right", width: "10%", children: "Status" })] }), _jsx(Tbody, { children: loans?.length > 0 ? (_jsx(_Fragment, { children: loans.map((loan) => (_jsx(ActiveLoanListItem, { loan: loan, onClick: () => onLoanSelected(loan), view: view }, loan.loanId))) })) : (_jsx(TableEmptyRow, { children: _jsx("div", { className: s.empty, children: getEmptyText() }) })) })] }));
};
export { ActiveLoanList };
