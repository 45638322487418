import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STANDARD_FOP_DISCLAIMER_ID } from '@copper/constants/disclaimers';
import { approveOtcOrder, cancelOrder } from '@copper/entities/orders/orders-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useDisclaimer } from '@copper/hooks';
import { Button, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { useOrderContext } from '../../context-hook';
export const ApproveOtcControls = ({ order, size = 'l', onSuccess, fopTermsAccepted, isFopSettlement }) => {
    const [approveIsBusy, setApproveIsBusy] = useState(false);
    const [rejectIsBusy, setRejectIsBusy] = useState(false);
    const { handleAcceptDisclaimer, disclaimer: disclaimerData, checkDisclaimer } = useDisclaimer(STANDARD_FOP_DISCLAIMER_ID);
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { popupContext } = useOrderContext();
    const onApprove = async () => {
        setApproveIsBusy(true);
        const isLite = order.portfolioType === 'settlement-lite';
        try {
            if (!isLite && isFopSettlement && !disclaimerData?.accepted) {
                if (!fopTermsAccepted) {
                    openSnackbar({
                        type: 'warning',
                        message: 'You need to accept the legal disclaimer about FoP'
                    });
                    return;
                }
                await handleAcceptDisclaimer();
            }
            await dispatch(approveOtcOrder({ order, portfolioIdOverride: popupContext.otcPortfolioIdOverride })).unwrap();
            openSnackbar({ type: 'success', message: 'Settlement initiated' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            onSuccess && onSuccess();
            setApproveIsBusy(false);
        }
    };
    const onReject = async () => {
        setRejectIsBusy(true);
        try {
            await dispatch(cancelOrder({ orderId: order.orderId })).unwrap();
            openSnackbar({ type: 'success', message: 'Order declined' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            onSuccess && onSuccess();
            setRejectIsBusy(false);
        }
    };
    useEffect(() => {
        organizationId && checkDisclaimer();
    }, [organizationId]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { loading: rejectIsBusy, onClick: onReject, view: "decline", text: "Decline", size: size }), _jsx(Button, { loading: approveIsBusy, onClick: onApprove, view: "positive", text: "Accept", size: size })] }));
};
