import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ENTERED, ENTERING, EXITED, EXITING, UNMOUNTED } from 'react-transition-group/Transition';
import cn from 'classnames';
import { TransitionAnimation } from '@copper/ui-kit';
import { Context } from './context';
import s from './Collapse.module.css';
const collapseStyles = {
    [EXITED]: s.hidden,
    [EXITING]: s.collapsing,
    [ENTERING]: s.collapsing,
    [ENTERED]: '',
    [UNMOUNTED]: ''
};
const Collapse = ({ renderToggle, children, open = false, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(open);
    const nodeRef = useRef(null);
    const handleOpenContent = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    useEffect(() => {
        setIsOpen(disabled ? true : open);
    }, [disabled, open]);
    const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleOpenContent();
        }
    }, [handleOpenContent]);
    const handleEnter = (elem) => {
        elem.style.height = '0';
    };
    const handleEntered = (elem) => {
        setIsOpen(true);
        elem.style.removeProperty('height');
    };
    const handleEntering = (elem) => {
        elem.style.height = `${elem['scrollHeight']}px`;
    };
    const handleExit = (elem) => {
        elem.style.height = `${elem['scrollHeight']}px`;
        elem.offsetHeight; // trigger browser reflow
    };
    const handleExiting = (elem) => {
        elem.style.removeProperty('height');
    };
    return (_jsx(Context.Provider, { value: { isOpen, handleToggleCollapse: handleOpenContent }, children: _jsxs(_Fragment, { children: [renderToggle(isOpen, handleOpenContent, onKeyDown), _jsx(TransitionAnimation, { inProp: isOpen || disabled, onEnter: handleEnter, onEntered: handleEntered, onEntering: handleEntering, onExit: handleExit, onExiting: handleExiting, nodeRef: nodeRef, children: (state) => (_jsx("div", { ref: nodeRef, className: cn(s.content, collapseStyles[state]), children: children })) })] }) }));
};
export { Collapse };
