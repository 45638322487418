import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import { FormResultFrame } from '@copper/components';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { ButtonLink } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import { useFormContext } from 'react-hook-form';
import s from './Success.module.css';
export const Success = ({ onDone, onRepeat, loanId }) => {
    const navigate = useNavigate();
    const { getValues } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const { amount, currency, collateralAmount, collateralCurrency } = getValues();
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.frameContainer, children: _jsxs(FormResultFrame, { status: "success", onDone: onDone, children: [_jsx("p", { className: s.title, children: "Your loan has been booked" }), _jsxs("p", { className: s.loanAmount, children: [formatCurrency(amount || '0', {
                                    decimalPlaces: getDecimals(currency.currency, { useDictionaryDecimal: true })
                                }), ' ', currency.currency] }), _jsxs("p", { className: s.collateralAmount, children: ["Collateral", ' ', formatCurrency(collateralAmount || '0', {
                                    decimalPlaces: getDecimals(collateralCurrency?.currency, {
                                        useDictionaryDecimal: true
                                    })
                                }), ' ', collateralCurrency?.currency] })] }) }), _jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "View loan details", onClick: () => navigate(`/accounts/all/lending?loanId=${loanId}`) }) }) }), _jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "Repeat loan", onClick: onRepeat }) }) })] }));
};
