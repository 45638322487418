import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
export const useTotalCollateral = () => {
    const currenciesRates = useSelector(selectRates);
    const calculateTotalCollateral = (collaterals) => collaterals.reduce((acc, collateral) => {
        const rate = currenciesRates[collateral.currency] ?? 1;
        const collateralMarketValue = new BigNumber(collateral.amount).times(rate);
        return new BigNumber(acc).plus(collateralMarketValue).toFixed();
    }, '0');
    return {
        calculateTotalCollateral
    };
};
