import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { fetchPortfolios, updatePortfolio } from '@copper/entities/portfolios/portfolios-reducer';
import { Button, FormControl, FormLabel, Input, Popup, PopupHeader, useSnackBar } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { PopupFooter } from '@copper/ui-kit/Popup/PopupFooter';
import { getErrorData } from '@copper/utils';
const EditPortfolioNamePopup = ({ onBack, portfolio }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            name: portfolio?.portfolioName
        }
    });
    const onSubmit = async (values) => {
        try {
            await dispatch(updatePortfolio({
                portfolioId: portfolio?.portfolioId ?? '',
                portfolioName: values.name
            })).unwrap();
            await dispatch(fetchPortfolios({ organizationId })).unwrap();
            onBack();
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    if (!portfolio)
        return null;
    return (_jsxs(Popup, { onClose: onBack, children: [_jsx(PopupHeader, { onBack: onBack, children: "Edit name" }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(PopupContent, { children: _jsxs(FormControl, { errorMessage: errors?.name?.message, children: [_jsx(FormLabel, { children: "Account name" }), _jsx(Input, { size: "l", ...register('name', { required: 'Enter an account name.' }), maxLength: 128, testId: "account-name" })] }) }), _jsx(PopupFooter, { rightSection: _jsx(Button, { type: "submit", text: "Save" }) })] })] }));
};
export { EditPortfolioNamePopup };
