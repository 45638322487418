import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import cuid from 'cuid';
import { Snack } from './Snack';
import { SnackbarContext } from './SnackbarContext';
import s from './Snackbar.module.css';
const DEFAULT_OPTIONS = {
    autoClose: true,
    duration: 4000,
    message: '',
    type: 'error'
};
const SnackBarProvider = ({ children, right, top, listPosition }) => {
    const [snackbarItems, setSnackbarItems] = useState([]);
    const openSnackbar = useCallback((options) => {
        const newItem = {
            ...DEFAULT_OPTIONS,
            ...options,
            id: cuid()
        };
        setSnackbarItems((state) => [...state, newItem]);
    }, []);
    const hideSnackbar = useCallback((id) => {
        setSnackbarItems((state) => [...state.filter((item) => item.id !== id)]);
    }, []);
    return (_jsxs(SnackbarContext.Provider, { value: { openSnackbar }, children: [_jsx("div", { className: s.list, style: {
                    ...(right && { right }),
                    ...(top && { top }),
                    ...(listPosition && { position: listPosition })
                }, children: snackbarItems.map((item) => (_jsx(Snack, { onClose: hideSnackbar, item: item }, item.id))) }), children] }));
};
export { SnackBarProvider };
