import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const SOL = {
    title: 'SOL',
    id: 'sol',
    qa: [
        {
            question: 'How do I stake SOL?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 0.01 SOL)." }), _jsx("li", { children: "Choose a validator to stake with from 3 options: Copper validator, inputting another validator address, or staking through Marinade." }), _jsx(Alert, { message: "Marinade Native is a staking service offering an automated and optimised delegation strategy for your staked Solana. For more information, see 'How do I stake SOL through Marinade?'.", type: "info" }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the bonding period (also known as a warm-up period on Solana)." })] }))
        },
        {
            question: 'How do I unstake SOL?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "If you are staking through Marinade, specify the amount to unstake. " }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 2-4 day unbonding period." }), _jsx("li", { children: "At the end of the unbonding period, claim your unstaked assets from the Outstanding tab on your dashboard, and sign once more to confirm." })] }) }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for SOL?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 0.01 SOL (and with Marinade, 1 SOL), but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "0.01 SOL (or 1 SOL with Marinade) " })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn SOL staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Rewards are issued every epoch (approximately every two days). Some validators may distribute additional, MEV (Maximal Extractable Value) rewards. The time and frequency of these additional reward payments are at the discretion of the validator." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 2 days" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Additional payouts" }) }), _jsx("td", { className: s.td, children: "Variable frequency" })] })] }) })] }))
        },
        {
            question: 'How can I claim my SOL staking rewards?',
            answer: (_jsx("p", { children: "Rewards for SOL are automatically restaked. You will need to unstake assets to claim rewards. See 'How do I unstake SOL?'." }))
        },
        {
            question: 'What is Marinade Native?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Marinade Native is a fee-free staking tool offering an automated and optimised delegation strategy for your staked SOL." }), _jsx("p", { className: s.spaceAfter, children: _jsx("i", { children: "How it works" }) }), _jsx("p", { className: s.spaceAfter, children: "Marinade Native will manage your position while you retain control over the assets. The only action this tool is allowed to perform on your behalf is to delegate your stake to different validators." }), _jsx("p", { className: s.spaceAfter, children: "Your stake will be spread among top-performing validators and your position automatically monitored and rebalanced until you exit the position by unstaking." }), _jsx("p", { className: s.spaceAfter, children: "At the end of every epoch, you will receive rewards directly in your staking account, as is the case if you delegate SOL to a Copper validator or a validator of your choosing on our platform. Each epoch lasts around 2 to 3 days." }), _jsx("p", { children: "You can unstake some or all of your SOL at any time, and claim the unstaked amount at the end of the usual cool-down period." })] }))
        },
        {
            question: 'How do I stake SOL through Marinade Native?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "To stake your SOL through Marinade Native on our platform, simply select Marinade at the time of choosing a validator." }), _jsx("p", { className: s.spaceAfter, children: "Marinade Native recommends staking at least 100 SOL to have your stake fully distributed." }), _jsx("p", { children: "You can fully or partially unstake your SOL at any time, and claim the unstaked amount at the end of the usual cool-down period." })] }))
        },
        {
            question: 'What are the benefits of staking through Marinade Native?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Here are some of the key benefits of staking your Solana through Marinade Native:" }), _jsxs("ul", { className: s.ul, children: [_jsxs("li", { children: [_jsx("b", { children: "Efficient validator selection." }), " Your stake is split across top-performing validators based on the procotol\u2019s publicly available delegation strategy and eligibility criteria. For example, taking into account which validators extract and redistribute the most value to their stakers."] }), _jsxs("li", { children: [_jsx("b", { children: "Optimal position management." }), " Your position is automatically rebalanced every epoch."] }), _jsxs("li", { children: [_jsx("b", { children: "Safeguards." }), " Marinade Native identifies and protects you against risks such as loss of rewards due to downtime and commission rugging (where a validator increases their commission rate to accrue rewards intended for stakers)."] }), _jsxs("li", { children: [_jsx("b", { children: "Free of charge." }), " You will not be charged any additional fees for using Marinade Native when you stake on our platform."] })] })] }))
        },
        {
            question: 'What are the risks of staking through Marinade Native?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Marinade Native does not rely on a smart contract, therefore there are no risks associated with smart contract exposure. Solana staking through Marinade Native presents the same risks as regular staking." }), _jsxs("p", { children: ["For more information, you can refer to the protocol\u2019s delegation strategy documented on", ' ', _jsx("a", { href: "https://docs.marinade.finance", target: "_blank", rel: "noreferrer", children: "Marinade\u2019s website." })] })] }))
        }
    ]
};
