import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { rootReducer } from '@copper/store/rootReducer';
export const createStore = (preloadedState) => {
    const store = configureStore({
        preloadedState,
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) => {
            const middlewares = getDefaultMiddleware({
                serializableCheck: false
            });
            return middlewares;
        }
    });
    if (module.hot) {
        module.hot.accept('@copper/store/rootReducer', () => store.replaceReducer(rootReducer));
    }
    return store;
};
export const useAppDispatch = useDispatch;
