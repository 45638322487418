import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { Alert } from '@copper/ui-kit';
import s from './Result.module.css';
const statusTitleMap = {
    lock: 'Assets delegated',
    unlock: 'Assets undelegated'
};
const PENDING_STATUSES = [
    'pending',
    'rejected-part-signed-tx-added',
    'part-signed-tx-added',
    'waiting-approve',
    'new',
    'working'
];
export const Result = ({ errorMessage, transaction, formData, onDone }) => {
    const groupedPortfolios = useSelector(selectPortfolios);
    const { amount, currency, clearLoopType, origin } = formData;
    const isPending = PENDING_STATUSES.includes(transaction?.status || '');
    const isApprovalRequired = transaction?.status === 'co-sign-require';
    if (!currency || !origin || !amount) {
        return null;
    }
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        if ((clearLoopType === 'unlock' && isPending) || isApprovalRequired) {
            return 'pending';
        }
        return 'success';
    };
    const getHeadingText = () => {
        if (errorMessage) {
            return 'Transaction failed';
        }
        if (isApprovalRequired)
            return 'Pending approval';
        if (clearLoopType === 'unlock' && isPending) {
            return 'Unlocking';
        }
        return statusTitleMap[clearLoopType];
    };
    const parentPortfolioId = origin?.extra?.parentPortfolioId;
    if (!parentPortfolioId) {
        return null;
    }
    const parentPortfolio = groupedPortfolios[parentPortfolioId];
    if (!parentPortfolio) {
        return null;
    }
    const getResultRoute = () => {
        const originAccount = clearLoopType === 'lock' ? parentPortfolio : origin;
        const destinationAccount = clearLoopType === 'lock' ? origin : parentPortfolio;
        if (errorMessage) {
            return (_jsxs(_Fragment, { children: ["From", ' ', _jsx(NavLink, { to: `/accounts/${originAccount?.portfolioId}`, children: originAccount?.portfolioName }), ' ', "to", ' ', _jsx(NavLink, { to: `/accounts/${destinationAccount?.portfolioId}`, children: destinationAccount?.portfolioName })] }));
        }
        if (clearLoopType === 'lock' && isPending) {
            return (_jsxs(_Fragment, { children: ["Once the exchange has unlocked your assets, they'll be available in your", ' ', _jsx(NavLink, { to: `/accounts/${parentPortfolio?.portfolioId}`, children: parentPortfolio?.portfolioName }), "."] }));
        }
        if (clearLoopType === 'unlock') {
            return (_jsxs(_Fragment, { children: ["Assets are available in your", ' ', _jsx(NavLink, { to: `/accounts/${parentPortfolio?.portfolioId}`, children: parentPortfolio?.portfolioName }), "."] }));
        }
        if (isApprovalRequired) {
            return (_jsxs("div", { className: s.nextStepsWrapper, children: [_jsx("h4", { className: s.nextStepsHeading, children: "Next steps" }), _jsx("p", { className: s.nextStepsBody, children: "We've notified approvers of your transaction request. If approved, the transaction will then need to be signed by a member of your team." })] }));
        }
        return 'Assets are safely delegated.';
    };
    const status = getResultStatus();
    const headingText = getHeadingText();
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: headingText }), _jsxs(FormResultAccent, { children: [amount, " ", currency.currency] }), _jsx(FormResultRegular, { children: getResultRoute() }), errorMessage && (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) }))] }));
};
