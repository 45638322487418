import { jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { cancelOrder } from '@copper/entities/orders/orders-reducer';
import { capitalizeText } from '@copper/helpers/capitalize-text';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './CancelOrder.module.css';
const CancelOrder = ({ order, forceShow, showSuccessMessage = false, testId = 'cancel-order-button', indent = 'm' }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const [isSubmitting, setSubmitting] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const orderName = order.orderType === 'wallet-message' ? 'message' : 'transaction';
    if (!order?._embedded?.isCancelable && !forceShow) {
        return null;
    }
    const handleClick = async () => {
        setSubmitting(true);
        try {
            await dispatch(cancelOrder({ orderId: order.orderId })).unwrap();
            setIsCanceled(true);
            showSuccessMessage &&
                openSnackbar({
                    type: 'success',
                    message: `${capitalizeText(orderName)} successfully cancelled`
                });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setSubmitting(false);
        }
    };
    return (_jsxs("button", { disabled: isSubmitting || isCanceled, className: cn(s.link, s[`indent_${indent}`]), onClick: handleClick, "data-testid": testId, children: ["Cancel ", orderName] }));
};
export { CancelOrder };
