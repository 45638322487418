import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { PortfolioIcon } from '@copper/components';
import { selectUser } from '@copper/entities/user/user-selector';
import { ButtonIcon, IconAlert, IconArrowDown, Tooltip } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
import s from './SubAccountList.module.css';
export const SubAccountList = ({ item, onClose }) => {
    const [openChild, setOpenChild] = useState(false);
    const { isAdmin } = useSelector(selectUser);
    const { item: portfolio, next, nestingLevel } = item;
    const autodiscoveredAccountStatus = portfolio?.extra?.autodiscoveredAccountStatus;
    const handleArrowClick = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenChild(!openChild);
    }, [openChild]);
    return (_jsxs("div", { className: cn(s.portfolio, s[`portfolioLevel_${nestingLevel}`]), children: [_jsxs(NavLink, { to: isAdmin
                    ? `./accounts/${portfolio.portfolioId}`
                    : `../../accounts/${portfolio.portfolioId}`, onClick: onClose, className: s.portfolioWrapper, children: [!!next?.length && (_jsx("div", { className: s.arrowButton, children: _jsx(ButtonIcon, { tooltipLabel: openChild ? 'Collapse' : 'Expand', onClick: handleArrowClick, children: _jsx("span", { className: cn(s.arrow, { [s.arrowOpen]: openChild }), children: _jsx(IconArrowDown, { width: "16px", height: "16px" }) }) }) })), _jsxs("div", { className: s.portfolioLeft, children: [_jsx("div", { className: s.portfolioIcon, children: _jsx(PortfolioIcon, { portfolio: portfolio }) }), _jsxs("div", { className: s.nameWrapper, children: [_jsx("div", { className: s.name, children: portfolio.portfolioName }), _jsx("div", { className: s.balance, children: formatFiat(portfolio.balance, { short: true }) })] })] }), _jsx("div", { className: s.portfolioRight, children: Boolean(autodiscoveredAccountStatus === 'frozen' ||
                            autodiscoveredAccountStatus === 'information-required') && (_jsx(Tooltip, { label: autodiscoveredAccountStatus === 'frozen'
                                ? 'Account frozen'
                                : 'Additional information needed', children: _jsx(IconAlert, { type: "error" }) })) })] }), openChild && !!next?.length && (_jsx(_Fragment, { children: next.map((item) => (_jsx(SubAccountList, { item: item, onClose: onClose }, `child_${item.item.portfolioId}`))) }))] }, portfolio.portfolioId));
};
