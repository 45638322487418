import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ButtonIcon, IconClipboard, IconCross } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import { useSnackBar } from '../SnackbarContext';
import s from './Snack.module.css';
export const Snack = ({ onClose, item }) => {
    const { openSnackbar } = useSnackBar();
    const [isClosed, setIsClosed] = useState(false);
    const remainingRef = useRef(item.duration || 0);
    const startRef = useRef(0);
    const timerRef = useRef();
    const resume = () => {
        if (!item.autoClose || timerRef.current) {
            return;
        }
        startRef.current = Date.now();
        timerRef.current = setTimeout(handleClose, remainingRef.current);
    };
    const pause = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }
        remainingRef.current -= Date.now() - startRef.current;
    };
    useEffect(() => {
        resume();
    }, []);
    const handleClose = useCallback(() => {
        setIsClosed(true);
        setTimeout(() => {
            onClose(item.id);
        }, 400);
    }, [onClose, item]);
    return (_jsxs("div", { className: cn(s.snack, s[`type_${item.type}`], { [s.close]: isClosed }), onMouseEnter: pause, onMouseLeave: resume, children: [item?.autoClose && _jsx("div", { className: s.closeTimerBar }), _jsx("div", { className: s.content, children: item.message }), item.action && _jsx("div", { className: s.action, children: item.action }), item?.errorId && (_jsxs("div", { className: s.errorIdRow, children: [_jsx("span", { className: s.errorIdTitle, children: "Error ID" }), _jsxs("div", { className: s.errorIdWrapper, children: [item.errorId, _jsx("div", { className: s.errorIdCopy, children: _jsx(ButtonIcon, { onClick: () => copy(item.errorId, () => openSnackbar({ type: 'success', message: 'Error ID copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) }) })] })] })), _jsx(ButtonIcon, { className: s.button, onClick: handleClose, children: _jsx(IconCross, {}) })] }));
};
