import { keyBy } from 'lodash-es';
export const normalizeAuthorizedActions = (authorizedActions) => {
    return authorizedActions.authorizedActions.reduce((acc, { action, includingPortfolios = [], excludingPortfolios = [] }) => ({
        ...acc,
        [action]: {
            action,
            ...(excludingPortfolios.length && {
                excludingPortfolios: keyBy(excludingPortfolios, (id) => id)
            }),
            ...(includingPortfolios.length && {
                includingPortfolios: keyBy(includingPortfolios, (id) => id)
            })
        }
    }), {});
};
