import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { Alert, Button } from '@copper/ui-kit';
import s from './Result.module.css';
const Result = ({ formData, errorMessage, transaction, onDone }) => {
    const navigate = useNavigate();
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        if (transaction?.status !== 'executed' &&
            transaction?.status !== 'processing' &&
            transaction?.status !== 'new') {
            return 'pending';
        }
        return 'success';
    };
    const getHeadingText = () => {
        if (errorMessage) {
            return 'Transfer failed';
        }
        return 'Transaction created and ready for signing your side';
    };
    const { portfolio, amount, currency } = formData;
    const status = getResultStatus();
    const handleButtonClick = () => {
        navigate({
            pathname: '/accounts/all',
            search: `orderId=${transaction?.orderId ?? ''}`
        });
        onDone();
    };
    return (_jsx("div", { className: s.container, children: _jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: getHeadingText() }), _jsxs(FormResultAccent, { children: [amount, " ", currency?.currency ?? ''] }), portfolio && (_jsxs(FormResultRegular, { children: ["From", ' ', _jsx(NavLink, { to: `/accounts/${portfolio?.portfolioId}`, children: portfolio?.portfolioName })] })), errorMessage ? (_jsx(Alert, { type: "error", message: errorMessage })) : (_jsxs("div", { className: s.status, children: [_jsx("div", { className: s.statusTitle, children: "Sign transaction" }), _jsx("div", { className: s.statusDescription, children: "You can only fulfil one transaction at a time. Once this transaction has been broadcasted, you can move to the next one." }), _jsx(Button, { size: "l", text: "Start", fullWidth: true, onClick: handleButtonClick }), transaction && _jsx(CancelOrder, { forceShow: true, showSuccessMessage: true, order: transaction })] }))] }) }));
};
export { Result };
