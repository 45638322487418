import * as counterpartiesApi from '@copper/api/counterparties';
export const checkNetworkIdAvailability = (organizationId, networkTag) => counterpartiesApi
    .checkNetworkIdAvailability(organizationId, { networkTag })
    .then((res) => res.data);
export const getCounterpartiesByQuery = (params) => counterpartiesApi
    .getCounterpartiesByQuery(params)
    .then((response) => response.data.counterparties);
export const createLendingArrangement = (organizationId, data) => counterpartiesApi
    .createLendingArrangement({ organizationId, data })
    .then((response) => response.data);
export const removeLendingArrangement = (organizationId, data) => counterpartiesApi
    .removeLendingArrangement({ organizationId, data })
    .then((response) => response.data);
