import BigNumber from 'bignumber.js';
import { capitalizeText } from '@copper/helpers/capitalize-text';
export const ADMIN_STATUS_VALUES = [
    'paid',
    'partially_paid',
    'processing_payment',
    'approved',
    'complete',
    'review',
    'disputed',
    'deleted',
    'written_off'
];
export const CLIENT_STATUS_VALUES = [
    'paid',
    'partially_paid',
    'processing_payment',
    'approved'
];
export const checkIsInvoiceOld = (invoice) => 'oldInvoiceExtra' in invoice.extra;
export const checkIsInvoicePaidByCrypto = (payments) => payments.find(({ reportingCurrency }) => reportingCurrency !== 'USD');
export const checkIsInvoicePaidByFiat = (payments) => payments.find(({ reportingCurrency }) => reportingCurrency === 'USD');
export const getInvoiceAmounts = (invoice, payments) => {
    const amountDue = getInvoiceAmountDue(invoice, payments);
    const amountPaid = getInvoiceAmountPaid(payments);
    return { amountDue, amountPaid };
};
export const getInvoiceAmountDue = (invoice, payments) => {
    const amountPaid = getInvoiceAmountPaid(payments);
    return new BigNumber(invoice.amount).minus(amountPaid).toFixed();
};
export const getInvoiceAmountPaid = (payments) => payments.reduce((acc, { amount }) => new BigNumber(acc).plus(amount).toFixed(), '0');
export const getInvoiceFeeLabel = (feeType) => {
    switch (feeType) {
        case 'copper-connect':
            return 'Copper Connect';
        case 'vat':
            return 'VAT';
        default:
            return capitalizeText(feeType).replace(/-/g, ' ');
    }
};
export const getInvoiceStatusAsObject = (status) => {
    switch (status) {
        case 'incomplete':
            return {
                color: 'red',
                status: 'Incomplete'
            };
        case 'complete':
            return {
                color: 'orange',
                status: 'Invoice not sent'
            };
        case 'approved':
            return {
                color: 'oxide',
                status: 'Awaiting payment'
            };
        case 'processing_payment':
            return {
                color: 'base',
                status: 'Processing payment'
            };
        case 'partially_paid':
            return {
                color: 'base',
                status: 'Partially paid'
            };
        case 'paid':
            return {
                color: 'green',
                status: 'Paid'
            };
        case 'deleted':
            return {
                color: 'red',
                status: 'Deleted'
            };
        case 'disputed':
            return {
                color: 'red',
                status: 'Disputed'
            };
        case 'review':
            return {
                color: 'orange',
                status: 'In review'
            };
        case 'written_off':
            return {
                color: 'red',
                status: 'Written off'
            };
        default:
            return {
                color: 'base',
                status: status ?? ''
            };
    }
};
