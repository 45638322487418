import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { forEach } from 'lodash-es';
import * as tokenAllowancesApi from '@copper/api/tokenAllowances';
import { normalizeTokenAllowances } from '@copper/entities/tokenAllowances/tokenAllowances-normalize';
import { setRevokeOrderId } from './tokenAllowances-action';
const initialState = {
    entities: {}
};
export const fetchTokenAllowances = createAsyncThunk('tokenAllowances/fetchTokenAllowances', async (params, thunkAPI) => {
    try {
        const response = await tokenAllowancesApi.getTokenAllowances(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const tokenAllowancesSlice = createSlice({
    name: 'tokenAllowances',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchTokenAllowances.fulfilled, (state, action) => {
            const { entities } = normalizeTokenAllowances(action.payload);
            //To persist order ids
            forEach(state.entities, (existingTokenAllowance, key) => {
                const tokenAllowance = entities[key];
                if (tokenAllowance && existingTokenAllowance) {
                    tokenAllowance.orderId = existingTokenAllowance.orderId;
                }
            });
            state.entities = entities;
        });
        addCase(setRevokeOrderId, (state, action) => {
            const tokenAllowance = state.entities[action.payload.allowanceId];
            {
                if (tokenAllowance)
                    tokenAllowance.orderId = action.payload.orderId;
            }
        });
    }
});
const { reducer } = tokenAllowancesSlice;
export const tokenAllowances = reducer;
