import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AssetIcon } from '@copper/components';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { activateToken } from '@copper/entities/depositTargets/depositTargets-actions';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName } from '@copper/helpers/currencies';
import { Badge, Button, ButtonIcon, Dropdown, IconMore, Tooltip, useSnackBar } from '@copper/ui-kit';
import { getErrorData, truncateStr } from '@copper/utils';
import { AddressButtons } from '../AddressButtons';
import { AddressFormWrapper } from '../AddressFormWrapper';
import { EditAddressForm } from '../EditAddressForm';
import { isExternalPortfolio } from '@copper/helpers/portfolios';
import s from './AddressItem.module.css';
const AddressItem = ({ portfolioType, currency, view = 'primary', mainCurrency, depositTarget, fullAddress, isSubmitting, showMoreActions, onRemoveDepositAddress, onUpdateDepositAddress, onActivateToken }) => {
    const { openSnackbar } = useSnackBar();
    const { checkPermissions } = useCheckPermissionsContext();
    const [isEditing, setIsEditing] = useState(false);
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const { memo, status } = depositTarget;
    const isProcessing = status === 'pending' || status === 'approval-pending';
    const isActivationRequired = currency !== mainCurrency &&
        depositTarget.acceptAllTokens &&
        depositTarget.requireTokensActivation &&
        !depositTarget.activatedTokens?.find((activatedToken) => activatedToken.currency === currency);
    const getDropdownActions = () => {
        const actions = [
            {
                title: 'Edit',
                value: 'edit',
                dangerous: false
            }
        ];
        if (isExternalPortfolio(portfolioType) &&
            checkPermissions(['update-external-wallet-deposit-address'])) {
            actions.push({
                title: 'Delete',
                value: 'delete',
                dangerous: true
            });
        }
        return actions;
    };
    const renderNameAndProtocol = (depositTarget) => {
        const currencyName = getCurrencyName(currency, currenciesEntities);
        const currencyProtocol = tokens[`${currency}-${depositTarget.mainCurrency}`]?.protocol;
        return (_jsxs(_Fragment, { children: [currencyName, currencyProtocol && ` ${currencyProtocol.toUpperCase()}`] }));
    };
    const onMoreActionsClickHandler = ({ value }) => {
        if (value === 'delete') {
            onRemoveDepositAddress?.();
        }
        if (value === 'edit') {
            setIsEditing(true);
        }
    };
    const activate = async () => {
        try {
            await activateToken(depositTarget.depositTargetId, { currency });
            onActivateToken?.();
            openSnackbar({
                type: 'success',
                message: 'Token activated'
            });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.addressItem, children: [_jsxs("div", { className: s.addressItemContent, children: [_jsx("div", { className: s.addressItemAsset, children: _jsx(AssetIcon, { currency: mainCurrency, size: "s" }) }), _jsxs("div", { className: s.addressItemDetails, children: [_jsxs("div", { className: s.addressItemDetail, children: [currency, " (", renderNameAndProtocol(depositTarget), ")", depositTarget.isNonCustodialWallet && (_jsx(Badge, { altView: true, view: "tool", form: "squared", textForm: "regular", content: "Non-custodial" }))] }), _jsx("div", { className: s.addressItemAddress, children: fullAddress
                                            ? depositTarget.address
                                            : truncateStr(depositTarget.address ?? '', 10, null, 20) }), depositTarget.name && _jsx("div", { className: s.addressItemText, children: depositTarget.name }), memo && _jsxs("div", { className: s.addressItemText, children: ["TAG: ", memo] })] })] }), isProcessing ? (_jsx(Tooltip, { label: depositTarget.status === 'approval-pending'
                            ? 'An authorised team member needs to approve your request to add this address.'
                            : 'This address is in the process of being generated.', placement: "top", type: "hint", view: view, children: _jsx("div", { className: s.addressItemProcessing, children: "Pending" }) })) : (_jsx("div", { className: s.addressActions, children: isActivationRequired ? (_jsx(Button, { text: "Activate", view: view, onClick: activate, size: "s" })) : (_jsxs(_Fragment, { children: [depositTarget.address && (_jsx(AddressButtons, { view: view, depositTargetAddress: depositTarget.address })), showMoreActions && (_jsx(Dropdown, { highlightSelectedItem: false, placement: "top", view: view, getTitle: ({ title }) => title, getKey: ({ title }) => title, onChange: (value) => onMoreActionsClickHandler(value), items: getDropdownActions(), listWidth: 145, getModifier: ({ dangerous }) => (dangerous ? 'dangerous' : undefined), getToggle: (isOpen) => (_jsx(ButtonIcon, { state: isOpen ? 'active' : undefined, tooltipLabel: "More", children: _jsx(IconMore, {}) })) }))] })) }))] }), isEditing && (_jsx(AddressFormWrapper, { view: view, title: `Edit ${currency} (${currenciesEntities[currency]?.name})`, children: _jsx(EditAddressForm, { view: view, depositTarget: depositTarget, isSubmitting: isSubmitting, onCancel: () => setIsEditing(false), onSubmit: (id, name) => onUpdateDepositAddress?.(id, name) }) }))] }));
};
export { AddressItem };
