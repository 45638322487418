import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOrderContext } from '@copper/components/OrderPopup/context-hook';
import { cancelOrder, cosignOrder, fetchOrder } from '@copper/entities/orders/orders-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Button, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
export const CoSignControls = ({ order, size = 'l' }) => {
    const { openSnackbar } = useSnackBar();
    const { saveContext } = useOrderContext();
    const dispatch = useDispatch();
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [acceptIsBusy, setAccept] = useState(false);
    const [rejectIsBusy, setRejectIsBusy] = useState(false);
    const onAccept = async () => {
        if (order.portfolioType === 'external' && currentOrganization.extra?.encryptionPublicKey) {
            return saveContext({
                popupContext: {
                    header: {
                        returnCallback: () => {
                            saveContext({
                                popupContext: {}
                            });
                        },
                        title: 'Enter your withdrawal password',
                        hideAssetInfo: true
                    },
                    operation: 'withdrawalPassword'
                }
            });
        }
        setAccept(true);
        try {
            await dispatch(cosignOrder({ orderId: order.orderId })).unwrap();
            await dispatch(fetchOrder(order.orderId)).unwrap();
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setAccept(false);
        }
    };
    const onReject = async () => {
        setRejectIsBusy(true);
        try {
            await dispatch(cancelOrder({ orderId: order.orderId }));
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setRejectIsBusy(false);
        }
    };
    const isCoSignable = Boolean(order?._embedded?.isCoSignable);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { loading: rejectIsBusy, onClick: onReject, view: "decline", text: isCoSignable ? 'Reject' : 'Cancel', size: size }), isCoSignable && (_jsx(Button, { loading: acceptIsBusy, onClick: onAccept, view: "positive", text: "Approve", size: size }))] }));
};
