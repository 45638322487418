export const normalizeExchanges = (exchanges = []) => {
    const result = {
        entities: {}
    };
    exchanges.forEach((exchange) => {
        const { _embedded, ...exchangesResponseProps } = exchange;
        result.entities[exchange.exchangeId] = {
            ...exchangesResponseProps,
            exchangeAccounts: _embedded?.exchangeAccounts ?? []
        };
    });
    return result;
};
export const normalizeClearloopExchanges = (exchanges = []) => {
    const result = {
        entities: {}
    };
    exchanges.forEach((exchange) => {
        result.entities[exchange.exchangeId] = exchange;
    });
    return result;
};
