import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import s from './ConnectionLine.module.css';
const OFFSET_HEIGHT = 8;
export const ConnectionLine = ({ startId, stopId }) => {
    const [height, setHeight] = useState(0);
    const [offsetTop, setOffsetTop] = useState(0);
    const { formState: { errors } } = useFormContext();
    useEffect(() => {
        const startOffsetTop = document.querySelector(`#${startId}`)?.offsetTop;
        const stopOffsetTop = document.querySelector(`#${stopId}`)?.offsetTop;
        if (startOffsetTop !== undefined && stopOffsetTop !== undefined) {
            setOffsetTop(startOffsetTop);
            setHeight(stopOffsetTop - startOffsetTop + OFFSET_HEIGHT);
        }
        // recalculate position if there are errors
        // (error message changes height of the element)
    }, [errors, startId, stopId]);
    if (height <= 0) {
        return null;
    }
    return (_jsx("div", { className: s.container, style: { top: offsetTop, height }, children: _jsx("div", { className: s.line }) }));
};
