import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { FiatSettlementInfo } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/FiatSettlementInfo';
import { ExplorerLink } from '@copper/components/OrderPopup/ui/ExplorerLink';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { StepItem } from '@copper/ui-kit';
import s from './AwaitingInitiatorDeposit.module.css';
const AwaitingInitiatorDeposit = ({ order, status, disabled, index, viewByAdmin = false, testId, isReceivingFiat }) => {
    const counterparties = useSelector(selectCounterpartiesEntities);
    const { counterpartyName = 'Counterparty' } = counterparties[order?.extra?.fromCounterpartyId ?? ''] || {};
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin
            ? `${counterpartyName} Processes settlement`
            : 'Counterparty processes settlement'}`, disabled: disabled, children: isReceivingFiat ? (_jsx(FiatSettlementInfo, { order: order })) : (_jsxs(_Fragment, { children: [counterpartyName, " has been instructed to process their leg of the settlement.", order.extra?.deliveryType === 'free-of-payment' && order.extra.transactionId && (_jsxs("div", { className: s.explorerContainer, children: ["The settlement will go through as soon as enough block confirmations are in.", _jsx("div", { className: s.explorerButtonContainer, children: _jsx(ExplorerLink, { order: order }) })] }))] })) }));
};
export { AwaitingInitiatorDeposit };
