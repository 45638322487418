import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectTimeFormat } from '@copper/entities/revault/revault-selector';
import { getCommonFormattedDateAndTime, getExpandedDateAndTimeAsObject } from '@copper/utils';
export const useDateTime = () => {
    const timeformat = useSelector(selectTimeFormat);
    const getDateAndTimeAsObject = useCallback((timestamp, timezone, isFormatToWord) => {
        return getExpandedDateAndTimeAsObject({ timeformat, timestamp, timezone, isFormatToWord });
    }, [timeformat]);
    const getFormattedDateAndTime = useCallback((timestamp, isFormatToWord) => {
        return getCommonFormattedDateAndTime({ timeformat, timestamp, isFormatToWord });
    }, [timeformat]);
    return {
        getDateAndTimeAsObject,
        getFormattedDateAndTime
    };
};
