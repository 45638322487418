import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { ButtonIcon, IconClose } from '@copper/ui-kit';
import s from './Popup.module.css';
const delay = 200;
const Popup = ({ onClose, children, withoutStyle, visuallyHidden, closeOnBackdropClick }) => {
    const [container] = React.useState(() => document.createElement('div'));
    const containerRef = useRef(null);
    const handleClose = (e) => {
        e && e.stopPropagation();
        if (container) {
            container.classList.add(`${s.closeAnimation}`);
        }
        setTimeout(onClose, delay);
    };
    const onClickEscape = (e) => {
        const modals = document.body.querySelectorAll('.Modals');
        if (modals.length > 1) {
            if (container !== modals[modals.length - 1]) {
                return;
            }
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            handleClose();
        }
    };
    useEffect(() => {
        document.body.classList.add('noScroll');
        container.className = cn('Modals', s.popup);
        document.body.appendChild(container);
        document.addEventListener('keyup', onClickEscape, true);
        return () => {
            document.body.removeChild(container);
            document.body.classList.remove('noScroll');
            document.removeEventListener('keyup', onClickEscape, true);
        };
    }, []);
    useEffect(() => {
        containerRef.current?.focus();
    }, [containerRef.current]);
    const renderModal = () => (_jsx(_Fragment, { children: _jsx("div", { className: s.overlay, onClick: (e) => {
                e.stopPropagation();
                if (closeOnBackdropClick) {
                    onClose();
                }
            }, style: { visibility: visuallyHidden ? 'hidden' : 'visible' }, role: "presentation", children: _jsxs("div", { className: s.contentContainer, children: [_jsx("div", { ref: containerRef, onClick: (e) => e.stopPropagation(), className: cn(s.content, { [s.withoutStyle]: withoutStyle }), style: { visibility: visuallyHidden ? 'hidden' : 'visible' }, role: "dialog", children: children }), _jsx("div", { className: s.close, children: _jsx(ButtonIcon, { onClick: handleClose, tooltipLabel: "Close", testId: 'button-close-popup', children: _jsx(IconClose, {}) }) })] }) }) }));
    return createPortal(renderModal(), container);
};
export { Popup };
