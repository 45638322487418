import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Network } from '@copper/components';
import { selectNonCustodialWalletsEnabled } from '@copper/entities/organizations/organizations-selector';
import { Button, FormControl, FormLabel, Input, Radio } from '@copper/ui-kit';
import { NonCustodialConfirmation } from './NonCustodialConfirmation';
import { validationSchema } from './helpers';
import s from './AddAddressForm.module.css';
const AddAddressForm = ({ currency, mainCurrency, isSubmitting, onCancel, onSubmit, isAddMemo }) => {
    const [isConfirmationView, setIsConfirmationView] = useState(false);
    const nonCustodialWalletsEnabled = useSelector(selectNonCustodialWalletsEnabled);
    const { control, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            mainCurrency: mainCurrency,
            targetName: ''
        },
        resolver: yupResolver(validationSchema)
    });
    const handleOnSubmit = async ({ mainCurrency, targetName, walletType }) => {
        if (walletType === 'non-custodial' && !isConfirmationView) {
            return setIsConfirmationView(true);
        }
        await onSubmit(mainCurrency, targetName, walletType === 'non-custodial');
    };
    if (isConfirmationView) {
        return (_jsx(NonCustodialConfirmation, { onAgree: async () => {
                await handleSubmit(handleOnSubmit)();
                setIsConfirmationView(false);
            }, onCancel: () => setIsConfirmationView(false) }));
    }
    return (_jsxs("form", { onSubmit: handleSubmit(handleOnSubmit), children: [_jsx(Network, { control: control, currency: currency, view: "tool", errorMessage: errors?.mainCurrency?.message, name: "mainCurrency", required: true }), _jsxs(FormControl, { errorMessage: errors?.targetName?.message, children: [_jsxs(FormLabel, { children: [isAddMemo ? 'Memo' : 'Address', " name"] }), _jsx(Controller, { control: control, name: "targetName", render: ({ field }) => _jsx(Input, { size: "l", view: "tool", ...field }) })] }), !isAddMemo && nonCustodialWalletsEnabled && (_jsxs(FormControl, { errorMessage: errors?.walletType?.message, children: [_jsx(FormLabel, { children: "Wallet type" }), _jsx(Controller, { control: control, name: "walletType", defaultValue: "custodial", render: ({ field }) => (_jsx(Radio, { items: [
                                {
                                    label: 'Custodial Wallet',
                                    value: 'custodial',
                                    tooltipInfo: 'A wallet that is custodied by Copper.'
                                },
                                {
                                    label: 'Non-Custodial Treasury Wallet',
                                    value: 'non-custodial',
                                    tooltipInfo: 'A self-custodial wallet that is linked to your selected Copper account.'
                                }
                            ], orientation: "vertical", tooltipView: "tool", name: field.name, value: field.value, onChange: field.onChange })) })] })), _jsxs("div", { className: s.addressFormButtons, children: [_jsx(Button, { text: "Cancel", type: "button", view: "tool", onClick: onCancel }), _jsx(Button, { loading: isSubmitting, text: "Save", type: "submit", view: "primary" })] })] }));
};
export { AddAddressForm };
