import { idleReset } from '@copper/helpers/refresh-token';
const eventsToListen = ['mousemove', 'keydown', 'wheel', 'mousewheel', 'mousedown', 'touchstart'];
export const addIdleResetListener = () => {
    eventsToListen.forEach((e) => {
        document.addEventListener(e, idleReset, {
            capture: true,
            passive: true
        });
    });
};
export const removeIdleResetListener = () => {
    eventsToListen.forEach((e) => {
        document.removeEventListener(e, idleReset);
    });
};
