import { createSelector } from '@reduxjs/toolkit';
const selectCustodyState = (state) => state.custody;
export const selectLegacyAppVersion = createSelector(selectCustodyState, (custody) => custody.legacyAppVersion);
export const selectNewAppVersion = createSelector(selectCustodyState, (custody) => custody.newAppVersion);
export const selectIsLegacyAppOutdated = createSelector(selectCustodyState, (custody) => Boolean(custody.legacyAppVersion &&
    custody.userLegacyVersion &&
    custody.legacyAppVersion !== custody.userLegacyVersion));
export const selectIsNewAppOutdated = createSelector(selectCustodyState, (custody) => Boolean(custody.newAppVersion &&
    custody.userNewVersion &&
    custody.newAppVersion !== custody.userNewVersion));
export const selectShowLegacyAppNotification = createSelector(selectCustodyState, selectIsLegacyAppOutdated, (custody, isLegacyAppOutdated) => isLegacyAppOutdated && custody.deferredLegacyAppVersion !== custody.legacyAppVersion);
export const selectShowNewAppNotification = createSelector(selectCustodyState, selectIsNewAppOutdated, (custody, isNewAppOutdated) => isNewAppOutdated && custody.deferredNewAppVersion !== custody.newAppVersion);
