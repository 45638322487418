import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName } from '@copper/helpers/currencies';
import { Button } from '@copper/ui-kit';
import s from './GenerateAddress.module.css';
const GenerateAddress = ({ currency = '', mainCurrency = '', isSubmitting, onSubmit }) => {
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const renderNameAndProtocol = () => {
        const currencyName = getCurrencyName(currency, currenciesEntities);
        const currencyProtocol = tokens[`${currency}-${mainCurrency}`]?.protocol;
        return (_jsxs("span", { children: [currencyName, currencyProtocol && ` (${currencyProtocol.toUpperCase()})`] }));
    };
    return (_jsxs("div", { className: s.container, children: [_jsx("h6", { className: s.title, children: "Generate a deposit address" }), _jsxs("p", { className: s.text, children: ["You don't have a ", renderNameAndProtocol(), " address.", _jsx("br", {}), "Generate one now instantly."] }), _jsx(Button, { loading: isSubmitting, text: "Generate address", onClick: () => onSubmit(mainCurrency, 'Address name') })] }));
};
export { GenerateAddress };
