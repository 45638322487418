import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { SkeletonLoader as SkeletonTable } from '@copper/components/OrdersList/SkeletonLoader';
import { useFullWidthLayout } from '@copper/contexts/layout';
import { Skeleton } from '@copper/ui-kit';
import s from './SkeletonLoader.module.css';
const SkeletonLoader = memo(() => {
    useFullWidthLayout();
    return (_jsxs("div", { className: s.content, children: [_jsxs("div", { className: s.header, children: [_jsx("h1", { className: s.title, children: "ClearLoop risk analytics" }), _jsx("div", { className: s.menu, children: _jsx(Skeleton, { width: 250, height: 16, color: "secondary" }) })] }), _jsx("div", { className: s.table, children: _jsx(SkeletonTable, { items: 3 }) }), _jsx("div", { className: s.table, children: _jsx(SkeletonTable, { items: 6 }) }), _jsx("div", { className: s.table, children: _jsx(SkeletonTable, { items: 8 }) })] }));
});
SkeletonLoader.displayName = 'SkeletonLoader';
export { SkeletonLoader };
