import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getUserZendeskSSOToken } from '@copper/api/users';
import { selectUserId } from '@copper/entities/user/user-selector';
import { Loader, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './ZendeskLoginPageLayout.module.css';
const ZendeskLogin = () => {
    const { openSnackbar } = useSnackBar();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const submitRef = useRef(null);
    const userId = useSelector(selectUserId);
    const { register, setValue } = useForm({
        mode: 'onSubmit'
    });
    const fetchToken = async () => {
        try {
            const { data: { token } } = await getUserZendeskSSOToken(userId);
            setValue('jwt', token ?? '');
            submitRef.current?.requestSubmit();
        }
        catch (e) {
            openSnackbar(getErrorData(e));
        }
    };
    useEffect(() => {
        fetchToken();
    }, []);
    return (_jsxs("div", { className: s.container, children: [_jsx(Loader, { size: "l", text: "Securing account..." }), _jsx("form", { action: `https://copper.zendesk.com/access/jwt?return_to=${searchParams.get('return_to')}`, method: "POST", ref: submitRef, children: _jsx("input", { ...register('jwt'), type: "hidden" }) })] }));
};
export { ZendeskLogin };
