import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as stakesApi from '@copper/api/stakes';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { normalizeAggregatedActiveStakes } from './stake-normalize';
export const fetchActiveStakes = createAsyncThunk('stakes/getActiveStakes', async (params, thunkAPI) => {
    try {
        const response = await stakesApi.getActiveStakes(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchPendingStakes = createAsyncThunk('stakes/getPendingStakes', async (params, thunkAPI) => {
    try {
        const response = await stakesApi.getPendingStakes(params);
        return response.data.pendingStakes;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOutstandingStakes = createAsyncThunk('stakes/getOutstandingStakes', async (params, thunkAPI) => {
    try {
        const response = await stakesApi.getOutstandingStakes(params);
        return response.data.outstandingStakes;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchAggregatedStakes = createAsyncThunk('stakes/getAggregatedStakes', async (params, thunkAPI) => {
    try {
        const response = await stakesApi.getAggregatedActiveStakes(params);
        const rates = selectRates(thunkAPI.getState());
        return normalizeAggregatedActiveStakes(response.data.aggregatedStakes, rates);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchStakeCounters = createAsyncThunk('stakes/getStakeSummary', async (params, thunkAPI) => {
    try {
        const response = await stakesApi.getStakeCounters(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const stakesSlice = createSlice({
    name: 'stakes',
    initialState: {
        activeStakes: [],
        pendingStakes: [],
        outstandingStakes: [],
        claimableStakes: [],
        aggregatedStakes: [],
        counters: {
            active: 0,
            pending: 0,
            outstanding: 0,
            claimable: 0
        }
    },
    reducers: {
        updateClaimable(state, { payload }) {
            state.claimableStakes = payload;
            state.counters = {
                ...state.counters,
                claimable: payload.length
            };
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchActiveStakes.fulfilled, (state, { payload }) => {
            state.activeStakes = [...payload.activeStakes];
        });
        addCase(fetchStakeCounters.fulfilled, (state, { payload }) => {
            state.counters = {
                ...state.counters,
                active: Number(payload?.activeCount ?? 0),
                pending: Number(payload?.pendingCount ?? 0),
                outstanding: Number(payload?.outstandingCount ?? 0)
            };
        });
        addCase(fetchPendingStakes.fulfilled, (state, { payload }) => {
            state.pendingStakes = payload;
        });
        addCase(fetchOutstandingStakes.fulfilled, (state, { payload }) => {
            state.outstandingStakes = payload;
        });
        addCase(fetchAggregatedStakes.fulfilled, (state, { payload }) => {
            state.aggregatedStakes = payload;
        });
    }
});
const { actions, reducer } = stakesSlice;
export const { updateClaimable } = actions;
export const stakes = reducer;
