import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestBulkSettlementTemplate, uploadBulkSettlementTemplate } from '@copper/api/bulkTemplates';
import { bulkCreate } from '@copper/api/orders';
import { getFile } from '@copper/api/users';
import { asyncPoll } from '@copper/utils/asyncPoll';
export const downloadBulkTemplate = createAsyncThunk('settlements/download-bulk-template', async ({ organizationId, userId }, thunkApi) => {
    try {
        const { data: { fileId } } = await requestBulkSettlementTemplate({ organizationId });
        const fileData = await asyncPoll(() => getFile(userId, fileId, { responseType: 'blob' }), 1000, 30000);
        return {
            fileId,
            fileData
        };
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const uploadBulkTemplate = createAsyncThunk('settlements/upload-bulk-template', async (file, thunkApi) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { data: { orders = [] } } = await uploadBulkSettlementTemplate(formData);
        return orders;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const bulkOrderCreate = createAsyncThunk('settlements/bulk-create', async (ordersToCreate, thunkApi) => {
    try {
        const { data: { orders = [] } } = await bulkCreate(ordersToCreate);
        return orders;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
