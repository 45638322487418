import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { ProfilePhoto } from '@copper/components/ProfilePhoto';
import { selectUser, selectUserEmail } from '@copper/entities/user/user-selector';
import s from './Header.module.css';
export const Header = () => {
    const user = useSelector(selectUser);
    const email = useSelector(selectUserEmail);
    return (_jsxs("div", { className: s.avatarContainer, children: [_jsx(ProfilePhoto, { size: "l" }), _jsxs("p", { className: s.userName, children: [user.firstName, " ", user.lastName ?? ''] }), _jsx("p", { className: s.email, children: email })] }));
};
