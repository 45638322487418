export const getCurrenciesByPurpose = (order) => {
    const { orderType, baseCurrency, mainCurrency, quoteCurrency, quoteMainCurrency } = order;
    if (orderType === 'buy') {
        return {
            receivingCurrency: baseCurrency,
            receivingMainCurrency: mainCurrency,
            returningCurrency: quoteCurrency,
            returningMainCurrency: quoteMainCurrency,
            depositCurrency: quoteCurrency,
            depositMainCurrency: quoteMainCurrency
        };
    }
    return {
        receivingCurrency: quoteCurrency,
        receivingMainCurrency: quoteMainCurrency,
        returningCurrency: baseCurrency,
        returningMainCurrency: mainCurrency,
        depositCurrency: baseCurrency,
        depositMainCurrency: mainCurrency
    };
};
