import qrcode from 'qrcode';
const SMALL_WIDTH = 300;
const LARGE_SIZE = 630;
const LARGE_PADDING = 280;
const errorCorrectionLevel = 'L';
export const animationSpeed = 300;
export const renderQRCode = (el, text, size, callback) => {
    if (el) {
        const windowHeight = window.innerHeight - LARGE_PADDING;
        const largeSize = windowHeight > LARGE_SIZE ? LARGE_SIZE : windowHeight;
        const dimensions = size === 'full' ? '100%' : `${size === 'large' ? largeSize : SMALL_WIDTH}px`;
        qrcode.toCanvas(el, text, {
            errorCorrectionLevel,
            margin: 1.5,
            width: size === 'large' ? largeSize : SMALL_WIDTH * (window.devicePixelRatio ?? 1)
        }, () => {
            if (el) {
                el.style.width = dimensions;
                el.style.height = dimensions;
            }
            if (callback) {
                callback();
            }
        });
    }
};
