import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const ZIL = {
    title: 'ZIL',
    id: 'zil',
    qa: [
        {
            question: 'How do I stake ZIL?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 10 ZIL)." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake ZIL?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "To unstake ZIL, you must first initiate a rewards claim, even if your rewards balance is 0:" }), _jsx("i", { children: "Step 1 - Get rewards" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your ZIL staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." })] }), _jsx(Alert, { type: "info", message: "If you skip the above steps prior to clicking Unstake, the transaction will fail once it has been broadcast." }), _jsx("i", { children: "Step 2 - Unstaking" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the unbonding period." }), _jsx("li", { children: "Claim your unstaked assets and sign the transaction." })] })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for ZIL?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 10 ZIL, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "10 ZIL" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn ZIL staking rewards?',
            answer: (_jsx("p", { children: "You can view the reward frequency applicable at the time of staking. Or in your staking details, by clicking on the transaction on your staking dashboard." }))
        },
        {
            question: 'How can I claim my ZIL staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your ZIL rewards anytime by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your ZIL staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "Your assets will be released into your staking vault right away, as there is no unbonding period." })] }))
        }
    ]
};
