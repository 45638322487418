import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_ITEMS_PER_PAGE_VALUE } from '@copper/constants/pagination';
import { getNonCustodialWallets } from '@copper/entities/addressBook/addressBook-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
export const useFetchNonCustodianWallets = ({ enabled, portfolioId, currency, mainCurrency, search }) => {
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [offset, setOffset] = useState(0);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [isLoadingNonCustodial, setIsLoadingNonCustodial] = useState(false);
    const [nonCustodialWallets, setNonCustodialWallets] = useState([]);
    const fetchNonCustodianWallets = useCallback(async () => {
        if (!enabled || isLoadingNonCustodial) {
            return;
        }
        setIsLoadingNonCustodial(true);
        try {
            const wallets = await getNonCustodialWallets({
                organizationId,
                userId: '',
                currency,
                mainCurrency,
                portfolioId,
                search,
                limit: DEFAULT_ITEMS_PER_PAGE_VALUE.toString(),
                offset: offset.toString()
            });
            if (offset === 0) {
                setNonCustodialWallets(wallets);
            }
            else {
                setNonCustodialWallets((currentWallets) => [...currentWallets, ...wallets]);
            }
            if (wallets.length === 0) {
                setHasMoreItems(false);
            }
        }
        catch (_) {
            setNonCustodialWallets([]);
        }
        finally {
            setIsLoadingNonCustodial(false);
        }
    }, [enabled, portfolioId, currency, mainCurrency, search, offset, isLoadingNonCustodial]);
    useEffect(() => {
        if (hasMoreItems) {
            fetchNonCustodianWallets();
        }
    }, [fetchNonCustodianWallets, hasMoreItems]);
    useEffect(() => {
        setOffset(0);
        setHasMoreItems(true);
    }, [search]);
    const handleNonCustodialInfiniteScroll = (e) => {
        const bottom = e.currentTarget.scrollHeight - e.currentTarget.clientHeight === e.currentTarget.scrollTop;
        if (bottom) {
            setOffset((prevOffset) => prevOffset + DEFAULT_ITEMS_PER_PAGE_VALUE);
        }
    };
    return {
        isLoadingNonCustodial,
        nonCustodialWallets,
        fetchNonCustodianWallets,
        handleNonCustodialInfiniteScroll
    };
};
