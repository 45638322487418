import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDayPicker, useNavigation } from 'react-day-picker';
import { format, setMonth, setYear, startOfMonth, startOfYear } from 'date-fns';
import { FormLabel, Select } from '@copper/ui-kit';
import s from './Caption.module.css';
export const Caption = ({ displayMonth, captionRef, view }) => {
    const { goToMonth } = useNavigation();
    const { fromDate, toDate } = useDayPicker();
    const dropdownYears = [];
    const dropdownMonths = [];
    for (let month = 0; month <= 11; month++) {
        dropdownMonths.push(setMonth(startOfMonth(new Date()), month));
    }
    const fromFullYear = fromDate?.getFullYear() ?? new Date().getFullYear();
    const toFullYear = toDate?.getFullYear() ?? new Date().getFullYear();
    for (let year = fromFullYear; year <= toFullYear; year++) {
        dropdownYears.push(setYear(startOfYear(new Date()), year));
    }
    const handleChangeMonth = (value) => {
        const selectedMonth = value?.getMonth();
        if (selectedMonth != null) {
            const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth);
            goToMonth(newMonth);
        }
    };
    const handleChangeYear = (value) => {
        const selectedMonth = value?.getFullYear();
        if (selectedMonth) {
            const newMonth = setYear(startOfMonth(displayMonth), selectedMonth);
            goToMonth(newMonth);
        }
    };
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.month, children: [_jsx(FormLabel, { children: "Month" }), _jsx(Select, { dropwdownRef: captionRef, size: "m", items: dropdownMonths, onChange: handleChangeMonth, selected: displayMonth, getKey: (month) => month.getMonth().toString(), getBottomRow: (month) => format(month, 'LLLL'), view: view })] }), _jsxs("div", { className: s.year, children: [_jsx(FormLabel, { children: "Year" }), _jsx(Select, { dropwdownRef: captionRef, size: "m", items: dropdownYears, onChange: handleChangeYear, selected: displayMonth, getKey: (year) => year.getFullYear().toString(), getBottomRow: (year) => format(year, 'yyyy'), view: view })] })] }));
};
