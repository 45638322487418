import BigNumber from 'bignumber.js';
export const normalizeAggregatedActiveStakes = (aggregatedStakes, rates) => aggregatedStakes.map((aggregatedStake) => {
    const rate = rates[aggregatedStake.currency] ?? '0';
    const totalAmount = aggregatedStake.totalAmount ?? '0';
    return {
        ...aggregatedStake,
        totalAmount,
        totalAmountReporting: new BigNumber(totalAmount).times(rate).toFixed()
    };
});
