import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const NEAR = {
    title: 'NEAR',
    id: 'near',
    qa: [
        {
            question: 'How do I stake NEAR?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake NEAR?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 2-day unbonding period." }), _jsx("li", { children: "At the end of the unbonding period, claim your unstaked assets from the Pending tab on your dashboard, and sign once more to confirm." })] }) }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for NEAR?',
            answer: _jsx("p", { children: "No, you can choose up to 100% of your available balance." })
        },
        {
            question: 'How often will I earn NEAR staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "NEAR rewards are issued daily." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my NEAR staking rewards?',
            answer: (_jsx("p", { children: "Rewards for NEAR are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }))
        }
    ]
};
