const isScrollable = function (el) {
    const hasScrollableContent = el.scrollHeight > el.clientHeight;
    const overflowYStyle = window.getComputedStyle(el).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;
    return hasScrollableContent && !isOverflowHidden;
};
export const getScrollableParent = function (el) {
    return !el || el === document.body
        ? document.body
        : isScrollable(el)
            ? el
            : getScrollableParent(el.parentNode);
};
