import { intersection } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { getCounterpartiesByQuery } from '@copper/entities/counterparties/counterparties-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData, serializeParams } from '@copper/utils';
const useFetchCounterparties = ({ searchQueryString = '', enabled = true, isConnected, counterpartyType = [], operations = [] }) => {
    const { openSnackbar } = useSnackBar();
    const { checkPermissions } = useCheckPermissionsContext();
    const { organizationId, counterparty: currentOrganizationCounterpartyInfo } = useSelector(selectCurrentOrganization);
    const [isSearching, setIsSearching] = useState(false);
    const [counterparties, setCounterparties] = useState([]);
    const fetchCounterparties = useCallback(debounce(async ({ searchQueryString, isConnected, counterpartyType }) => {
        try {
            const data = await getCounterpartiesByQuery({
                organizationId: organizationId,
                queryString: serializeParams({
                    search: searchQueryString,
                    ...(typeof isConnected == 'boolean' && { isConnected }),
                    ...(counterpartyType && { counterpartyType: counterpartyType.join(',') })
                })
            });
            //temporary as BE promised to add operations tags filter to the API
            if (operations.length) {
                const counterpartiesFilteredByOperations = data.filter((counterparty) => {
                    // temporary as BE will add the logic for lend tag in the operations tags
                    if (operations.includes('lend')) {
                        return counterparty._embedded?.counterpartyConnection?.extra?.lendingArrangements?.find((lendingArrangement) => lendingArrangement.lenderCounterpartyId ===
                            currentOrganizationCounterpartyInfo?.counterpartyId);
                    }
                    return intersection(operations, counterparty.operations).length;
                });
                setCounterparties(counterpartiesFilteredByOperations);
            }
            else {
                setCounterparties(data);
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsSearching(false);
        }
    }, 500), []);
    useEffect(() => {
        if (checkPermissions(['find-counterparties']) && enabled) {
            setIsSearching(true);
            fetchCounterparties({
                searchQueryString,
                isConnected,
                counterpartyType
            });
        }
    }, [searchQueryString, enabled]);
    return {
        isSearching,
        counterparties,
        fetchCounterparties
    };
};
export { useFetchCounterparties };
