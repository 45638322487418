import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InfoList, InfoRow } from '@copper/components';
import { useOrderContext } from '@copper/components/OrderPopup/context-hook';
import { SettlementInformation } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/SettlementInformation';
import { StepContentContainer } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/StepContentContainer';
import { ApproveOtcControls } from '@copper/components/OrderPopup/ui/ApproveOtcControls';
import { UserSnippet } from '@copper/components/UserSnippet';
import { STANDARD_FOP_DISCLAIMER_ID } from '@copper/constants/disclaimers';
import { selectCounterpartiesEntitiesByOrgIds } from '@copper/entities/counterparties/counterparties-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useDateTime, useDisclaimer } from '@copper/hooks';
import { ButtonLink, Checkbox, Status, StepItem } from '@copper/ui-kit';
import s from './OfferReview.module.css';
const OfferReview = ({ order, status, disabled, index, viewByAdmin = false, testId }) => {
    const counterpartiesEntitiesByOrgId = useSelector(selectCounterpartiesEntitiesByOrgIds);
    const recipientName = counterpartiesEntitiesByOrgId[order?.organizationId ?? '']?.counterpartyName;
    const [fopTermsAccepted, setFopTermsAccepted] = useState(false);
    const { popupContext: { orderChanges } } = useOrderContext();
    const change = orderChanges?.find(({ changeType }) => changeType === 'order-accepted') ||
        orderChanges?.find(({ changeType }) => changeType === 'order-canceled');
    const isAccepted = change?.changeType === 'order-accepted';
    const fopDisclaimer = useDisclaimer(STANDARD_FOP_DISCLAIMER_ID);
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { handleDisclaimerDownload, showDisclaimer, checkDisclaimer } = fopDisclaimer;
    const { getFormattedDateAndTime } = useDateTime();
    useEffect(() => {
        organizationId && checkDisclaimer();
    }, [organizationId]);
    const showFopDisclaimer = order.extra?.deliveryType === 'free-of-payment' &&
        showDisclaimer &&
        order.portfolioType !== 'settlement-lite';
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin
            ? `${recipientName} reviews the settlement request`
            : 'Review settlement instruction'}`, disabled: disabled, children: _jsxs(StepContentContainer, { children: [_jsx(SettlementInformation, { order: order, viewByAdmin: viewByAdmin }), status === 'processing' && !viewByAdmin && (_jsxs(_Fragment, { children: [showFopDisclaimer && (_jsx("div", { className: s.termsCheckbox, children: _jsx(Checkbox, { name: "fopTerms", checked: fopTermsAccepted, onChange: setFopTermsAccepted, label: _jsxs("div", { className: s.termsLabel, children: ["I\u2019ve read and agree to the", ' ', _jsx(ButtonLink, { onClick: handleDisclaimerDownload, text: "Settlement Agreement." })] }) }) })), _jsx("div", { className: s.buttonsContainer, children: _jsx(ApproveOtcControls, { order: order, size: "m", fopTermsAccepted: fopTermsAccepted, isFopSettlement: order.extra?.deliveryType === 'free-of-payment' }) })] })), change && (_jsx(InfoList, { view: "primary-rare", children: _jsx(InfoRow, { title: _jsx(UserSnippet, { userId: change.changedBy, userData: change.user, secondaryInfo: "Reviewer" }), children: _jsxs("div", { className: s.changeDetails, children: [_jsx(Status, { color: isAccepted ? 'green' : 'red', children: isAccepted ? 'Accepted' : 'Declined' }), _jsx("div", { className: s.changeDate, children: getFormattedDateAndTime(change.changedAt) })] }) }) }))] }) }));
};
export { OfferReview };
