import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { Alert, Button } from '@copper/ui-kit';
import s from './Result.module.css';
export const Result = ({ errorMessage, pledge, onDone }) => {
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        return 'signature';
    };
    const status = getResultStatus();
    return (_jsx("div", { className: s.container, children: _jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: errorMessage ? 'Instruction failed' : 'Pending signature' }), _jsx(FormResultAccent, { children: "Almost there" }), _jsxs(FormResultRegular, { children: ["To confirm your DCT creation request, please sign this", _jsx("br", {}), " this transaction."] }), errorMessage && (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) })), pledge && (_jsx("div", { className: s.buttonContainer, children: _jsx(NavLink, { to: `/accounts/${pledge.portfolioId}/transactions?orderId=${pledge.processingOrderId}`, children: _jsx(Button, { fullWidth: true, text: "Sign", size: "xl" }) }) })), pledge?._embedded?.order && (_jsx(CancelOrder, { forceShow: true, showSuccessMessage: true, order: pledge._embedded.order }))] }) }));
};
