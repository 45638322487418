export const loadData = (keysToLoad) => {
    try {
        const recoveredStore = {};
        keysToLoad.forEach((key) => {
            const serializedStoreKey = localStorage.getItem(key);
            if (serializedStoreKey) {
                recoveredStore[key] = JSON.parse(serializedStoreKey);
            }
        });
        return recoveredStore;
    }
    catch (_) {
        return {};
    }
};
export const setKeyValue = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    }
    catch (_) { }
};
export const removeKeyValue = (key) => {
    localStorage.removeItem(key);
};
export const getKeyValue = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value) {
            return JSON.parse(value);
        }
    }
    catch (_) { }
};
export const getValueByKey = (key) => {
    let keyValue = {};
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue !== null) {
            keyValue = JSON.parse(serializedValue);
        }
    }
    catch (_) { }
    return keyValue;
};
export const saveAuthData = ({ ...args }) => {
    try {
        const currentValues = getKeyValue('auth');
        const serializedStoreKey = JSON.stringify({
            ...(typeof currentValues === 'object' ? currentValues : {}),
            ...args
        });
        localStorage.setItem('auth', serializedStoreKey);
    }
    catch (error) {
        console.error(error);
    }
};
