import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { setCssProperty } from '@copper/utils/styles';
import { BulkSettlementPopup } from './Popup';
import { Result } from './Result';
import { UploadStep } from './UploadStep';
import s from './Bulk.module.css';
const SettleBulk = () => {
    const { modalContext, machineState } = useBulkOrder();
    const { setTopPanel } = useTopPanel();
    useEffect(() => {
        setCssProperty('--toolbar-extend-width', '316px');
        return () => setCssProperty('--toolbar-extend-width', '0px');
    }, []);
    useEffect(() => {
        let subTitle = 'Single step';
        if (machineState.context === 'result') {
            subTitle = 'Settlement instruction created';
        }
        setTopPanel({
            title: 'Settle',
            subTitle
        });
    }, [machineState.context]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [machineState.context === 'data' && _jsx(UploadStep, {}), machineState.context === 'result' && _jsx(Result, {})] }), modalContext && _jsx(BulkSettlementPopup, {})] }));
};
export { SettleBulk };
