import { request } from '@copper/utils';
export const normalizeAccountActivitiesParams = ({ activityTypes, userIds, ...params }) => ({
    ...params,
    activityTypes: activityTypes?.join(','),
    userIds: userIds?.join(',')
});
export const getAccountActivities = ({ params, headers }) => request.get(`/organizations/${params.organizationId}/activities`, {
    params: normalizeAccountActivitiesParams(params),
    headers
});
