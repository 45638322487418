import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const XRD = {
    title: 'XRD',
    id: 'xrd',
    qa: [
        {
            question: 'How do I stake XRD?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar and then select Radix (XRD)." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 100 XRD)." }), _jsx("li", { children: "Choose a validator and enter their address in the validator address field." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }) }))
        },
        {
            question: 'How do I unstake XRD?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Specify the amount to unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 7-day unbonding period." }), _jsx(Alert, { message: "Your stake will appear under the Pending tab for the duration of the unbonding period. At the end of the unbonding period, your XRD staking details will be shown under Outstanding.", type: "info" }), _jsx("li", { children: "Go to the Outstanding tab and claim your unstaked XRD." }), _jsx("li", { children: "Sign once more to confirm." })] }) }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for XRD?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 100 XRD, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "100 XRD" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn XRD staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "XRD rewards are issued and accrue every 4 minutes approximately, at the end of each epoch." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 4 minutes" })] }) }) })] }))
        },
        {
            question: 'How can I claim my XRD staking rewards?',
            answer: (_jsx(_Fragment, { children: _jsx("p", { children: "Rewards for XRD are automatically restaked. You will need to unstake assets to claim rewards. See 'How do I unstake XRD?'" }) }))
        }
    ]
};
