import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNft, getNftsCollection } from '@copper/api/nfts';
const initialState = {
    nftCollection: []
};
export const fetchNftsCollection = createAsyncThunk('nfts/getNftsCollection', async ({ organizationId, portfolioId }, { rejectWithValue }) => {
    try {
        const { data } = await getNftsCollection({
            organizationId,
            portfolioId
        });
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const fetchNft = createAsyncThunk('nfts/getNft', async ({ nftId }, { rejectWithValue }) => {
    try {
        const { data } = await getNft({ nftId });
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const nftSlice = createSlice({
    name: 'nfts',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchNftsCollection.fulfilled, (state, { payload }) => {
            state.nftCollection = payload;
        });
    }
});
const { reducer } = nftSlice;
export const nfts = reducer;
