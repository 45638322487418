import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { useMatches } from 'react-router-dom';
import { Breadcrumb } from './Breadcrumb';
import s from './Breadcrumbs.module.css';
const Breadcrumbs = () => {
    const matchesWithCrumbs = useMatches().filter(({ handle }) => handle && typeof handle === 'object' && 'crumb' in handle);
    return (_jsx("ul", { className: s.breadcrumbs, children: matchesWithCrumbs.map(({ id, params, pathname, handle }) => {
            if (!handle || typeof handle !== 'object' || !('crumb' in handle)) {
                return null;
            }
            if (typeof handle.crumb === 'function') {
                return _jsx(Fragment, { children: handle.crumb(params, pathname) }, id);
            }
            if (typeof handle.crumb === 'string') {
                return (_jsx(Breadcrumb, { pathname: pathname, children: handle.crumb }, `breadcrumb-${id}`));
            }
        }) }));
};
export { Breadcrumbs };
