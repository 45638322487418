import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import s from './ButtonLink.module.css';
const ButtonLink = React.forwardRef((props, ref) => {
    const { text = '', onClick, size = 'm', color, className, disabled, testId, strong, leftSection, rightSection, ...otherProps } = props;
    return (_jsxs("button", { ...otherProps, ref: ref, type: "button", disabled: disabled, onClick: onClick, "data-testid": testId, className: cn(s.button, s[`size_${size}`], s[`color_${color}`], className, {
            [s.strong]: strong
        }), children: [Boolean(leftSection) && _jsx("span", { className: s.leftSection, children: leftSection }), _jsx("span", { className: s.text, children: text }), Boolean(rightSection) && _jsx("span", { className: s.rightSection, children: rightSection })] }));
});
ButtonLink.displayName = 'ButtonLink';
export { ButtonLink };
