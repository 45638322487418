import { useRef } from 'react';
let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const useComponentId = (prefix) => {
    const idRef = useRef();
    if (idRef.current === undefined) {
        idRef.current = getUniqueId();
    }
    return `${prefix}-${idRef.current}`;
};
