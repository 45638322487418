import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { CounterpartyNetworkCard } from '../CounterpartyNetworkCard';
import s from './NetworkPortfolioSelect.module.css';
export const NetworkPortfolioSelect = ({ showTitle = false, showEditMessage = false, counterparty }) => {
    const { control, formState: { errors } } = useFormContext();
    return (_jsxs(_Fragment, { children: [showTitle && _jsx("div", { className: s.title, children: "Set settlement account" }), _jsx("div", { className: s.description, children: "Designate the account you'd like to use when transacting with this counterparty." }), _jsx(PortfolioSelect, { name: "portfolio", label: "Designated account", types: ['trading-vault', 'trading-default'], control: control, errorMessage: errors.portfolio?.message, testId: "select-network-account", view: "primary", hasUnavailableToggle: false, initialPortfolioId: counterparty?._embedded?.counterpartyConnection?.receiverPortfolioId ||
                    counterparty?._embedded?.counterpartyConnection?.requestorPortfolioId ||
                    '' }), showEditMessage && (_jsx("div", { className: s.smallDescription, children: "You can change this at any time from your counterparty's network profile." })), counterparty && (_jsxs("div", { className: s.counterpartyContainer, children: [_jsx("div", { className: s.label, children: "Counterparty" }), _jsx(CounterpartyNetworkCard, { counterparty: counterparty, readOnly: true, form: "xs" })] }))] }));
};
