import { request } from '@copper/utils';
export const getTravelRulesByName = (exhangeName) => request.get('/travel-rule/vasp', {
    params: {
        name: exhangeName
    }
});
export const checkTravelRules = (cryptoAddress) => {
    const { mainCurrency, currency, address } = cryptoAddress;
    return request.get(`/travel-rule/addresses/address-ownerships/${address}`, {
        params: {
            currency,
            mainCurrency
        }
    });
};
