import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { PasskeyIcons } from '@copper/components/Auth/Passkey/PasskeyIcons';
import { createPasskeyChallenge } from '@copper/entities/auth/auth-actions';
import { Button, ButtonLink } from '@copper/ui-kit';
import { get, parseRequestOptionsFromJSON } from '@github/webauthn-json/browser-ponyfill';
import s from './PasskeyTwoFa.module.css';
export const PasskeyTwoFa = ({ email, errorMessage, requestedAction, target, onSuccess, onSkipPasskey, description, challengeAuthParams }) => {
    const [challengeIsPending, setChallengeIsPending] = useState(false);
    const passkeyButtonRef = useRef(null);
    const handlePasskeySubmit = useCallback(async () => {
        try {
            setChallengeIsPending(true);
            const challenge = await createPasskeyChallenge({
                email,
                target,
                action: requestedAction
            }, challengeAuthParams);
            const credential = await get(parseRequestOptionsFromJSON(challenge));
            return onSuccess(btoa(JSON.stringify({
                credential,
                target
            })));
        }
        finally {
            setChallengeIsPending(false);
        }
    }, [requestedAction, email]);
    useEffect(() => {
        setTimeout(() => passkeyButtonRef && passkeyButtonRef?.current?.focus(), 0);
    }, [passkeyButtonRef]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.passkeyIcons, children: _jsx(PasskeyIcons, {}) }), errorMessage && _jsx("div", { className: s.errorMessage, children: errorMessage }), description && _jsx("div", { className: s.description, children: description }), _jsx("div", { className: s.link, children: _jsx(ButtonLink, { size: "m", text: "Try another way", onClick: onSkipPasskey }) }), _jsx("div", { className: s.passkeyButton, children: _jsx(Button, { ref: passkeyButtonRef, fullWidth: true, size: "xl", onClick: handlePasskeySubmit, loading: challengeIsPending, text: "Continue" }) })] }));
};
