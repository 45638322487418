import { useDispatch } from 'react-redux';
import { fetchCustodyVersion, fetchNewCustodyVersion, fetchUserCustodyVersion } from '@copper/entities/custody/custody-reducer';
export const useFetchAppVersion = () => {
    const dispatch = useDispatch();
    return (userId) => Promise.all([
        dispatch(fetchCustodyVersion()),
        dispatch(fetchNewCustodyVersion()),
        dispatch(fetchUserCustodyVersion({ userId }))
    ]);
};
