import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectIsAuthorized } from '@copper/entities/auth/auth-selector';
const RequireAuth = ({ children }) => {
    const location = useLocation();
    const IsAuthorized = useSelector(selectIsAuthorized);
    if (IsAuthorized) {
        return _jsx(_Fragment, { children: children });
    }
    // Remember the direct link url to restore it after log in
    const { fromUrl = `${location.pathname}${location.search}` } = location.state ?? {};
    return _jsx(Navigate, { to: "/login", replace: true, state: { fromUrl } });
};
export { RequireAuth };
