import { Secret, TOTP } from 'otpauth';
export const generateSecret = (email) => {
    const totp = new TOTP({
        issuer: window.location.hostname,
        label: `${email.toLowerCase()}`
    });
    return {
        base32: totp.secret.base32,
        otpauth_url: totp.toString()
    };
};
export const verifyingToken = (token, secret) => {
    const totp = new TOTP({
        secret: Secret.fromBase32(secret.base32)
    });
    const delta = totp.validate({ token });
    return delta !== null;
};
export const getPriorityTwoFaType = (twoFaTypes, ignorePasskey = false) => {
    if (twoFaTypes?.includes('passkey') && !ignorePasskey) {
        return 'passkey';
    }
    if (twoFaTypes?.includes('yubikey')) {
        return 'yubikey';
    }
    return 'totp';
};
