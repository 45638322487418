import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { debounce } from 'lodash-es';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { calculatePledge } from '@copper/entities/oxygen-pledges/pledges-actions';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
export const usePledgeCalculation = () => {
    const { openSnackbar } = useSnackBar();
    const currenciesRates = useSelector(selectRates);
    const [pledgeCalculation, setPledgeCalculation] = useState();
    const fetchPledgeCalculation = useCallback(debounce(async (data) => {
        try {
            const pledgeCalculation = await calculatePledge(data);
            const totalCollateralMarketValue = data.collaterals.reduce((acc, collateral) => {
                const rate = currenciesRates[collateral.currency] ?? 1;
                const collateralMarketValue = new BigNumber(collateral.amount).times(rate);
                return new BigNumber(acc).plus(collateralMarketValue).toFixed();
            }, '0');
            const DCTRate = currenciesRates['DCT'] ?? 1;
            const maxDebtMarketValue = new BigNumber(pledgeCalculation?.maxDebt ?? 0)
                .times(DCTRate)
                .toFixed();
            const minRatio = new BigNumber(totalCollateralMarketValue)
                .div(maxDebtMarketValue ?? 1)
                .toFixed(8);
            setPledgeCalculation({
                ...pledgeCalculation,
                minRatio
            });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    }, 1000), []);
    return {
        pledgeCalculation,
        fetchPledgeCalculation
    };
};
