import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import s from './Checkbox.module.css';
const Checkbox = ({ name, checked, disabled = false, intermediate = false, align = 'left', view = 'primary', label = '', testId, onChange, activeWrapper = true }) => {
    const handleChange = (event) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };
    const Component = activeWrapper ? 'label' : 'div';
    return (_jsxs(Component, { className: cn(s.container, align === 'right' && s.containerRight, disabled && s.disabledContainer), "data-testid": testId, children: [_jsx("div", { className: s.inputWrapper, children: _jsx("input", { type: "checkbox", name: name, checked: checked, disabled: disabled, className: cn(s.input, intermediate && s.input_intermediate, disabled && s.disabledInput), onChange: handleChange }) }), label && (_jsx("span", { className: cn(s.label, s[`label_view_${view}`], s[`label_align_${align}`], disabled && s.disabledText), children: label }))] }));
};
export { Checkbox };
