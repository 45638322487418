import * as Yup from 'yup';
export const validationSchema = Yup.object()
    .shape({
    currency: Yup.object().shape({ currency: Yup.string().required('Select an asset.') }),
    account: Yup.object().shape({
        portfolioId: Yup.string().required('Select an account.')
    }),
    amount: Yup.string().required('Enter an amount.'),
    bankAccount: Yup.object().shape({
        accountName: Yup.string().required('Select a bank account.')
    }),
    fromBankAccount: Yup.object().when('account', {
        is: (account) => account?.portfolioType !== 'oxygen-vault',
        then: (schema) => schema.shape({
            bankAccountName: Yup.string().required('Select a bank account.')
        })
    })
})
    .required();
