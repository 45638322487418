import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { Checkbox, IconArrowDown, IconTick } from '@copper/ui-kit';
import s from './Option.module.css';
export const Option = ({ data, isHighlighted, isSelectedComponent = false, getKey, getIcon, getBottomRow, getRightSection, getTopRow, paddingLeft, reversedStyles, selected, testId, view, ...restProps }) => {
    const isReversedStyles = typeof reversedStyles === 'function' ? reversedStyles(data) : reversedStyles ?? false;
    const bottomRowContent = getBottomRow && getBottomRow(data, isSelectedComponent);
    const topRowContent = getTopRow && getTopRow(data, isSelectedComponent);
    const hasNodes = data instanceof Object ? data.hasNodes : false;
    const nestingLevel = data instanceof Object ? data.nestingLevel : 0;
    const toggleNode = data instanceof Object ? data.toggleNode : undefined;
    const isOpenNodes = data instanceof Object ? data.isOpenNodes : false;
    return (_jsxs("div", { className: cn(s.option, {
            [s.highlighted]: isHighlighted,
            [s.noPaddings]: isSelectedComponent,
            [s.paddingLeft]: !isSelectedComponent && paddingLeft === 'l'
        }), ...restProps, "data-testid": testId ? `${testId}-option-${getKey(data)}` : undefined, "data-node-key": !isSelectedComponent && hasNodes ? getKey(data) : undefined, children: [_jsxs("div", { className: cn(s.dataContainer, !isSelectedComponent && s[`nestingLevel_${nestingLevel}`]), children: [Boolean(!isSelectedComponent && hasNodes) && (_jsx("button", { type: "button", className: s.nestingButton, onClick: (e) => {
                            e.stopPropagation();
                            toggleNode?.();
                        }, children: _jsx("span", { className: cn(s.nestingArrow, { [s.nestingArrowOpen]: isOpenNodes }), children: _jsx(IconArrowDown, { width: "16px", height: "16px" }) }) })), getIcon && _jsx("div", { className: s.iconContainer, children: getIcon(data, isSelectedComponent) }), _jsxs("div", { className: s.textContainer, children: [topRowContent && (_jsx("div", { className: cn(s[`text_${view}`], {
                                    [s.topText]: bottomRowContent,
                                    [s.text]: !isReversedStyles,
                                    [s.accentText]: isReversedStyles
                                }), children: topRowContent })), bottomRowContent && (_jsx("div", { className: cn(s[`text_${view}`], {
                                    [s.text]: isReversedStyles,
                                    [s.accentText]: !isReversedStyles
                                }), children: bottomRowContent }))] })] }), getRightSection && _jsx("div", { className: s.rightSection, children: getRightSection(data) }), Array.isArray(selected) ? (_jsx(_Fragment, { children: !restProps.disabled && (_jsx(Checkbox, { name: getKey(data), activeWrapper: false, checked: selected.some((item) => getKey(item) === getKey(data)) })) })) : (_jsx(_Fragment, { children: selected && getKey(selected) === getKey(data) && (_jsx("div", { className: s.selectedTick, children: _jsx(IconTick, {}) })) }))] }));
};
