import { useEffect, useState } from 'react';
export const useIntersection = (ref, options, disconnectAfterIntersection = false) => {
    const [intersectionObserverEntry, setIntersectionObserverEntry] = useState();
    useEffect(() => {
        if (ref?.current && typeof IntersectionObserver === 'function') {
            const observer = new IntersectionObserver((entries) => {
                setIntersectionObserverEntry(entries.reverse()[0]);
                if (entries[0].isIntersecting && disconnectAfterIntersection) {
                    observer.disconnect();
                }
            }, { threshold: options?.threshold ?? 0 });
            observer.observe(ref.current);
            return () => observer.disconnect();
        }
        return undefined;
    }, [options?.threshold, disconnectAfterIntersection, ref]);
    return intersectionObserverEntry;
};
