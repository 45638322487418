import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Transition from 'react-transition-group/Transition';
const TransitionAnimation = ({ inProp, onEnter, onEntering, onEntered, onExit, onExiting, onExited, children, nodeRef }) => {
    // const nodeRef = useRef<HTMLElement>(null);
    const normalizeCallback = (callback) => () => {
        if (callback && nodeRef?.current) {
            callback(nodeRef.current);
        }
    };
    const handleEnter = normalizeCallback(onEnter);
    const handleEntering = normalizeCallback(onEntering);
    const handleEntered = normalizeCallback(onEntered);
    const handleExit = normalizeCallback(onExit);
    const handleExiting = normalizeCallback(onExiting);
    const handleExited = normalizeCallback(onExited);
    return (_jsx(Transition, { timeout: 300, in: inProp, nodeRef: nodeRef, onEnter: handleEnter, onEntered: handleEntered, onEntering: handleEntering, onExit: handleExit, onExited: handleExited, onExiting: handleExiting, children: typeof children === 'function' ? ((status) => children(status)) : (_jsx("div", { children: children })) }));
};
export { TransitionAnimation };
