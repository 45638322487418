export const detectOS = async () => {
    if (navigator.userAgent.indexOf('Win') !== -1)
        return 'Windows';
    if (navigator.userAgent.indexOf('Mac') !== -1) {
        try {
            if (
            // @ts-expect-error navigator has no userAgentData yet
            (await navigator.userAgentData?.getHighEntropyValues?.(['architecture']))?.architecture ===
                'arm') {
                return 'MacOS ARM';
            }
        }
        catch (_) { }
        return 'MacOS';
    }
    if (navigator.userAgent.indexOf('Linux') !== -1)
        return 'Linux';
    if (navigator.userAgent.indexOf('X11') !== -1)
        return 'UNIX';
};
export const detectBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('edg/'))
        return 'Edge';
    if (userAgent.includes('chrome'))
        return 'Chrome';
    if (userAgent.includes('firefox'))
        return 'Firefox';
    if (userAgent.includes('safari'))
        return 'Safari';
    return 'Unknown';
};
