import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const CCD = {
    title: 'CCD',
    id: 'ccd',
    qa: [
        {
            question: 'How do I stake CCD?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "There are two options to stake CCD on the Copper Platform. (1) Active Staking \u2013 this allows you to stake to any public staking pool (baker) on the Concordium Network. And (2) Passive Staking \u2013 this allows you to distribute your stake amongst all available bakers in the network." }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Option 1 \u2013 Active Staking" }) }), _jsx("p", { className: s.spaceAfter, children: "If you would like to conduct Active Staking:" }), _jsxs("ol", { className: s.ol, children: [_jsxs("li", { children: ["Determine your chosen baker (", _jsx("a", { href: "https://ccdscan.io/staking", rel: "noreferrer", target: "_blank", children: "view list of available bakers" }), ")."] }), _jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Enter the baker ID in the validator address field." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Option 2 \u2013 Passive Staking" }) }), _jsx("p", { className: s.spaceAfter, children: "If you would like to conduct Passive Staking:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Enter the word \u201Cpassive\u201D in the validator address field." }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx(Alert, { type: "info", message: "A single wallet can only hold one stake position \u2013 either a passive stake or an active stake to a single baker." })] })] }))
        },
        {
            question: 'How do I unstake CCD?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can choose to unstake your CCD at any point. We support partial unstaking, so you can unstake any specified amount." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the staking dashboard, select your CCD validator (baker)." }), _jsx("li", { children: "Select Unstake and specify the amount to unstake." }), _jsx("li", { children: "Click confirm and sign the unstake transaction using Copper Unlimited." }), _jsx("li", { children: "Wait out the 14-day unbonding period." })] }), _jsx("p", { className: s.spaceBefore, children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for CCD?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 14,000 CCD, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "14,000 CCD" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn CCD staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Rewards are distributed on the Concordium network every 24 hours at around 09:00AM GMT. These rewards will appear in your transaction history on the Copper Dashboard and will be available within downloadable reports." }), _jsx("p", { children: "Rewards on the Concordium network are automatically restaked \u2013 in other words, they are auto-compounding. Therefore, if you would like to transfer rewards, unstaking must first occur." })] }))
        },
        {
            question: 'How can I claim my CCD staking rewards?',
            answer: (_jsx("p", { children: "Rewards for CCD are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }))
        }
    ]
};
