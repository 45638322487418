import { getBaseStepStatus } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/helpers';
export const getLitePvpFlowStep = (order, status) => {
    switch (status) {
        case 'require-counterparty-approve':
            return 'offerReview';
        case 'waiting-addresses': {
            if (order.extra?.receivingAddressId && order.extra.returningAddressId)
                return 'addressReview';
            if (order.extra?.receivingAddressId)
                return 'setReturningAddress';
            return 'setReceivingAddress';
        }
        case 'waiting-funds':
        case 'ready-for-settlement':
            return 'counterpartyDeposit';
        case 'settled':
        case 'processing':
            return 'processing';
        case 'executed':
            return 'completed';
    }
};
export const getInitiatorPvpStep = (order, status) => {
    switch (status) {
        case 'co-sign-require':
            return 'awaitingCoSign';
        case 'waiting-counterparty-approve':
            return 'offerReview';
        case 'waiting-addresses': {
            if (order.extra?.receivingAddressId && order.extra.returningAddressId)
                return 'addressReview';
            return 'awaitingAddresses';
        }
        case 'waiting-counterparty-settlement':
            return 'counterpartyDeposit';
        case 'processing':
        case 'part-signed-tx-added':
            return 'processing';
        case 'awaiting-settlement':
        case 'working':
            return 'sign';
        case 'executed':
            return 'completed';
        case 'ready-for-settlement':
            return 'startSettlement';
    }
};
export const isStepDisabled = ({ currentStepIndex, index }) => currentStepIndex < index;
export const getStepStatus = ({ order, currentStepIndex, index }) => {
    const isCanceled = ['error', 'canceled', 'rejected'].includes(order.status);
    const baseStepStatus = getBaseStepStatus(currentStepIndex, index);
    if (isCanceled && currentStepIndex <= index) {
        return 'canceled';
    }
    return baseStepStatus;
};
