import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { BigNumber } from 'bignumber.js';
import cn from 'classnames';
import { EmptyFallback } from '@copper/components';
import { getLoanLtvColor } from '@copper/components/Loans/helpers';
import s from './LoanLtv.module.css';
const formatLtv = (value, prefix) => {
    if (value) {
        const bigNumber = new BigNumber(value);
        if (bigNumber.gt(0)) {
            const formattedLtv = bigNumber.toFormat(2, BigNumber.ROUND_HALF_EVEN);
            return `${prefix} ${formattedLtv}%`;
        }
    }
};
const LoanLtv = ({ loan, prefix = 'LTV' }) => {
    const loanLtvColor = getLoanLtvColor(loan);
    return (_jsx("div", { className: cn(s.ltv, s[`color_${loanLtvColor}`]), children: _jsx(EmptyFallback, { children: formatLtv(loan.ltvPercentage, prefix) }) }));
};
export { LoanLtv };
