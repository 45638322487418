export const getDynamicMobileBannerStyle = (top = 0, defaultTop = 0) => top === 0
    ? {
        top: `${defaultTop}px`,
        opacity: 1
    }
    : top > 0
        ? {
            top: `${top - 60}px`,
            opacity: 1,
            transition: 'opacity .3s'
        }
        : {
            top: '0',
            opacity: 0,
            transition: 'all .3s'
        };
