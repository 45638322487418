import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { Button } from '@copper/ui-kit';
import s from './SelectAccountForm.module.css';
const SelectAccountForm = ({ form, onSubmit }) => {
    const { control, handleSubmit, formState: { errors, isSubmitting } } = form;
    const formId = 'loan-select-account-form';
    return (_jsx(FormProvider, { ...form, children: _jsxs("form", { id: formId, onSubmit: handleSubmit(onSubmit), children: [_jsx(PortfolioSelect, { view: "primary", name: "portfolio", label: "Account", types: ['trading-vault', 'trading-default'], control: control, errorMessage: errors?.portfolio?.portfolioId?.message, testId: "loan-portfolio" }), _jsx("div", { className: s.buttonContainer, children: _jsx(Button, { loading: isSubmitting, text: "Confirm", type: "submit" }) })] }) }));
};
export { SelectAccountForm };
