export const normalizeWallets = (wallets = []) => {
    const result = {
        wallets: {},
        walletIdsByPortfolioId: {}
    };
    wallets.forEach((wallet) => {
        result.wallets[wallet.walletId] = wallet;
        if (Array.isArray(result.walletIdsByPortfolioId[wallet.portfolioId])) {
            result.walletIdsByPortfolioId[wallet.portfolioId].push(wallet.walletId);
        }
        else {
            result.walletIdsByPortfolioId[wallet.portfolioId] = [wallet.walletId];
        }
    });
    return result;
};
