export const isSomeActionAvailable = ({ authorizedActions, actions = [], portfolioIds }) => actions.some((actionName) => {
    const actionValue = authorizedActions[actionName];
    // permission not exist
    if (!actionValue) {
        return false;
    }
    // permission exist but not need to check portfolios limitations because they not passed into function
    if (!portfolioIds?.length) {
        return true;
    }
    const { includingPortfolios, excludingPortfolios } = actionValue;
    if (excludingPortfolios) {
        return !portfolioIds.some((portfolioId) => portfolioId in excludingPortfolios);
    }
    if (includingPortfolios) {
        return portfolioIds.some((portfolioId) => portfolioId in includingPortfolios);
    }
    // portfolios limitations passed into function but action don't have any specific portfolios limitation
    return true;
});
export const getPermissionGroupsFromSet = (permissionSets) => Object.keys(permissionSets.reduce((acc, { permissions = [] }) => {
    const availableGroups = permissions.reduce((groupsAcc, { allow, groupId }) => {
        if (allow && typeof groupId === 'string') {
            return { ...groupsAcc, [groupId]: true };
        }
        return groupsAcc;
    }, {});
    return { ...acc, ...availableGroups };
}, {}));
