export const getScrollBarWidth = () => {
    const el = document.createElement('div');
    const { style } = el;
    style.display = 'block';
    style.position = 'absolute';
    style.width = '100px';
    style.height = '100px';
    style.left = '-999px';
    style.top = '-999px';
    style.overflow = 'scroll';
    document.body.insertBefore(el, null);
    const { clientWidth } = el;
    const scrollbarWidth = 100 - clientWidth;
    document.body.removeChild(el);
    return scrollbarWidth;
};
