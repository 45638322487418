import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { AssetIcon, EmptyFallback } from '@copper/components';
import { AssetInfoWrapper } from '@copper/components/AssetInfoWrapper';
import { oxygenMainNetwork } from '@copper/constants/currency';
import { selectCurrenciesEntities, selectTokenEntities } from '@copper/entities/currencies/currencies-selector';
import { getCurrencyName } from '@copper/helpers';
import s from './AssetInfo.module.css';
export const AssetInfo = ({ align, currency, secondCurrency, mainCurrency, additionalTitleInfo, view = 'column', iconSize = 's', separator = '→', hideSubTitle = false, truncateTitles }) => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const tokenEntities = useSelector(selectTokenEntities);
    const withTruncateTitles = typeof truncateTitles === 'boolean' ? truncateTitles : Boolean(currency && secondCurrency);
    const title = () => {
        if (typeof secondCurrency === 'string') {
            return (_jsxs(_Fragment, { children: [_jsx(EmptyFallback, { children: currency }), ' ', _jsx("span", { className: cn(!currency && !secondCurrency && s.separatorDisabled), children: separator }), ' ', _jsx(EmptyFallback, { children: secondCurrency })] }));
        }
        else if (mainCurrency) {
            return (_jsxs("div", { children: [_jsxs("div", { className: cn(s.title, withTruncateTitles && s.titleTruncated), children: [currency && getCurrencyName(currency, currenciesEntities), _jsx("span", { className: s.ticker, children: _jsx(EmptyFallback, { children: currency }) })] }), _jsxs("div", { className: cn(s.small, s.title, withTruncateTitles && s.titleTruncated), children: [mainCurrency, _jsx("span", { className: cn(s.ticker, s.inlineTicker), children: _jsx(EmptyFallback, { children: getCurrencyName(mainCurrency, currenciesEntities) }) })] })] }));
        }
        else if (currency) {
            return (_jsxs(_Fragment, { children: [getCurrencyName(currency, currenciesEntities), additionalTitleInfo] }));
        }
    };
    return (currency && (_jsxs(AssetInfoWrapper, { title: title(), subtitle: Boolean(mainCurrency || hideSubTitle)
            ? undefined
            : tokenEntities[currency + '-' + oxygenMainNetwork]?.extra?.tokenizedFrom || currency, align: align, view: view, truncateTitles: withTruncateTitles, children: [_jsx("div", { className: s.icon, children: _jsx(AssetIcon, { currency: currency, size: iconSize }) }), typeof secondCurrency === 'string' && (_jsx("div", { className: s.secondIcon, children: _jsx(AssetIcon, { currency: secondCurrency, size: iconSize }) }))] })));
};
