import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { Option } from '@copper/ui-kit/Select/Option';
import s from './SelectedComponent.module.css';
export const SelectedComponent = ({ getKey, getIcon, getTopRow, getBottomRow, getRightSection, selected, reversedStyles, placeholder, fieldTheme, view }) => {
    if (Array.isArray(selected) && selected.length) {
        return (_jsx("div", { className: s.selectedText, children: selected.length === 1
                ? getBottomRow?.(selected?.[0], true) ?? getTopRow?.(selected?.[0], true)
                : `${selected.length} selected` }));
    }
    if (selected && !Array.isArray(selected)) {
        return (_jsx(Option, { view: view, data: selected, isSelectedComponent: true, getIcon: getIcon, getKey: getKey, getTopRow: getTopRow, getBottomRow: getBottomRow, getRightSection: getRightSection, reversedStyles: reversedStyles }));
    }
    return _jsx("div", { className: cn(s.placeholderText, s[`field_${fieldTheme}`]), children: placeholder ?? '' });
};
