import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormResultFrame } from '@copper/components/FormResultFrame';
import { Alert } from '@copper/ui-kit';
import { AssetList } from '../AssetList';
import s from './Result.module.css';
export const Result = ({ transactions, errorMessage, assets, onDone }) => {
    const nonZeroAssets = assets?.filter((asset) => Number(asset.available) > 0);
    const rejectedTransactions = transactions
        ?.filter((transaction) => transaction?.value?.meta?.requestStatus === 'rejected')
        .map((transaction) => transaction?.value?.meta?.arg?.baseCurrency);
    const rejectedAssets = nonZeroAssets?.filter((asset) => rejectedTransactions?.includes(asset.currency));
    const executedTransactions = transactions
        ?.filter((transaction) => transaction?.value?.meta?.requestStatus === 'fulfilled' ||
        transaction?.value?.meta?.requestStatus === 'executed')
        .map((transaction) => transaction?.value?.meta?.arg?.baseCurrency);
    const executedAssets = nonZeroAssets?.filter((asset) => executedTransactions?.includes(asset.currency));
    const isAllExecuted = transactions?.every((order) => order?.value?.meta?.requestStatus === 'fulfilled' ||
        order?.value?.meta?.requestStatus === 'executed');
    const isAllRejected = transactions?.every((order) => order?.value?.meta?.requestStatus === 'rejected');
    const getResultStatus = () => {
        if (errorMessage || isAllRejected) {
            return 'failure';
        }
        if (!isAllRejected && !isAllExecuted) {
            return 'pending';
        }
        return 'success';
    };
    const getHeadingText = () => {
        if (errorMessage || isAllRejected) {
            return 'Migrate failed';
        }
        if (!isAllRejected && !isAllExecuted) {
            return 'Some transactions failed';
        }
        return 'Your assets have been sent';
    };
    const status = getResultStatus();
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx("h3", { className: s.resultHeading, children: getHeadingText() }), !isAllRejected && !isAllExecuted && !errorMessage && (_jsxs("p", { className: s.resultSubHeading, children: ["We could only process ", executedTransactions?.length, " out of ", transactions?.length, ' ', "transactions."] })), _jsxs("div", { className: s.container, children: [rejectedAssets && rejectedAssets.length > 0 ? (_jsx(AssetList, { assets: rejectedAssets, status: "error" })) : (''), executedAssets && executedAssets.length > 0 ? (_jsx(AssetList, { assets: executedAssets, status: "success" })) : ('')] }), errorMessage && (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) }))] }));
};
