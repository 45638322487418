import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { BulkOrderProvider } from '@copper/client/components/BulkOrderTools';
import { MigrateFunds } from '@copper/client/components/MigrateFunds';
import { Rules } from '@copper/client/components/Rules';
import { TransferBulk } from '@copper/client/components/TransferFunds/Bulk';
import { TransferSingle } from '@copper/client/components/TransferFunds/Single';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { ButtonIcon, Dropdown, IconMore, Tabs } from '@copper/ui-kit';
import s from './Transfer.module.css';
const MORE_ITEMS = [
    {
        title: 'Move all assets from exchange',
        value: 'migration'
    }
];
const Transfer = () => {
    const { route } = useRoutingContext();
    const [activeTab, setActiveTab] = useState((route?.name === 'transfer' && route?.data?.tab) || 'single');
    const { isAllowedInAnyPortfolio } = useCheckPermissionsContext();
    const onTabClickHandler = ({ value }) => setActiveTab(value);
    useEffect(() => {
        if (route && 'state' in route) {
            if (route.state === 'rules')
                setActiveTab('rules');
        }
    }, [route]);
    const TAB_ITEMS = [
        {
            title: 'Single',
            value: 'single'
        },
        {
            title: 'Bulk',
            value: 'bulk'
        }
    ];
    if (isAllowedInAnyPortfolio(['create-rebalancing-rules'])) {
        TAB_ITEMS.push({
            title: 'Rules',
            value: 'rules'
        });
    }
    return (_jsxs("div", { className: cn(s.container, s[`container_${activeTab}`]), children: [_jsxs("div", { className: s.tabsContainer, children: [_jsx(Tabs, { view: "green", items: TAB_ITEMS, value: activeTab, onChange: onTabClickHandler }), _jsx("div", { className: s.moreTab, children: _jsx(Dropdown, { highlightSelectedItem: false, view: "tool", getTitle: ({ title }) => title, getKey: ({ title }) => title, onChange: onTabClickHandler, items: MORE_ITEMS, getToggle: (isOpen) => (_jsxs("span", { className: s.title, children: ["More", _jsx(ButtonIcon, { state: isOpen ? 'active' : undefined, children: _jsx(IconMore, { type: "horizontal" }) })] })) }) })] }), activeTab === 'single' && _jsx(TransferSingle, {}), activeTab === 'bulk' && (_jsx(BulkOrderProvider, { children: _jsx(TransferBulk, {}) })), activeTab === 'rules' && _jsx(Rules, {}), activeTab === 'migration' && _jsx(MigrateFunds, {})] }));
};
export { Transfer };
