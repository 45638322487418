import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as rebalancingRulesApi from '@copper/api/rebalancingRules';
import { getArchivedRebalancerRules, getRebalancerRules } from '@copper/api/rules';
const initialState = {
    rulesList: [],
    archivedRulesList: []
};
export const fetchRebalancerRules = createAsyncThunk('rules/fetch', async ({ portfolioId, organizationId }, { rejectWithValue }) => {
    try {
        const { data: { rules } } = await getRebalancerRules({
            portfolioId,
            organizationId
        });
        return rules;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const fetchArchivedRebalancerRules = createAsyncThunk('archivedRules/fetch', async (organizationId, { rejectWithValue }) => {
    try {
        const { data: { rules } } = await getArchivedRebalancerRules(organizationId);
        return rules;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const createRebalancingRules = createAsyncThunk('rebalancingRules/create', async (rebalancingRuleParams, thunkApi) => {
    try {
        const response = await rebalancingRulesApi.create(rebalancingRuleParams);
        return response.data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const pauseRebalancingRule = createAsyncThunk('rebalancingRules/pause', async ({ ruleId }, thunkApi) => {
    try {
        const { data: rule } = await rebalancingRulesApi.pause(ruleId);
        return rule;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const unpauseRebalancingRule = createAsyncThunk('rebalancingRules/unpause', async ({ ruleId }, thunkApi) => {
    try {
        const { data: rule } = await rebalancingRulesApi.unpause(ruleId);
        return rule;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const archiveRebalancingRule = createAsyncThunk('rebalancingRules/archive', async ({ ruleId }, thunkApi) => {
    try {
        const { data: rule } = await rebalancingRulesApi.archive(ruleId);
        return rule;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const unArchiveRebalancingRule = createAsyncThunk('rebalancingRules/unArchive', async ({ ruleId }, thunkApi) => {
    try {
        const { data: rule } = await rebalancingRulesApi.unArchive(ruleId);
        return rule;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateRebalancingRule = createAsyncThunk('rebalancingRules/update', async ({ ruleId, data }, thunkApi) => {
    try {
        const { data: rule } = await rebalancingRulesApi.update(ruleId, data);
        return rule;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
const rulesSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {
        resetRuleList: (state) => {
            state.rulesList = [];
            state.archivedRulesList = [];
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchRebalancerRules.fulfilled, (state, { payload }) => {
            state.rulesList = payload;
        });
        addCase(pauseRebalancingRule.fulfilled, (state, { payload }) => {
            state.rulesList = state.rulesList.map((rule) => {
                if (rule.ruleId === payload.ruleId) {
                    return payload;
                }
                return rule;
            });
        });
        addCase(unpauseRebalancingRule.fulfilled, (state, { payload }) => {
            state.rulesList = state.rulesList.map((rule) => {
                if (rule.ruleId === payload.ruleId) {
                    return payload;
                }
                return rule;
            });
        });
        addCase(archiveRebalancingRule.fulfilled, (state, { payload }) => {
            state.rulesList = state.rulesList.filter((rule) => rule.ruleId !== payload.ruleId);
        });
        addCase(updateRebalancingRule.fulfilled, (state, { payload }) => {
            state.rulesList = state.rulesList.map((rule) => {
                if (rule.ruleId === payload.ruleId) {
                    return payload;
                }
                return rule;
            });
        });
        addCase(fetchRebalancerRules.rejected, (state) => {
            state.rulesList = [];
        });
        addCase(fetchArchivedRebalancerRules.fulfilled, (state, { payload }) => {
            state.archivedRulesList = payload;
        });
        addCase(fetchArchivedRebalancerRules.rejected, (state) => {
            state.archivedRulesList = [];
        });
        addCase(unArchiveRebalancingRule.fulfilled, (state, { payload }) => {
            state.archivedRulesList = state.archivedRulesList.filter((rule) => rule.ruleId !== payload.ruleId);
        });
    }
});
const { reducer, actions } = rulesSlice;
export const { resetRuleList } = actions;
export const rules = reducer;
