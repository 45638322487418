import * as Yup from 'yup';
export const validationSchema = Yup.object()
    .shape({
    origin: Yup.object().shape({
        portfolioType: Yup.string().required('Select a from account.')
    }),
    destination: Yup.object().required('Select a to account.')
})
    .required();
export function getMainCurrency(currenciesEntities, currency) {
    const mainCurrencies = currenciesEntities[currency?.currency ?? '']?.mainCurrencies || [];
    return mainCurrencies.length > 1 ? mainCurrencies[0] : undefined;
}
