import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { QrCode } from '@copper/components/QrCode';
import { selectUser } from '@copper/entities/user/user-selector';
import { ButtonLink, DropdownButton, IconQrCode } from '@copper/ui-kit';
import { getKeyValue, setKeyValue } from '@copper/utils';
import copperIOSIcon from './img/copper-ios.svg';
import s from './style.module.css';
const MobileAppBanner = ({ variant = 'sign' }) => {
    const [showBanner, setShowBanner] = useState(false);
    const { isAdmin } = useSelector(selectUser);
    useEffect(() => {
        const hideBanner = Boolean(getKeyValue('hideMobileAppBanner'));
        if (!hideBanner) {
            setShowBanner(true);
        }
    }, []);
    const handleOnDismiss = () => {
        setShowBanner(false);
        setKeyValue('hideMobileAppBanner', 'true');
    };
    if (!showBanner || isAdmin)
        return null;
    return (_jsx("div", { className: cn(s.container, {
            [s.signBackground]: variant == 'sign',
            [s.approveBackground]: variant == 'approve'
        }), children: _jsxs("div", { className: s.content, children: [_jsx("img", { src: copperIOSIcon, className: s.logo, alt: "" }), _jsx("div", { className: s.title, children: "Authorise operations faster" }), _jsx("div", { className: s.description, children: "Sign and approve transactions in seconds with the Copper iOS app - a secure and convenient way to stay in control of your accounts." }), _jsx(DropdownButton, { items: [{ label: 'ios', value: 'ios' }], listWidth: 228, text: "Download", icon: _jsx(IconQrCode, { width: 24, height: 24 }), placement: "bottom-start", size: "m", getTitle: () => (_jsxs("div", { className: s.dropdownContainer, children: [_jsx("p", { className: s.qrInfo, children: "Scan to download" }), _jsx(QrCode, { qr: "https://apps.apple.com/app/copper/id1581547104", hideFullscreenIcon: true, size: "full", qrContainerClass: s.qrContainer })] })), "data-testid": "mobile-app-promo-download" }), _jsx(ButtonLink, { size: "s", className: s.closeButton, text: "Don't show again", onClick: handleOnDismiss, "data-testid": "mobile-app-promo-do-not-show" })] }) }));
};
export { MobileAppBanner };
