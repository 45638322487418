import { request } from '@copper/utils';
export const getPledges = (params) => request.get('/oxygen/pledges', {
    params
});
export const createPledge = (data) => request.post('/oxygen/pledges', data);
export const updatePledgeMeta = (pledgeId, data) => request.patch(`/oxygen/pledges/${pledgeId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.rename+json'
    }
});
export const addPledgeCollateral = (pledgeId, data) => request.patch(`/oxygen/pledges/${pledgeId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.add-collateral+json'
    }
});
export const createMoreDct = (pledgeId, data) => request.patch(`/oxygen/pledges/${pledgeId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.create-more-dct+json'
    }
});
export const repayPledge = (pledgeId, data) => request.patch(`/oxygen/pledges/${pledgeId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.repay+json'
    }
});
export const withdrawPledgeCollateral = (pledgeId, data) => request.patch(`/oxygen/pledges/${pledgeId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.withdraw-collateral+json'
    }
});
export const calculatePledge = (data) => request.post('/oxygen/pledges', data, {
    headers: {
        'Content-Type': 'application/vnd.dry-run+json'
    }
});
