import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isValid } from 'date-fns';
import { ConfirmationPopup } from '@copper/components';
import { useLayoutState } from '@copper/contexts/layout';
import { useTwoFaContext } from '@copper/contexts/twoFa';
import { deleteApiKeyIdentity, fetchUserApiKeys } from '@copper/entities/apiKeys/apiKeys-reducer';
import { selectUserApiKeysForCC } from '@copper/entities/apiKeys/apiKeys-selector';
import { fetchSessions, killAllOtherSessions, killSession } from '@copper/entities/auth/auth-actions';
import { selectToken } from '@copper/entities/auth/auth-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { ButtonLink, useSnackBar } from '@copper/ui-kit';
import { IconCopperConnect, IconDesktop, IconTablet } from '@copper/ui-kit/icons';
import { getErrorData } from '@copper/utils';
import { TABLET } from './constants';
import { getDeviceInfo } from './helpers';
import { SkeletonLoader } from './skeleton';
import s from './CurrentSessions.module.css';
const CurrentSessions = () => {
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const currentToken = useSelector(selectToken);
    const { loadingLayout } = useLayoutState();
    const [sessions, setSessions] = useState([]);
    const [currentSession, setCurrentSession] = useState();
    const copperConnectApiKeys = useSelector(selectUserApiKeysForCC);
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [tokenIdLogout, setTokenIdLogout] = useState('');
    const [isLoading, setIsLoading] = useState(loadingLayout);
    const { requestTwoFa, resolveTwoFa, twoFaErrorCatcher } = useTwoFaContext();
    const getSessions = async () => {
        try {
            const sessions = await fetchSessions();
            setSessions(sessions.sort((a, b) => (parseInt(a.lastUsedAt) > parseInt(b.lastUsedAt) ? -1 : 1)));
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const fetchData = async () => {
        await getSessions();
        if (!copperConnectApiKeys.length) {
            await dispatch(fetchUserApiKeys()).unwrap();
        }
        setIsLoading(false);
    };
    useEffect(() => {
        !loadingLayout && fetchData();
    }, [loadingLayout]);
    const handleLogout = async () => {
        try {
            setIsLoading(true);
            if (tokenIdLogout === 'all') {
                await killAllOtherSessions(currentToken);
            }
            else {
                await killSession({ tokenId: tokenIdLogout });
            }
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setTokenIdLogout('');
            setIsLoading(false);
            getSessions();
        }
    };
    const handleRevokeCopperConnect = async (copperConnectKey) => {
        try {
            // here twoFa actully not required by backend but used more like approve of delete, maybe we should get rid of it and use confirm modal
            const twoFaCode = await requestTwoFa('update-apikey');
            await dispatch(deleteApiKeyIdentity({ twoFaCode, organizationId, apiKey: copperConnectKey.value })).unwrap();
            resolveTwoFa();
            openSnackbar({
                type: 'success',
                message: 'Copper Connect permission revoked successfully'
            });
        }
        catch (error) {
            twoFaErrorCatcher(error, () => handleRevokeCopperConnect(copperConnectKey));
        }
    };
    useEffect(() => {
        const currentSessionIndex = sessions.findIndex(({ tokenId }) => tokenId === currentToken);
        setCurrentSession(sessions[currentSessionIndex]);
    }, [sessions]);
    const renderSession = (session) => {
        const deviceInfo = getDeviceInfo(session);
        const lastUsedDate = new Date(parseInt(session.lastUsedAt));
        const current = session.tokenId === currentToken;
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.leftSide, children: [_jsx("span", { className: s.icon, children: deviceInfo.device === TABLET ? _jsx(IconTablet, {}) : _jsx(IconDesktop, {}) }), _jsxs("span", { className: s.sessionDetails, children: [_jsx("div", { className: s.title, children: `${deviceInfo.browser}, ${deviceInfo.os}` }), _jsx("div", { className: s.subTitle, children: deviceInfo.ip }), _jsx("div", { className: s.subTitle, children: current ? 'online' : isValid(lastUsedDate) && format(lastUsedDate, 'd MMM yyyy') })] })] }), !current && (_jsx("div", { className: s.rightSide, children: _jsx(ButtonLink, { text: "Log out", onClick: () => setTokenIdLogout(session.tokenId) }) }))] }));
    };
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.header, children: [_jsx("div", { className: s.mainTitle, children: "Active sessions and devices" }), _jsx("div", { className: s.subTitle, children: "You're currently logged in to your Copper account on these devices" })] }), isLoading && _jsx(SkeletonLoader, {}), !isLoading && (_jsxs(_Fragment, { children: [Boolean(copperConnectApiKeys.length) && (_jsxs("div", { className: s.section, children: [_jsx("div", { className: s.sectionTitle, children: "Copper Connect" }), _jsx("div", { className: s.sectionWrapper, children: copperConnectApiKeys.map((copperConnectKey) => {
                                    const createdAt = new Date(parseInt(copperConnectKey?.createdAt || ''));
                                    return (_jsxs("div", { className: s.session, children: [_jsxs("div", { className: s.leftSide, children: [_jsx("span", { className: s.icon, children: _jsx(IconCopperConnect, {}) }), _jsxs("span", { className: s.sessionDetails, children: [_jsx("div", { className: s.title, children: "Copper Connect" }), _jsx("div", { className: s.subTitle, children: isValid(createdAt) && `Created at ${format(createdAt, 'd MMM yyyy')}` })] })] }), _jsx("div", { className: s.rightSide, children: _jsx(ButtonLink, { text: "Log out", onClick: () => handleRevokeCopperConnect(copperConnectKey) }) })] }, copperConnectKey.value));
                                }) })] })), currentSession && (_jsxs("div", { className: s.section, children: [_jsx("div", { className: s.sectionTitle, children: "current" }), _jsxs("div", { className: s.sectionWrapper, children: [_jsx("div", { className: s.session, children: renderSession(currentSession) }), _jsxs("div", { className: s.sectionBottom, children: [_jsx(ButtonLink, { text: "Log out all other sessions", onClick: () => setTokenIdLogout('all'), size: "l" }), _jsx("div", { children: "Logs you out of Copper from every device you're currently logged in, except for this device." })] })] })] })), sessions.length > 1 && (_jsxs("div", { className: s.section, children: [_jsx("div", { className: s.sectionTitle, children: "other" }), _jsx("div", { className: s.sectionWrapper, children: sessions.map((session) => session.tokenId !== currentToken ? (_jsx("div", { className: s.session, children: renderSession(session) }, session.tokenId)) : null) })] }))] })), tokenIdLogout && (_jsx(ConfirmationPopup, { onCancel: () => setTokenIdLogout(''), onConfirm: handleLogout, isSubmitting: isLoading, title: `Log out ${tokenIdLogout === 'all' ? 'all other sessions' : 'of the session'}?` }))] }));
};
export { CurrentSessions };
