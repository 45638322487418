import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const DOT = {
    title: 'DOT',
    id: 'dot',
    qa: [
        {
            question: 'How do I stake DOT?',
            answer: (_jsxs(_Fragment, { children: [_jsx("i", { children: "Step 1 - Bonding assets" }), _jsx("p", { className: s.spaceAfter, children: "First, you\u2019ll need to bond assets to the Polkadot network." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 10 DOT)." }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the 28-day bonding period." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Step 2 - Choosing validators" }) }), _jsx("p", { className: s.spaceAfter, children: "Next, choose one or more validators to delegate your stake to." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On your dashboard, go to the Outstanding tab and select Add validator." }), _jsx(Alert, { type: "info", message: "You can stake Polkadot with up to 16 validators. The more you select, the more chances you have of earning rewards." }), _jsx("li", { children: "Sign using Copper Unlimited." })] })] }))
        },
        {
            question: 'How do I unstake DOT?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 28-day unbonding period." }), _jsx("li", { children: "Withdraw some or all of your unstaked DOT, and sign the transaction." }), _jsx("li", { children: "Claim the available amount and sign once more to confirm." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for DOT?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 10 DOT, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "10 DOT" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn DOT staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "DOT rewards are issued daily." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my DOT staking rewards?',
            answer: (_jsx(_Fragment, { children: _jsx("p", { children: "Rewards for DOT are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }) }))
        },
        {
            question: 'How do I switch validators?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the dashboard, click the row showing your DOT staking details." }), _jsx("li", { children: "Under Validators, select Change and follow on-screen instructions." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }) }))
        }
    ]
};
