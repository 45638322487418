import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ChoiceCard, ContactActionPopup, PortfolioIcon } from '@copper/components';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { IconArrowBack, IconGradientDefi } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import s from './VaultTypes.module.css';
const VaultTypes = ({ onNext }) => {
    const [isOpenContactPopup, setIsOpenContactPopup] = useState(false);
    const { checkPermissions } = useCheckPermissionsContext();
    const { organizationType } = useSelector(selectCurrentOrganization);
    return (_jsxs(PopupContent, { children: [_jsx("div", { className: s.description, children: "All our vaults use MPC technology to secure your assets whether you hold, trade or interact with DeFi." }), _jsx(ChoiceCard, { onClick: () => onNext({ portfolioType: 'custody' }), title: "Vault", description: "Secure storage for holding assets", leftSection: _jsx(PortfolioIcon, { portfolio: "custody" }), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) }), organizationType == 'standard' && (_jsx(ChoiceCard, { onClick: () => onNext({ portfolioType: 'custody', isDefiVault: true }), title: "DeFi vault", description: "Fast interaction with DeFi apps", leftSection: _jsx(IconGradientDefi, {}), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), checkPermissions(['create-trading-vault-portfolio']) && (_jsx(ChoiceCard, { onClick: () => onNext({ portfolioType: 'trading-vault' }), title: "Trading vault", description: "Secure storage for trading", leftSection: _jsx(PortfolioIcon, { portfolio: "trading-vault" }), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), checkPermissions(['create-fees-vault-portfolio']) && (_jsx(ChoiceCard, { onClick: () => onNext({ portfolioType: 'fees-vault' }), title: "Fee vault", description: "Dedicated to paying proxy deposit fees", leftSection: _jsx(PortfolioIcon, { portfolio: "fees-vault" }), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), isOpenContactPopup && (_jsx(ContactActionPopup, { title: "Please contact us to open this account", description: "Your Account Manager will be happy to help you set it up.", onClose: () => setIsOpenContactPopup(false) }))] }));
};
export { VaultTypes };
