import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getCustodyUserVersion, getCustodyVersion, getNewCustodyVersion, setCustodyUserVersion } from '@copper/api/custody';
import { getKeyValue, removeKeyValue, setKeyValue } from '@copper/utils/localstorage';
export const fetchCustodyVersion = createAsyncThunk('custody/getVersion', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getCustodyVersion();
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const fetchNewCustodyVersion = createAsyncThunk('custody/getNewVersion', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getNewCustodyVersion();
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const fetchUserCustodyVersion = createAsyncThunk('custody/getUserVersion', async ({ userId }, { rejectWithValue }) => {
    try {
        const { data } = await getCustodyUserVersion(userId);
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const updateUserCustodyVersion = createAsyncThunk('custody/setUserVersion', async ({ userId, data }, { rejectWithValue }) => {
    try {
        await setCustodyUserVersion(userId, data);
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const custodySlice = createSlice({
    name: 'custody',
    initialState: {
        legacyAppVersion: '',
        newAppVersion: '',
        userLegacyVersion: '',
        userNewVersion: '',
        deferredLegacyAppVersion: getKeyValue('upgradeDeferredVersion') ?? '',
        deferredNewAppVersion: getKeyValue('deferredNewAppVersion') ?? ''
    },
    reducers: {
        updateDeferredLegacyAppVersion: (state, { payload }) => {
            state.deferredLegacyAppVersion = payload;
            if (payload) {
                setKeyValue('upgradeDeferredVersion', payload);
            }
            else {
                removeKeyValue('upgradeDeferredVersion');
            }
        },
        updateDeferredNewAppVersion: (state, { payload }) => {
            state.deferredNewAppVersion = payload;
            if (payload) {
                setKeyValue('deferredNewAppVersion', payload);
            }
            else {
                removeKeyValue('deferredNewAppVersion');
            }
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchCustodyVersion.fulfilled, (state, { payload }) => {
            state.legacyAppVersion = payload.version;
        });
        addCase(fetchNewCustodyVersion.fulfilled, (state, { payload }) => {
            state.newAppVersion = payload.desktop.version;
        });
        addMatcher(isAnyOf(fetchUserCustodyVersion.fulfilled, updateUserCustodyVersion.fulfilled), (state, { payload }) => {
            state.userLegacyVersion = payload?.version ?? '';
            state.userNewVersion = payload?.newVersion ?? '';
        });
    }
});
const { actions, reducer } = custodySlice;
export const { updateDeferredLegacyAppVersion, updateDeferredNewAppVersion } = actions;
export const custody = reducer;
