import { getFopStepStatus, isFopStepDisabled } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/Flows/Fop/helpers';
import { isCurrencyFiat } from '@copper/helpers';
export const isReceivingCurrencyFiat = (order, currencyEntities) => (order.orderType === 'sell' && isCurrencyFiat(currencyEntities, order.quoteCurrency)) ||
    (order.orderType === 'buy' && isCurrencyFiat(currencyEntities, order.baseCurrency));
export const getLiteClientFiatStep = (order, currencyEntities, status) => {
    const isReceivingFiat = isReceivingCurrencyFiat(order, currencyEntities);
    switch (status) {
        case 'require-counterparty-approve':
            return 'offerReview';
        case 'waiting-addresses': {
            if (order.extra?.returningAddressId || order.extra?.receivingAddressId) {
                return 'addressReview';
            }
            if (isReceivingFiat) {
                return 'setReturningAddress';
            }
            return 'setReceivingAddress';
        }
        case 'waiting-funds':
        case 'ready-for-settlement':
        case 'processing':
            return 'counterpartyDeposit';
        case 'executed':
        case 'settled':
            return 'completed';
    }
};
export const getInitiatorFiatStep = (order, status) => {
    switch (status) {
        case 'co-sign-require':
            return 'awaitingCoSign';
        case 'waiting-counterparty-approve':
            return 'offerReview';
        case 'waiting-addresses': {
            if (order.extra?.receivingAddressId || order.extra?.returningAddressId) {
                return 'addressReview';
            }
            return 'awaitingAddresses';
        }
        case 'waiting-counterparty-settlement':
            return 'counterpartyDeposit';
        case 'ready-for-settlement':
            return 'startSettlement';
        case 'processing':
        case 'part-signed-tx-added':
            return 'processing';
        case 'awaiting-settlement':
        case 'working':
            return 'sign';
        case 'settled':
            return 'completed';
        case 'executed':
            return 'completed';
    }
};
export const isFiatStepDisabled = ({ currentStepIndex, index, order, stepName }) => {
    const isClient = order.portfolioType === 'trading';
    const isCurrentStep = index === currentStepIndex;
    if (stepName === 'stepName' && isClient && order.status === 'canceled' && isCurrentStep)
        return true;
    if (order.status === 'ready-for-settlement' &&
        ['counterpartyDeposit', 'awaitingCounterpartyDeposit'].includes(stepName)) {
        return false;
    }
    if (['settled', 'executed'].includes(order.status) && stepName === 'processing') {
        return false;
    }
    return isFopStepDisabled({ order, currentStepIndex, index, stepName });
};
export const getFiatStepStatus = ({ order, currentStepIndex, index, stepName, isReceivingFiat }) => {
    const isCanceled = ['error', 'canceled', 'rejected'].includes(order.status);
    if (isCanceled && currentStepIndex <= index) {
        return 'canceled';
    }
    const processingStatuses = ['ready-for-settlement', 'processing', 'waiting-funds', 'settled'];
    if (stepName === 'awaitingCounterpartyDeposit') {
        if (order.extra?.correlatedOrderSettled)
            return 'completed';
        if (processingStatuses.includes(order.status))
            return 'processing';
        return 'awaiting';
    }
    if (stepName === 'counterpartyDeposit') {
        if (!isReceivingFiat &&
            order.extra?.externalFundsReceived &&
            order.extra.externalFundsSent &&
            ['settled', 'executed'].includes(order.status)) {
            return 'completed';
        }
        if (order.status === 'settled')
            return 'completed';
        if (isReceivingFiat &&
            ['executed', 'ready-for-settlement', 'processing'].includes(order.status))
            return 'completed';
        if (processingStatuses.includes(order.status))
            return 'processing';
        return 'awaiting';
    }
    if (stepName === 'awaitingDeposit') {
        if (order.extra?.correlatedOrderSettled)
            return 'completed';
        if (processingStatuses.includes(order.status))
            return 'processing';
        return 'awaiting';
    }
    if (stepName === 'processing') {
        if (['settled', 'executed'].includes(order.status) &&
            order.extra?.externalFundsReceived &&
            order.extra?.externalFundsSent &&
            order.extra?.correlatedOrderSettled) {
            return 'completed';
        }
        return 'awaiting';
    }
    return getFopStepStatus({ order, currentStepIndex, index, stepName });
};
