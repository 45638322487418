import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { ButtonLink } from '@copper/ui-kit';
import { getKeyValue, setKeyValue } from '@copper/utils';
import { IconError, IconInfo, IconSuccess, IconWarning } from './Icons';
import s from './Alert.module.css';
const Alert = ({ dismissId, message, rightSection, extendedMessage, type = 'error', view = 'primary', noBottomMargin = false, iconPosition = 'center', actionButton }) => {
    const [isAlertHidden, setIsAlertHidden] = useState(() => Boolean(getKeyValue(dismissId ?? '')));
    const [showMore, setShowMore] = useState(false);
    const handleDismissClick = () => {
        if (!dismissId)
            return;
        setIsAlertHidden(true);
        setKeyValue(dismissId, 'hidden');
    };
    const iconsMap = useMemo(() => ({
        warning: IconWarning,
        success: IconSuccess,
        error: IconError,
        info: IconInfo
    }), []);
    const IconComponent = iconsMap[type];
    if (isAlertHidden)
        return null;
    return (_jsxs("div", { className: cn(s.alert, s[`type_${type}`], s[`view_${view}`], noBottomMargin && s.noBottomMargin), children: [IconComponent && (_jsx("div", { className: cn(s[`icon_position_${iconPosition}`]), children: _jsx(IconComponent, {}) })), _jsxs("div", { className: s.content, children: [message, extendedMessage && (_jsxs(_Fragment, { children: [showMore && ` ${extendedMessage}`, ' ', _jsx("button", { className: s.showMore, onClick: () => setShowMore(!showMore), children: showMore ? 'Show less' : 'Show more' })] })), dismissId && _jsx(ButtonLink, { text: "Don't show this again", onClick: handleDismissClick }), actionButton && _jsx("div", { className: s.actionButtonContainer, children: actionButton })] }), rightSection && _jsx("div", { className: s.rightSection, children: rightSection })] }));
};
export { Alert };
