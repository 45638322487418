import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { getCurrencyName } from '@copper/helpers';
import { IconLogo } from '@copper/ui-kit';
import { truncateStr } from '@copper/utils';
import s from './Summary.module.css';
export const Summary = ({ isSubmitting, data, onNext }) => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const { amount, currency, portfolio, poolId, predefinedPool, poolOperation } = data;
    if (!currency || !portfolio) {
        return null;
    }
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.wrapper, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Asset", titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [getCurrencyName(currency.currency, currenciesEntities), _jsx("span", { className: s.ticker, children: currency.currency }), _jsx(AssetIcon, { currency: currency.currency })] }) }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio }) }), poolOperation === 'selectPredefined' && predefinedPool && (_jsx(InfoRow, { title: "Validator", titleColor: "ghost", children: _jsxs("div", { className: s.pool, children: [predefinedPool?.poolName?.includes('Copper') && (_jsx(IconLogo, { width: 22, height: 22, viewBox: "0 0 32 32" })), _jsxs("div", { className: s.poolWrapper, children: [_jsx("div", { className: s.name, children: predefinedPool.poolName }), _jsx("div", { className: s.poolId, children: truncateStr(predefinedPool.poolId) })] })] }) })), poolOperation === 'connect' && poolId && (_jsx(InfoRow, { title: "Validator", titleColor: "ghost", children: poolId })), amount && (_jsx(InfoRow, { title: "Amount", titleColor: "ghost", children: _jsxs("div", { className: s.total, children: [amount, " ", currency.currency, _jsx("div", { className: s.includeFee, children: "Fee is not included" })] }) }))] }), _jsx(SwitchStateRow, { loading: isSubmitting, onClick: onNext, text: "Confirm" })] }));
};
