import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const ADA = {
    title: 'ADA',
    id: 'ada',
    qa: [
        {
            question: 'How do I stake ADA?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from. You\u2019ll need a minimum account balance of 10 ADA." }), _jsx(Alert, { type: "info", message: "When you stake ADA, you are staking your Cardano wallet address and all your ADA balance at that address." }), _jsx("li", { children: "Choose a validator to stake with \u2013 you can change validators later on." }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the bonding period." })] }), _jsx("p", { children: "Once you\u2019ve signed the transaction, your stake will appear in the Pending tab on your staking dashboard for the duration of the bonding period (the waiting period before you can start earning staking rewards)." })] }))
        },
        {
            question: 'How do I unstake ADA?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." })] }), _jsx("p", { children: "Your assets will be released into your staking vault right away, as there is no unbonding period when staking on Cardano." })] }))
        },
        {
            question: 'Is there a minimum or maximum amount of ADA I can stake?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "There is no minimum staking amount. The maximum is 64 million ADA per wallet address." }), "\u00A0", _jsx("span", { className: s.alert, children: _jsx(Alert, { type: "info", message: `When you stake ADA, you are staking your Cardano wallet address and all your ADA balance at that address. You do not choose a stake amount. For example, if you hold 25 ADA in your selected staking vault, you’re automatically staking all 25 ADA.              
              
              You can’t stake less than the account balance. Therefore, the maximum staking amount (64 million ADA) refers to the maximum vault balance at the time of staking.` }) }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "None" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "64,000,000 ADA" })] })] }) })] }))
        },
        {
            question: 'How often will I earn ADA staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You will start earning rewards after approximately 20 staking days, excluding the bonding period. After that, rewards will be issued every 5 days." }), _jsx("p", { children: "You can check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "First payout" }) }), _jsx("td", { className: s.td, children: "After 20 days" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 5 days" })] })] }) })] }))
        },
        {
            question: 'How can I claim my ADA staking rewards?',
            answer: (_jsx("p", { children: "Rewards for ADA are automatically restaked. To claim rewards, you will need to unstake." }))
        },
        {
            question: 'Can I change validators while my assets are staked?',
            answer: (_jsx("p", { children: "Yes. To change validators, simply click on the active position on your staking dashboard. Then, select Edit next to the validator\u2019s address." }))
        }
    ]
};
