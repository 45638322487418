export const normalizeWebhookSubscriptions = (data) => {
    const result = {
        entities: {},
        eventTypes: data._embedded?.webhookEventTypes ?? []
    };
    data.webhookSubscriptions.forEach((subscription) => {
        result.entities[subscription.subscriptionId] = subscription;
    });
    return result;
};
