import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash-es';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { FormControl, FormLabel, Input, Radio, TextareaAutosize } from '@copper/ui-kit';
const ExchangeAccountFields = ({ errors, exchangeAccount, control, name, type = 'create' }) => {
    const { portfolioId } = useParams();
    const portfolios = useSelector(selectPortfolios);
    const portfolio = portfolios[portfolioId || ''];
    const isEdit = type === 'edit';
    if (!exchangeAccount || !exchangeAccount.fields) {
        return null;
    }
    return (_jsx(_Fragment, { children: exchangeAccount.fields
            .filter((field) => field.name !== 'parentPortfolioId')
            .map((item, index) => {
            const hasValue = typeof get(portfolio?.extra, item.name) === 'string' &&
                get(portfolio?.extra, item.name) !== '';
            const isEncrypted = typeof get(portfolio?.extra, item.name) !== 'string';
            return (_jsxs(FormControl, { errorMessage: errors?.[index]?.[item.name]?.message, children: [_jsx(FormLabel, { children: item.title }), _jsx(Controller, { shouldUnregister: true, name: `${name}.${index}.${item.name}`, defaultValue: isEncrypted ? '' : get(portfolio?.extra, item.name), rules: {
                            required: isEdit && (hasValue || isEncrypted) ? false : `Enter ${item.title}.`
                        }, control: control, render: ({ field }) => {
                            if (item.radio) {
                                const items = item.radio?.map(({ title, value }) => ({
                                    label: title,
                                    value
                                }));
                                return (_jsx(Radio, { items: items, value: field.value, onChange: field.onChange, orientation: "vertical", name: `exchangeAccountFields.${index}.${item.name}` }));
                            }
                            if (item.multiline) {
                                return (_jsx(TextareaAutosize, { size: "l", view: "primary", value: field.value ?? '', onChange: field.onChange, readOnly: isEdit && item.readOnly }));
                            }
                            return (_jsx(Input, { size: "l", view: "primary", placeholder: isEdit && isEncrypted ? '***' : '', value: field.value ?? '', onChange: field.onChange, disabled: isEdit && item.readOnly }));
                        } })] }, item.name));
        }) }));
};
export { ExchangeAccountFields };
