import * as Yup from 'yup';
export const validationSchema = Yup.object()
    .shape({
    name: Yup.string().required('Enter an account name.'),
    shardType: Yup.string().required('Select shard type')
})
    .required();
export const getPopupVaultTitle = ({ portfolioType = 'trading-vault', isDefiVault = false }) => {
    let portfolioTypeName = 'vault';
    if (portfolioType === 'trading-vault') {
        portfolioTypeName = 'trading vault';
    }
    else if (portfolioType === 'custody' && isDefiVault) {
        portfolioTypeName = 'DeFi vault';
    }
    else if (portfolioType === 'fees-vault') {
        portfolioTypeName = 'fee vault';
    }
    return `Create ${portfolioTypeName} account`;
};
