import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useSnackBar } from '@copper/ui-kit';
import s from './FileInput.module.css';
const FileInput = React.forwardRef((props, ref) => {
    const { dropElement, onChange, text, view = 'primary', accept = '.copper', children = null, fullHeight = false, hidden = false } = props;
    const innerRef = useRef(null);
    const { openSnackbar } = useSnackBar();
    const [dropOver, setDropOver] = useState(false);
    useEffect(() => {
        const node = dropElement ? dropElement : innerRef?.current;
        const onDragEnter = (event) => {
            event.preventDefault();
            setDropOver(true);
        };
        const onDragLeave = (event) => {
            event.preventDefault();
            if (node?.contains(event?.relatedTarget)) {
                return;
            }
            setDropOver(false);
        };
        const onDragOver = (e) => {
            e.preventDefault();
        };
        const checkAcceptableFiles = (files) => {
            const extensions = accept.split(',');
            return Object.values(files).every(({ name }) => extensions.some((extension) => name.includes(extension.trim())));
        };
        const onDrop = (event) => {
            event.preventDefault();
            setDropOver(false);
            const files = event.dataTransfer?.files;
            if (files) {
                const isAcceptableFiles = checkAcceptableFiles(files);
                if (isAcceptableFiles) {
                    onChange(files);
                }
                else {
                    openSnackbar({ message: `File type not accepted. Please include only ${accept}` });
                }
            }
        };
        if (node) {
            node.addEventListener('dragenter', onDragEnter, false);
            node.addEventListener('dragover', onDragOver, false);
            node.addEventListener('dragleave', onDragLeave, false);
            node.addEventListener('drop', onDrop, false);
        }
        return () => {
            if (node) {
                node.removeEventListener('dragenter', onDragEnter);
                node.removeEventListener('dragover', onDragOver);
                node.removeEventListener('dragleave', onDragLeave);
                node.removeEventListener('drop', onDrop);
            }
        };
    }, [dropElement, onChange]);
    const handleInput = (event) => {
        if (event.currentTarget.files) {
            onChange(event.currentTarget.files);
        }
    };
    return (_jsx("div", { ref: innerRef, className: cn(s.container, fullHeight && s.containerFullHeight, s[`view_${view}`], hidden && s.hidden), children: _jsxs("label", { ref: ref, className: cn(s.dropZone, { [s.dropZoneOver]: dropOver }), children: [_jsx("input", { type: "file", onInput: handleInput, name: "file", accept: accept, hidden: true }), text && _jsx("p", { className: s.text, children: text }), children] }) }));
});
FileInput.displayName = 'FileInput';
export { FileInput };
