import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { LayoutContext } from '@copper/contexts/layout';
import { selectIsAuthorized, selectToken } from '@copper/entities/auth/auth-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectUser, selectUserEmail, selectUserId } from '@copper/entities/user/user-selector';
import { logoutWS as disconnectWs, newToken as reconnectOnNewToken } from '@copper/helpers/websocket';
import { useFetchAppVersion } from '@copper/hooks/useFetchAppVersion';
import { useFetchClientData } from '@copper/hooks/useFetchClientData';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { setupZendeskWidget } from '@copper/utils/zendesk';
const App = () => {
    const { openSnackbar } = useSnackBar();
    const authToken = useSelector(selectToken);
    const userId = useSelector(selectUserId);
    const user = useSelector(selectUser);
    const email = useSelector(selectUserEmail);
    const IsAuthorized = useSelector(selectIsAuthorized);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [loadingLayout, setLoadingLayout] = useState(true);
    const [fullWidth, setFullWidth] = useState(false);
    const fetchData = useFetchClientData();
    const fetchAppVersion = useFetchAppVersion();
    const ssoSessionToken = searchParams.get('ssoSessionToken');
    const nextOrgId = searchParams.get('organizationId');
    const configurationId = searchParams.get('configurationId');
    useEffect(() => {
        if (IsAuthorized && userId) {
            // Because of token extension via sso requires page reload, we need to handle org fetch and loadingLayout in another place to avoid race conditions
            const authExtendViaSsoInProgress = Boolean(ssoSessionToken && nextOrgId && configurationId && !location.pathname.includes('/login'));
            fetchData(userId, authExtendViaSsoInProgress)
                .then(() => {
                !authExtendViaSsoInProgress && setLoadingLayout(false);
                fetchAppVersion(userId);
            })
                .catch((err) => {
                openSnackbar(getErrorData(err));
            });
        }
    }, [IsAuthorized, userId]);
    useEffect(() => {
        if (IsAuthorized &&
            (currentOrganization?.extra?.zendeskOrganizationId ||
                currentOrganization?.organizationType === 'lite')) {
            setupZendeskWidget({
                email,
                user,
                organizationId: currentOrganization?.organizationId
            });
        }
    }, [IsAuthorized, currentOrganization, email, user]);
    useEffect(() => {
        if (authToken) {
            reconnectOnNewToken(authToken);
        }
        else {
            disconnectWs();
        }
    }, [authToken]);
    return (_jsx(LayoutContext.Provider, { value: {
            loadingLayout,
            setLoadingLayout,
            isFullWidth: fullWidth,
            setIsFullWidth: setFullWidth
        }, children: _jsx(Outlet, {}) }));
};
export { App };
