import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AssetIcon } from '@copper/components/AssetIcon';
import { FilterWrapper } from '@copper/components/FilterWrapper';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { FormLabel, Select } from '@copper/ui-kit';
const outcomeValues = ['defaulted', 'cancelled', 'repaid'];
const Filters = ({ handleApply, loanParties = [], group, loanAssets = [], tab }) => {
    const currencies = useSelector(selectCurrencies);
    const form = useForm({
        defaultValues: {
            assets: [],
            parties: [],
            outcomes: []
        }
    });
    const { control, handleSubmit, getValues, reset } = form;
    const handleFilterFormSend = async () => {
        const { assets, parties, outcomes } = getValues();
        if (!assets && !parties && !outcomes)
            handleApply(undefined);
        handleApply({ assets: assets, parties: parties, outcomes: outcomes });
    };
    return (_jsx("form", { onSubmit: handleSubmit(handleFilterFormSend), children: _jsxs(FilterWrapper, { onReset: reset, children: [_jsxs("div", { children: [_jsx(FormLabel, { children: "Asset" }), _jsx(Controller, { control: control, name: "assets", render: ({ field }) => (_jsx(Select, { view: "primary", placeholder: "Select asset", searchPlaceholder: "Search...", items: loanAssets, searchFields: ['name', 'value'], getKey: (asset) => asset, getIcon: (asset) => _jsx(AssetIcon, { currency: asset, size: "s" }), getTopRow: (asset) => currencies?.entities[asset]?.name, getBottomRow: (asset) => asset, onChangeMultiple: field.onChange, selected: field.value ?? [], reversedStyles: true })) })] }), _jsxs("div", { children: [_jsx(FormLabel, { children: group === 'lending' ? 'Borrowers' : 'Lenders' }), _jsx(Controller, { control: control, name: "parties", render: ({ field }) => (_jsx(Select, { view: "primary", placeholder: `Select ${group === 'lending' ? 'borrowers' : 'lenders'}`, searchPlaceholder: "Search...", items: loanParties.map((org) => org.organizationId), searchFields: ['name', 'value'], getKey: (item) => item, getBottomRow: (item) => item, getTopRow: (item) => loanParties.find((org) => org.organizationId === item)?.organizationName, onChangeMultiple: field.onChange, selected: field.value ?? [], reversedStyles: true })) })] }), tab === 'History' && (_jsxs("div", { children: [_jsx(FormLabel, { children: "Outcome" }), _jsx(Controller, { control: control, name: "outcomes", render: ({ field }) => (_jsx(Select, { view: "primary", placeholder: "Select outcome", searchPlaceholder: "Search...", items: outcomeValues, getKey: (item) => item, getTopRow: (item) => item, onChangeMultiple: field.onChange, selected: field.value ?? [], reversedStyles: true })) })] }))] }) }));
};
export { Filters };
