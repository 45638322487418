import * as Yup from 'yup';
import { checkRequiredNetwork, validateAmountField } from '@copper/helpers/validation';
export const validationSchema = Yup.object()
    .shape({
    currency: Yup.object().shape({ currency: Yup.string().required('Select an asset.') }),
    mainCurrency: Yup.string().when(['origin', 'destination', 'currency'], {
        is: (origin, destination) => checkRequiredNetwork({ origin, destination }),
        then: (schema) => schema.required('Select a network.')
    }),
    origin: Yup.object().shape({
        portfolioType: Yup.string().required('Select a from account.')
    }),
    destination: Yup.object().shape({
        bankAccountId: Yup.string().required('Select a to account.')
    }),
    amount: validateAmountField(),
    description: Yup.string().max(500, 'No more than 500 characters.')
})
    .required();
