import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { selectGroupedPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { createSubAccountsStructure, flattenNestedPortfolios } from '@copper/helpers/nestedPortfolios';
import { isClearloopPortfolio } from '@copper/helpers/portfolios';
import { Alert } from '@copper/ui-kit';
import { SubAccountList } from './SubAccountList';
import s from './SubAccounts.module.css';
const SubAccounts = ({ autoActivation, portfolio }) => {
    const portfolios = useSelector(selectGroupedPortfolios);
    const nestedPortfolios = createSubAccountsStructure(portfolios.externalPortfolios, portfolio.portfolioId);
    const flattenItems = flattenNestedPortfolios(nestedPortfolios);
    const needRequiredInfo = flattenItems.some((portfolio) => portfolio?.extra?.autodiscoveredAccountStatus === 'information-required');
    let descriptionText = '';
    if (autoActivation) {
        if (nestedPortfolios.length === 0) {
            descriptionText = 'We could not find any sub-accounts.';
        }
        if (needRequiredInfo) {
            descriptionText = isClearloopPortfolio(portfolio.portfolioType)
                ? 'Please provide any required information to connect your sub-accounts.'
                : 'You can do this later but the affected accounts will only become available once we have the required information.';
        }
        else {
            descriptionText = 'All sub-accounts have been connected successfully.';
        }
    }
    return (_jsxs("div", { className: s.container, children: [_jsx("h3", { className: s.title, children: "Sub-accounts" }), autoActivation ? (_jsx("p", { className: s.description, children: descriptionText })) : (_jsx(Alert, { type: "warning", message: "We cannot connect sub-accounts from this exchange automatically.\n           You can add individual accounts one by one." })), _jsx("div", { className: s.portfolios, children: _jsx(SubAccountList, { portfolios: nestedPortfolios }) })] }));
};
export { SubAccounts };
