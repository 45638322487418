import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const INJ = {
    title: 'INJ',
    id: 'inj',
    qa: [
        {
            question: 'How do I stake INJ?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 0.0001 INJ)." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake INJ?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 21-day unbonding period." })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for INJ?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, there is a minimum staking amount of 0.0001 INJ, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "0.0001 INJ" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn INJ staking rewards?',
            answer: (_jsx("p", { children: "You can view the reward frequency applicable at the time of staking. Or in your staking details, by clicking on the transaction on your staking dashboard." }))
        },
        {
            question: 'How can I claim my INJ staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your INJ rewards anytime by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your INJ staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "Your assets will be released into your staking vault right away, as there is no unbonding period." })] }))
        }
    ]
};
