import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as organizationApi from '@copper/api/organizations';
import * as usersApi from '@copper/api/users';
import { selectUserId } from '@copper/entities/user/user-selector';
const initialState = {
    apiKeys: []
};
export const fetchUserApiKeys = createAsyncThunk('apiKeys/getUserApiKeys', async (_, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await usersApi.getUserIdentities(userId, {
            params: { type: 'apikey' }
        });
        return response.data.identities;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOrganizationApiKeys = createAsyncThunk('apiKeys/getOrganizationApiKeys', async (organizationId, thunkAPI) => {
    try {
        const response = await organizationApi.getApiKeys(organizationId);
        return response.data.identities;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createApiKeyIdentity = createAsyncThunk('apiKeys/createApiKeyIdentity', async ({ organizationId, data, twoFaCode }, thunkAPI) => {
    try {
        const response = await organizationApi.createApiKey(organizationId, data, twoFaCode);
        //needed as the response doesn't include the embedded.apiKeyOwner data
        await thunkAPI.dispatch(fetchOrganizationApiKeys(organizationId));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getApiKeyIdentities = createAsyncThunk('apiKeys/getApiKeyIdentities', async (_, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await usersApi.getUserIdentities(userId, {
            params: { type: 'apikey' }
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateApiKeyIdentity = createAsyncThunk('apiKeys/updateApiKeyIdentity', async ({ fieldsToUpdate, twoFaCode, organizationId, apiKey }, thunkAPI) => {
    try {
        await organizationApi.updateApiKey(fieldsToUpdate, twoFaCode, organizationId, apiKey.value);
        return {
            ...apiKey,
            extra: {
                ...apiKey.extra,
                ipWhitelist: fieldsToUpdate.ipWhitelist
            }
        };
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteApiKeyIdentity = createAsyncThunk('apiKeys/deleteApiKeyIdentity', async ({ organizationId, apiKey, twoFaCode }, thunkAPI) => {
    try {
        await organizationApi.deleteApiKey(organizationId, apiKey, twoFaCode);
        return apiKey;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const apiKeysSlice = createSlice({
    name: 'apiKeys',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchUserApiKeys.fulfilled, (state, { payload }) => {
            state.apiKeys = payload;
        });
        addCase(fetchOrganizationApiKeys.fulfilled, (state, { payload }) => {
            state.apiKeys = payload;
        });
        addCase(updateApiKeyIdentity.fulfilled, (state, { payload }) => {
            state.apiKeys = state.apiKeys?.map((apiKeyIdentity) => {
                if (apiKeyIdentity.value !== payload.value) {
                    return apiKeyIdentity;
                }
                return payload;
            });
        });
        addCase(deleteApiKeyIdentity.fulfilled, (state, { payload }) => {
            state.apiKeys = state.apiKeys?.filter((apiKey) => apiKey.value !== payload);
        });
    }
});
export const apiKeys = apiKeysSlice.reducer;
