import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { acceptDisclaimer, checkAgreedDisclaimer, downloadDisclaimer } from '@copper/entities/disclaimers/disclaimers-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { saveBlobAsFile } from '@copper/helpers/save-blob-as-file';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
const useDisclaimer = (disclaimerId) => {
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [disclaimer, setDisclaimer] = useState();
    const [isDisclaimerLoading, setIsDisclaimerLoading] = useState(false);
    const { openSnackbar } = useSnackBar();
    const handleDisclaimerDownload = async () => {
        try {
            const fileData = await downloadDisclaimer({ disclaimerId });
            const blob = new Blob([fileData], { type: 'application/pdf' });
            saveBlobAsFile(blob, `${disclaimerId}.pdf`);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const checkDisclaimer = useCallback(async () => {
        setIsDisclaimerLoading(true);
        try {
            const disclaimer = await checkAgreedDisclaimer(organizationId, disclaimerId);
            setDisclaimer(disclaimer);
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsDisclaimerLoading(false);
        }
    }, [organizationId]);
    const handleAcceptDisclaimer = useCallback(async () => {
        if (!disclaimer)
            return;
        try {
            return await acceptDisclaimer(organizationId, {
                disclaimerId: disclaimer.disclaimerId,
                version: disclaimer.version
            });
        }
        catch (err) {
            throw new Error(getErrorData(err).message);
        }
    }, [disclaimer, organizationId]);
    return {
        isDisclaimerLoading,
        disclaimer,
        handleAcceptDisclaimer,
        handleDisclaimerDownload,
        checkDisclaimer,
        showDisclaimer: !isDisclaimerLoading && !disclaimer?.accepted
    };
};
export { useDisclaimer };
