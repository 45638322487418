import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import s from './EmptyList.module.css';
export const EmptyList = ({ dataTitle, type = 'data', message }) => {
    if (type === 'data') {
        return (_jsx("div", { className: cn(s.empty, s[`empty_${type}`]), children: _jsx("p", { className: s.message, children: dataTitle || 'No account found' }) }));
    }
    const emptyMessage = (type === 'search' && 'No account with this name found') ||
        (type === 'favorites' && 'No starred accounts');
    return (_jsx("div", { className: cn(s.empty, s[`empty_${type}`]), children: _jsx("p", { className: s.message, children: message || emptyMessage }) }));
};
