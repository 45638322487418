import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { LazyImage } from '@copper/components';
import { AssetIconWrapper } from '@copper/components/AssetIconWrapper';
import { CURRENCY_CDN } from '@copper/constants/cdn';
import * as cdnCurrencies from '@copper/currencies';
import { getApiUrl } from '@copper/helpers';
import s from './AssetIcon.module.css';
export const AssetIcon = ({ currency, size = 'm' }) => {
    const [isImageError, setIsImageError] = useState(false);
    // Otherwise, the image does not change if fallback has been shown once. e.g. the selected item in the select component
    useEffect(() => {
        setIsImageError(false);
    }, [currency]);
    if (!currency) {
        return null;
    }
    const iconPath = cdnCurrencies.has(currency?.toLowerCase())
        ? `${CURRENCY_CDN}/${currency?.toLowerCase()}.svg`
        : `${getApiUrl()}/images/public/icons/128x128/${currency?.toLowerCase()}.png`;
    if (isImageError) {
        return (_jsx(AssetIconWrapper, { size: size, children: _jsx("img", { alt: "", src: `${CURRENCY_CDN}/fallback.svg`, className: cn(s.icon, s.fallback, s[`icon_size_${size}`]) }) }));
    }
    return (_jsx(AssetIconWrapper, { size: size, children: _jsx(LazyImage, { onError: () => setIsImageError(true), className: cn(s.icon, s[`icon_size_${size}`]), src: iconPath }) }));
};
