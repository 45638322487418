import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { isPlainObject } from 'lodash-es';
import { getIconDimensions } from './helpers';
import s from './ChoiceGroup.module.css';
const defaultSize = 'm';
const defaultView = 'primary';
const defaultForm = 'united';
const defaultDisplay = 'inline';
const ChoiceGroup = ({ items, name, getItemLabel, getItemSubLabel, getItemKey, onChange, onClick, getItemIcon, getItemSubTitle, getItemSecondSubTitle, getCheckedModifier, view = defaultView, form = defaultForm, size = defaultSize, display = defaultDisplay, onlyIcon = false, value = null, fullWidth = false, testId = 'option' }) => {
    const keyGetter = getItemKey;
    return (_jsx("div", { className: cn(s.container, s[`form_${form}`], s[`size_${size}`], s[`display_${display}`], s[`view_${view}`], fullWidth && s.fullWidth, onlyIcon && s.onlyIcon), children: items.map((item) => {
            const valueByKey = value && isPlainObject(value) ? keyGetter(value) : value;
            const isChecked = valueByKey === keyGetter(item);
            const Icon = getItemIcon && getItemIcon(item);
            const subTitle = getItemSubTitle && getItemSubTitle(item);
            const secondSubTitle = getItemSecondSubTitle && getItemSecondSubTitle(item);
            const checkedModifier = getCheckedModifier && getCheckedModifier(item);
            const key = keyGetter(item);
            return (_jsxs("label", { className: cn(s.item, isChecked && s.checked, {
                    [s[`checked-${checkedModifier}`]]: isChecked && checkedModifier
                }), "data-testid": `${testId}-${key}`, children: [_jsx("input", { type: "radio", name: name, className: s.input, checked: isChecked, onChange: (event) => onChange && onChange({ event, newValue: item }), onClick: (event) => onClick && onClick({ event, newValue: item }) }), Icon && _jsx(Icon, { className: s.icon, ...getIconDimensions(form, size) }), !onlyIcon && (_jsxs("div", { className: s.labelContainer, children: [_jsx("span", { className: s.label, children: getItemLabel(item) }), getItemSubLabel && _jsx("div", { className: s.subLabel, children: getItemSubLabel(item) }), (subTitle || secondSubTitle) && (_jsxs("div", { className: s.subTitle, children: [subTitle && _jsx("div", { children: subTitle }), secondSubTitle && _jsx("span", { children: secondSubTitle })] }))] }))] }, key));
        }) }));
};
export { ChoiceGroup };
