import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CounterpartyLogo } from '@copper/components';
import { SkeletonLoader } from '@copper/components/Loans';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectPledgeConnections } from '@copper/entities/pledge/pledge-selector';
import { Table, TableEmptyRow, Tabs, Tbody, Td, TdDoubleRowContent, Th, Thead, Tr } from '@copper/ui-kit';
import s from './ConnectionsList.module.css';
const ConnectionList = ({ onConnectionSelected = () => ({}), isLoading = false }) => {
    const connections = useSelector(selectPledgeConnections);
    const organization = useSelector(selectCurrentOrganization);
    const [activeTab, setActiveTab] = useState('connected');
    const sortByOursFirst = (lhs, rhs) => organization?.organizationId === lhs.pledgeeOrganizationId
        ? -1
        : organization?.organizationId === rhs.pledgeeOrganizationId
            ? 1
            : 0;
    const filterByGroup = (conn) => {
        if (activeTab === 'connected') {
            return conn.active;
        }
        return !conn.active;
    };
    const connectionsToDisplay = useMemo(() => connections.filter(filterByGroup).sort(sortByOursFirst), [connections, activeTab]);
    const handleTabClick = (item) => {
        setActiveTab(item.value);
    };
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(SkeletonLoader, { items: 5 }), !isLoading && (_jsxs(_Fragment, { children: [_jsx(Tabs, { items: [
                            {
                                title: 'Connected',
                                value: 'connected'
                            },
                            {
                                title: 'Disconnected',
                                value: 'disconnected'
                            }
                        ], value: activeTab, onChange: handleTabClick }), _jsxs(Table, { withTabs: true, children: [_jsxs(Thead, { children: [_jsx(Th, { children: "Lender" }), _jsx(Th, { children: "Borrower" }), _jsx(Th, { align: "right", children: "State" })] }), _jsx(Tbody, { children: connectionsToDisplay.length > 0 ? (_jsx(_Fragment, { children: connectionsToDisplay.map((connection) => (_jsxs(Tr, { onClick: () => onConnectionSelected(connection), children: [_jsx(Td, { children: _jsxs("div", { className: s.organizationWrapper, children: [_jsx(CounterpartyLogo, { counterpartyName: connection._embedded?.pledgee?.organizationName || '', size: "xs" }), _jsx("div", { children: _jsx(TdDoubleRowContent, { text: connection._embedded?.pledgee?.organizationName, subText: connection._embedded?.pledgee?.alias }) })] }) }), _jsx(Td, { children: _jsxs("div", { className: s.organizationWrapper, children: [_jsx(CounterpartyLogo, { counterpartyName: connection._embedded?.pledgor?.organizationName || '', size: "xs" }), _jsx("div", { children: _jsx(TdDoubleRowContent, { text: connection._embedded?.pledgor?.organizationName, subText: connection._embedded?.pledgor?.alias }) })] }) }), _jsx(Td, { align: "right", children: connection.active ? (_jsx("p", { className: s.connectedText, children: "Connected" })) : (_jsx("p", { className: s.disconnectedText, children: "Disconnected" })) })] }, connection.connectionId))) })) : (_jsx(TableEmptyRow, { children: _jsx("div", { className: s.empty, children: activeTab === 'connected'
                                            ? 'No active connections'
                                            : 'No historic connections' }) })) })] })] }))] }));
};
export { ConnectionList };
