const normalizePortfolio = (portfolio, isShadow = false) => ({
    ...portfolio,
    isShadow,
    balance: '0',
    available: '0',
    positiveAvailable: '0',
    reserve: '0',
    stakeBalance: '0',
    pledged: '0'
});
export const normalizePortfolios = (portfolios, isShadow = false) => portfolios.reduce((acc, portfolio) => ({
    ...acc,
    [portfolio.portfolioId]: normalizePortfolio(portfolio, isShadow)
}), {});
