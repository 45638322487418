import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useFormControl } from '@copper/ui-kit';
import { useForkRef } from '@copper/ui-kit/utils/useForkRef';
import s from './Input.module.css';
const Input = React.forwardRef((props, ref) => {
    const { autoFocus = false, className, state, leftSection, rightSection, view = 'primary', size = 'm', type = 'text', roundedSide = 'both', dataId, hasFocus = true, onBlur, onFocus, testId, fullWidth, autoComplete = 'off', onPaste, ...otherProps } = props;
    const [focus, setFocus] = useState(autoFocus);
    const inputRef = React.useRef(null);
    const formControl = useFormControl();
    useEffect(() => {
        if (autoFocus) {
            // setTimeout is used to prevent scroll
            setTimeout(() => {
                inputRef.current?.focus();
            });
        }
    }, []);
    return (_jsxs("div", { className: cn(s.field, s[`size_${size}`], s[`view_${view}`], s[`rounded_${roundedSide}`], s[`state_${state || formControl.state}`], {
            [s.focus]: focus && hasFocus,
            [s.fullWidth]: fullWidth
        }), children: [Boolean(leftSection) && _jsx("div", { className: s.leftSection, children: leftSection }), _jsx("input", { onPaste: onPaste, ref: useForkRef([inputRef, ref]), type: type, className: cn(s.input, className, s[`view_${view}`]), "data-id": dataId, "data-testid": testId, autoComplete: autoComplete, ...otherProps, onBlur: (event) => {
                    setFocus(false);
                    typeof onBlur === 'function' && onBlur(event);
                }, onFocus: (event) => {
                    setFocus(true);
                    typeof onFocus === 'function' && onFocus(event);
                } }), Boolean(rightSection) && _jsx("div", { className: s.rightSection, children: rightSection })] }));
});
Input.displayName = 'Input';
export { Input };
