import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import cn from 'classnames';
import { ButtonIcon, IconArrowBack } from '@copper/ui-kit';
import { setCssProperty } from '@copper/utils/styles';
import s from './PopupHeader.module.css';
export const PopupHeader = ({ testId, title, subTitle, onBack, children, rightSection }) => {
    useEffect(() => {
        setCssProperty('--popup-header-height', '65px');
        return () => setCssProperty('--popup-header-height', '0px');
    }, []);
    return (_jsxs("header", { className: s.header, "data-testid": testId, children: [onBack && (_jsx(ButtonIcon, { onClick: onBack, className: s.backButton, tooltipLabel: "Go back", children: _jsx(IconArrowBack, {}) })), _jsxs("div", { className: s.leftSection, children: [title && _jsx("h2", { className: cn(s.title, { [s.titleLarge]: !subTitle }), children: title }), subTitle && _jsx("p", { className: s.subTitle, children: subTitle }), children] }), rightSection && _jsx("div", { className: s.rightSection, children: rightSection })] }));
};
