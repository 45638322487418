import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, Popup, PopupContent, PopupFooter, PopupHeader, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { NetworkPortfolioSelect } from '../NetworkPortfolioSelect';
import s from './EditNetworkPortfolioModal.module.css';
const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.')
});
export const EditNetworkPortfolioModal = ({ onClose, onSubmit, counterparty, title = 'Select settlement account', showEditMessage = true, isLoading = false }) => {
    const { openSnackbar } = useSnackBar();
    const form = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit'
    });
    const { handleSubmit } = form;
    const handleOnSubmit = async (formValues) => {
        try {
            if (formValues.portfolio) {
                onSubmit && onSubmit(formValues.portfolio.portfolioId);
            }
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
    };
    return (_jsx(Popup, { onClose: onClose, children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(handleOnSubmit), children: [_jsx(PopupHeader, { title: title }), _jsx(PopupContent, { children: _jsx(NetworkPortfolioSelect, { showEditMessage: showEditMessage, counterparty: counterparty }) }), _jsx(PopupFooter, { rightSection: _jsxs("div", { className: s.buttonsContainer, children: [_jsx(Button, { text: "Cancel", type: "button", view: "secondary", onClick: onClose, loading: isLoading }), _jsx(Button, { text: "Confirm", type: "submit", view: "primary", loading: isLoading }), ' '] }) })] }) }) }));
};
