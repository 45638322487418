import { createSelector } from '@reduxjs/toolkit';
const sortGroupActions = (actions) => [...actions].sort((a, b) => a?.description?.localeCompare(b?.description));
const sortGroupActionsDictionaries = (groupActionsDictionaries) => groupActionsDictionaries?.map((group) => ({
    ...group,
    actions: sortGroupActions(group.actions ?? [])
}));
const normalizeGroupActions = (groupActionsDictionaries) => groupActionsDictionaries.reduce((acc, actionsGroup) => ({
    ...acc,
    [actionsGroup.groupId]: actionsGroup
}), {});
const selectPermissions = (store) => store.permissions;
const selectClientAssignableGroups = createSelector(selectPermissions, (permissions) => sortGroupActionsDictionaries(permissions.clientAssignableGroups));
export const selectClientAssignableGroupsById = createSelector(selectClientAssignableGroups, (permissions) => normalizeGroupActions(permissions));
export const selectUserAssignableGroups = createSelector(selectClientAssignableGroups, (assignableGroups) => normalizeGroupActions(assignableGroups.filter((group) => group.tags.includes('user'))));
export const selectServiceAccountAssignableGroups = createSelector(selectClientAssignableGroups, (assignableGroups) => normalizeGroupActions(assignableGroups.filter((group) => group.tags.includes('service-account'))));
export const selectUserAssignableGroupsList = createSelector(selectUserAssignableGroups, (assignableGroups) => Object.values(assignableGroups));
export const selectServiceAccountAssignableGroupsList = createSelector(selectServiceAccountAssignableGroups, (assignableGroups) => Object.values(assignableGroups));
export const selectAdminAssignableGroups = createSelector(selectPermissions, (permissions) => sortGroupActionsDictionaries(permissions?.adminAssignableGroups ?? []));
export const selectAdminAssignableGroupsById = createSelector(selectAdminAssignableGroups, (permissions) => normalizeGroupActions(permissions));
export const selectAuthorizedActions = createSelector(selectPermissions, ({ authorizedActions }) => authorizedActions);
