import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { PageSwitcher } from '@copper/components';
import { AddAddressButton } from '@copper/components/DepositAddresses/AddAddressButton';
import { IconInformation, Tooltip } from '@copper/ui-kit';
import { DESTINATION_TYPE_MAP, TARGET_TYPE_MAP } from '../constants';
import { canTargetHaveMultipleAddresses } from '../helpers';
import s from './Header.module.css';
const Header = ({ depositTargetsLength, destinationType, portfolioType, targetType, view, isFirstPage, hasMorePages, onClickNext, onClickPrev, onClickAddButton, showAddButton }) => (_jsxs("div", { className: s.addressesHeader, children: [canTargetHaveMultipleAddresses(portfolioType, targetType) &&
            depositTargetsLength &&
            destinationType ? (_jsxs("div", { className: s.addressesHeaderTitle, children: ["Destination ", DESTINATION_TYPE_MAP[destinationType]] })) : (_jsxs("div", { className: s.addressesHeaderTitle, children: [TARGET_TYPE_MAP[targetType].title, _jsx(Tooltip, { label: TARGET_TYPE_MAP[targetType].titleTooltip, placement: "top", type: "hint", view: view, children: _jsx(IconInformation, { className: s.addressesHeaderIcon, height: "16px", width: "16px" }) })] })), _jsxs("div", { className: s.addressesHeaderButtons, children: [_jsx(PageSwitcher, { isFirstPage: isFirstPage, hasMorePages: hasMorePages, onClickNext: onClickNext, onClickPrev: onClickPrev }), showAddButton && (_jsx(AddAddressButton, { tooltipLabel: depositTargetsLength && destinationType
                        ? `Add new ${DESTINATION_TYPE_MAP[destinationType]}`
                        : TARGET_TYPE_MAP[targetType].addAddressTooltip, onClick: onClickAddButton }))] })] }));
export { Header };
