import { request } from '@copper/utils';
export const getLoansCollection = (organizationId) => request
    .get(`/loans?includeSummary=true&organizationId=${organizationId}`)
    .then((response) => response.data);
export const createLoan = (params) => request.post('/loans', params).then((response) => response.data);
export const approveDefault = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.nec-received+json'
    }
});
export const cancelLoan = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel+json'
    }
});
export const writeOffLoan = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.write-off+json'
    }
});
export const defaultLoan = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.start-default+json'
    }
});
export const cancelDefault = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel-default+json'
    }
});
export const marginCallLoan = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.start-margin-call+json'
    }
});
export const rebalanceLoan = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.start-rebalance+json'
    }
});
export const repayLoan = (loanId) => request.patch(`/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.start-repayment+json'
    }
});
export const getLoanConnections = (organizationId) => request
    .get(`/loan-connections?organizationId=${organizationId}`)
    .then((res) => res.data.connections);
export const getLoanValuations = (params) => request
    .get(`/loan-valuations`, {
    params
})
    .then((res) => res.data);
export const createLoanConnection = (data) => request.post('/loan-connections', data).then((res) => res.data);
export const reconnectLoanConnection = (connectionId) => request
    .patch(`/loan-connections/${connectionId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.activate+json'
    }
})
    .then((res) => res.data);
export const disconnectLoanConnection = (connectionId) => request.delete(`/loan-connections/${connectionId}`).then((res) => res.data);
export const updateBorrowerPortfolio = (update) => request.patch(`/loans/${update.loanId}`, update, {
    headers: {
        'Content-Type': 'application/vnd.update-borrower-portfolio+json'
    }
});
export const updateLenderPortfolio = (update) => request.patch(`/loans/${update.loanId}`, update, {
    headers: {
        'Content-Type': 'application/vnd.update-lender-portfolio+json'
    }
});
