import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as approvalRequestsApi from '@copper/api/approvalRequests';
import { subscribe } from '@copper/helpers/websocket';
import { serializeParams } from '@copper/utils';
export const fetchNewMemberPendingApprovalRequests = createAsyncThunk('approvalRequests/fetchNewMembersPending', async ({ organizationId, userId }, thunkAPI) => {
    try {
        const params = {
            userId,
            organizationId,
            actionName: 'create-organization-member',
            requestStatus: 'waiting-approval,rejected'
        };
        const response = await approvalRequestsApi.getApprovalRequests(params);
        return response.data.requests;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchСreateAdminApprovalRequests = createAsyncThunk('approvalRequests/fetchAdminRequests', async ({ userId }, thunkAPI) => {
    try {
        const params = {
            userId,
            actionName: 'create-admin-user',
            requestStatus: 'waiting-approval,rejected',
            includeApprovalAudits: true,
            includeNumberApprovesRequired: true,
            limit: '1000'
        };
        const response = await approvalRequestsApi.getApprovalRequests(params);
        return response.data.requests;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const reviewСreateAdminApprovalRequests = createAsyncThunk('approvalRequests/reviewAdminCreateRequest', async ({ requestId, data }, thunkAPI) => {
    try {
        const response = await approvalRequestsApi.updateApprovalRequestReview(requestId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelCreateAdminApprovalRequests = createAsyncThunk('approvalRequests/cancelAdminCreateRequest', async ({ requestId }, thunkAPI) => {
    try {
        const response = await approvalRequestsApi.cancelApprovalRequest(requestId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchNewMemberPendingApprovalRequestDetails = createAsyncThunk('approvalRequests/fetchNewMemberPendingDetails', async ({ approvalRequestId }, thunkAPI) => {
    try {
        const response = await approvalRequestsApi.getApprovalRequestDetails(approvalRequestId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const subscribeRequests = createAsyncThunk('approvalRequests/subscribe', async (params, thunkAPI) => {
    const { onUpdate, cancelRequest, ...requestQueryObject } = params;
    const serializedPayload = serializeParams(requestQueryObject);
    try {
        return await subscribe({
            uri: `/approval-requests${serializedPayload}`,
            callback: onUpdate,
            cancelRequest
        });
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const approvalRequestsSlice = createSlice({
    name: 'approvalRequests',
    initialState: {
        newMembersPending: [],
        createAdminUsers: {}
    },
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchNewMemberPendingApprovalRequests.fulfilled, (state, { payload }) => {
            state.newMembersPending = payload;
        });
        addCase(fetchNewMemberPendingApprovalRequestDetails.fulfilled, (state, { payload }) => {
            const requestIndex = state.newMembersPending.findIndex((storedRequest) => storedRequest.requestId === payload.requestId);
            if (payload.requestStatus === 'cancelled') {
                state.newMembersPending.splice(requestIndex, 1);
            }
            else {
                state.newMembersPending[requestIndex] = payload;
            }
        });
        addCase(fetchСreateAdminApprovalRequests.fulfilled, (state, { payload }) => {
            state.createAdminUsers = payload.reduce((acc, request) => ({
                ...acc,
                [request.requestId]: request
            }), {});
        });
        addMatcher(isAnyOf(reviewСreateAdminApprovalRequests.fulfilled, cancelCreateAdminApprovalRequests.fulfilled), (state, { payload }) => {
            state.createAdminUsers = {
                ...state.createAdminUsers,
                [payload.requestId]: payload
            };
        });
    }
});
export const approvalRequests = approvalRequestsSlice.reducer;
