import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PortfolioInfoPopup } from '@copper/components/PortfolioInfoPopup';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { ButtonIcon, IconMore } from '@copper/ui-kit';
export const PortfolioInfoButton = ({ portfolioId }) => {
    const portfolios = useSelector(selectPortfolios);
    const [showPortfolioInfo, setShowPortfolioInfo] = useState(false);
    const portfolio = portfolios[portfolioId || ''];
    if (!portfolio) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(ButtonIcon, { onClick: () => setShowPortfolioInfo(true), children: _jsx(IconMore, { type: "horizontal", width: "16px", height: "16px" }) }), showPortfolioInfo && portfolio && (_jsx(PortfolioInfoPopup, { onClose: () => setShowPortfolioInfo(false), portfolio: portfolio }))] }));
};
