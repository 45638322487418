import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAssets } from '@copper/components/Holdings/helpers';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
export const usePortfolioAssets = (origin) => {
    const [assets, setAssets] = useState(null);
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const wallets = useMemo(() => walletsByPortfolioId[origin?.portfolioId || ''] || [], [origin?.portfolioId, walletsByPortfolioId]);
    const data = useMemo(() => getAssets(wallets, currenciesEntities), [currenciesEntities, wallets]);
    useEffect(() => {
        setAssets(data);
    }, [data]);
    return assets;
};
