import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
import { validateAmountField } from '@copper/helpers/validation';
import { lazy } from 'yup';
export const validationSchema = Yup.object().shape({
    currency: Yup.object().required('Select an asset.'),
    portfolio: Yup.object().required('Select an account.'),
    validatorRegistration: Yup.string().when('currency', {
        is: (currency) => currency?.currency === 'ADA',
        then: (schema) => schema.required('Enter a validator registration.')
    }),
    signatures: lazy((_, { parent }) => Yup.array().of(Yup.object().shape({
        signature: Yup.string().when('currency', {
            is: () => parent.currency?.currency === 'ADA',
            then: (schema) => schema.required('Enter a signature.')
        })
    }))),
    commissionPercentage: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .when('poolType', {
        is: (poolType) => poolType === 'staking-pool',
        then: (schema) => schema.max(100, 'Commission percentage should be less than or equal to 100.'),
        otherwise: (schema) => schema.max(10000, 'Commission percentage should be less than or equal to 10000.')
    })
        .when('currency', {
        is: (currency) => currency?.currency === 'APT',
        then: (schema) => schema.required('Enter a commission percentage.')
    }),
    voter: Yup.string().when(['currency', 'poolType'], {
        is: (currency, poolType) => currency?.currency === 'APT' && poolType === 'staking-pool',
        then: (schema) => schema.required('Enter a voter.')
    }),
    operator: Yup.string().when(['currency', 'poolType'], {
        is: (currency, poolType) => currency?.currency === 'APT' && poolType === 'staking-pool',
        then: (schema) => schema.required('Enter an operator.')
    }),
    amount: Yup.string()
        .when(['currency', 'poolType'], {
        is: (currency, poolType) => (currency?.currency === 'APT' && poolType === 'staking-pool') ||
            currency?.currency === 'STX',
        then: validateAmountField
    })
        .when(['minStake', 'poolType'], {
        is: (minStake, poolType) => minStake && poolType === 'staking-pool',
        then: (schema) => schema.test({
            name: 'amount',
            test: (value, { parent, createError }) => {
                const currency = parent?.currency?.currency;
                const minStake = parent?.minStake;
                if (new BigNumber(value ?? 0).minus(minStake ?? 0).gte(0)) {
                    return true;
                }
                return createError({
                    message: `The minimum you can stake is ${minStake} ${currency}.`
                });
            }
        })
    }),
    rewardAddress: Yup.string().when('currency', {
        is: (currency) => currency?.currency === 'STX',
        then: (schema) => schema.required('Enter a reward address.')
    }),
    rewardCycle: Yup.string().when('currency', {
        is: (currency) => currency?.currency === 'STX',
        then: (schema) => schema.required('Enter a reward cycle.')
    }),
    signerSignature: Yup.string().when('currency', {
        is: (currency) => currency?.currency === 'STX',
        then: (schema) => schema.required('Enter a signer signature.')
    }),
    signerKey: Yup.string().when('currency', {
        is: (currency) => currency?.currency === 'STX',
        then: (schema) => schema.required('Enter a signer key.')
    }),
    authId: Yup.string().when('currency', {
        is: (currency) => currency?.currency === 'STX',
        then: (schema) => schema.required('Enter an auth ID.')
    })
});
