import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from '@copper/hooks/useIntersection';
const LazyImage = ({ src, className, onError }) => {
    const [loadingFailed, setLoadingFailed] = useState(false);
    useEffect(() => {
        loadingFailed && setLoadingFailed(false);
    }, [src]);
    const imageRef = useRef(null);
    const intersection = useIntersection(imageRef, {}, true);
    const handleError = () => {
        if (onError) {
            onError();
        }
        setLoadingFailed(true);
    };
    return React.createElement(!intersection?.isIntersecting || loadingFailed ? 'div' : 'img', {
        className,
        ref: imageRef,
        src: intersection?.isIntersecting ? src : '',
        onError: intersection?.isIntersecting ? handleError : undefined
    });
};
export { LazyImage };
