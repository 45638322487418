import { request } from '@copper/utils';
export const createCounterparty = ({ organizationId, data }) => request.post(`/organizations/${organizationId}/counterparties`, data);
export const deleteCounterparty = ({ organizationId, counterpartyId }) => request.delete(`/organizations/${organizationId}/counterparties/${counterpartyId}`);
export const getCounterpartyById = ({ organizationId, counterpartyId }) => request.get(`/organizations/${organizationId}/counterparties/${counterpartyId}`);
export const getCounterparties = () => request.get(`/counterparties`);
export const getCounterpartiesByOrg = (organizationId, params) => request.get(`/organizations/${organizationId}/counterparties`, { params });
export const checkNetworkIdAvailability = (organizationId, data) => request.post(`/organizations/${organizationId}/counterparties`, data, {
    headers: {
        'Content-type': 'application/vnd.check-network-tag-availability+json'
    }
});
export const getCounterpartiesByQuery = ({ organizationId, queryString = '' }) => request.get(`/organizations/${organizationId}/counterparties${queryString}`);
export const inviteCounterparty = ({ organizationId, data }) => request.post(`/organizations/${organizationId}/counterparties`, data, {
    headers: {
        'Content-Type': 'application/vnd.invite-lite-organization-member+json'
    }
});
export const updateCounterparty = ({ organizationId, counterpartyId, data }) => request.patch(`/organizations/${organizationId}/counterparties/${counterpartyId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-counterparty+json'
    }
});
export const updateRelatedCounterparties = ({ organizationId, counterpartyId, data }) => request.patch(`/organizations/${organizationId}/counterparties/${counterpartyId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-counterparty-group+json'
    }
});
export const uploadCounterpartyLogo = ({ organizationId, counterpartyId, formData }) => request.put(`/organizations/${organizationId}/counterparties/${counterpartyId}/images/logo`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteCounterpartyLogo = ({ organizationId, counterpartyId }) => request.delete(`/organizations/${organizationId}/counterparties/${counterpartyId}/images/logo`);
export const updateCounterpartyNetworkTag = ({ data, counterpartyId, organizationId }) => request.patch(`/organizations/${organizationId}/counterparties/${counterpartyId}`, data, {
    headers: { 'Content-Type': 'application/vnd.update-network-tag+json' }
});
export const uploadCounterpartyBackground = ({ organizationId, counterpartyId, formData }) => request.put(`/organizations/${organizationId}/counterparties/${counterpartyId}/images/background`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
export const createCounterpartyConnection = ({ organizationId, data }) => request.post(`/organizations/${organizationId}/counterparty-connections`, data);
export const cancelCounterpartyConnection = (organizationId, connectionId) => request.patch(`/organizations/${organizationId}/counterparty-connections/${connectionId}`, {}, { headers: { 'Content-Type': 'application/vnd.cancel-counterparty-connection+json' } });
export const acceptCounterpartyConnection = ({ organizationId, data }) => request.patch(`/organizations/${organizationId}/counterparty-connections/${data.connectionId}`, data, { headers: { 'Content-Type': 'application/vnd.accept-counterparty-connection+json' } });
export const declineCounterpartyConnection = (organizationId, connectionId) => request.patch(`/organizations/${organizationId}/counterparty-connections/${connectionId}`, {}, { headers: { 'Content-Type': 'application/vnd.decline-counterparty-connection+json' } });
export const updateCounterpartyConnectionPortfolio = ({ organizationId, connectionId, data }) => request.patch(`/organizations/${organizationId}/counterparty-connections/${connectionId}`, data, { headers: { 'Content-Type': 'application/vnd.change-connection-portfolio+json' } });
export const deleteCounterpartyConnection = ({ organizationId, data }) => request.delete(`/organizations/${organizationId}/counterparty-connections/${data.connectionId}`);
export const createLendingArrangement = ({ organizationId, data }) => request.patch(`/organizations/${organizationId}/counterparty-connections/${data.connectionId}`, data, { headers: { 'Content-Type': 'application/vnd.add-lending-arrangement+json' } });
export const removeLendingArrangement = ({ organizationId, data }) => request.patch(`/organizations/${organizationId}/counterparty-connections/${data.connectionId}`, data, { headers: { 'Content-Type': 'application/vnd.remove-lending-arrangement+json' } });
