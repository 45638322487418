import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectOrganizationUsers } from '@copper/entities/organizations/organizations-selector';
import { getImageSrcById } from '@copper/helpers';
import { Avatar, IconBot } from '@copper/ui-kit';
import s from './UserSnippet.module.css';
export const UserSnippet = ({ userId = '', userData, align = 'left', secondaryInfo, label, fallback }) => {
    const users = useSelector(selectOrganizationUsers);
    const user = users?.[userId] || userData;
    if (!user && !fallback)
        return null;
    return (_jsxs("div", { className: cn(s.container, s[`align_${align}`]), children: [user ? (user.userType === 'service-user' ? (_jsx("div", { className: s.iconBot, children: _jsx(IconBot, {}) })) : (_jsx(Avatar, { image: user?.imageId && getImageSrcById(user?.imageId), initials: user?.initials, size: "s" }))) : (_jsx(_Fragment, { children: fallback && fallback.icon })), _jsxs("div", { className: s.info, children: [label && _jsx("div", { className: s.secondary, children: label }), _jsx("div", { className: s.fullName, children: user
                            ? `${user.firstName} ${user.lastName ? user.lastName : ''}`
                            : fallback && fallback.fullName }), secondaryInfo && _jsx("div", { className: s.secondary, children: secondaryInfo })] })] }));
};
