import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { WithdrawalPassword } from '@copper/components/WithdrawalPassword';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Alert } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
import { AssetList } from '../AssetList';
import s from './Summary.module.css';
const Summary = ({ isSubmitting, data, total, onNext, assets }) => {
    const currentOrganization = useSelector(selectCurrentOrganization);
    const { origin, destination } = data;
    if (!origin || !destination || !assets) {
        return null;
    }
    const isWithdrawalPassword = origin?.portfolioType === 'external' && currentOrganization.extra?.encryptionPublicKey;
    const confirmSummary = (password) => {
        onNext(password);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.containerSummary, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx("div", { className: s.alert, children: _jsx(Alert, { type: "warning", message: "Please ensure the assets held on the exchange are supported by your recipient account. If unsupported, the affected assets will remain on the exchange." }) }), _jsxs("div", { className: s.portfolios, children: [_jsx(InfoRow, { title: "From", titleColor: "ghost", children: _jsx(InfoAccount, { account: origin }) }), _jsx(InfoRow, { title: "To", titleColor: "ghost", children: _jsx(InfoAccount, { account: destination }) })] }), assets && assets.length > 0 ? _jsx(AssetList, { assets: assets }) : '', _jsx("div", { className: s.totalWrapper, children: _jsx(InfoRow, { title: "Total", titleColor: "ghost", children: _jsx("span", { className: s.total, children: formatFiat(total) }) }) })] }), isWithdrawalPassword && (_jsx(WithdrawalPassword, { formId: "master-password", submitWithdrawalPassword: confirmSummary }))] }), isWithdrawalPassword && (_jsx(SwitchStateRow, { loading: isSubmitting, type: "submit", form: "master-password", text: "Confirm" })), !isWithdrawalPassword && (_jsx(SwitchStateRow, { loading: isSubmitting, text: "Confirm", onClick: onNext }))] }));
};
export { Summary };
