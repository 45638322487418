import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { InfoAccount, InfoRow } from '@copper/components';
import { Button } from '@copper/ui-kit';
import s from '../../Generate.module.css';
export const Summary = ({ onSubmit }) => {
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { portfolio, blockchainTransactionType, voteKey, selectionKey, voteFirst, voteLast, voteKeyDilution, stateProofKey } = getValues();
    return (_jsxs("div", { className: s.summaryContent, children: [_jsxs("div", { className: s.innerContent, children: [_jsx("p", { className: s.summaryTitle, children: "Summary" }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio, address: portfolio.organizationId }) }), _jsx(InfoRow, { title: "Registration type", titleColor: "ghost", children: blockchainTransactionType === 'register-online' ? 'Online' : 'Offline' }), blockchainTransactionType === 'register-online' && (_jsxs(_Fragment, { children: [_jsx(InfoRow, { title: "Vote key", titleColor: "ghost", children: voteKey }), _jsx(InfoRow, { title: "Selection key", titleColor: "ghost", children: selectionKey })] })), _jsx(InfoRow, { title: "Vote first", titleColor: "ghost", children: voteFirst }), blockchainTransactionType === 'register-online' && (_jsxs(_Fragment, { children: [_jsx(InfoRow, { title: "Vote last", titleColor: "ghost", children: voteLast }), _jsx(InfoRow, { title: "Vote key dilution", titleColor: "ghost", children: voteKeyDilution }), _jsx(InfoRow, { title: "State proof key", titleColor: "ghost", children: stateProofKey })] }))] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "algo-registration-submit-button" }) })] }));
};
