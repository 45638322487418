import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetInfo, InfoList, InfoRow } from '@copper/components';
import { selectCounterpartiesEntitiesByOrgIds } from '@copper/entities/counterparties/counterparties-selector';
import { acceptOrderAddresses, fetchOrder } from '@copper/entities/orders/orders-reducer';
import { Button, StepItem, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { getCurrenciesByPurpose } from '../helpers';
import s from './ReviewCounterpartyAddress.module.css';
const ReviewCounterpartyAddress = ({ order, status, disabled, index, viewByAdmin, testId }) => {
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const [pendingOperation, setPendingOperation] = useState();
    const { depositCurrency, depositMainCurrency } = getCurrenciesByPurpose(order);
    const counterpartiesEntitiesByOrgId = useSelector(selectCounterpartiesEntitiesByOrgIds);
    const initiatorName = counterpartiesEntitiesByOrgId[order?.organizationId ?? '']?.counterpartyName;
    const sendResolution = async (operation) => {
        if (pendingOperation) {
            return; // don't send decline while approve requist is in progress and vice versa
        }
        setPendingOperation(operation);
        try {
            await dispatch(acceptOrderAddresses({ orderId: order.orderId, accepted: operation === 'accept' })).unwrap();
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setPendingOperation(undefined);
        }
    };
    const getOrder = useCallback(async () => {
        try {
            await dispatch(fetchOrder(order.orderId)).unwrap();
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    }, [order.orderId]);
    useEffect(() => {
        if (status === 'processing' &&
            order.status === 'waiting-addresses' &&
            typeof order._embedded?.isReceivingAddressAcceptable !== 'boolean') {
            getOrder();
        }
    }, [getOrder, disabled]);
    const showActions = !viewByAdmin && order._embedded?.isReceivingAddressAcceptable;
    return (_jsxs(StepItem, { testId: testId, status: status, open: status === 'processing', title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin ? `${initiatorName} reviews the receiving address` : 'Client address checks'}`, disabled: disabled, children: ["Please approve the receiving address provided by the counterparty.", status === 'processing' && (_jsxs("div", { className: s.reviewDetails, children: [_jsx(InfoList, { view: "primary-rare", children: _jsx(InfoRow, { titleColor: "secondary", title: "Address", children: _jsx("div", { className: s.addressWrapper, children: order?.extra?.receivingAddress || order._embedded?.receivingAddress?.address }) }) }), _jsx(InfoRow, { titleColor: "primary", title: "Currency", children: _jsx(AssetInfo, { currency: depositCurrency, align: "right" }) }), depositCurrency !== depositMainCurrency && (_jsx(InfoRow, { titleColor: "primary", title: "Network", children: _jsx(AssetInfo, { currency: depositMainCurrency, align: "right" }) })), showActions && (_jsxs("div", { className: s.buttonsRow, children: [_jsx(Button, { loading: pendingOperation === 'decline', onClick: () => sendResolution('decline'), view: "decline", text: "Decline" }), _jsx(Button, { loading: pendingOperation === 'accept', onClick: () => sendResolution('accept'), view: "positive", text: "Accept" })] }))] }))] }));
};
export { ReviewCounterpartyAddress };
