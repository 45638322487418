import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { HistoricLoanListItem } from '@copper/components/Loans/LoanList/HistoricLoanList/HistoricLoanListItem';
import { Table, TableEmptyRow, Tbody, Th, Thead } from '@copper/ui-kit';
import s from '@copper/components/Loans/LoanList/LoanList.module.css';
const HistoricLoanList = ({ group, loans, onLoanSelected }) => {
    const isBorrowingTab = group === 'borrowing';
    return (_jsxs(Table, { withTabs: true, noBottomRounding: true, children: [_jsxs(Thead, { children: [_jsx(Th, { width: "10%", className: s.contentAlign, children: "Asset" }), _jsx(Th, { align: "right", width: "10%", children: "Amount" }), _jsx(Th, { width: "10%", children: "Term" }), _jsx(Th, { align: "left", children: isBorrowingTab ? 'Lender' : 'Borrower' }), _jsx(Th, { align: "right", children: "Outcome" })] }), _jsx(Tbody, { children: loans?.length > 0 ? (_jsx(_Fragment, { children: loans.map((loan) => (_jsx(HistoricLoanListItem, { loan: loan, onClick: () => onLoanSelected(loan) }, loan.loanId))) })) : (_jsx(TableEmptyRow, { children: _jsx("div", { className: s.empty, children: "No previous loans" }) })) })] }));
};
export { HistoricLoanList };
