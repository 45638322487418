import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { InfoList, InfoRow } from '@copper/components';
import { useOrderContext } from '@copper/components/OrderPopup/context-hook';
import { StepContentContainer } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/StepContentContainer';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { useDateTime } from '@copper/hooks';
import { StepItem } from '@copper/ui-kit';
const AwaitingAddressReview = ({ order, status, disabled, index, viewByAdmin = false, testId, isFiatSettlement, isReceivingFiat }) => {
    const { getFormattedDateAndTime } = useDateTime();
    const counterparties = useSelector(selectCounterpartiesEntities);
    const fromCounterparty = counterparties[order?.extra?.fromCounterpartyId ?? ''];
    const { popupContext: { orderChanges } } = useOrderContext();
    const change = orderChanges?.find(({ changeType }) => changeType === 'receiving-address-approved-by-counterparty') || orderChanges?.find(({ changeType }) => changeType === 'order-canceled');
    const counterpartyName = fromCounterparty?.counterpartyName ?? 'Counterparty';
    const getText = () => {
        if (isFiatSettlement) {
            return isReceivingFiat
                ? undefined
                : `The specified receiving address has been sent to ${counterpartyName} for approval.`;
        }
        return `The specified receiving and return addresses have been sent to ${counterpartyName} for approval.`;
    };
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin ? `${counterpartyName} review the addresses` : 'Address checks'}`, disabled: disabled, children: _jsxs(StepContentContainer, { children: [getText(), change && (_jsx(InfoList, { view: "primary-rare", children: _jsx(InfoRow, { titleColor: "secondary", title: `${change.changeType === 'receiving-address-approved-by-counterparty'
                            ? 'Accepted'
                            : 'Declined'} at`, children: getFormattedDateAndTime(change.changedAt) }) }))] }) }));
};
export { AwaitingAddressReview };
