import { request } from '@copper/utils';
export const create = (data) => request.post('/rebalancer/rules', data);
export const pause = (ruleId) => request.patch(`rebalancer/rules/${ruleId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.pause-rule+json'
    }
});
export const unpause = (ruleId) => request.patch(`rebalancer/rules/${ruleId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.unpause-rule+json'
    }
});
export const archive = (ruleId) => request.patch(`rebalancer/rules/${ruleId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.archive-rule+json'
    }
});
export const update = (ruleId, data) => request.patch(`rebalancer/rules/${ruleId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-rule+json'
    }
});
export const unArchive = (ruleId) => request.patch(`rebalancer/rules/${ruleId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.unarchive-rule+json'
    }
});
