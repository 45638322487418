import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiatSettlementInfo } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/FiatSettlementInfo';
import { ExplorerLink } from '@copper/components/OrderPopup/ui/ExplorerLink';
import { startOtcSettlement } from '@copper/entities/orders/orders-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectUserId } from '@copper/entities/user/user-selector';
import { getQuoteAmount } from '@copper/helpers';
import { Button, StepItem, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './StartSettlement.module.css';
const StartSettlement = ({ order, status, disabled, testId, isFiatSettlement, isReceivingFiat }) => {
    const [isBusy, setIsBusy] = useState(false);
    const dispatch = useDispatch();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const userId = useSelector(selectUserId);
    const { openSnackbar } = useSnackBar();
    const settlingAmount = order.orderType === 'sell' ? order.amount : getQuoteAmount(order);
    const settlingCurrency = order.orderType === 'sell' ? order.baseCurrency : order.quoteCurrency;
    const handleStartSettlement = async () => {
        setIsBusy(true);
        try {
            const data = {
                orderId: order.orderId,
                portfolioId: order.portfolioId,
                organizationId,
                settledBy: userId
            };
            await dispatch(startOtcSettlement(data)).unwrap();
            openSnackbar({ type: 'success', message: 'Settlement started' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsBusy(false);
        }
    };
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, subTitle: "Process settlement", disabled: disabled, children: isFiatSettlement && !isReceivingFiat ? (_jsx(FiatSettlementInfo, { order: order })) : (_jsxs(_Fragment, { children: [_jsxs("div", { children: ["To complete your leg of the settlement, please deliver ", settlingAmount, ' ', settlingCurrency, " to the specified receiving address by clicking 'Complete settlement'."] }), order.status === 'ready-for-settlement' && (_jsx("div", { className: s.startButton, children: _jsx(Button, { text: "Complete settlement", loading: isBusy, onClick: handleStartSettlement }) })), order.extra?.deliveryType === 'free-of-payment' && order.extra.transactionId && (_jsxs("div", { className: s.explorerContainer, children: ["The settlement will go through as soon as enough block confirmations are in.", _jsx("div", { className: s.explorerButtonContainer, children: _jsx(ExplorerLink, { order: order }) })] }))] })) }));
};
export { StartSettlement };
