import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { SettlementInformation } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/SettlementInformation';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { selectCounterpartiesEntities, selectCounterpartiesEntitiesByOrgIds } from '@copper/entities/counterparties/counterparties-selector';
import { StepItem } from '@copper/ui-kit';
const AwaitingOfferReview = ({ order, status, disabled, index, viewByAdmin = false, testId }) => {
    const counterparties = useSelector(selectCounterpartiesEntities);
    const { counterpartyName = 'Counterparty' } = counterparties[order?.extra?.toCounterpartyId ?? ''] || {};
    const counterpartiesEntitiesByOrgId = useSelector(selectCounterpartiesEntitiesByOrgIds);
    const initiatorName = counterpartiesEntitiesByOrgId[order?.organizationId ?? '']?.counterpartyName;
    return (_jsxs(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin
            ? `${counterpartyName} reviews ${initiatorName} settlement instruction`
            : 'Counterparty reviews settlement instruction'}`, disabled: disabled, children: [_jsx(SettlementInformation, { order: order, viewByAdmin: viewByAdmin }), _jsx("div", { children: _jsx(CancelOrder, { order: order }) })] }));
};
export { AwaitingOfferReview };
