import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { FormResultFrame } from '@copper/components';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { Button } from '@copper/ui-kit';
import s from '../../Generate.module.css';
export const Result = ({ transaction, onDone }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { getValues } = useFormContext();
    const { blockchainTransactionType, portfolio } = getValues();
    if (transaction.status === 'executed' ||
        (portfolio.portfolioType === 'external' && transaction.status !== 'co-sign-require')) {
        return null;
    }
    const handleButtonClick = () => {
        navigate({
            pathname,
            search: `orderId=${transaction.orderId}`
        });
    };
    return (_jsx(FormResultFrame, { status: "pending", onDone: onDone, children: _jsxs("div", { className: s.resultContainer, children: [_jsx("h3", { className: s.resultTitle, children: "Pending signature" }), _jsx("p", { className: s.resultDescription, children: blockchainTransactionType === 'register-online'
                        ? 'To confirm your online registration, continue to sign this transaction.'
                        : 'To confirm your offline registration, continue to sign this transaction' }), _jsx(Button, { size: "l", text: "Sign", fullWidth: true, onClick: handleButtonClick }), _jsx(CancelOrder, { order: transaction })] }) }));
};
