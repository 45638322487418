import * as lendingApi from '@copper/api/lending';
import { subscribe } from '@copper/helpers/websocket';
import { serializeParams } from '@copper/utils';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { normalizeLendingLoans } from './lending-normalize';
import { normalizeFindLoansParams } from './lending-normalize';
const mergeLoan = (currentLoan, newLoan) => ({
    ...currentLoan,
    ...newLoan,
    _embedded: { ...currentLoan._embedded, ...newLoan._embedded }
});
const initialState = {
    loans: {},
    openedLoans: {},
    counterparties: {}
};
export const subscribeLoans = createAsyncThunk('loans/subscribe', async (data, thunkAPI) => {
    const { onUpdate, cancelRequest, ...params } = data;
    const serializedPayload = serializeParams(normalizeFindLoansParams(params));
    try {
        return await subscribe({
            uri: `/organizations/${params.organizationId}/loans${serializedPayload}`,
            callback: onUpdate,
            cancelRequest
        });
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchLoans = createAsyncThunk('agencyLending/fetchAllLoans', async (params, thunkAPI) => {
    try {
        const response = await lendingApi.getLoans(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchLoan = createAsyncThunk('agencyLending/fetchLoan', async (params, thunkAPI) => {
    try {
        const response = await lendingApi.getLoanById(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createLoan = createAsyncThunk('lending/createLoan', async ({ organizationId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.createAgencyLoan({ organizationId, data });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelLoan = createAsyncThunk('lending/cancelLoan', async ({ organizationId, loanId }, thunkAPI) => {
    try {
        const response = await lendingApi.cancelPrincipalLoan(organizationId, loanId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptLoan = createAsyncThunk('lending/acceptLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.acceptLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addCollateral = createAsyncThunk('lending/addCollateral', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.addCollateral(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const repayLoan = createAsyncThunk('lending/repayLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.repayLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rebalanceLoan = createAsyncThunk('lending/rebalanceLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.rebalanceLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const topUpLoan = createAsyncThunk('lending/topUpLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.topUpLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const defaultLoan = createAsyncThunk('lending/defaultLoan', async ({ organizationId, loanId }, thunkAPI) => {
    try {
        const response = await lendingApi.defaultLoan(organizationId, loanId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelLoanAction = createAsyncThunk('lending/cancelLoanAction', async ({ organizationId, loanId, allocationType, data }, thunkAPI) => {
    try {
        if (allocationType === 'top-up') {
            const response = await lendingApi.cancelLoanTopUpAction(organizationId, loanId, data);
            return response.data;
        }
        if (['release-collateral', 'rebalance'].includes(allocationType)) {
            const response = await lendingApi.cancelLoanReleaseCollateralAction(organizationId, loanId, data);
            return response.data;
        }
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptLoanAction = createAsyncThunk('lending/acceptLoanAction', async ({ organizationId, loanId, allocationType, data }, thunkAPI) => {
    try {
        if (allocationType === 'top-up') {
            const response = await lendingApi.acceptLoanTopUpAction(organizationId, loanId, data);
            return response.data;
        }
        if (['release-collateral', 'rebalance'].includes(allocationType)) {
            const response = await lendingApi.acceptLoanReleaseCollateralAction(organizationId, loanId, data);
            return response.data;
        }
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const lendingSlice = createSlice({
    name: 'lending',
    initialState,
    reducers: {
        updateLoans(state, { payload: { data } }) {
            const { loans: updatedLoans } = normalizeLendingLoans(data);
            Object.values(updatedLoans).forEach((updatedLoan) => {
                if (updatedLoan) {
                    const existingLoan = state.loans[updatedLoan.loanId];
                    if (existingLoan) {
                        state.loans[updatedLoan.loanId] = existingLoan
                            ? mergeLoan(existingLoan, updatedLoan)
                            : updatedLoan;
                    }
                    const existingOpenedLoan = state.openedLoans[updatedLoan.loanId];
                    if (existingOpenedLoan) {
                        state.openedLoans = {
                            ...state.openedLoans,
                            [updatedLoan.loanId]: mergeLoan(existingOpenedLoan, updatedLoan)
                        };
                    }
                }
            });
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(subscribeLoans.fulfilled, (state, { payload }) => {
            const { data } = payload;
            const { loans, counterparties } = normalizeLendingLoans(data);
            state.loans = loans;
            state.counterparties = counterparties;
        });
        addCase(fetchLoans.fulfilled, (state, action) => {
            const { loans, counterparties } = normalizeLendingLoans(action.payload);
            state.loans = loans;
            state.counterparties = counterparties;
        });
        addCase(fetchLoans.rejected, (state) => {
            state.loans = {};
            state.counterparties = {};
        });
        addCase(fetchLoan.fulfilled, (state, { payload }) => {
            state.openedLoans = { ...state.openedLoans, [payload.loanId]: payload };
        });
        addMatcher(isAnyOf(acceptLoan.fulfilled, createLoan.fulfilled, cancelLoan.fulfilled, addCollateral.fulfilled, repayLoan.fulfilled, rebalanceLoan.fulfilled, topUpLoan.fulfilled, defaultLoan.fulfilled, cancelLoanAction.fulfilled), (state, { payload: loan }) => {
            const existingLoan = state.loans[loan.loanId];
            if (state.openedLoans[loan.loanId]) {
                const currentLoan = state.openedLoans[loan.loanId];
                state.openedLoans = {
                    ...state.openedLoans,
                    [loan.loanId]: mergeLoan(currentLoan, loan)
                };
            }
            if (existingLoan) {
                state.loans[loan.loanId] = mergeLoan(existingLoan, loan);
            }
        });
    }
});
const { reducer, actions } = lendingSlice;
export const { updateLoans } = actions;
export const lending = reducer;
