export const groupByParent = (portfolios = []) => portfolios.reduce((acc, portfolio) => {
    const { extra: { parentPortfolioId = '' } = {} } = portfolio;
    const key = parentPortfolioId ? parentPortfolioId : 'root';
    const currentValue = acc[key];
    return {
        ...acc,
        [key]: typeof currentValue === 'undefined' ? [portfolio] : currentValue.concat(portfolio)
    };
}, {});
export const createNestedStructure = (list, groupedPortfolios, nestingLevel = 1, nodeKeys = []) => list.map((portfolio) => {
    const childPortfolios = groupedPortfolios[portfolio.portfolioId];
    const parentKeys = typeof childPortfolios === 'undefined' ? nodeKeys : [...nodeKeys, portfolio.portfolioId];
    return {
        parentKeys,
        item: portfolio,
        next: typeof childPortfolios === 'undefined'
            ? null
            : createNestedStructure(childPortfolios, groupedPortfolios, nestingLevel + 1, parentKeys),
        nestingLevel
    };
});
export const createPortfolioList = (portfolios) => {
    const groupedByParent = groupByParent(portfolios);
    const { root: rootPortfolios = [] } = groupedByParent;
    return createNestedStructure(rootPortfolios, groupedByParent);
};
export const flattenNestedPortfolios = (portfoliosList) => portfoliosList.reduce((acc, value) => {
    acc.push({ ...value.item, parentKeys: value.parentKeys });
    if (value.next) {
        acc = acc.concat(flattenNestedPortfolios(value.next));
    }
    return acc;
}, []);
export const createSubAccountsStructure = (portfolios, portfolioId, nestingLevel = 1) => {
    const data = portfolios.filter((item) => item?.extra?.parentPortfolioId === portfolioId);
    return data.map((item) => {
        const nextLevelItems = createSubAccountsStructure(portfolios, item.portfolioId, nestingLevel + 1);
        return { item, nestingLevel, next: nextLevelItems };
    });
};
export const isParentPortfolio = (portfolios, portfolioId) => portfolios.some((portfolio) => portfolio.extra?.parentPortfolioId === portfolioId);
