import * as Yup from 'yup';
import { checkRequiredNetwork } from '@copper/helpers/validation';
import { formatCurrency, formatFiat } from '@copper/utils';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    currency: Yup.object().required('Select an asset.'),
    amountThreshold: Yup.string().required('Enter an amount.'),
    transferMinBalance: Yup.string().when(['ruleType'], {
        is: (ruleType) => ruleType === 'below',
        then: (schema) => schema.required('Enter the minimum balance that must be left in the account after transfer.')
    }),
    transferPortfolio: Yup.object()
        .required('Select an account.')
        .test('portfolio', 'Unable to move this asset to selected account', (value, { parent }) => value?.portfolioId !== parent.portfolio?.portfolioId),
    mainCurrency: Yup.string().when(['transferPortfolio', 'portfolio', 'currency'], {
        is: (transferPortfolio, portfolio) => checkRequiredNetwork({ origin: transferPortfolio, destination: portfolio }),
        then: (schema) => schema.required('Select a network.')
    }),
    targetBalanceLimit: Yup.string()
        .required('Enter a required balance.')
        .when('ruleType', {
        is: 'above',
        then: (schema) => schema.test('amountThreshold', 'You must enter an amount lower than the original balance.', (value = '0', { parent }) => Number(value) < Number(parent.amountThreshold))
    })
        .when('ruleType', {
        is: 'below',
        then: (schema) => schema.test('amountThreshold', 'You must enter an amount greater than the original balance.', (value = '0', { parent }) => Number(value) > Number(parent.amountThreshold))
    })
});
export const getCurrencyCode = (currency, amountBy) => {
    if (!currency)
        return '';
    return amountBy === 'quote' ? 'USD' : currency?.currency;
};
export const formatRuleAmount = (amount, currencyCode) => {
    if (!currencyCode || !amount)
        return '';
    return currencyCode === 'USD' ? formatFiat(amount) : formatCurrency(amount);
};
