import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { FiatSettlementInfo } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/FiatSettlementInfo';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { StepItem } from '@copper/ui-kit';
const AwaitingCounterpartyDeposit = ({ order, status, disabled, index, viewByAdmin = false, testId, isReceivingFiat }) => {
    const counterparties = useSelector(selectCounterpartiesEntities);
    const { counterpartyName = 'Counterparty ' } = counterparties[order?.extra?.toCounterpartyId ?? ''] ?? {};
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin
            ? `${counterpartyName} processes settlement`
            : 'Counterparty processes settlement'}`, disabled: disabled, children: isReceivingFiat ? (_jsx(FiatSettlementInfo, { order: order })) : ('The counterparty has been instructed to complete their leg of the settlement.') }));
};
export { AwaitingCounterpartyDeposit };
