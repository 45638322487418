import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { archivePortfolio } from '@copper/entities/portfolios/portfolios-reducer';
import { Button, IconWarning, Popup, PopupContent, PopupHeader, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './DisconnectClearLoop.module.css';
const DisconnectClearLoopPopup = ({ portfolio, onBack, onClose, unbindEligibilityData }) => {
    const navigate = useNavigate();
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { unbindEligible, preconditionsNotMet = [] } = unbindEligibilityData ?? {};
    const hasMultipleReasons = preconditionsNotMet?.length > 1;
    const disconnectClearLoopAccount = useCallback(async () => {
        setLoading(true);
        try {
            await dispatch(archivePortfolio({ portfolioId: portfolio.portfolioId })).unwrap();
            openSnackbar({ type: 'success', message: 'Account disconnected' });
            onClose();
            navigate('/accounts/all');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setLoading(false);
        }
    }, [portfolio]);
    return (_jsxs(Popup, { onClose: onClose, children: [_jsx(PopupHeader, { onBack: onBack, title: "Disconnect" }), _jsx(PopupContent, { children: _jsxs("div", { className: s.wrapper, children: [_jsx("div", { className: s.icon, children: _jsx(IconWarning, {}) }), unbindEligible ? (_jsxs(_Fragment, { children: [_jsx("h2", { className: s.title, children: "Disconnect account" }), _jsxs("p", { className: s.text, children: ["This will disconnect your Copper account from your", ' ', _jsx("span", { className: s.exchangeName, children: portfolio.extra?.exchange ?? '' }), " account."] }), _jsxs("div", { className: s.buttonWrapper, children: [_jsx(Button, { size: "l", text: "Cancel", view: "secondary", className: s.actionButton, onClick: onBack }), _jsx(Button, { size: "l", text: "Disconnect", loading: loading, className: s.actionButton, onClick: disconnectClearLoopAccount })] })] })) : (_jsxs(_Fragment, { children: [_jsx("h2", { className: s.title, children: "Can't disconnect account" }), _jsx("p", { className: s.text, "data-testid": "paragraph-disconnect-clearLoop-reason", children: hasMultipleReasons
                                        ? 'Please follow the below steps to disconnect your ClearLoop account and try again:'
                                        : preconditionsNotMet[0] }), hasMultipleReasons && (_jsx("ul", { className: s.list, "data-testid": "paragraph-disconnect-clearLoop-reasons", children: preconditionsNotMet.map((reason, idx) => (_jsx("li", { children: reason }, idx))) })), _jsx("div", { className: s.buttonWrapper, children: _jsx(Button, { size: "l", fullWidth: true, text: "Close", onClick: onBack, "data-testid": "button-close-disconnectClearloop" }) })] }))] }) })] }));
};
export { DisconnectClearLoopPopup };
