import { checkIsAvailable } from '../TransferPortfolio/helpers';
export const filterUnavailable = ({ portfolios, walletsByPortfolioId, currenciesEntities, currencyId, mainCurrencyId, checkAvailableBalance = true }) => portfolios.filter((portfolio) => {
    const available = checkIsAvailable({
        walletsByPortfolioId,
        currenciesEntities,
        portfolio,
        currencyId,
        mainCurrencyId,
        showDestination: !checkAvailableBalance
    });
    return portfolio.isActive && available;
});
