import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { LoansPage } from '@copper/client/pages/Loans/LoansPage';
import { selectLoansSummary } from '@copper/entities/loans/loans-selector';
const Loans = () => {
    const summary = useSelector(selectLoansSummary);
    const [searchParams] = useSearchParams();
    const loanId = searchParams.get('loanId');
    const target = parseInt(summary?.numberLent ?? '0') > 0
        ? 'lending'
        : parseInt(summary?.numberBorrowed ?? '0') > 0
            ? 'borrowing'
            : 'lending';
    const query = loanId ? `?loanId=${loanId}` : '';
    return (_jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: `${target}${query}` }) }), _jsx(Route, { path: "/:activeTab", element: _jsx(LoansPage, {}) })] }));
};
export { Loans };
