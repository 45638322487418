import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as organizations from '@copper/api/organizations';
import { normalizeBilling } from '@copper/entities/billing/billing-normalize';
export const fetchBillingSubscriptions = createAsyncThunk('billing/fetchBillingSubscriptions', async ({ organizationId, params }, thunkAPI) => {
    try {
        const response = await organizations.getBillingSubscriptions(organizationId, params);
        return normalizeBilling(response.data);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const billingSlice = createSlice({
    name: 'billing',
    initialState: {
        products: {},
        paymentMethods: [],
        invoices: [],
        subscriptions: []
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchBillingSubscriptions.fulfilled, (_, { payload }) => payload);
    }
});
export const billing = billingSlice.reducer;
