import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    loanCurrency: Yup.object().required('Select asset to lend.'),
    loanNetworkCurrency: Yup.string().when('loanCurrency', {
        is: (currency) => currency?.mainCurrencies.length > 1,
        then: (schema) => schema.required('Select network.')
    }),
    amount: Yup.string().required('Enter loan amount.'),
    counterparty: Yup.object().required('Select counterparty.'),
    collateralCurrency: Yup.object().required('Select collateral asset.'),
    collateralNetworkCurrency: Yup.string().when('collateralCurrency', {
        is: (currency) => currency?.mainCurrencies.length > 1,
        then: (schema) => schema.required('Select network.')
    }),
    collateralAmount: Yup.string().required('Enter collateral amount.'),
    marginCallPercentage: Yup.string().required('Enter margin call.'),
    rebalancePercentage: Yup.string().required('Enter rebalance.'),
    defaultPercentage: Yup.string().required('Enter default.')
});
