import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import s from './Button.module.css';
const Button = React.forwardRef((props, ref) => {
    const { type = 'button', text = '', onClick, form, view = 'primary', size = 'm', state = 'default', indent = 'm', shape = 'default', fullWidth, leftSection, rightSection, className, loading, disabled, testId, ...otherProps } = props;
    return (_jsxs("button", { ...otherProps, ref: ref, type: type, form: form, disabled: disabled || loading, className: cn(s.button, s[`size_${size}`], s[`view_${view}`], s[`state_${state}`], s[`shape_${shape}`], s[`indent_${indent}`], { [s.fullWidth]: fullWidth, [s.inactive]: disabled || loading }, className), onClick: onClick, "data-testid": testId, children: [loading && _jsx("div", { className: s.loader }), Boolean(leftSection) && _jsx("div", { className: s.leftSection, children: leftSection }), _jsx("span", { className: s.text, children: text }), Boolean(rightSection) && _jsx("div", { className: s.rightSection, children: rightSection })] }));
});
Button.displayName = 'Button';
export { Button };
