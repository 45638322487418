import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import cn from 'classnames';
import { range } from 'lodash-es';
import { useFullWidthLayout } from '@copper/contexts/layout';
import { Skeleton } from '@copper/ui-kit';
import s from './SkeletonLoader.module.css';
const SkeletonLoader = memo(() => {
    useFullWidthLayout();
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.header, children: [_jsxs("div", { className: s.headerSummary, children: [_jsx("div", { className: s.title, children: "Copper Network" }), _jsx(Skeleton, { width: 176, height: 22, color: "secondary" })] }), _jsx(Skeleton, { width: 376, height: 32 })] }), _jsx("div", { className: s.skeletonContent, children: range(0, 7).map((index) => (_jsxs("div", { className: cn(s.card, s.skeletonCard), children: [_jsx(Skeleton, { width: 105, height: 56, style: { margin: '48px 16px 16px', borderRadius: '4px' } }), _jsx(Skeleton, { width: 176, height: 22, style: { margin: '0 16px 16px' } }), _jsx(Skeleton, { width: 84, height: 32, style: { margin: '58px 16px 16px', borderRadius: '4px' } })] }, `skeleton-${index}`))) })] }));
});
SkeletonLoader.displayName = 'SkeletonLoader';
export { SkeletonLoader };
