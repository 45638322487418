import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SettingMenuItem } from '@copper/components/Sidebar/SettingMenuItem';
import { useLayoutState } from '@copper/contexts/layout';
import { IconNotification, IconSecurity, IconSliders, IconUser, Skeleton } from '@copper/ui-kit';
import s from './PersonalSettingsMenu.module.css';
const PersonalSettingsMenu = () => {
    const { loadingLayout } = useLayoutState();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.container, children: !loadingLayout ? (_jsx("h2", { className: s.title, children: "Account settings" })) : (_jsx(Skeleton, { width: 210, height: 28, style: { margin: '4px 0 12px' } })) }), _jsxs("div", { className: s.list, children: [_jsx(SettingMenuItem, { title: "General", link: "/my/general", icon: _jsx(IconUser, {}), asSkeleton: loadingLayout }), _jsx(SettingMenuItem, { title: "Security", link: "/my/security", icon: _jsx(IconSecurity, {}), asSkeleton: loadingLayout }), _jsx(SettingMenuItem, { title: "Notifications", link: "/my/notifications", icon: _jsx(IconNotification, {}), asSkeleton: loadingLayout }), _jsx(SettingMenuItem, { title: "UI settings", link: "/my/ui", icon: _jsx(IconSliders, {}), asSkeleton: loadingLayout })] })] }));
};
export { PersonalSettingsMenu };
