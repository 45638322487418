import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { IconForbidden } from '@copper/ui-kit';
import { setCssProperty } from '@copper/utils/styles';
import s from './BlockedOrganizationBanner.module.css';
const BlockedOrganizationBanner = ({ text }) => {
    const organization = useSelector(selectCurrentOrganization);
    const blocked = organization.extra?.blocked;
    useEffect(() => {
        setCssProperty('--organization-blocked-banner-height', blocked ? '48px' : '0px');
    }, [blocked]);
    if (!blocked)
        return null;
    return (_jsx("div", { className: s.container, children: _jsxs("div", { className: s.wrapper, children: [_jsx(IconForbidden, {}), _jsx("div", { className: s.text, children: text })] }) }));
};
export { BlockedOrganizationBanner };
