export class Err extends Error {
    constructor(message, meta) {
        super(message);
        this.meta = meta;
    }
}
export const getErrorData = (err) => {
    if (err instanceof Object) {
        return {
            message: 'message' in err ? err.message : 'Something went wrong. Please try again.',
            error: 'error' in err ? err.error : undefined,
            errorId: 'Correlation-Id' in err ? err['Correlation-Id'] : undefined,
            autoClose: !('Correlation-Id' in err),
            code: 'code' in err ? err.code : undefined,
            meta: 'meta' in err ? err.meta : undefined
        };
    }
    if (typeof err === 'string') {
        return { message: err };
    }
    return { message: 'Something went wrong. Please try again.' };
};
