import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalizeCurrencyRates } from '@copper/entities/currencyRates/currencyRates-normalize';
import { subscribe } from '@copper/helpers/websocket';
export const subscribeCurrencyRates = createAsyncThunk('currencyRates/subscribe', async (data, thunkAPI) => {
    const { onUpdate, params } = data;
    try {
        return await subscribe({
            uri: `/dictionaries/currency-prices?${new URLSearchParams(params).toString()}`,
            callback: onUpdate
        });
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err);
    }
});
export const currencyRatesSlice = createSlice({
    name: 'currencyRates',
    initialState: {},
    reducers: {
        setCurrencyRates: (_, { payload }) => normalizeCurrencyRates(payload),
        updateCurrencyRates: (state, { payload }) => {
            for (const marketPrice of payload) {
                state[marketPrice.baseCurrency] = marketPrice.price;
            }
        }
    }
});
export const { setCurrencyRates, updateCurrencyRates } = currencyRatesSlice.actions;
export const currencyRates = currencyRatesSlice.reducer;
