import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectExchangesEntities } from '@copper/entities/exchanges/exchanges-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { hasClearloopAutoActivation } from '@copper/helpers/exchanges';
import { Button, IconComplete, IconPending } from '@copper/ui-kit';
import { SubAccounts } from '../SubAccounts';
import s from './ClearloopResult.module.css';
const ClearloopResult = ({ portfolioId, onSuccess }) => {
    const exchangesEntities = useSelector(selectExchangesEntities);
    const portfolios = useSelector(selectPortfolios);
    // portfolio from store to get autodiscovery status after updating sub-accounts
    const portfolio = portfolios[portfolioId ?? ''];
    const currentExchange = exchangesEntities[portfolio?.extra?.exchange ?? ''];
    const autodiscoveredAccountStatus = portfolio?.extra?.autodiscoveredAccountStatus;
    if (!portfolio) {
        return null;
    }
    return (_jsxs("div", { children: [autodiscoveredAccountStatus === 'frozen' ? (_jsxs(_Fragment, { children: [_jsx("div", { className: cn(s.iconContainer, s.iconPending), children: _jsx(IconPending, {}) }), _jsxs("div", { className: s.content, children: [_jsx("h3", { className: s.title, children: "Almost there" }), _jsx("p", { className: s.description, children: "We need some extra information to connect sub-accounts." })] })] })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: cn(s.iconContainer, s.iconSuccess), children: _jsx(IconComplete, {}) }), _jsxs("div", { className: s.content, children: [_jsx("h3", { className: s.title, children: "Account connected" }), _jsxs("p", { className: s.description, children: [portfolio.portfolioName, " is now available for trading."] }), _jsx(Button, { view: "secondary", fullWidth: true, onClick: onSuccess, text: "Done" })] })] })), (currentExchange?.exchangeAccounts?.length || 0) > 1 && (_jsx(SubAccounts, { portfolio: portfolio, autoActivation: hasClearloopAutoActivation(currentExchange) }))] }));
};
export { ClearloopResult };
