import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { StartSettlement } from '@copper/components/OrderPopup/StartSettlement';
import { selectCounterpartiesEntitiesByOrgIds } from '@copper/entities/counterparties/counterparties-selector';
import { StepItem } from '@copper/ui-kit';
import s from './StartSign.module.css';
const StartSign = ({ order, status, disabled, viewByAdmin = false, index, testId }) => {
    const counterpartiesEntitiesByOrgId = useSelector(selectCounterpartiesEntitiesByOrgIds);
    const initiatorName = counterpartiesEntitiesByOrgId[order?.organizationId ?? '']?.counterpartyName;
    return (_jsxs(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin ? `${initiatorName} should` : 'Sign'} transaction`, disabled: disabled, children: ["To confirm, ", viewByAdmin ? `${initiatorName} should` : 'please', " proceed to sign the transaction.", status === 'processing' && !viewByAdmin && (_jsx("div", { className: s.buttonContainer, children: _jsx(StartSettlement, { order: order, type: "simple" }) }))] }));
};
export { StartSign };
