import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import * as Yup from 'yup';
import useStateMachine from '@cassiozen/usestatemachine';
import { AlgoGovernanceForm } from '@copper/client/components/Generate/AlgoGovernance/AlgoGovernanceForm';
import { Result } from '@copper/client/components/Generate/AlgoGovernance/Result';
import { Summary } from '@copper/client/components/Generate/AlgoGovernance/Summary';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import s from '@copper/client/components/Generate/Generate.module.css';
const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    memo: Yup.string().optional(),
    externalAddress: Yup.string().required('Enter external address.')
});
export const AlgoGovernance = ({ onDone, onBack }) => {
    const portfolios = useSelector(selectPortfolios);
    const [transaction, setTransaction] = useState();
    const dispatch = useDispatch();
    const { route } = useRoutingContext();
    const { openSnackbar } = useSnackBar();
    const { setTopPanel } = useTopPanel();
    const algoGovernanceForm = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {}
    });
    const { handleSubmit, reset, getValues } = algoGovernanceForm;
    const [state, send] = useStateMachine({
        initial: 'algoGovernanceForm',
        states: {
            algoGovernanceForm: {
                on: {
                    SUMMARY: 'summary'
                }
            },
            summary: {
                on: {
                    BACK: 'algoGovernanceForm',
                    RESULT: 'result'
                }
            },
            result: {}
        }
    });
    const handleCreateOrder = handleSubmit(async () => {
        try {
            const formValues = getValues();
            const data = {
                portfolioId: formValues.portfolio.portfolioId,
                memo: formValues.memo,
                toAddress: formValues.externalAddress,
                externalOrderId: cuid(),
                baseCurrency: 'ALGO',
                mainCurrency: 'ALGO',
                orderType: 'withdraw',
                amount: '0',
                blockchainTransactionType: 'governance-vote'
            };
            const res = await dispatch(createOrder(data)).unwrap();
            setTransaction(res);
            send('RESULT');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    });
    const handleDone = () => {
        reset();
        onDone();
    };
    useEffect(() => {
        setTopPanel({
            title: 'Generate',
            onBack: state.value === 'result'
                ? undefined
                : () => {
                    if (state.value === 'algoGovernanceForm')
                        return onBack();
                    return send('BACK');
                }
        });
    }, [state, setTopPanel]);
    useEffect(() => {
        if (route?.name === 'generate' && route?.state === 'algoGovernance') {
            const { data } = route;
            reset({
                portfolio: portfolios[data?.portfolioId ?? ''],
                ...(data?.toAddress && { externalAddress: data?.toAddress }),
                memo: data?.memo
            });
        }
    }, [route]);
    return (_jsx("div", { className: s.mainWrapper, children: _jsxs(FormProvider, { ...algoGovernanceForm, children: [state.value === 'algoGovernanceForm' && (_jsx(AlgoGovernanceForm, { onNext: handleSubmit(() => send('SUMMARY')) })), state.value === 'summary' && _jsx(Summary, { onSubmit: handleCreateOrder }), state.value === 'result' && transaction && (_jsx(Result, { onDone: handleDone, transaction: transaction }))] }) }));
};
