import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { format, isValid, parse } from 'date-fns';
import { useOutsideClick } from '@copper/hooks';
import { ButtonIcon, DATE_FORMAT, DatePicker, FormLabel, IconCalendar, Input } from '@copper/ui-kit';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import s from './DatePicker.module.css';
export const SingleDatePicker = ({ date, disabled, label, onChange, fromDate, toDate, view = 'primary', testId = 'input-date-picker' }) => {
    const captionRef = useRef(null);
    const inputRef = useRef(null);
    const [isFocus, setIsFocus] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: 'bottom-end',
        middleware: [offset(4), flip(), shift()]
    });
    useOutsideClick([refs.floating, refs.reference, captionRef], useCallback(() => {
        setIsFocus(false);
        setIsCalendarOpen(false);
    }, []));
    const handleFocus = () => {
        inputRef.current?.focus();
        setIsFocus(true);
        setIsCalendarOpen(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        const date = parse(e.target.value, DATE_FORMAT, new Date());
        if (isValid(date)) {
            onChange(date);
        }
        else {
            onChange(undefined);
        }
    };
    const handleDaySelect = (date) => {
        onChange(date);
        if (date) {
            setInputValue(format(date, DATE_FORMAT));
            setIsCalendarOpen(false);
        }
        else {
            setInputValue('');
        }
    };
    useEffect(() => {
        if (date) {
            setInputValue(format(date, DATE_FORMAT));
        }
        else {
            setInputValue('');
        }
    }, [date]);
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: label }), _jsx("div", { ref: refs.setReference, children: _jsx(Input, { testId: testId, disabled: disabled, fullWidth: true, size: "l", view: view, autoFocus: isFocus, ref: inputRef, onFocus: handleFocus, value: inputValue, onChange: handleInputChange, placeholder: "Select date", rightSection: _jsx(ButtonIcon, { state: isFocus ? 'active' : undefined, onClick: handleFocus, children: _jsx(IconCalendar, {}) }) }) }), isCalendarOpen &&
                ReactDOM.createPortal(_jsx("div", { ref: refs.setFloating, className: cn(s.calendarContainer, s[`container_${view}`]), style: floatingStyles, children: _jsx(DatePicker, { mode: "single", singleValue: date, onSingleChange: handleDaySelect, captionRef: captionRef, fromDate: fromDate, toDate: toDate, view: view }) }), document.body)] }));
};
