import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '@copper/components';
import { PortfolioInfoButton } from '@copper/components/Portfolio/PortfolioInfoButton';
import { useLayoutState } from '@copper/contexts/layout';
import s from './TopNavBar.module.css';
export const TopNavBar = () => {
    const { loadingLayout } = useLayoutState();
    const { portfolioId } = useParams();
    if (loadingLayout) {
        return _jsx("div", { className: s.container });
    }
    return (_jsxs("div", { className: s.container, children: [_jsx(Breadcrumbs, {}), portfolioId && _jsx(PortfolioInfoButton, { portfolioId: portfolioId })] }));
};
