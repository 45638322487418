import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as webhooksApi from '@copper/api/webhooks';
import { normalizeWebhookSubscriptions } from '@copper/entities/webhooks/webhooks-normalize';
export const getWebhookSubscriptions = createAsyncThunk('webhooks/getWebhookSubscriptions', async (data, thunkAPI) => {
    try {
        const response = await webhooksApi.getWebhookSubscriptions(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createWebhookSubscription = createAsyncThunk('webhooks/createWebhookSubscription', async (data, thunkAPI) => {
    try {
        const response = await webhooksApi.createWebhookSubscription(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteWebhookSubscription = createAsyncThunk('webhooks/deleteWebhookSubscription', async (data, thunkAPI) => {
    try {
        const response = await webhooksApi.deleteWebhookSubscription(data);
        thunkAPI.dispatch(deleteWebhook(data));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const pauseWebhookSubscription = createAsyncThunk('webhooks/pauseWebhookSubscription', async (data, thunkAPI) => {
    try {
        const response = await webhooksApi.pauseWebhookSubscription(data);
        thunkAPI.dispatch(updateWebhook(data));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateWebhookSubscription = createAsyncThunk('webhooks/updateWebhookSubscription', async (data, thunkAPI) => {
    try {
        const response = await webhooksApi.updateWebhookSubscription(data);
        thunkAPI.dispatch(updateWebhook(data));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const webhooksSlice = createSlice({
    name: 'webhooks',
    initialState: {
        entities: {},
        eventTypes: []
    },
    reducers: {
        deleteWebhook(state, { payload }) {
            const { subscriptionId } = payload;
            delete state.entities[subscriptionId];
        },
        updateWebhook(state, { payload }) {
            const { subscriptionId } = payload;
            state.entities[subscriptionId] = {
                ...state.entities[subscriptionId],
                ...payload
            };
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(getWebhookSubscriptions.fulfilled, (state, { payload }) => {
            const { entities, eventTypes } = normalizeWebhookSubscriptions(payload);
            state.entities = entities;
            state.eventTypes = eventTypes;
        });
        addCase(createWebhookSubscription.fulfilled, (state, { payload }) => {
            const { subscriptionId } = payload;
            state.entities[subscriptionId] = payload;
        });
    }
});
const { actions, reducer } = webhooksSlice;
export const { deleteWebhook, updateWebhook } = actions;
export const webhooks = reducer;
