import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { TopNavBar } from '@copper/components';
import { useLayoutState } from '@copper/contexts/layout';
import { fetchCounterpartiesByOrg } from '@copper/entities/counterparties/counterparties-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { addIdleResetListener, removeIdleResetListener } from '@copper/helpers/idle-reset';
import { useBalanceSubscription } from '@copper/hooks/useBalanceSubscription';
import { useCurrencyRatesSubscription } from '@copper/hooks/useCurrencyRatesSubscription';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { Toolbar } from '../Toolbar';
import s from './Layout.module.css';
const Layout = () => {
    const dispatch = useDispatch();
    const { isFullWidth, loadingLayout } = useLayoutState();
    const subscribeToBalances = useBalanceSubscription();
    const subscribeToCurrencyRates = useCurrencyRatesSubscription();
    const { organizationId } = useSelector(selectCurrentOrganization);
    useEffect(() => {
        addIdleResetListener();
        return () => {
            removeIdleResetListener();
        };
    }, []);
    useEffect(() => {
        if (organizationId && !loadingLayout) {
            subscribeToBalances(organizationId);
            subscribeToCurrencyRates(organizationId);
            dispatch(fetchCounterpartiesByOrg({
                organizationId: organizationId
            })).unwrap();
        }
    }, [organizationId, loadingLayout]);
    return (_jsxs("main", { className: s.container, children: [_jsx(Header, {}), !isFullWidth && (_jsx("aside", { className: s.sidebar, children: _jsx(Sidebar, {}) })), _jsxs("section", { className: cn(s.content, {
                    [s.fullWidth]: isFullWidth
                }), children: [_jsx(TopNavBar, {}), _jsx(Outlet, {}), _jsx(Toolbar, {})] })] }));
};
export { Layout };
