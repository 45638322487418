import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import cn from 'classnames';
import { Button, Dropdown, IconKeyboardArrowDown } from '@copper/ui-kit';
import s from './DropdownButton.module.css';
export const DropdownButton = ({ view = 'primary', listWidth = 180, items, size = 'm', fullWidth = false, text, onChange = () => undefined, testId, icon, getTitle, placement = 'bottom-end' }) => {
    const [listWidthValue, setListWidthValue] = useState(listWidth);
    const setButtonRef = (button) => {
        if (button && listWidth) {
            const { offsetWidth } = button;
            setListWidthValue(offsetWidth > listWidth ? offsetWidth : listWidth);
        }
    };
    const dropdownArrowStyles = {
        width: size === 's' ? '16px' : '24px',
        height: size === 's' ? '16px' : '24px'
    };
    const ArrowElement = () => (_jsxs("div", { className: s.buttonContainer, "data-testid": testId, children: [_jsx(Button, { ref: setButtonRef, fullWidth: fullWidth, size: size, view: view, text: text, rightSection: _jsx("div", { className: s.buttonDummy }) }), _jsx("div", { className: s.buttonRightSection, children: _jsx("div", { className: cn(s.arrowIconContainer, s[`arrow_${size}`], s[`arrow_${view}`]), children: icon || _jsx(IconKeyboardArrowDown, { ...dropdownArrowStyles }) }) })] }));
    return (_jsx(Dropdown, { placement: placement, highlightSelectedItem: false, listWidth: listWidthValue, getKey: (item) => item.value, getTitle: (item) => (getTitle ? getTitle(item) : item.label), onChange: onChange, items: items, getToggle: ArrowElement, testId: `${testId}-dropdown` }));
};
