export const normalizeCurrencyRates = (currencies) => {
    const result = {};
    currencies.forEach((currency) => {
        if (currency._embedded && currency._embedded.price) {
            const price = currency._embedded.price;
            result[price.baseCurrency] = price.price;
        }
    });
    return result;
};
