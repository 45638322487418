import { createContext, useContext, useLayoutEffect } from 'react';
export const LayoutContext = createContext({});
export const useLayoutState = () => useContext(LayoutContext);
export const useFullWidthLayout = (isFullWidth = true) => {
    const { setIsFullWidth } = useLayoutState();
    useLayoutEffect(() => {
        setIsFullWidth && setIsFullWidth(isFullWidth);
        return () => {
            setIsFullWidth && setIsFullWidth(!isFullWidth);
        };
    }, []);
};
