import { detectBrowser } from '@copper/utils/os';
export const getPlatformType = (hostName) => {
    if (/qa\.boople\.co/i.test(hostName)) {
        return 'qa';
    }
    else if (/demo|stage\.copper.co$/i.test(hostName)) {
        return 'stage';
    }
    else if (/testnet\.copper.co$/i.test(hostName)) {
        return 'testnet';
    }
    return 'live';
};
export const generateCUDeepLink = (token, orderId, platform, correlationId) => `copperunlimited://sign?token=${token}&browser=${detectBrowser()}&sign_type=order&sign_id=${orderId}&platform=${platform}&correlation_id=${correlationId}`;
