import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { Checkbox, Th } from '@copper/ui-kit';
import s from '../TableRows.module.css';
const HeaderRow = ({ handleToggleAll, ordersListLength }) => {
    const { selectedOrdersIds } = useBulkOrder();
    return (_jsxs(_Fragment, { children: [_jsx(Th, { className: s.checkboxCol, children: _jsx("div", { className: s.selectAllCheckboxWrapper, children: _jsx(Checkbox, { checked: Boolean(selectedOrdersIds.length), name: "selectAll", intermediate: selectedOrdersIds.length !== ordersListLength, onChange: handleToggleAll, disabled: !ordersListLength }) }) }), _jsx(Th, { className: s.sideCol, children: "Side" }), _jsx(Th, { className: cn(s.assetsCol, s.assetsHeaderCell), children: "Assets" }), _jsx(Th, { align: "right", children: "Amount" }), _jsx(Th, { className: s.accountTypeCol }), _jsx(Th, { className: s.routeCol, children: "Account / counterparty" }), _jsx(Th, { className: s.statusCol, align: "center" }), _jsx(Th, { className: s.actionCol, align: "center", children: "Action" })] }));
};
export { HeaderRow };
