import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import s from './Tooltip.module.css';
const Tooltip = ({ type = 'tooltip', textAlign = 'center', wrapperClassName, children, disabled = false, label, view = 'primary', offsetY = 4, placement = 'bottom', delayHide = 150, delayShow }) => {
    let computedDelayShow = delayShow;
    if (!computedDelayShow) {
        computedDelayShow = type === 'hint' ? 250 : 500;
    }
    const timer = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        placement,
        middleware: [offset(offsetY), flip(), shift()]
    });
    const showTooltip = useCallback(() => {
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => setIsOpen(true), computedDelayShow);
    }, [computedDelayShow]);
    const hideTooltip = useCallback(() => {
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => setIsOpen(false), delayHide);
    }, [delayHide]);
    const handleClick = useCallback(() => {
        setIsOpen(false);
        clearTimeout(timer.current);
    }, []);
    useEffect(() => () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
    }, []);
    const triggerProps = {
        onMouseEnter: showTooltip,
        onMouseLeave: hideTooltip,
        onClick: handleClick
    };
    if (disabled) {
        return children;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: cn(s.container, wrapperClassName), ...triggerProps, ref: refs.setReference, children: children }), isOpen &&
                createPortal(_jsx("div", { ref: refs.setFloating, style: { zIndex: 2000, ...floatingStyles }, children: _jsx("div", { className: cn(s.tooltip, s[`type_${type}`], s[`view_${view}`], s[`text_align_${textAlign}`]), children: label }) }), document.body)] }));
};
export { Tooltip };
