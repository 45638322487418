import * as approvalRequestsApi from '@copper/api/approvalRequests';
export const getApprovalRequests = (params) => approvalRequestsApi.getApprovalRequests(params).then((response) => response.data);
export const getApprovalRequestDetails = (requestId) => approvalRequestsApi.getApprovalRequestDetails(requestId).then((response) => response.data);
export const updateApprovalRequests = (data) => approvalRequestsApi.updateApprovalRequests(data).then((response) => response.data);
export const updateApprovalRequest = (requestId, data) => approvalRequestsApi.updateApprovalRequest(requestId, data).then((response) => response.data);
export const reviewApprovalRequest = (requestId, data) => approvalRequestsApi
    .updateApprovalRequestReview(requestId, data)
    .then((response) => response.data);
export const cancelApprovalRequest = (requestId) => approvalRequestsApi.cancelApprovalRequest(requestId).then((response) => response.data);
export const fetchPendingApprovalRequestDetails = (requestId) => approvalRequestsApi.getApprovalRequestDetails(requestId).then((response) => response.data);
