export const normalizeAccountActivities = (data) => {
    const result = {
        entities: {},
        activityCategories: {},
        activityTypes: {}
    };
    if (data.activities) {
        data.activities.forEach((activity) => {
            result.entities[activity.activityId] = activity;
        });
    }
    if (data?._embedded?.activityCategories) {
        data._embedded.activityCategories.forEach((activityCategory) => {
            result.activityCategories[activityCategory.category] = activityCategory;
        });
    }
    if (data?._embedded?.activityTypes) {
        data._embedded.activityTypes.forEach((activityType) => {
            result.activityTypes[activityType.activityType] = activityType;
        });
    }
    return result;
};
