import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cuid from 'cuid';
import { Icon } from '@copper/ui-kit/Icon';
const IconError = (props) => (_jsx(Icon, { ...props, path: _jsxs("g", { fill: "none", children: [_jsx("path", { d: "M12 22.0001C6.47715 22.0001 2 17.523 2 12.0001C2 6.47727 6.47715 2.00012 12 2.00012C17.5228 2.00012 22 6.47727 22 12.0001C22 17.523 17.5228 22.0001 12 22.0001Z", fill: "url(#IconErrorExclamation_paint0_linear_43_658)" }), _jsx("path", { d: "M12 21.5001C6.75329 21.5001 2.5 17.2468 2.5 12.0001C2.5 6.75342 6.75329 2.50012 12 2.50012C17.2467 2.50012 21.5 6.75342 21.5 12.0001C21.5 17.2468 17.2467 21.5001 12 21.5001Z", stroke: "white", strokeOpacity: "0.05" }), _jsx("circle", { cx: "12", cy: "17", r: "1", fill: "white" }), _jsx("path", { d: "M11 14V6H13V14H11Z", fill: "url(#IconErrorExclamation_paint1_linear_43_658)" }), _jsxs("defs", { children: [_jsxs("linearGradient", { id: "IconErrorExclamation_paint0_linear_43_658", x1: "14", y1: "15.0001", x2: "3.5", y2: "-4.49988", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#E74C3C" }), _jsx("stop", { offset: "1", stopColor: "#E74C3C", stopOpacity: "0" })] }), _jsxs("linearGradient", { id: "IconErrorExclamation_paint1_linear_43_658", x1: "12", y1: "9.5", x2: "12", y2: "15.5", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "white" }), _jsx("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })] })] })] }) }));
const IconWarning = (props) => (_jsx(Icon, { ...props, path: _jsxs("g", { fill: "none", children: [_jsx("path", { d: "M12.0004 22.0001C6.47754 22.0001 2.00039 17.5229 2.00039 12.0001C2.00039 6.47723 6.47754 2.00008 12.0004 2.00008C17.5232 2.00008 22.0004 6.47723 22.0004 12.0001C22.0004 17.5229 17.5232 22.0001 12.0004 22.0001Z", fill: "url(#paint0_linear_1136_2938)" }), _jsx("path", { d: "M12.0004 21.5001C6.75368 21.5001 2.50039 17.2468 2.50039 12.0001C2.50039 6.75337 6.75368 2.50008 12.0004 2.50008C17.2471 2.50008 21.5004 6.75337 21.5004 12.0001C21.5004 17.2468 17.2471 21.5001 12.0004 21.5001Z", stroke: "white", strokeOpacity: "0.05" }), _jsx("circle", { cx: "12", cy: "8", r: "1", fill: "white" }), _jsx("path", { d: "M11 18V11H13V18H11Z", fill: "white", fillOpacity: "0.8" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_1136_2938", x1: "15.0004", y1: "16.5001", x2: "4.00039", y2: "-3.49992", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#FFB740" }), _jsx("stop", { offset: "1", stopColor: "#F5A623", stopOpacity: "0" })] }) })] }) }));
const IconInfo = (props) => {
    const uniqId_1 = cuid();
    const uniqId_2 = cuid();
    return (_jsx(Icon, { ...props, path: _jsxs("g", { fill: "none", children: [_jsxs("defs", { children: [_jsxs("linearGradient", { id: uniqId_1, x1: "15.0005", y1: "16.5000", x2: "4.00049", y2: "-3.49994", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#2FBAE6" }), _jsx("stop", { offset: "1", stopColor: "#2FBAE5", stopOpacity: "0" })] }), _jsxs("linearGradient", { id: uniqId_2, x1: "12", y1: "15.5", x2: "12", y2: "10.5", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "white" }), _jsx("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })] })] }), _jsx("path", { fill: `url(#${uniqId_1})`, d: "M12.0005 22.0001C6.47764 22.0001 2.00049 17.5229 2.00049 12.0001C2.00049 6.47721 6.47764 2.00006 12.0005 2.00006C17.5233 2.00006 22.0005 6.47721 22.0005 12.0001C22.0005 17.5229 17.5233 22.0001 12.0005 22.0001Z" }), _jsx("path", { d: "M12.0005 21.5001C6.75378 21.5001 2.50049 17.2468 2.50049 12.0001C2.50049 6.75336 6.75378 2.50006 12.0005 2.50006C17.2472 2.50006 21.5005 6.75336 21.5005 12.0001C21.5005 17.2468 17.2472 21.5001 12.0005 21.5001Z", stroke: "white", strokeOpacity: "0.05" }), _jsx("circle", { cx: "12", cy: "8", r: "1", fill: "white" }), _jsx("path", { d: "M11 18V11H13V18H11Z", fill: `url(#${uniqId_2})` })] }) }));
};
const IconSuccess = (props) => {
    const uniqId_1 = cuid();
    const uniqId_2 = cuid();
    return (_jsx(Icon, { ...props, path: _jsxs("g", { fill: "none", children: [_jsx("path", { d: "M4 3.75V14.2917C4 18.5083 6.84444 19.6083 12 23V1C8.97776 3.65833 7.1111 3.75 4 3.75Z", fill: `url(#${uniqId_1})` }), _jsx("path", { d: "M12 1V23C17.1555 19.6083 20 18.5083 20 14.2917V3.75C16.8889 3.75 15.0222 3.65833 12 1Z", fill: `url(#${uniqId_2})` }), _jsxs("defs", { children: [_jsxs("linearGradient", { id: uniqId_1, x1: "2.98325", y1: "-2.56473", x2: "2.98325", y2: "25.0274", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#75FF7E" }), _jsx("stop", { offset: "1", stopColor: "#75FF7E", stopOpacity: "0" })] }), _jsxs("linearGradient", { id: uniqId_2, x1: "10.9833", y1: "-2.56473", x2: "10.9833", y2: "25.0274", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#75FF7E", stopOpacity: "0" }), _jsx("stop", { offset: "0.9995", stopColor: "#75FF7E" })] })] })] }) }));
};
export { IconError, IconInfo, IconSuccess, IconWarning };
