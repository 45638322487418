import { createSelector } from '@reduxjs/toolkit';
import { isBorrowerOrganization, isNeedsAttention } from '@copper/components/Loans/helpers';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
export const selectLoansEntities = (store) => store.loans.entities;
export const selectLoans = createSelector(selectLoansEntities, (loans) => Object.values(loans));
export const selectLoansSummary = (store) => store.loans.summary;
export const selectLoansBorrowers = (store) => store.loans.summary?.borrowers;
export const selectLoansLenders = (store) => store.loans.summary?.lenders;
export const selectLoansAssets = (store) => store.loans.summary?.assets;
export const selectLoanRequests = createSelector(selectCurrentOrganization, selectLoans, (organization, loans) => loans.filter((loan) => {
    const isBorrower = isBorrowerOrganization(loan, organization);
    return loan.pendingUserActions && isNeedsAttention(loan.pendingUserActions, isBorrower);
}));
export const selectAdminRequests = createSelector(selectLoans, (loans) => loans.filter((loan) => loan.pendingUserActions &&
    loan.pendingUserActions.includes('notice-of-exclusive-control-required')));
export const selectLoanValuations = (store) => store.loans.valuations;
