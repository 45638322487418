import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { ButtonIcon, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import s from './InfoAddress.module.css';
const InfoAddress = ({ address, iconSize = 24, textSize = 's' }) => {
    const { openSnackbar } = useSnackBar();
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: cn(s.text, s[`text_size_${textSize}`]), children: address }), _jsx(ButtonIcon, { onClick: (e) => {
                    e.stopPropagation();
                    copy(address, () => openSnackbar({ type: 'success', message: 'Copied' }));
                }, tooltipLabel: "Copy address", children: _jsx(IconClipboard, { height: iconSize, width: iconSize }) })] }));
};
export { InfoAddress };
