import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { ResultSignInfo } from '@copper/components/Forms/ResultSignInfo';
import { Alert } from '@copper/ui-kit';
import s from './Result.module.css';
const Result = ({ transaction, errorMessage, formData, onDone }) => {
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        if (transaction?.status === 'processing' ||
            (transaction?.status !== 'executed' && transaction?.status !== 'new')) {
            return 'pending';
        }
        return 'success';
    };
    const status = getResultStatus();
    const { origin, destination } = formData;
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: errorMessage ? 'Transfer failed' : 'Awaiting approval by Copper' }), _jsxs(FormResultAccent, { children: [formData.amount, " ", formData.currency?.currency] }), _jsx(FormResultRegular, { children: _jsxs(_Fragment, { children: ["From ", _jsx(NavLink, { to: `/accounts/${origin?.portfolioId}`, children: origin?.portfolioName }), " to", ' ', destination?.bankAccountName ?? 'Unknown'] }) }), errorMessage ? (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) })) : (_jsx(ResultSignInfo, { currencyId: formData.currency?.currency, transaction: transaction, origin: formData.origin }))] }));
};
export { Result };
