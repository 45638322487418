import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { SettleForm } from '@copper/client/components/Settle/Form';
import { validationSchema } from '@copper/client/components/Settle/helpers';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { selectActivePlatformCurrencies } from '@copper/entities/currencies/currencies-selector';
import { selectPortfolioEntities } from '@copper/entities/portfolios/portfolios-selector';
import { Button } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { PopupFooter } from '@copper/ui-kit/Popup/PopupFooter';
import { yupResolver } from '@hookform/resolvers/yup';
import s from './EditForm.module.css';
const formId = 'bulk-edit-form';
const defaultValues = {
    transferBy: 'network',
    availableForSettlement: '0'
};
const SettlementDryOrderEditForm = () => {
    const activeCurrencies = useSelector(selectActivePlatformCurrencies);
    const { setModalContext, modalOrderData, onUpdate, showIncompletedOnly, onUnselect } = useBulkOrder();
    const portfolioEntities = useSelector(selectPortfolioEntities);
    const counterpartiesEntities = useSelector(selectCounterpartiesEntities);
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    });
    useEffect(() => {
        const formValues = {
            orderType: modalOrderData?.orderType ?? 'buy',
            portfolio: portfolioEntities[modalOrderData?.portfolioId ?? ''],
            amount: modalOrderData?.amount,
            priceOrQuoteAmount: modalOrderData?.priceLimit,
            baseCurrency: activeCurrencies.find((item) => item.currency === modalOrderData?.baseCurrency),
            mainCurrency: modalOrderData?.mainCurrency,
            quoteCurrency: activeCurrencies.find((item) => item.currency === modalOrderData?.quoteCurrency),
            quoteMainCurrency: modalOrderData?.quoteMainCurrency,
            counterparty: counterpartiesEntities[modalOrderData?.toCounterpartyId ?? ''],
            description: modalOrderData?.description
        };
        form.reset({ ...defaultValues, ...formValues });
    }, [modalOrderData]);
    const closePopup = () => setModalContext();
    const onSave = (formValues) => {
        if (!modalOrderData?.orderId) {
            return;
        }
        const { orderId, statusDescription } = modalOrderData;
        const updatedData = {
            orderId,
            orderType: formValues.orderType,
            limitType: 'otc',
            portfolioId: formValues?.portfolio?.portfolioId,
            amount: formValues?.amount,
            baseCurrency: formValues?.baseCurrency?.currency,
            mainCurrency: formValues?.mainCurrency,
            priceLimit: formValues?.priceOrQuoteAmount,
            quoteCurrency: formValues?.quoteCurrency?.currency,
            quoteMainCurrency: formValues?.quoteMainCurrency,
            daysToSettle: '0',
            description: formValues?.description,
            toCounterpartyId: formValues?.counterparty?.counterpartyId,
            counterparty: formValues?.counterparty
        };
        if (showIncompletedOnly && statusDescription) {
            onUnselect([orderId]);
        }
        onUpdate(updatedData);
        closePopup();
    };
    return (_jsxs(_Fragment, { children: [_jsx(PopupContent, { children: _jsx(SettleForm, { formId: formId, form: form, onSubmit: onSave, view: "primary" }) }), _jsx(PopupFooter, { rightSection: _jsxs("div", { className: s.buttons, children: [_jsx(Button, { text: "Cancel", view: "secondary", onClick: closePopup }), _jsx(Button, { type: "submit", form: formId, text: "Save" })] }) })] }));
};
export { SettlementDryOrderEditForm };
