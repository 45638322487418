import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { Button } from '@copper/ui-kit';
import { normalizeDryRunResult } from '../../helpers';
import s from './Result.module.css';
const Result = () => {
    const { machineSend, setDryOrdersBlanks, resultOrders } = useBulkOrder();
    const failedOrders = resultOrders.filter((order) => order.status === 'error');
    const status = failedOrders.length ? 'warn' : 'success';
    const handleRecoverClick = () => {
        setDryOrdersBlanks(normalizeDryRunResult(failedOrders));
        machineSend('RECOVER');
    };
    const onDone = () => {
        machineSend('RESET');
    };
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: status === 'success' ? 'Success' : 'Attention' }), _jsx(FormResultAccent, { children: status === 'success' ? (_jsxs(_Fragment, { children: ["Your transfer instructions have", _jsx("br", {}), " been sent"] })) : ('Some instructions failed') }), _jsx(FormResultRegular, { children: status === 'success' ? ('You can manage your transfers from the Dashboard.') : (_jsxs(_Fragment, { children: ["We could only process ", resultOrders.length - failedOrders.length, " out of", ' ', resultOrders.length, " instructions. You can ", _jsx("br", {}), " view and edit failed instructions and try again."] })) }), status === 'warn' && (_jsx("div", { className: s.restoreFailedButton, children: _jsx(Button, { size: "l", text: "See failed instructions", onClick: handleRecoverClick }) }))] }));
};
export { Result };
