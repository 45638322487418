import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as jurisdictionsApi from '@copper/api/jurisdictions';
import { normalizeJurisdictions } from '@copper/entities/jurisdictions/jurisdictions-normalize';
export const fetchJurisdictions = createAsyncThunk('jurisdictions/fetchJurisdictions', async (_, thunkAPI) => {
    try {
        const response = await jurisdictionsApi.getJurisdictions();
        return response.data.jurisdictions;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const jurisdictionsSlice = createSlice({
    name: 'jurisdictions',
    initialState: {
        entities: {}
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchJurisdictions.fulfilled, (state, { payload }) => {
            const { entities } = normalizeJurisdictions(payload);
            state.entities = entities;
        });
    }
});
export const jurisdictions = jurisdictionsSlice.reducer;
