import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { InfoAddress } from '@copper/client/components/Generate/AlgoGovernance/Summary/InfoAddress';
import { InfoAccount, InfoRow } from '@copper/components';
import { Button } from '@copper/ui-kit';
import s from '../../Generate.module.css';
export const Summary = ({ onSubmit }) => {
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { portfolio, memo, externalAddress = '' } = getValues();
    return (_jsxs("div", { className: s.summaryContent, children: [_jsxs("div", { className: s.innerContent, children: [_jsx("p", { className: s.summaryTitle, children: "Summary" }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio, address: portfolio.organizationId }) }), _jsx(InfoRow, { title: "To", titleColor: "ghost", children: _jsx(InfoAddress, { externalAddress: externalAddress }) }), memo && _jsx(InfoRow, { title: "Memo", children: memo })] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "algo-governance-submit-button" }) })] }));
};
