import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { FormResultFrame } from '@copper/components';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { ButtonLink } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import s from './Success.module.css';
export const Success = ({ onDone, loanId }) => {
    const navigate = useNavigate();
    const { getValues } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const { amount, loanCurrency, collateralAmount, collateralCurrency } = getValues();
    return (_jsxs(FormResultFrame, { status: "success", onDone: onDone, children: [_jsx("p", { className: s.title, children: "Your loan has been booked" }), _jsxs("p", { className: s.loanAmount, children: [formatCurrency(amount || '0', {
                        decimalPlaces: getDecimals(loanCurrency.currency, { useDictionaryDecimal: true })
                    }), ' ', loanCurrency.currency] }), _jsxs("p", { className: s.collateralAmount, children: ["Collateral", ' ', formatCurrency(collateralAmount || '0', {
                        decimalPlaces: getDecimals(collateralCurrency.currency, { useDictionaryDecimal: true })
                    }), ' ', collateralCurrency.currency] }), _jsx("div", { className: s.viewButton, children: _jsx(ButtonLink, { text: "View loan", onClick: () => navigate(`/loans/requests?loanId=${loanId}`) }) })] }));
};
