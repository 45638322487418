import { add, addDays, formatDistanceStrict, isBefore, isSameDay, subDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
const formatDateToWord = (dateString, isFormatToWord) => {
    let wordDate = dateString.split('-').join(' ');
    if (isFormatToWord) {
        const theDate = new Date(wordDate);
        const today = new Date();
        const yesterday = subDays(today, 1);
        const tomorrow = addDays(today, 1);
        if (isSameDay(theDate, yesterday))
            wordDate = 'Yesterday';
        if (isSameDay(theDate, today))
            wordDate = 'Today';
        if (isSameDay(theDate, tomorrow))
            wordDate = 'Tomorrow';
    }
    return wordDate;
};
export const getExpandedDateAndTimeAsObject = ({ timestamp, timeformat = '24-hour', timezone = Intl.DateTimeFormat().resolvedOptions().timeZone, isFormatToWord = true }) => {
    try {
        const delimiter = '_';
        const [date, time, formatedTz] = formatInTimeZone(parseInt(timestamp), timezone, `dd-MMM-yyyy${delimiter}${timeformat === '24-hour' ? 'HH:mm' : 'hh:mm a'}${delimiter}${timezone === 'UTC' ? 'z' : 'O'}`).split(delimiter);
        const trimmedTimezone = formatedTz === 'GMT+0' ? 'GMT' : formatedTz;
        const wordDate = formatDateToWord(date, isFormatToWord);
        return {
            date: wordDate,
            time,
            timezone: trimmedTimezone
        };
    }
    catch (_) {
        return {
            date: '-',
            time: '-',
            timezone: ''
        };
    }
};
export const getCommonFormattedDateAndTime = ({ timeformat, timestamp, isFormatToWord = true }) => {
    const { date, time, timezone } = getExpandedDateAndTimeAsObject({
        timeformat,
        timestamp,
        isFormatToWord
    });
    return `${date} at ${time} ${timezone}`;
};
export const formatExpirationDate = (expiresAt) => `${formatDistanceStrict(parseInt(expiresAt), new Date().getTime())} left`;
export const isExpiring = (expiresAt, fromNowLimit) => isBefore(parseInt(expiresAt), add(new Date(), fromNowLimit));
export const convertDateToSeconds = (timestamp) => Math.floor(timestamp / 1000);
