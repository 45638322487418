import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AssetIcon } from '@copper/components';
import s from './CurrencyInfo.module.css';
export const CurrencyInfo = ({ currency }) => {
    if (!currency) {
        return null;
    }
    return (_jsxs("div", { className: s.container, children: [_jsx(AssetIcon, { currency: currency.currency, size: "l" }), _jsxs("div", { className: s.wrapper, children: [_jsx("h5", { className: s.name, children: currency.name }), _jsx("div", { className: s.ticker, children: currency.currency })] })] }));
};
