import { fuzzySearch } from '@copper/ui-kit';
export const getOpenedNodes = (data, openNodes, getKey, setOpenNodes) => data.reduce((acc, node) => {
    const { item, next, nestingLevel } = node;
    const key = getKey ? getKey(item) : '';
    const hasNodes = Array.isArray(next) && next.length > 0;
    const isOpenNodes = hasNodes && openNodes.includes(key);
    const toggleNode = hasNodes
        ? () => {
            const newOpenNodes = openNodes.includes(key)
                ? openNodes.filter((openNode) => openNode !== key)
                : [...openNodes, key];
            setOpenNodes(newOpenNodes);
        }
        : undefined;
    const currentItem = {
        ...item,
        hasNodes,
        isOpenNodes,
        nestingLevel,
        toggleNode
    };
    const nextLevelItems = isOpenNodes
        ? getOpenedNodes(next, openNodes, getKey, setOpenNodes)
        : [];
    return [...acc, currentItem, ...nextLevelItems];
}, []);
export const flattenNodes = (items) => items.reduce((acc, value) => {
    acc.push({ ...value.item });
    if (value.next) {
        acc = acc.concat(flattenNodes(value.next));
    }
    return acc;
}, []);
export const filterItems = ({ items = [], searchQuery = '', searchFields = [], searchType = 'weak' }) => {
    if (searchQuery === '') {
        return items;
    }
    return fuzzySearch({
        searchQuery,
        keys: [...searchFields],
        items,
        searchType
    });
};
export const filterNestedItemsByArray = (nestedItems, filterItems, getKey) => nestedItems.reduce((acc, nestedItem) => {
    const next = nestedItem.next
        ? filterNestedItemsByArray(nestedItem.next, filterItems, getKey)
        : null;
    if (filterItems.some((filterItem) => getKey(nestedItem.item) === getKey(filterItem)) ||
        next?.length) {
        return acc.concat({
            ...nestedItem,
            next
        });
    }
    return acc;
}, []);
export const getLeaves = (items) => items.reduce((acc, value) => {
    if (value.next) {
        acc.push(...getLeaves(value.next));
    }
    else {
        acc.push({ ...value });
    }
    return acc;
}, []);
export const getParentKeys = (nestedItems) => nestedItems.reduce((acc, item) => {
    acc.push(...(item.parentKeys ?? []));
    return acc;
}, []);
