import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const DIVI = {
    title: 'DIVI',
    id: 'divi',
    qa: [
        {
            question: 'How do I stake DIVI?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Enter a validator address." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake DIVI?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." })] }), _jsx("p", { children: "Your assets will be released into your staking vault right away, as there is no unbonding period." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for DIVI?',
            answer: _jsx("p", { children: "No, you can choose up to 100% of your available balance." })
        },
        {
            question: 'How often will I earn DIVI staking rewards?',
            answer: (_jsx("p", { children: "You can check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }))
        },
        {
            question: 'How can I claim my DIVI staking rewards?',
            answer: (_jsx("p", { children: "Rewards for DIVI are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }))
        }
    ]
};
