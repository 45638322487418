import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, IconWarning } from '@copper/ui-kit';
import s from './ErrorBoundary.module.css';
const ZendeskError = () => {
    const handleOnClick = () => {
        location.replace('/');
    };
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.icon, children: _jsx(IconWarning, {}) }), _jsx("h1", { children: "Something went wrong" }), _jsx("div", { className: s.text, children: "There was an unexpected error. Please try again later. If the problem continues, contact us for support." }), _jsx(Button, { text: "Back to home", onClick: handleOnClick })] }));
};
export { ZendeskError };
