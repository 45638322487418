import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormControl, Input } from '@copper/ui-kit';
import s from './TwoFaCore.module.css';
export const INPUT_LENGTH = 6;
const CODE_BACKSPACE = 8;
const CODE_ARROW_LEFT = 37;
const CODE_ARROW_RIGHT = 39;
const getTwoFaValues = (arr) => arr.twoFaCode.map((item) => item.value).filter(Boolean);
const TwoFaCore = ({ onSuccess, errorMessage, formId, validate, autoFocus = false }) => {
    const inputsRef = useRef([]);
    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            twoFaCode: [...Array(INPUT_LENGTH).fill({ value: '' })]
        }
    });
    const { fields } = useFieldArray({
        control,
        name: 'twoFaCode',
        rules: validate ? { validate } : undefined
    });
    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);
    useEffect(() => {
        if (errorMessage || autoFocus) {
            goToInput(0);
        }
    }, [errorMessage, autoFocus]);
    const goToInput = (index, select = false) => {
        const elem = inputsRef.current[index];
        if (elem) {
            elem.focus();
            select && elem.select();
        }
    };
    const handlePaste = (event) => {
        if (event.type !== 'paste') {
            return;
        }
        const code = event.clipboardData?.getData('text') || '';
        if (code.length === 6 && typeof Number(code) === 'number') {
            fields.forEach((_, index) => {
                setValue(`twoFaCode.${index}.value`, code[index], { shouldDirty: true });
            });
            if (!formId) {
                handleSubmit(onSubmit)();
            }
        }
    };
    const handleChange = useCallback((e, index) => {
        if (e.target.value === '') {
            return;
        }
        if (inputsRef && inputsRef.current && index !== INPUT_LENGTH - 1) {
            goToInput(index + 1);
        }
        if (getTwoFaValues(getValues()).length === INPUT_LENGTH) {
            if (!formId) {
                handleSubmit(onSubmit)();
                setTimeout(() => {
                    inputsRef.current[INPUT_LENGTH - 1].blur();
                }, 10);
            }
        }
    }, [getValues, handleSubmit]);
    const handleKeyUpCode = useCallback((event) => {
        const { keyCode, target } = event;
        const index = Number(target.getAttribute('data-id'));
        if (keyCode === CODE_ARROW_RIGHT) {
            goToInput(index + 1);
        }
        else if (keyCode === CODE_ARROW_LEFT) {
            goToInput(index - 1);
        }
        else if (keyCode === CODE_BACKSPACE) {
            goToInput(index - 1, true);
            setValue(`twoFaCode.${index}.value`, '', { shouldDirty: true });
        }
    }, [setValue]);
    const onSubmit = async (data) => {
        const value = getTwoFaValues(data).join('');
        onSuccess(value);
    };
    return (_jsx("form", { id: formId, onSubmit: handleSubmit(onSubmit), children: _jsx(FormControl, { errorMessage: errorMessage ?? errors?.twoFaCode?.root?.message, children: _jsx("div", { className: s.group, children: fields.map((item, index) => (_jsxs(Fragment, { children: [_jsx("div", { className: s.wrapper, children: _jsx(Controller, { name: `twoFaCode.${index}.value`, control: control, rules: { required: true }, render: ({ field }) => {
                                    const { ref, onChange, ...rest } = field;
                                    return (_jsx(Input, { ...rest, size: "xl", view: "code", testId: "2fa-input", type: "text", state: "default" // Error state receives from FormControl
                                        , maxLength: 1, dataId: index, className: s.input, autoFocus: index === 0 ? autoFocus : false, onKeyUp: handleKeyUpCode, autoComplete: "one-time-code", onChange: (e) => {
                                            onChange(e);
                                            handleChange(e, index);
                                        }, ref: (e) => {
                                            if (e && !inputsRef.current[index]) {
                                                ref(e);
                                                inputsRef.current[index] = e;
                                            }
                                        } }));
                                } }) }), index === 2 && _jsx("div", { className: s.separator })] }, item.id))) }) }) }));
};
export { TwoFaCore };
