import axios from 'axios';
import cuid from 'cuid';
import { selectIsAuthorized } from '@copper/entities/auth/auth-selector';
import { getApiUrl } from '@copper/helpers';
import { hideZendeskWidget } from '@copper/utils/zendesk';
axios.defaults.timeout = 30000;
axios.defaults.timeoutErrorMessage = 'Request timed out';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${getApiUrl()}`;
axios.defaults.paramsSerializer = (params) => serializeParams(params, true);
export const getBearerFromLs = () => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    return auth.token || null;
};
export const clearAuthData = () => {
    const itemsToRemove = ['auth', 'user'];
    itemsToRemove.forEach((item) => {
        localStorage.removeItem(item);
    });
    hideZendeskWidget();
};
const setAuthorizationHeader = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else {
        delete axios.defaults.headers.common['Authorization'];
    }
};
const setupAxiosInterceptors = (store, onAuthError) => {
    axios.interceptors.response.use(undefined, (error) => {
        const state = store.getState();
        if (error.response.data.error === 'scheduled-maintenance') {
            setAuthorizationHeader(null);
            clearAuthData();
            onAuthError();
            localStorage.setItem('scheduled-maintenance-message', error.response.data.message);
            return window.location.replace('/scheduled-maintenance');
        }
        if (error.response && error.response.status === 401) {
            if ((selectIsAuthorized(state) &&
                !['incorrect-two-fa', 'bad-credentials'].includes(error.response.data.error)) ||
                error.response.data.error === 'incorrect-token') {
                setAuthorizationHeader(null);
                clearAuthData();
                onAuthError();
            }
        }
        return Promise.reject(error.response
            ? {
                ...error.response.data,
                'Correlation-Id': error.response.data.error === 'incorrect-token'
                    ? undefined
                    : error?.config?.headers['Correlation-Id']
            }
            : error);
    });
    axios.interceptors.request.use((config) => {
        const token = getBearerFromLs();
        if (config.headers) {
            config.headers['Correlation-Id'] = cuid();
            if (!config.headers['Authorization'] && token) {
                config.headers['Authorization'] = `Bearer ${token}`;
                setAuthorizationHeader(token);
            }
        }
        return config;
    });
};
const serializeParams = (queryObject, withoutLeadingDelimiter = false) => {
    const queryKeys = Object.keys(queryObject);
    const queryString = queryKeys
        .filter((key) => queryObject[key] !== undefined && queryObject[key] !== null && queryObject[key] !== '')
        .reduce((acc, key, i) => {
        const delimiter = i === 0 ? '?' : '&';
        const encodedKey = encodeURIComponent(key);
        const encodedVal = encodeURIComponent(queryObject[key]);
        return [acc, delimiter, encodedKey, '=', encodedVal].join('');
    }, '');
    return withoutLeadingDelimiter ? queryString.replace('?', '') : queryString;
};
export { axios as request, serializeParams, setAuthorizationHeader, setupAxiosInterceptors };
