import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { PasskeyTwoFa } from '@copper/components/Auth/Passkey';
import { Yubikey } from '@copper/components/Auth/Yubikey';
import { TwoFaCore } from '@copper/components/TwoFaCore';
import { selectUserEmail } from '@copper/entities/user/user-selector';
import { Button, Loader, Popup } from '@copper/ui-kit';
import s from './TwoFaPopup.module.css';
const TwoFaPopup = ({ isSubmitting = false, twoFaType = 'totp', target = 'client', requestedAction, onSkipPasskey, onCancel, onSuccess, errorMessage = '' }) => {
    const email = useSelector(selectUserEmail);
    return (_jsx(Popup, { onClose: onCancel, children: _jsxs("div", { className: s.popupContainer, children: [_jsx("div", { className: s.title, children: twoFaType === 'passkey'
                        ? 'Use your passkey to verify it’s you'
                        : 'Enter verification code' }), _jsx("div", { className: s.popupInput, children: isSubmitting ? (_jsx("div", { className: s.loader, children: _jsx(Loader, { size: "l" }) })) : (_jsxs(_Fragment, { children: [twoFaType === 'passkey' && (_jsx("div", { className: s.passkeyContainer, children: _jsx(PasskeyTwoFa, { email: email, errorMessage: errorMessage, description: "Use your passkey to verify it\u2019s you", requestedAction: requestedAction, target: target, onSkipPasskey: onSkipPasskey, onSuccess: onSuccess }) })), twoFaType === 'totp' && (_jsxs(_Fragment, { children: [_jsx("div", { className: s.description, children: "Get a code from your authentication app to verify it\u2019s you." }), _jsx(TwoFaCore, { autoFocus: true, errorMessage: errorMessage, onSuccess: onSuccess }), _jsx("div", { className: s.helpText, children: "Need help? Please contact Copper's Middle Office." })] })), twoFaType === 'yubikey' && (_jsxs(_Fragment, { children: [_jsx("div", { className: s.description, children: "Get a code from your authentication app to verify it\u2019s you." }), _jsx(Yubikey, { errorMessage: errorMessage, onSuccess: onSuccess }), _jsxs("div", { className: s.button, children: [_jsx(Button, { form: "yubikey-form", loading: isSubmitting, fullWidth: true, size: "l", type: "submit", text: "Authenticate" }), _jsx("div", { className: s.helpText, children: "Need help? Please contact Copper's Middle Office." })] })] }))] })) })] }) }));
};
export { TwoFaPopup };
