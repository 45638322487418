import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ConnectionList, LoanBalances, LoanList, SkeletonLoader } from '@copper/components/Loans';
import { LoanModal } from '@copper/components/Loans/LoanModal';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { useFullWidthLayout, useLayoutState } from '@copper/contexts/layout';
import { subscribeLoans, updateLoans } from '@copper/entities/loans/loans-reducer';
import { selectLoanRequests, selectLoans } from '@copper/entities/loans/loans-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { fetchPledgeConnections } from '@copper/entities/pledge/pledge-reducer';
import { Badge, Tabs, Tooltip, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './Loans.module.css';
const generateLoansTabs = () => {
    const tabs = [
        {
            title: 'Lending',
            value: 'lending'
        },
        {
            title: 'Borrowing',
            value: 'borrowing'
        },
        {
            title: 'Attention Needed',
            value: 'requests'
        },
        {
            title: 'Connections',
            value: 'connections'
        }
    ];
    return tabs;
};
const LoansPage = () => {
    useFullWidthLayout();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { checkPermissions } = useCheckPermissionsContext();
    const { loadingLayout } = useLayoutState();
    const { openSnackbar } = useSnackBar();
    const currentOrganization = useSelector(selectCurrentOrganization);
    const requests = useSelector(selectLoanRequests);
    const loans = useSelector(selectLoans);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState();
    const [subscription, setSubscription] = useState({
        unsubscribe: () => null
    });
    const { activeTab } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const loanId = searchParams.get('loanId');
    useEffect(() => {
        if (loanId) {
            const loan = loans.find((loan) => loan.loanId === loanId);
            loan && setSelectedLoan(loan);
        }
    }, [loanId, loans]);
    const onTabChangeHandler = useCallback((value) => navigate(`/loans/${value}`), [navigate]);
    const subscribeToLoans = async () => {
        try {
            const result = await dispatch(subscribeLoans({
                command: { organizationId: currentOrganization?.organizationId, includeSummary: true },
                onUpdate: (onUpdateResponse) => {
                    if (!onUpdateResponse.error) {
                        dispatch(updateLoans(onUpdateResponse.data));
                    }
                    else if (onUpdateResponse.error === 'connection-closed-from-worker') {
                        setSubscription({
                            unsubscribe: () => null
                        });
                        subscribeToLoans();
                    }
                    else {
                        openSnackbar({
                            type: 'error',
                            message: onUpdateResponse.errorMessage ?? onUpdateResponse.error
                        });
                    }
                }
            })).unwrap();
            setSubscription({
                unsubscribe: result.unsubscribe
            });
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const getConnections = useCallback(async () => {
        try {
            setIsLoading(true);
            await dispatch(fetchPledgeConnections(currentOrganization?.organizationId));
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsLoading(false);
        }
    }, [currentOrganization?.organizationId]);
    useEffect(() => {
        if (activeTab === 'connections') {
            getConnections();
        }
    }, [activeTab, getConnections]);
    useEffect(() => {
        setIsLoading(true);
        currentOrganization.organizationId && subscribeToLoans();
    }, [currentOrganization?.organizationId]);
    useEffect(() => {
        if (!loadingLayout && !checkPermissions(['view-loans'])) {
            navigate('/accounts/all');
        }
    }, [checkPermissions, loadingLayout]);
    useEffect(() => () => {
        subscription.unsubscribe?.();
    }, [subscription]);
    const handleCloseLoanModal = () => {
        setSearchParams();
        setSelectedLoan(undefined);
    };
    const handleSelectLoan = (loan) => {
        setSearchParams({ loanId: loan.loanId });
        setSelectedLoan(loan);
    };
    if (!activeTab) {
        return _jsx(Navigate, { to: "/loans" });
    }
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.title, children: "Loans" }), _jsxs("div", { className: s.contentContainer, children: [_jsx(Tabs, { view: "dark_transparent", items: generateLoansTabs(), value: activeTab, onChange: ({ value }) => onTabChangeHandler(value), getTitle: ({ title }) => _jsx("span", { className: s.tab, children: title }), getRightSection: ({ value }) => value === 'requests' && requests.length ? (_jsx(Tooltip, { label: "Loans that need your attention.", placement: "top-start", type: "hint", children: _jsx(Badge, { content: requests.length }) })) : undefined }), loadingLayout || isLoading ? (_jsx("div", { className: s.tableContainer, children: _jsx(SkeletonLoader, { items: 5 }) })) : (_jsx(_Fragment, { children: activeTab !== 'connections' ? (_jsxs("div", { className: s.tableContainer, children: [_jsx(LoanBalances, { group: activeTab, isLoading: loadingLayout || isLoading }), _jsx(LoanList, { group: activeTab, isLoading: loadingLayout || isLoading, onLoanSelected: handleSelectLoan }), _jsx(LoanModal, { loan: selectedLoan, onClose: handleCloseLoanModal })] })) : (_jsx("div", { className: s.tableContainer, children: _jsx(ConnectionList, {}) })) }))] })] }));
};
export { LoansPage };
