import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { setCssProperty } from '@copper/utils/styles';
import { getSidebarWidth, MAX_SIDEBAR_WIDTH, MIN_SIDEBAR_WIDTH, saveSidebarWidth } from './helpers';
import { Resizer } from './Resizer';
import s from './SidebarContainer.module.css';
export const SidebarContainer = ({ children }) => {
    const sidebarRef = useRef(null);
    const [width, setWidth] = useState(getSidebarWidth());
    const handleResize = (movementX) => {
        if (!sidebarRef.current) {
            return;
        }
        let value = width - movementX;
        if (value < MIN_SIDEBAR_WIDTH) {
            value = MIN_SIDEBAR_WIDTH;
        }
        else if (value > MAX_SIDEBAR_WIDTH) {
            value = MAX_SIDEBAR_WIDTH;
        }
        setCssProperty('--sidebar-width', `${value}px`);
    };
    const handleResizeEnd = () => {
        if (sidebarRef.current) {
            const { width } = sidebarRef.current.getBoundingClientRect();
            setWidth(width);
            saveSidebarWidth(width);
        }
    };
    useEffect(() => {
        const value = getSidebarWidth();
        setCssProperty('--sidebar-width', `${value}px`);
    }, []);
    return (_jsxs("div", { ref: sidebarRef, className: s.container, children: [_jsx(Resizer, { onResizeEnd: handleResizeEnd, onResize: handleResize }), children] }));
};
