import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as pledgeApi from '@copper/api/pledge';
const initialState = {
    connections: []
};
export const fetchPledgeConnections = createAsyncThunk('loans/getPledgeConnections', async (organizationId, thunkAPI) => {
    try {
        return await pledgeApi.getPledgeConnections(organizationId);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createPledgeConnection = createAsyncThunk('loans/createPledgeConnection', async (data, thunkAPI) => {
    try {
        return await pledgeApi.createPledgeConnection(data);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const disconnectPledgeConnection = createAsyncThunk('loans/disconnectPledgeConnection', async (connectionId, thunkAPI) => {
    try {
        return await pledgeApi.disconnectPledgeConnection(connectionId);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const reconnectPledgeConnection = createAsyncThunk('loans/reconnectPledgeConnection', async (connectionId, thunkAPI) => {
    try {
        return await pledgeApi.reconnectPledgeConnection(connectionId);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const pledgeSlice = createSlice({
    name: 'pledge',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchPledgeConnections.fulfilled, (state, { payload }) => {
            state.connections = payload;
        });
        addCase(createPledgeConnection.fulfilled, (state, { payload }) => {
            state.connections = state.connections.concat(payload);
        });
        addCase(disconnectPledgeConnection.fulfilled, (state, { payload }) => {
            state.connections = state.connections.map((connection) => {
                if (connection.connectionId === payload.connectionId)
                    return { ...connection, ...payload };
                return connection;
            });
        });
        addCase(reconnectPledgeConnection.fulfilled, (state, { payload }) => {
            state.connections = state.connections.map((connection) => {
                if (connection.connectionId === payload.connectionId)
                    return { ...connection, ...payload };
                return connection;
            });
        });
    }
});
export const pledge = pledgeSlice.reducer;
