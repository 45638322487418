import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createClearloopAccount } from '@copper/entities/clearloop/clearloop-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Button, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import settleSecurelyImg from './img/settle-securely.svg';
import tradingImg from './img/trading.svg';
import unionImg from './img/union.svg';
import unlimitedProtectionIcon from './img/unlimited-protection.svg';
import s from './Marketing.module.css';
const ListItem = ({ title, subTitle, icon }) => (_jsxs("li", { className: s.listItem, children: [_jsx("div", { className: s.itemIcon, children: icon && _jsx("img", { src: icon, className: s.itemIcon, alt: "" }) }), _jsxs("div", { className: s.itemContent, children: [_jsx("p", { className: s.itemTitle, children: title }), _jsx("p", { className: s.itemSubTitle, children: subTitle })] })] }));
const marketingList = [
    {
        title: 'Minimise counterparty risk',
        subTitle: 'Trade on exchanges from your trading account or segregated MPC custody.',
        icon: unlimitedProtectionIcon
    },
    {
        title: 'Trade immediately',
        subTitle: 'Deploy capital instantly, avoiding delays, costs and risks of on-chain transfers.',
        icon: tradingImg
    },
    {
        title: 'Settle quickly and securely',
        subTitle: 'Receive your profits swiftly and securely – settled and cleared by us.',
        icon: settleSecurelyImg
    },
    {
        title: 'Increase capital efficiency',
        subTitle: 'Redeploy profits instantly post settlement.',
        icon: unionImg
    }
];
export const Marketing = () => {
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequested, setIsRequested] = useState(false);
    const { openSnackbar } = useSnackBar();
    const handleOnClick = async () => {
        try {
            setIsLoading(true);
            await createClearloopAccount({
                organizationId: currentOrganization?.organizationId
            });
            openSnackbar({
                message: 'We’ve received your request and will contact you soon',
                type: 'success'
            });
            setIsRequested(true);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsLoading(false);
        }
    };
    return (_jsx("div", { className: s.container, children: _jsxs("div", { className: s.content, children: [_jsx("div", { className: s.featureClearLoop }), _jsx("h2", { className: s.title, children: "Trade from secure custody with ClearLoop" }), _jsx("p", { className: s.subTitle, children: "Start trading balances in custody across multiple exchanges without moving assets." }), _jsx("ul", { className: s.list, children: marketingList.map(({ title, subTitle, icon }) => (_jsx(ListItem, { title: title, subTitle: subTitle, icon: icon }, title))) }), _jsx("div", { className: s.actionButton, children: _jsx(Button, { fullWidth: true, size: "xl", disabled: isRequested, loading: isLoading, text: isRequested ? 'Request sent' : 'Request an account', view: isRequested ? 'tool' : 'primary', onClick: handleOnClick }) })] }) }));
};
