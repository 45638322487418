import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { AssetIcon } from '@copper/components/AssetIcon';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { hasAdditionalFields } from '@copper/helpers/currencies';
import { ButtonIcon, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import s from './DepositAddressDetails.module.css';
export const DepositAddressDetails = ({ addressDetails }) => {
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const { openSnackbar } = useSnackBar();
    const { name = '' } = currenciesEntities[(addressDetails.currency || addressDetails.mainCurrency) ?? ''] || {};
    const memoAsDestinationTag = hasAdditionalFields('destinationTag', tokens, addressDetails.currency, addressDetails.mainCurrency);
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.section, children: [_jsx(AssetIcon, { currency: addressDetails.currency || addressDetails.mainCurrency }), _jsxs("div", { className: s.mainContent, children: [_jsx("div", { className: s.title, children: name }), _jsx("div", { className: s.info, children: addressDetails.address })] }), _jsx(ButtonIcon, { className: s.addressButton, onClick: () => copy(addressDetails.address ?? '', () => openSnackbar({ type: 'success', message: 'Address copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) })] }), addressDetails?.memo && (_jsxs("div", { className: s.section, children: [_jsxs("div", { className: s.mainContent, children: [_jsx("div", { className: s.title, children: memoAsDestinationTag ? 'Destination tag' : 'Memo' }), _jsx("div", { className: s.info, children: addressDetails.memo })] }), _jsx(ButtonIcon, { className: s.addressButton, onClick: () => copy(addressDetails.memo ?? '', () => openSnackbar({ type: 'success', message: 'Destination tag copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) })] }))] }));
};
