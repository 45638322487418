import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as invoiceApi from '@copper/api/invoices';
const initialState = {
    invoices: [],
    unpaidInvoices: []
};
export const fetchInvoicesByOrganizationId = createAsyncThunk('invoices/getInvoicesByOrganizationId', async (organizationId, thunkAPI) => {
    try {
        const response = await invoiceApi.getInvoicesByOrganizationId(organizationId);
        return response.data.invoices;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setUnpaidInvoices(state, { payload }) {
            state.unpaidInvoices = payload;
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchInvoicesByOrganizationId.fulfilled, (state, { payload }) => {
            state.invoices = payload;
        });
    }
});
export const { setUnpaidInvoices } = invoicesSlice.actions;
export const invoices = invoicesSlice.reducer;
