import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { AssetInfo, InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { Ratio } from '@copper/components/OxygenPledges/Ratio';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { useTotalCollateral } from '@copper/hooks/oxygen/useTotalCollateral';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { formatCurrency, formatFiat } from '@copper/utils';
import { getCollateralItem } from '../helpers';
import s from './Summary.module.css';
export const Summary = ({ isSubmitting, data, onNext }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { portfolio, amount, collaterals, newPledgeDescription, newPledgeName, pledgeCalculation } = data;
    const currenciesRates = useSelector(selectRates);
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const { calculateTotalCollateral } = useTotalCollateral();
    const DTCCurrency = currenciesEntities['DCT'];
    const DCTRate = currenciesRates['DCT'] ?? 1;
    const borrowAmountMarketValue = new BigNumber(amount).times(DCTRate).toFixed();
    const totalCollateralMarketValue = calculateTotalCollateral(getCollateralItem(collaterals));
    return (_jsx(_Fragment, { children: _jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.formValuesContainer, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio }) }), _jsxs(InfoRow, { title: "Pledge name/description", titleColor: "ghost", children: [newPledgeName, newPledgeDescription && _jsx("div", { className: s.subText, children: newPledgeDescription })] }), _jsx("div", { className: s.sectionTitle, children: "Collateral to be pledged" }), collaterals.map((collateral, index) => {
                            const rate = currenciesRates[collateral?.currency?.currency ?? ''] ?? 1;
                            const collateralMarketValue = new BigNumber(collateral?.amount ?? 0)
                                .times(rate)
                                .toFixed();
                            return (_jsxs("div", { className: s.amountRow, children: [_jsx(AssetInfo, { currency: collateral?.currency?.currency }), _jsxs("div", { className: s.amountValue, children: [formatCurrency(collateral?.amount || 0, {
                                                decimalPlaces: getDecimals(collateral?.currency?.currency)
                                            }), _jsx("div", { className: s.subText, children: formatFiat(collateralMarketValue) })] })] }, index));
                        }), _jsx("div", { className: s.sectionTitle, children: "DCT to be created" }), _jsxs("div", { className: s.amountRow, children: [_jsx(AssetInfo, { currency: DTCCurrency?.currency }), _jsxs("div", { className: s.amountValue, children: [formatCurrency(amount, {
                                            decimalPlaces: getDecimals('DCT')
                                        }), _jsx("div", { className: s.subText, children: formatFiat(borrowAmountMarketValue) })] })] }), _jsxs("div", { className: s.rowInfo, children: [_jsx("div", { className: s.rowTitle, children: "Total collateral value" }), formatFiat(totalCollateralMarketValue)] }), pledgeCalculation && (_jsxs("div", { className: s.rowInfo, children: [_jsxs("div", { className: s.rowTitle, children: ["Collateral ratio (min ", (Number(pledgeCalculation.minRatio) * 100).toFixed(2), "%)"] }), _jsx(Ratio, { size: "l", value: pledgeCalculation?.ratio })] }))] }), _jsx(SwitchStateRow, { text: "Confirm", onClick: onNext, loading: isSubmitting, testId: "borrow-button-send" })] }) }));
};
