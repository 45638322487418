import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getApiVersionAndStatus } from '@copper/api/version';
import { useInterval } from '@copper/hooks';
import { IconLogoOutlined } from '@copper/ui-kit';
import s from './MaintanancePlaceholder.module.css';
export const MaintanancePlaceholder = () => {
    const errorMessage = localStorage.getItem('scheduled-maintenance-message');
    const [apiStatusMessage, setApiStatusMessage] = useState('');
    const navigate = useNavigate();
    const fetchApiStatus = async () => {
        try {
            const { data } = await getApiVersionAndStatus();
            if (data.status === 'alive') {
                navigate('/login');
            }
            data.message && setApiStatusMessage(data.message);
        }
        catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchApiStatus();
    }, []);
    //Poll api once a minute
    useInterval(() => {
        fetchApiStatus();
    }, 60000);
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.logo, children: [_jsx("div", { className: s.logoWarning }), _jsx(IconLogoOutlined, { fill: "#000", width: 80, height: 80 })] }), _jsx("h2", { className: s.title, children: "Temporarily unavailable for maintenance" }), _jsx("p", { className: s.description, children: apiStatusMessage ?? errorMessage })] }));
};
