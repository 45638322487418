import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName } from '@copper/helpers/currencies';
import s from './DepositDetails.module.css';
const DepositDetails = ({ currency, mainCurrency, view = 'tool' }) => {
    const { tokens, entities: currencyEntities } = useSelector(selectCurrencies);
    const token = tokens[`${currency}-${mainCurrency}`];
    const networkName = getCurrencyName(mainCurrency, currencyEntities);
    if (!token) {
        return null;
    }
    return (_jsxs("div", { className: cn(s.container, s[`view_${view}`]), children: [_jsxs("div", { className: s.confirmations, children: [_jsx("span", { className: s.heading, children: "Expected arrival" }), _jsxs("span", { className: s.text, children: [token.confirmations, " ", token.confirmations === '1' ? 'confirmation' : 'confirmations'] })] }), _jsxs("ul", { className: s.list, children: [_jsxs("li", { className: s.listItem, children: ["Ensure the withdrawal network is", ' ', _jsxs("span", { className: s.highlight, children: [networkName, " (", mainCurrency, ")"] }), "."] }), _jsx("li", { className: s.listItem, children: "Do not send NFTs to this address" })] })] }));
};
export { DepositDetails };
