import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect } from 'react';
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, IconLogo } from '@copper/ui-kit';
import s from './NotFound.module.css';
const NotFound = ({ redirect = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const goHome = () => navigate('/', { replace: true });
    useLayoutEffect(() => {
        if (location.state?.from) {
            window.history.replaceState({}, '', location.state.from);
        }
    }, []);
    if (redirect) {
        return _jsx(Navigate, { to: "/404", state: { from: location.pathname }, replace: true });
    }
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.logoContainer, children: _jsx(NavLink, { to: "/", replace: true, className: s.logo, children: _jsx(IconLogo, { width: 32, height: 32, viewBox: "0 0 32 32" }) }) }), _jsx("h1", { className: s.errorCode, children: "404" }), _jsx("h2", { className: s.title, children: "Not found" }), _jsx("div", { className: s.text, children: "Sorry, we were unable to upload the page. Please try again later. If the problem continues, contact us for support." }), _jsx(Button, { text: "Back to home", onClick: goHome })] }));
};
export { NotFound };
