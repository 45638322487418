import * as Yup from 'yup';
import { validateDCTAmount, validateNewCollaterals, validatePledgeCalculation } from '@copper/helpers/oxygen-pledges';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    newPledgeName: Yup.string().required('Enter a name.'),
    amount: validateDCTAmount(),
    collaterals: validateNewCollaterals(),
    pledgeCalculation: validatePledgeCalculation()
});
export const getCollateralItem = (collaterals) => collaterals.reduce((acc, collateral) => {
    if (!collateral?.currency?.currency || !collateral?.amount) {
        return acc;
    }
    acc.push({
        currency: collateral.currency.currency,
        amount: collateral.amount
    });
    return acc;
}, []);
