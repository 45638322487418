import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { IconView, IconViewOff } from '@copper/ui-kit';
import { Input } from '../Input';
import s from './PasswordInput.module.css';
export const PasswordInput = React.forwardRef((props, ref) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    return (_jsx(Input, { ...props, className: s.passwordInput, ref: ref, type: isShowPassword ? 'text' : 'password', rightSection: _jsx("button", { type: "button", className: s.hideShowButton, onClick: () => setIsShowPassword(!isShowPassword), children: isShowPassword ? _jsx(IconViewOff, {}) : _jsx(IconView, {}) }) }));
});
PasswordInput.displayName = 'PasswordInput';
