import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Balance } from '@copper/components/Balances/Balance';
import { BalanceWrapper } from '@copper/components/Balances/BalanceWrapper';
import { selectLoansSummary } from '@copper/entities/loans/loans-selector';
import s from './LoanBalances.module.css';
const LoanBalances = ({ group = 'lending', isLoading = true }) => {
    const summary = useSelector(selectLoansSummary);
    const { numberLent, numberBorrowed, amountLent, amountBorrowed } = summary ?? {};
    if (isLoading || !summary || group === 'requests') {
        return null;
    }
    const amountPerType = group === 'lending' ? summary.amountLentPerType : summary.amountBorrowedPerType;
    const numberPerType = group === 'lending' ? summary.numberLentPerType : summary.numberBorrowedPerType;
    const { 'secured-pledged': pledgedAmount } = amountPerType ?? {};
    const { 'secured-pledged': pledgedCount } = numberPerType ?? {};
    return (_jsx("div", { className: s.container, children: _jsxs(BalanceWrapper, { children: [_jsx(Balance, { size: "l", hint: "The number of active loans and their total value in USD.", title: `Active (${group === 'lending' ? numberLent || 0 : numberBorrowed || 0})`, balance: group === 'lending' ? amountLent || 0 : amountBorrowed || 0 }), _jsx(Balance, { title: `Pledged (${pledgedCount || 0})`, hint: "The number of secured loans where pledged collateral is held in the borrower's account. And the total value of these loans in USD.", balance: pledgedAmount || 0 })] }) }));
};
export { LoanBalances };
