import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { cloneElement, isValidElement, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom';
import cn from 'classnames';
import Downshift from 'downshift';
import { IconTick } from '@copper/ui-kit';
import s from './DropDown.module.css';
const Dropdown = ({ getToggle, selected, items = [], onChange, getTitle, getSubTitle, getIcon, getKey, getModifier, listWidth, placement = 'bottom-start', highlightSelectedItem = true, view = 'primary', testId, zIndex = 1100 }) => {
    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        placement,
        middleware: [flip(), shift()]
    });
    const handleSelectedItemChange = useCallback((selectedItem) => {
        if (selectedItem) {
            onChange(selectedItem);
        }
    }, [onChange]);
    const renderToggle = useCallback((getToggleButtonProps, isOpen) => {
        const toggleElement = getToggle(isOpen);
        return (isValidElement(toggleElement) &&
            cloneElement(toggleElement, {
                ...getToggleButtonProps({
                    'aria-expanded': isOpen,
                    ref: refs.setReference
                })
            }));
    }, [getToggle]);
    return (_jsx(Downshift, { selectedItem: selected, onSelect: !highlightSelectedItem ? handleSelectedItemChange : undefined, onChange: highlightSelectedItem ? handleSelectedItemChange : undefined, itemToString: (item) => (item ? getKey(item) : ''), children: ({ getMenuProps, getToggleButtonProps, getItemProps, highlightedIndex, isOpen, selectedItem }) => (_jsxs("div", { className: s.container, children: [renderToggle(getToggleButtonProps, isOpen), isOpen &&
                    ReactDOM.createPortal(_jsx("div", { ref: refs.setFloating, style: { zIndex, ...floatingStyles }, children: refs.floating && (_jsx("div", { ...getMenuProps(), style: { width: listWidth }, className: cn(s.list, s[`view_${view}`], {
                                [s.closed]: !isOpen
                            }), "data-testid": testId, children: items.map((item, index) => {
                                const IconComponent = typeof getIcon === 'function' ? getIcon(item) : null;
                                return (_jsxs("div", { ...getItemProps({
                                        item,
                                        index
                                    }), className: cn(s.item, {
                                        [s.highlighted]: highlightedIndex === index
                                    }), "data-testid": "dropdown-item", children: [_jsxs("div", { className: s.leftSection, children: [IconComponent && (_jsx("div", { className: s.icon, children: _jsx(IconComponent, {}) })), _jsxs("div", { children: [_jsx("div", { className: cn(s.title, s[`modifier_${getModifier ? getModifier(item) : undefined}`]), children: getTitle(item) }), typeof getSubTitle === 'function' && getSubTitle(item) && (_jsx("div", { className: s.subTitle, children: getSubTitle(item) }))] })] }), item === selectedItem && highlightSelectedItem && (_jsx("div", { className: s.selectedTick, children: _jsx(IconTick, {}) }))] }, `list_item_${index}`));
                            }) })) }), document.body)] })) }));
};
export { Dropdown };
