import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { AssetInfo, EmptyFallback, InfoList, InfoRow } from '@copper/components';
import { displayMaturity, displayTimeToMaturity } from '@copper/components/Loans/helpers';
import { loanArrangers } from '@copper/constants/loanArrangers';
import { convertTypeToDisplay } from '@copper/helpers/loans';
import { useDateTime } from '@copper/hooks';
import s from '../LoanModalContent.module.css';
const LoanDetails = ({ loan }) => {
    const { getDateAndTimeAsObject, getFormattedDateAndTime } = useDateTime();
    const { date: startDate, time: startTime, timezone: startTimezone } = getDateAndTimeAsObject(loan.terms.startDate);
    const { loanType, terms, loanId, loanArranger, createdAt, loanDisbursedAt, closedAt, updatedAt } = loan;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [_jsx(InfoRow, { title: "Asset", titleColor: "secondary", children: _jsx(AssetInfo, { align: "right", currency: terms.loanCurrency }) }), _jsx(InfoRow, { title: "Network", titleColor: "secondary", children: _jsx(AssetInfo, { align: "right", currency: terms.loanNetworkCurrency }) })] }) }), _jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [_jsx(InfoRow, { title: "Type", titleColor: "secondary", children: convertTypeToDisplay(loanType) }), _jsx(InfoRow, { title: "Term", titleColor: "secondary", children: terms.termType === 'open' ? 'Open' : displayMaturity(loan) }), _jsxs(InfoRow, { title: "Start date", titleColor: "secondary", children: [startDate, " at ", startTime, " ", startTimezone] }), terms.termType !== 'open' && (_jsxs(InfoRow, { title: "End date", titleColor: "secondary", children: [_jsx("div", { children: (loan.terms.maturityDate && getFormattedDateAndTime(loan.terms.maturityDate)) ||
                                        '-' }), _jsx("div", { className: s.description, children: displayTimeToMaturity(loan) })] }))] }) }), loanArranger && (_jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [_jsx(InfoRow, { title: "Loan Arranger", titleColor: "secondary", children: _jsx(EmptyFallback, { children: loanArrangers.find((la) => la.value === loanArranger)?.label }) }), _jsx(InfoRow, { title: "External ID", titleColor: "secondary", children: _jsx(EmptyFallback, { children: loan.externalId }) })] }) })), _jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [_jsx(InfoRow, { title: "Loan Id", titleColor: "secondary", children: _jsx("span", { children: loanId }) }), _jsx(InfoRow, { title: "Created", titleColor: "secondary", children: _jsx("span", { children: getFormattedDateAndTime(createdAt) }) }), updatedAt && (_jsx(InfoRow, { title: "Last Updated", titleColor: "secondary", children: _jsx("span", { children: getFormattedDateAndTime(updatedAt) }) })), loanDisbursedAt && (_jsx(InfoRow, { title: "Disbursed", titleColor: "secondary", children: _jsx("span", { children: getFormattedDateAndTime(loanDisbursedAt) }) })), closedAt && (_jsx(InfoRow, { title: "Closed", titleColor: "secondary", children: _jsx("span", { children: getFormattedDateAndTime(closedAt) }) }))] }) })] }));
};
export { LoanDetails };
