import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import { selectAccountId } from '@copper/entities/accounts/accounts-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { createPortfolio, fetchPortfolios } from '@copper/entities/portfolios/portfolios-reducer';
import { Button, FormControl, FormLabel, Input, useSnackBar } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { PopupFooter } from '@copper/ui-kit/Popup/PopupFooter';
import { getErrorData } from '@copper/utils';
const ThirdPartyForm = ({ onSuccess }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const accountId = useSelector(selectAccountId);
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        mode: 'onSubmit'
    });
    const onSubmit = async (values) => {
        try {
            const data = {
                accountId,
                organizationId,
                portfolioId: cuid(),
                portfolioName: values.name?.trim() ?? '',
                portfolioType: 'external-wallets'
            };
            await dispatch(createPortfolio(data)).unwrap();
            // update portfolio list for correct sorting
            await dispatch(fetchPortfolios({ organizationId }));
            onSuccess();
            openSnackbar({ type: 'success', message: 'Your account has been added' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(PopupContent, { children: _jsxs(FormControl, { errorMessage: errors?.name?.message, children: [_jsx(FormLabel, { children: "Account name" }), _jsx(Input, { size: "l", ...register('name', { required: 'Enter an account name.' }), maxLength: 128, testId: "third-party-account-name" })] }) }), _jsx(PopupFooter, { rightSection: _jsx(Button, { type: "submit", loading: isSubmitting, text: "Create" }) })] }));
};
export { ThirdPartyForm };
