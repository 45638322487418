import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { isClearloopPortfolio, isClearloopSubAccount, isExternalCustodianClearloop } from '@copper/helpers/portfolios';
const selectWalletEntities = (store) => store.wallets.wallets;
export const selectWalletIdsByPortfolioId = (store) => store.wallets.walletIdsByPortfolioId;
export const selectWallets = createSelector(selectWalletEntities, selectRates, (wallets, rates) => {
    const result = {};
    Object.keys(wallets).forEach((walletId) => {
        const wallet = wallets[walletId];
        const rate = rates[wallet.currency] ?? 0;
        result[walletId] = {
            ...wallet,
            balance: wallet?.balance ?? '0',
            available: wallet?.available ?? '0',
            reserve: wallet?.reserve ?? '0',
            stakeBalance: wallet?.stakeBalance ?? '0',
            totalBalance: wallet?.totalBalance ?? '0',
            balanceReporting: new BigNumber(wallet?.balance || 0).times(rate).toFixed(),
            availableReporting: new BigNumber(wallet?.available || 0).times(rate).toFixed(),
            reserveReporting: new BigNumber(wallet?.reserve || 0).times(rate).toFixed(),
            stakeBalanceReporting: new BigNumber(wallet?.stakeBalance || 0).times(rate).toFixed(),
            totalBalanceReporting: new BigNumber(wallet?.totalBalance || 0).times(rate).toFixed()
        };
    });
    return result;
});
export const selectWalletList = createSelector(selectWallets, (wallets) => Object.values(wallets));
export const selectWalletsByPortfolioId = createSelector(selectWallets, selectWalletIdsByPortfolioId, (wallets, walletIdsByPortfolioId) => Object.keys(walletIdsByPortfolioId).reduce((acc, portfolioId) => {
    acc[portfolioId] = walletIdsByPortfolioId[portfolioId].map((walletId) => wallets[walletId]);
    return acc;
}, {}));
export const selectWalletsForTotalBalance = createSelector(selectWalletList, (state) => state.portfolios.portfolios, (wallets, portfolios) => wallets.reduce((acc, wallet) => {
    const portfolio = portfolios[wallet.portfolioId];
    if ((!isClearloopPortfolio(wallet.portfolioType) && !isClearloopSubAccount(portfolio)) ||
        isExternalCustodianClearloop(portfolio)) {
        acc.push(wallet);
    }
    return acc;
}, []));
export const selectClearloopWallets = createSelector(selectWalletList, (wallets) => wallets.reduce((acc, wallet) => {
    if (isClearloopPortfolio(wallet.portfolioType)) {
        acc.push(wallet);
    }
    return acc;
}, []));
export const selectOxygenWallets = createSelector(selectWalletList, (wallets) => wallets.filter((wallet) => wallet.portfolioType === 'oxygen-vault'));
