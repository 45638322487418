import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AssetIcon } from '@copper/components';
import { selectActivePlatformCurrencies, selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { isCurrencyFiat } from '@copper/helpers';
import { FormControl, FormLabel, Select } from '@copper/ui-kit';
export const AssetSelect = ({ label = 'Asset', control, errorMessage, testId, name, hideFiat, hideCrypto, view }) => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const activeCurrencies = useSelector(selectActivePlatformCurrencies);
    const items = useMemo(() => {
        if (!hideFiat && !hideCrypto)
            return activeCurrencies;
        if (hideFiat)
            return activeCurrencies.filter((currency) => !isCurrencyFiat(currenciesEntities, currency.currency));
        if (hideCrypto)
            return activeCurrencies.filter((currency) => isCurrencyFiat(currenciesEntities, currency.currency));
    }, [hideCrypto, hideFiat, activeCurrencies, currenciesEntities]);
    const { field: { onChange, value } } = useController({
        name,
        control: control
    });
    return (_jsxs(FormControl, { errorMessage: errorMessage, children: [_jsx(FormLabel, { view: view, children: label }), _jsx(Select, { view: view, placeholder: "Select asset", searchPlaceholder: "Search assets", items: items, searchFields: ['name', 'currency'], getKey: (asset) => asset?.currency, getIcon: (asset) => _jsx(AssetIcon, { currency: asset?.currency, size: "s" }), getTopRow: (asset) => asset?.name, getBottomRow: (asset) => asset?.currency, emptySearchString: "No assets match this name", onChange: onChange, selected: value, reversedStyles: true, testId: testId })] }));
};
