import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentOrganization, selectOrganizationUsers } from '@copper/entities/organizations/organizations-selector';
import { emptyArray } from '@copper/utils/empty';
export const selectUser = (store) => store.user;
export const selectFirstName = (store) => store.user?.firstName;
export const selectUserId = (store) => store.user?.userId;
export const selectPermissionGroupsForOrg = createSelector(selectUser, selectOrganizationUsers, (user, orgMembers) => orgMembers[user.userId]?.permissionGroups ?? emptyArray());
export const selectInitials = createSelector(selectUser, (user) => user?.initials ?? '');
export const selectUserIdentities = createSelector(selectUser, (user) => user?.identities ?? {});
export const selectUserEmail = createSelector(selectUser, ({ identities = {} }) => identities?.email?.[0]?.value ?? '');
export const selectUserPasskeys = createSelector(selectUser, ({ identities = {} }) => identities?.passkey ?? []);
export const selectCurrentUserImageId = (store) => store.user?.imageId;
export const selectUserApiKeys = createSelector(selectUser, ({ identities = {} }) => identities?.apikey ?? []);
export const selectUserApiKeysForCurrentOrg = createSelector(selectUserApiKeys, selectCurrentOrganization, (apiKeys, currentOrganization) => apiKeys.filter((identity) => identity.extra?.allowedOrganizationId === currentOrganization.organizationId));
