import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash-es';
import { TwoFaPopup } from '@copper/components/TwoFaPopup';
import { selectAuth, selectTwoFaTypes } from '@copper/entities/auth/auth-selector';
import { getPriorityTwoFaType } from '@copper/helpers/auth';
import { useSnackBar } from '@copper/ui-kit';
import { Err, getErrorData } from '@copper/utils';
export const TwoFaContext = createContext({});
export const TwoFaProvider = ({ children }) => {
    const { openSnackbar } = useSnackBar();
    const twoFaTypes = useSelector(selectTwoFaTypes);
    const { target } = useSelector(selectAuth);
    const [isTwoFaShown, setIsTwoFaShown] = useState(false);
    const [ignorePasskey, setIgnorePasskey] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [requestedAction, setRequestedAction] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initiatorIsHidden, setInitiatorIsHidden] = useState(false);
    const twoFaInputResolver = useRef((value) => noop(value));
    const waitCancel = useRef((error) => noop(error));
    const twoFaType = getPriorityTwoFaType(twoFaTypes, ignorePasskey);
    const requestTwoFa = async (requestedAction, hideInitiator = false) => {
        setIsTwoFaShown(true);
        setRequestedAction(requestedAction);
        setInitiatorIsHidden(hideInitiator);
        const { twoFaCode, twoFaType } = await new Promise((resolve, reject) => {
            twoFaInputResolver.current = resolve;
            waitCancel.current = reject;
        });
        setIsSubmitting(true);
        return `${twoFaType} ${twoFaCode}`;
    };
    const resolveTwoFa = () => {
        setIsTwoFaShown(false);
        setIsSubmitting(false);
        setIgnorePasskey(false);
        setRequestedAction(undefined);
        setErrorMessage('');
        setInitiatorIsHidden(false);
    };
    const onCancel = () => {
        waitCancel?.current(new Err('Verification interrupted', { nonCatchable: true, isTwoFaInterrupted: true }));
        resolveTwoFa();
    };
    const twoFaErrorCatcher = (error, onRetry) => {
        const { error: errorType, meta, message } = error;
        // in this case user prevented input and closed modal
        if (meta?.nonCatchable) {
            return;
        }
        // here if incorect only two-fa we don't close modal and user reenter twoFa -> resend form
        if (errorType === 'incorrect-two-fa') {
            setErrorMessage(twoFaType === 'passkey' && typeof message === 'string'
                ? message
                : 'Enter a valid verification code');
            setIsSubmitting(false);
            onRetry();
            return;
        }
        openSnackbar(getErrorData(error));
        resolveTwoFa();
    };
    return (_jsxs(_Fragment, { children: [_jsx(TwoFaContext.Provider, { value: {
                    requestTwoFa,
                    resolveTwoFa,
                    twoFaErrorCatcher,
                    twoFaOpened: isTwoFaShown
                }, children: !initiatorIsHidden && children }), isTwoFaShown && requestedAction && (_jsx(TwoFaPopup, { target: target, onSkipPasskey: () => setIgnorePasskey(true), isSubmitting: isSubmitting, onCancel: onCancel, errorMessage: errorMessage, requestedAction: requestedAction, twoFaType: twoFaType, onSuccess: (value) => {
                    twoFaInputResolver?.current({ twoFaCode: value, twoFaType });
                } }))] }));
};
export const useTwoFaContext = () => useContext(TwoFaContext);
