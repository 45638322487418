import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const ATOM = {
    title: 'ATOM',
    id: 'atom',
    qa: [
        {
            question: 'How do I stake ATOM?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake ATOM?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 21-day unbonding period" })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for ATOM?',
            answer: _jsx("p", { children: "No, you can choose up to 100% of your available balance." })
        },
        {
            question: 'How often will I earn ATOM staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "You will start earning rewards immediately after completing your staking transaction. Rewards will then be issued approximately every 7 seconds." }), _jsx("p", { children: "You can check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "First payout" }) }), _jsx("td", { className: s.td, children: "After transaction completed" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 7 seconds" })] })] }) })] }))
        },
        {
            question: 'How can I claim my ATOM staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your ATOM rewards anytime by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your ATOM staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "The funds will be made available in your staking vault right away." })] }))
        },
        {
            question: 'Can I change validators while my assets are staked?',
            answer: (_jsx("p", { children: "Yes. To change validators, simply click on the active position on your staking dashboard. Then, select Edit next to the validator\u2019s address." }))
        }
    ]
};
