export const getConnectionStatus = ({ currentOrganizationCounterpartyId = '', counterpartyConnection }) => {
    if (!counterpartyConnection) {
        return 'no-connection';
    }
    if (counterpartyConnection.requestStatus === 'accepted') {
        return 'connected';
    }
    if (counterpartyConnection.requestStatus === 'declined') {
        return 'declined';
    }
    if (counterpartyConnection.requestStatus === 'pending' &&
        counterpartyConnection.receiverCounterpartyId === currentOrganizationCounterpartyId) {
        return 'connection-received';
    }
    if (counterpartyConnection.requestStatus === 'pending' &&
        counterpartyConnection.receiverCounterpartyId !== currentOrganizationCounterpartyId) {
        return 'connection-requested';
    }
    return 'no-connection';
};
export const generateTabs = ({ counterpartyType, relatedCounterpartyIds }) => {
    const tabs = [
        {
            title: 'Settlements',
            path: `settlements`,
            rightSection: ''
        }
    ];
    if (counterpartyType !== 'lite') {
        tabs.push({
            title: 'Transfers',
            path: 'transfers',
            rightSection: ''
        });
    }
    if (relatedCounterpartyIds?.length) {
        tabs.push({
            title: 'Related organisations',
            path: 'related-organisations',
            rightSection: relatedCounterpartyIds.length.toString()
        });
    }
    return tabs;
};
export const generateLiteTabs = (relatedCounterpartyIds) => {
    const tabs = [
        {
            title: 'Settlements',
            path: `settlements`,
            rightSection: ''
        },
        {
            title: 'Wallets',
            path: 'wallets',
            rightSection: ''
        },
        {
            title: 'Deposits',
            path: 'deposits',
            rightSection: ''
        }
    ];
    if (relatedCounterpartyIds?.length) {
        tabs.push({
            title: 'Related organisations',
            path: 'related-organisations',
            rightSection: relatedCounterpartyIds.length.toString()
        });
    }
    return tabs;
};
