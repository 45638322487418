import { request } from '@copper/utils';
export const get = (params) => request.get('/deposit-targets', { params });
export const create = (data) => request.post('/deposit-targets', data);
export const update = (depositTargetId, data) => request.patch(`/deposit-targets/${depositTargetId}`, data, {
    headers: {
        'Content-type': 'application/vnd.update-info+json'
    }
});
export const activateToken = (depositTargetId, data) => request.patch(`/deposit-targets/${depositTargetId}`, data, {
    headers: {
        'Content-type': 'application/vnd.activate-deposit-target-currency+json'
    }
});
