import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
export const Context = React.createContext({});
export const RoutingContextProvider = ({ children }) => {
    const [route, setRouteContext] = useState();
    return (_jsx(Context.Provider, { value: {
            route,
            setRouteContext
        }, children: children }));
};
export const RoutingContextConsumer = Context.Consumer;
