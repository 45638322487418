import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { fetchWallets } from '@copper/entities/wallets/wallets-reducer';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
const walletsFetchedForCurrencies = new Set();
export const useFetchWallets = (defaultIsLoading = true) => {
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [isLoading, setIsLoading] = useState(defaultIsLoading);
    const fetchWalletsForCurrency = async (currency) => {
        if (walletsFetchedForCurrencies.has(currency.currency)) {
            return;
        }
        try {
            await dispatch(fetchWallets({ currency: currency.currency, organizationId })).unwrap();
            walletsFetchedForCurrencies.add(currency.currency);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const fetchPortfolioWallets = async (portfolioId) => {
        try {
            setIsLoading(true);
            await dispatch(fetchWallets({ organizationId, portfolioId })).unwrap();
        }
        catch (error) {
            return Promise.reject(error);
        }
        finally {
            setIsLoading(false);
        }
    };
    return { fetchWalletsForCurrency, fetchPortfolioWallets, walletsAreLoading: isLoading };
};
