import { useEffect, useRef, useState } from 'react';
const useEffectInEvent = (event, handler, useCapture) => {
    useEffect(() => {
        if (handler && window && 'addEventListener' in window) {
            handler();
            window.addEventListener(event, handler, useCapture);
            return () => window.removeEventListener(event, handler, useCapture);
        }
    }, []);
};
export const useGetBoundingClientRect = (event) => {
    const ref = useRef(null);
    const [rect, setRect] = useState();
    const set = () => setRect(ref.current?.getBoundingClientRect());
    useEffectInEvent(event, set, true);
    return [rect, ref];
};
