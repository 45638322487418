import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import cn from 'classnames';
import { BulkOrderProvider } from '@copper/client/components/BulkOrderTools';
import { SettleBulk } from '@copper/client/components/Settle/Bulk';
import { SettleSingle } from '@copper/client/components/Settle/Single';
import { Tabs } from '@copper/ui-kit';
import s from './Settle.module.css';
const Settle = () => {
    const [activeTab, setActiveTab] = useState('single');
    return (_jsxs("div", { className: cn(s.container, s[`container_${activeTab}`]), children: [_jsx(Tabs, { view: "green", items: [
                    {
                        title: 'Single',
                        value: 'single'
                    },
                    {
                        title: 'Bulk',
                        value: 'bulk'
                    }
                ], value: activeTab, onChange: (item) => setActiveTab(item.value) }), activeTab === 'single' && _jsx(SettleSingle, {}), activeTab === 'bulk' && (_jsx(BulkOrderProvider, { children: _jsx(SettleBulk, {}) }))] }));
};
export { Settle };
