import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectAnnouncementsList } from '@copper/entities/announcements/announcements-selector';
import { ButtonIcon, IconCross, IconSystemAlert } from '@copper/ui-kit';
import { setCssProperty } from '@copper/utils/styles';
import { differenceInDays } from 'date-fns';
import s from './SystemAnnouncementBanner.module.css';
export const SystemAnnouncementBanner = () => {
    // There could be more than one announcement but backend returns them sorted by priority and for now we need to show one only one with highest priority
    const [announcement] = useSelector(selectAnnouncementsList);
    const [open, setOpen] = useState(Boolean(announcement));
    const announcementStorageKey = `system-announcement-${announcement?.announcementId}-closed`;
    useEffect(() => {
        setCssProperty('--system-announcement-banner-height', open ? '48px' : '0px');
    }, [open]);
    useEffect(() => {
        (async () => {
            if (!announcement) {
                return;
            }
            const isClosedAt = localStorage.getItem(announcementStorageKey);
            // for alert type we should show banner once a day if closed
            if (announcement.announcementType === 'alert' && isClosedAt) {
                const closedMoreThanDayAgo = differenceInDays(Date.now(), Number(isClosedAt)) >= 1;
                closedMoreThanDayAgo && localStorage.removeItem(announcementStorageKey);
                return setOpen(closedMoreThanDayAgo ? true : false);
            }
            return setOpen(isClosedAt ? false : true);
        })();
    }, [announcement]);
    const onClose = async () => {
        setOpen(false);
        localStorage.setItem(announcementStorageKey, String(Date.now()));
    };
    if (!open)
        return null;
    return (_jsxs("div", { className: cn(s.headerBanner, announcement && s[`headerBanner_${announcement.announcementType}`]), children: [_jsx("div", { className: s.iconBlock, children: _jsx(IconSystemAlert, {}) }), _jsxs("div", { className: s.textBlock, children: [_jsxs("strong", { className: s.title, children: [announcement?.title, ":"] }), _jsx("strong", { children: announcement?.message })] }), _jsx(ButtonIcon, { tooltipLabel: announcement?.announcementType === 'alert' ? 'Hide for 24 hours' : 'Hide', className: s.closeIcon, onClick: onClose, children: _jsx(IconCross, {}) })] }));
};
