import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyDecimal } from '@copper/helpers';
export const useCurrenciesInfo = () => {
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const getDecimals = useCallback((currencyId, params) => getCurrencyDecimal({ tokens, currencyId, ...params }), [tokens, currenciesEntities]);
    return {
        getDecimals
    };
};
