import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useState } from 'react';
import './presets/dark.css';
import './presets/default.css';
export const DARK_THEME = 'dark';
export const DEFAULT_THEME = 'default';
const replaceClassName = (el, subStr, newValue) => {
    const list = el.className.split(' ').filter((item) => !item.includes(subStr));
    list.push(newValue);
    el.className = list.join(' ');
};
const ThemeContext = createContext({});
export const useTheme = () => useContext(ThemeContext);
const Theme = ({ children, theme }) => {
    const [currentTheme, setCurrentTheme] = useState(theme);
    useEffect(() => {
        setCurrentTheme(theme);
    }, [theme]);
    useEffect(() => {
        replaceClassName(document.documentElement, 'theme', `theme_${currentTheme}`);
    }, [currentTheme]);
    return (_jsx(ThemeContext.Provider, { value: { currentTheme, setCurrentTheme }, children: children }));
};
export { Theme };
