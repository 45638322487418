import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { CryptoForm } from '@copper/components/DepositCryptoForm';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { isCurrencyFiat } from '@copper/helpers';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { Tabs } from '@copper/ui-kit';
import { FiatForm } from './FiatForm';
import s from './Deposit.module.css';
export const Deposit = () => {
    const { route } = useRoutingContext();
    const { setTopPanel } = useTopPanel();
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [activeTab, setActiveTab] = useState('crypto');
    useEffect(() => {
        if (currentOrganization.organizationType === 'oxygen') {
            return setActiveTab('fiat');
        }
        if (route?.name === 'deposit') {
            return setActiveTab(isCurrencyFiat(currenciesEntities, route?.data?.currencyId) ? 'fiat' : 'crypto');
        }
    }, [route, currentOrganization.organizationType]);
    useEffect(() => {
        setTopPanel({
            title: 'Deposit',
            subTitle: 'Single step'
        });
    }, [setTopPanel]);
    return (_jsxs("div", { className: s.container, children: [currentOrganization.organizationType == 'standard' && (_jsx(Tabs, { view: "green", items: [
                    {
                        title: 'Crypto',
                        value: 'crypto'
                    },
                    {
                        title: 'Fiat',
                        value: 'fiat'
                    }
                ], value: activeTab, onChange: (item) => setActiveTab(item.value) })), _jsx("div", { className: s.content, children: _jsxs("div", { className: s.formContainer, children: [activeTab === 'crypto' && (_jsx(CryptoForm, { view: "tool", ...(route?.name === 'deposit' && route?.data) })), activeTab === 'fiat' && _jsx(FiatForm, {})] }) })] }));
};
