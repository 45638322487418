import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import { selectAccountId } from '@copper/entities/accounts/accounts-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { createPortfolio, fetchPortfolios } from '@copper/entities/portfolios/portfolios-reducer';
import { isFeesVault } from '@copper/helpers/portfolios';
import { Button, FormControl, FormLabel, Input, Radio, useSnackBar } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { PopupFooter } from '@copper/ui-kit/Popup/PopupFooter';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './helpers';
import s from './AccountForm.module.css';
const VaultForm = ({ portfolioType, isDefiVault, onSuccess }) => {
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const accountId = useSelector(selectAccountId);
    const { organizationId, organizationType } = useSelector(selectCurrentOrganization);
    const feesVault = isFeesVault(portfolioType);
    const { register, handleSubmit, formState, control } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            shardType: feesVault ? 'single-chain-shard' : 'multi-chain-shard'
        }
    });
    const { errors, isSubmitting } = formState;
    const onSubmit = async (values) => {
        if (!portfolioType) {
            return;
        }
        try {
            const data = {
                accountId,
                organizationId,
                portfolioId: cuid(),
                portfolioName: values.name,
                portfolioType,
                isDefiVault,
                isMultiChain: values.shardType === 'multi-chain-shard'
            };
            await dispatch(createPortfolio(data)).unwrap();
            // update portfolio list for correct sorting
            await dispatch(fetchPortfolios({ organizationId }));
            onSuccess();
            openSnackbar({ type: 'success', message: 'Your account has been added' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const shardTypes = [
        {
            label: 'Multi-Chain Shard',
            value: 'multi-chain-shard',
            tooltipInfo: 'A shard to access and secure multiple blockchain wallets. One shard for all blockchain assets sharing the same encryption.'
        },
        {
            label: 'Single-Chain Shard',
            value: 'single-chain-shard',
            tooltipInfo: 'A shard to access and secure one blockchain wallet. The wallet can hold all supported assets on that same blockchain.'
        }
    ];
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(PopupContent, { children: [isDefiVault && (_jsx("div", { className: s.description, children: "DeFi vaults let you interact with DeFi apps from the same secure storage as our regular vaults." })), _jsxs(FormControl, { errorMessage: errors.name?.message, children: [_jsx(FormLabel, { children: "Account name" }), _jsx(Input, { size: "l", ...register('name'), maxLength: 128 })] }), !feesVault && organizationType == 'standard' && (_jsxs("div", { className: s.shardType, children: [_jsx(FormLabel, { children: "Shard type" }), _jsx("div", { className: s.smallDescription, children: "Select the type of shard used to secure your Vault. Note, you won\u2019t be able to change this later." }), _jsx(Controller, { shouldUnregister: true, name: "shardType", control: control, render: ({ field }) => (_jsx(Radio, { items: shardTypes, value: field.value, onChange: field.onChange, orientation: "vertical", name: "shardType" })) })] }))] }), _jsx(PopupFooter, { rightSection: _jsx(Button, { type: "submit", text: "Create", loading: isSubmitting }) })] }));
};
export { VaultForm };
