import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconSystemAlert } from '@copper/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { getApprovalRequests } from '@copper/entities/approvalRequests/approvalRequests-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectUserId } from '@copper/entities/user/user-selector';
import { setCssProperty } from '@copper/utils/styles';
import { TermsAndConditionsPopup } from './TermsAndConditionsPopup';
import s from './NonCustodialWalletBanner.module.css';
export const NonCustodialWalletBanner = () => {
    const userId = useSelector(selectUserId);
    const { checkPermissions } = useCheckPermissionsContext();
    const { organizationId, extra } = useSelector(selectCurrentOrganization);
    const [isOpenTerms, setIsOpenTerms] = useState(false);
    const [waitingApprovalRequest, setWaitingApprovalRequest] = useState();
    const canAcceptNonCustodialWallets = checkPermissions('accept-non-custodial-wallets-service');
    const fetchApprovalRequests = useCallback(async () => {
        if (!organizationId) {
            return;
        }
        const { requests } = await getApprovalRequests({
            organizationId,
            actionName: 'accept-non-custodial-wallets-service',
            requestStatus: 'waiting-approval',
            userId
        });
        if (requests.length > 0) {
            setWaitingApprovalRequest(requests[0]);
        }
    }, [organizationId]);
    useEffect(() => {
        if (canAcceptNonCustodialWallets && !extra?.nonCustodialWalletsEnabled) {
            fetchApprovalRequests();
        }
    }, [fetchApprovalRequests, canAcceptNonCustodialWallets, extra?.nonCustodialWalletsEnabled]);
    useEffect(() => {
        setCssProperty('--non-custodial-wallet-banner-height', waitingApprovalRequest ? '48px' : '0px');
    }, [waitingApprovalRequest]);
    if (!waitingApprovalRequest) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.wrapper, children: [_jsx(IconSystemAlert, {}), "New Terms & Conditions. Please accept new feature terms for Non-Custodial Treasury Wallets. Otherwise, you will not be able to use the platform's tools for this feature."] }), _jsx(Button, { size: "s", view: "alert", onClick: () => setIsOpenTerms(true), text: "View and agree" })] }), isOpenTerms && (_jsx(TermsAndConditionsPopup, { requestId: waitingApprovalRequest.requestId, onClose: () => setIsOpenTerms(false), onAgree: () => setWaitingApprovalRequest(undefined) }))] }));
};
