export const normalizeLoans = (data) => {
    const result = {
        entities: {},
        summary: data._embedded?.lendingSummary,
        valuations: {
            page: '0',
            pageSize: '0',
            totalPages: '0',
            totalSize: '0',
            valuations: []
        }
    };
    data.loans.forEach((loan) => {
        if (loan.loanId) {
            result.entities[loan.loanId] = loan;
        }
    });
    return result;
};
