import * as Yup from 'yup';
import { isExternalPortfolio, isVaultPortfolio } from '@copper/helpers/portfolios';
export const validationSchema = Yup.object()
    .shape({
    currency: Yup.object().shape({ currency: Yup.string().required('Select an asset.') }),
    mainCurrency: Yup.string().when('account', {
        is: (account) => checkRequiredNetwork(account),
        then: (schema) => schema.required('Select a network.')
    }),
    account: Yup.object().shape({
        portfolioId: Yup.string().required('Select an account.')
    })
})
    .required();
export const checkRequiredNetwork = (account) => isVaultPortfolio(account?.portfolioType) || isExternalPortfolio(account?.portfolioType);
export const getExchangeMainCurrencies = (exchangesEntities, account, currency) => {
    const { exchangeAccountType, exchange } = account?.extra ?? {};
    const exchangeAccounts = exchangesEntities[exchange ?? '']?.exchangeAccounts?.find((exchangeAccount) => exchangeAccount.accountType === exchangeAccountType);
    return exchangeAccounts?.currencies.find((item) => item.currency === currency)?.networks;
};
