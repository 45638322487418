import { jsx as _jsx } from "react/jsx-runtime";
import React, { lazy } from 'react';
import { Loader } from '@copper/ui-kit';
const EmptyFallbackComponent = () => _jsx(Loader, {});
export const lazyWithRetry = (importComponent, fallbackElementWhileRetrying = EmptyFallbackComponent) => lazy(async () => {
    const isPageHasBeenForceRefreshed = JSON.parse(localStorage.getItem('page-has-been-force-refreshed') || 'false');
    try {
        const component = await importComponent();
        localStorage.setItem('page-has-been-force-refreshed', 'false');
        return component;
    }
    catch (_) {
        if (!isPageHasBeenForceRefreshed) {
            localStorage.setItem('page-has-been-force-refreshed', 'true');
            location.reload();
            return { default: fallbackElementWhileRetrying };
        }
        throw new Error('Error while force-reloading the page');
    }
});
