import { fuzzySearch } from '@copper/ui-kit';
export const filterStakingCurrencies = (items, searchQuery) => {
    if (searchQuery === '') {
        return items;
    }
    return fuzzySearch({
        searchQuery,
        keys: ['name', 'currency'],
        items
    });
};
export const sortStakingCurrencies = (items) => [...items].sort((a, b) => {
    const nameA = a.currency;
    const nameB = b.currency;
    if (nameA === 'ETH') {
        return -1;
    }
    if (nameB === 'ETH') {
        return 1;
    }
    return nameA.localeCompare(nameB);
});
