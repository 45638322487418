import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cn from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AssetInfo, InfoAccount, InfoList, InfoRow } from '@copper/components';
import { LoanStatus } from '@copper/components/Loans';
import { LoanLtv } from '@copper/components/Loans/LoanLtv';
import { LoanControls } from '@copper/components/Loans/LoanModalContent/LoanControls';
import { LoanFeeDetails } from '@copper/components/Loans/LoanModalContent/LoanFeeDetails';
import { LoanManagementActions } from '@copper/components/Loans/LoanModalContent/LoanManagementAction';
import { isBorrowerOrganization } from '@copper/components/Loans/helpers';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectPortfolioEntities } from '@copper/entities/portfolios/portfolios-selector';
import { convertTypeToDisplay } from '@copper/helpers/loans';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { PopupContent, PopupHeader, Tabs } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import { CollateralDetails } from './CollateralDetails';
import { LoanDetails } from './LoanDetails';
import s from './LoanModalContent.module.css';
const getTabs = (hasFees) => {
    const standardTabs = [
        {
            title: 'Loan',
            value: 'Loan'
        },
        {
            title: 'Collateral',
            value: 'Collateral'
        }
    ];
    const feeTab = {
        title: 'Fees',
        value: 'Fees'
    };
    if (hasFees) {
        return [...standardTabs, feeTab];
    }
    return standardTabs;
};
const LoanModalContent = ({ loan, showActions = true }) => {
    const { getDecimals } = useCurrenciesInfo();
    const organization = useSelector(selectCurrentOrganization);
    const portfolios = useSelector(selectPortfolioEntities);
    const [activeTab, setActiveTab] = useState('Loan');
    const { borrowerOrganizationId, ltvPercentage, loanType, terms, borrowerPortfolioId, lenderPortfolioId, lenderOrganizationId, _embedded, borrowerCollateralPortfolioId } = loan;
    const { loanCurrency, amount, borrowFeeAmount, borrowFeePercentage } = terms;
    const isBorrower = isBorrowerOrganization(loan, organization);
    const hasFees = !!(borrowFeeAmount || borrowFeePercentage);
    const portfolioId = isBorrower ? loan.borrowerPortfolioId : loan.lenderPortfolioId;
    const portfolio = portfolioId && portfolios[portfolioId];
    const collateralPortfolio = borrowerCollateralPortfolioId && portfolios[borrowerCollateralPortfolioId];
    const { lenderOrganization: lender, borrowerOrganization: borrower } = _embedded ?? {};
    return (_jsxs("div", { className: s.loanPopup, id: "loan_popup", children: [_jsx(PopupHeader, { title: convertTypeToDisplay(loanType), rightSection: _jsx(AssetInfo, { align: "right", currency: loanCurrency }) }), _jsxs(PopupContent, { children: [_jsxs("div", { className: s.amountDescription, children: ["Amount (", loanCurrency || '-', ")"] }), _jsx("div", { className: s.amount, children: formatCurrency(amount || '0', {
                            decimalPlaces: getDecimals(loanCurrency, { useDictionaryDecimal: true })
                        }) || '-' }), _jsxs("div", { className: s.statusHeader, children: [ltvPercentage && Number(ltvPercentage) > 0 && (_jsxs(_Fragment, { children: [_jsx(LoanLtv, { loan: loan }), _jsx("div", { className: cn(s.dot, s[`color_base`]), children: "\u2022" })] })), _jsx(LoanStatus, { loan: loan, view: "secondary" })] }), showActions && (_jsxs("div", { children: [_jsx(LoanControls, { loan: loan }), _jsx(LoanManagementActions, { loan: loan })] })), _jsx("div", { className: s.tabsContainer, children: _jsx(Tabs, { view: "dark_secondary", items: getTabs(hasFees), value: activeTab, onChange: ({ value }) => setActiveTab(value), getTitle: ({ value }) => value }) }), activeTab === 'Loan' && (_jsxs(_Fragment, { children: [portfolio && (_jsx("div", { className: s.loanDetailsContainer, children: _jsx(InfoList, { className: s.loanDataList, children: _jsx(InfoRow, { title: "Account", titleColor: "secondary", children: _jsx(InfoAccount, { account: portfolio, address: isBorrower ? borrowerPortfolioId : lenderPortfolioId }) }) }) })), _jsx("div", { className: s.loanDetailsContainer, children: _jsx(InfoList, { className: s.loanDataList, children: _jsx(InfoRow, { title: isBorrower ? 'Lender' : 'Borrower', titleColor: "secondary", children: isBorrower
                                            ? lender?.organizationName || lenderOrganizationId
                                            : borrower?.organizationName || borrowerOrganizationId }) }) }), _jsx(LoanDetails, { loan: loan })] })), activeTab === 'Collateral' && (_jsxs(_Fragment, { children: [collateralPortfolio && (_jsx("div", { className: s.loanDetailsContainer, children: _jsx(InfoList, { className: s.loanDataList, children: _jsx(InfoRow, { title: "Account", titleColor: "secondary", children: _jsx(InfoAccount, { account: collateralPortfolio, address: borrowerCollateralPortfolioId }) }) }) })), _jsx(CollateralDetails, { loan: loan })] })), activeTab === 'Fees' && _jsx(LoanFeeDetails, { loan: loan })] })] }));
};
export { LoanModalContent };
