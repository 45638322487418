import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { Result } from '@copper/client/components/Generate/ValidatorNode/Result';
import { Summary } from '@copper/client/components/Generate/ValidatorNode/Summary';
import { ValidatorNodeForm } from '@copper/client/components/Generate/ValidatorNode/ValidatorNodeForm';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { validationSchema } from './helpers';
import s from '../Generate.module.css';
export const ValidatorNode = ({ onDone, onBack }) => {
    const [transaction, setTransaction] = useState();
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const { setTopPanel } = useTopPanel();
    const validatorNodeForm = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            signatures: [{ signature: '' }]
        }
    });
    const { reset, handleSubmit } = validatorNodeForm;
    const [state, send] = useStateMachine({
        initial: 'validatorNodeForm',
        states: {
            validatorNodeForm: {
                on: {
                    SUMMARY: 'summary'
                }
            },
            summary: {
                on: {
                    BACK: 'validatorNodeForm',
                    RESULT: 'result'
                }
            },
            result: {}
        }
    });
    const handleCreateOrder = handleSubmit(async (formValues) => {
        const { poolType, currency, portfolio, amount, voter, operator, commissionPercentage, signatures, validatorRegistration, period, rewardAddress, rewardCycle, signerSignature, signerKey, authId } = formValues;
        try {
            const data = {
                // ADA
                txWitnesses: signatures?.map((signature) => signature.signature),
                unsignedTx: validatorRegistration,
                // APT
                voter: poolType === 'staking-pool' ? voter : undefined,
                operator: poolType === 'staking-pool' ? operator : undefined,
                commissionPercentage,
                mainCurrency: currency.currency,
                baseCurrency: currency.currency,
                portfolioId: portfolio.portfolioId,
                amount: amount ? amount : '0',
                externalOrderId: cuid(),
                orderType: 'withdraw',
                blockchainTransactionType: 'pool-creation',
                period,
                rewardAddress: rewardAddress?.trim(), // STX
                ...(currency.currency === 'STX' && {
                    rewardCycle,
                    signerSignature,
                    signerKey,
                    authId
                })
            };
            const res = await dispatch(createOrder(data)).unwrap();
            setTransaction(res);
            send('RESULT');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    });
    const handleDone = () => {
        setTransaction(undefined);
        reset();
        onDone();
    };
    useEffect(() => {
        setTopPanel({
            title: 'Generate',
            onBack: state.value === 'result'
                ? undefined
                : () => {
                    if (state.value === 'validatorNodeForm')
                        return onBack();
                    return send('BACK');
                }
        });
    }, [state, setTopPanel]);
    return (_jsx("div", { className: s.mainWrapper, children: _jsxs(FormProvider, { ...validatorNodeForm, children: [state.value === 'validatorNodeForm' && (_jsx(ValidatorNodeForm, { onNext: handleSubmit(() => send('SUMMARY')) })), state.value === 'summary' && _jsx(Summary, { onSubmit: handleCreateOrder }), state.value === 'result' && transaction && (_jsx(Result, { onDone: handleDone, transaction: transaction }))] }) }));
};
