import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as revaultApi from '@copper/api/revault';
import { selectCurrentOrganization } from '../organizations/organizations-selector';
import { selectUserId } from '../user/user-selector';
const initialState = {
    uiSettings: {
        transactionFlowSummaryPeriod: '1m',
        searchPreferences: 'weak',
        showZeroBalances: true,
        organizationsWithFavoritesSelected: {},
        hidePasskeyPromoPopup: false,
        timeFormat: '24-hour',
        paginationSettings: {}
    },
    favoriteOrganizations: [],
    favoritePortfolios: {}
};
export const fetchUISettings = createAsyncThunk('revault/getUISettings', async (_, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    try {
        const response = await revaultApi.getUISettings(userId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateUISettings = createAsyncThunk('revault/updateUISettings', async ({ data, optimisticMode = true }, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    const state = thunkAPI.getState();
    try {
        const updatedUISettings = {
            ...state.revault.uiSettings,
            ...data
        };
        if (optimisticMode) {
            revaultApi.updateUISettings(userId, updatedUISettings).catch();
        }
        else {
            await revaultApi.updateUISettings(userId, updatedUISettings);
        }
        return updatedUISettings;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchFavoriteOrganizations = createAsyncThunk('revault/getFavoriteOrganizations', async (_, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await revaultApi.getFavoriteOrganizations(userId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateFavoriteOrganizations = createAsyncThunk('revault/updateFavoriteOrganizations', async (data, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        await revaultApi.setFavoriteOrganizations(userId, data);
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchFavoritePortfolios = createAsyncThunk('revault/getFavoritePortfolios', async (orgId, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await revaultApi.getFavoritePortfolios(userId, orgId);
        return response.data;
    }
    catch (_) {
        return {};
    }
});
export const updateFavoritePortfolios = createAsyncThunk('revault/updateFavoritePortfolios', async (data, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    const currentOrganization = selectCurrentOrganization(thunkAPI.getState());
    try {
        await revaultApi.setFavoritePortfolios(userId, currentOrganization.organizationId, data);
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const revaultSlice = createSlice({
    name: 'uiSettings',
    initialState,
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchFavoriteOrganizations.fulfilled, (state, { payload }) => {
            state.favoriteOrganizations = payload;
        });
        addCase(updateFavoriteOrganizations.fulfilled, (state, { payload }) => {
            state.favoriteOrganizations = payload;
        });
        addCase(fetchFavoritePortfolios.fulfilled, (state, { payload }) => {
            state.favoritePortfolios = payload;
        });
        addCase(updateFavoritePortfolios.fulfilled, (state, { payload }) => {
            state.favoritePortfolios = { ...state.favoritePortfolios, ...payload };
        });
        addMatcher(isAnyOf(fetchUISettings.fulfilled, updateUISettings.fulfilled), (state, { payload }) => {
            state.uiSettings = payload;
        });
    }
});
export const revault = revaultSlice.reducer;
