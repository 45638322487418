import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ButtonIcon, IconAdd, IconSubtract, NumberInput, useFormControl } from '@copper/ui-kit';
import { trimTrailingZeroes } from '@copper/utils';
import s from './NumberStepper.module.css';
export const NumberStepper = ({ onChange, onBlur, onIncrement, onDecrement, value = '', inputView = 'primary', buttonsView = 'dark', placeholder = '0', minValue = '0', step = '1', decimalScale = 0, thousandSeparator = false, size = 'm', testId = 'testId', disabled = false, state }) => {
    const formControl = useFormControl();
    const handleDecrement = () => {
        if (onDecrement) {
            onDecrement(value);
        }
        else if (!value || new BigNumber(value).isLessThanOrEqualTo(minValue)) {
            onChange(minValue);
        }
        else {
            onChange(trimTrailingZeroes(new BigNumber(value).minus(step).toFormat(decimalScale)));
        }
    };
    const handleIncrement = () => {
        if (onIncrement) {
            onIncrement(value);
        }
        else {
            onChange(trimTrailingZeroes(new BigNumber(value ? value : minValue).plus(Number(step)).toFormat(decimalScale)));
        }
    };
    return (_jsxs("div", { className: cn(s.container, s[`view_${inputView}`], s[`state_${state || formControl.state}`]), children: [_jsx(ButtonIcon, { size: size, view: buttonsView, onClick: handleDecrement, testId: `${testId}-decrease`, disabled: disabled, children: _jsx(IconSubtract, {}) }), _jsx("div", { className: s.inputContainer, children: _jsx(NumberInput, { thousandSeparator: thousandSeparator, decimalScale: decimalScale, value: value, onValueChange: ({ value }) => {
                        onChange(value);
                    }, onBlur: () => onBlur && onBlur(value), size: size, view: "inline", placeholder: placeholder, disabled: disabled, style: { textAlign: 'center' }, state: state }) }), _jsx(ButtonIcon, { size: size, view: buttonsView, onClick: handleIncrement, testId: `${testId}-increase`, disabled: disabled, children: _jsx(IconAdd, {}) })] }));
};
