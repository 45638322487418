import { getApiUrl } from '@copper/helpers';
export const ENABLED_OS_LIST = ['MacOS', 'MacOS ARM', 'Windows'];
export const DESKTOP_APP_MENU_ITEMS = [
    {
        title: 'macOS',
        id: 'MacOS'
    },
    {
        title: 'macOS ARM',
        id: 'MacOS ARM'
    },
    { title: 'Windows', id: 'Windows' }
];
const uniqueId = new Date().getTime();
export const legacyCustodyAppLinks = {
    MacOS: `${getApiUrl()}/custody/downloads/mac?${uniqueId}`,
    'MacOS ARM': `${getApiUrl()}/custody/downloads/m1?${uniqueId}`,
    Windows: `${getApiUrl()}/custody/downloads/win?${uniqueId}`,
    iOS: 'https://apps.apple.com/app/id1521814069'
};
export const newCustodyAppLinks = {
    MacOS: `${getApiUrl()}/custody/unlimited-new/mac?${uniqueId}`,
    'MacOS ARM': `${getApiUrl()}/custody/unlimited-new/m1?${uniqueId}`,
    Windows: `${getApiUrl()}/custody/unlimited-new/win?${uniqueId}`
};
export const legacyRecoveryAppLinks = {
    MacOS: `${getApiUrl()}/custody/recovery/mac?${uniqueId}`,
    'MacOS ARM': `${getApiUrl()}/custody/recovery/mac?${uniqueId}`,
    Windows: `${getApiUrl()}/custody/recovery/win?${uniqueId}`
};
export const newRecoveryAppLinks = {
    MacOS: `${getApiUrl()}/custody/recovery-new/mac?${uniqueId}`,
    'MacOS ARM': `${getApiUrl()}/custody/recovery-new/m1?${uniqueId}`,
    Windows: `${getApiUrl()}/custody/recovery-new/win?${uniqueId}`
};
