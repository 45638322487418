export const normalizeLendingLoans = (payload) => {
    const results = {
        loans: {},
        counterparties: {}
    };
    const { loans, _embedded } = payload;
    loans?.forEach((loan) => {
        results.loans[loan.loanId] = loan;
    });
    if (_embedded && _embedded?.counterparties) {
        _embedded?.counterparties?.forEach((counterparty) => {
            results.counterparties[counterparty.counterpartyId] = counterparty;
        });
    }
    return results;
};
export const normalizeFindLoansParams = ({ loanType, status, ...params }) => ({
    ...params,
    loanType: loanType?.join(','),
    status: status?.join(',')
});
