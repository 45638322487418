import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTwoFaContext } from '@copper/contexts/twoFa';
import { createPasskeyParams } from '@copper/entities/user/user-actions';
import { createPasskeyIdentity, updatePasskeyIdentity } from '@copper/entities/user/user-reducer';
import { selectUserId } from '@copper/entities/user/user-selector';
import { Button, FormControl, FormLabel, IconReject, Input, Popup, PopupContent, PopupFooter, PopupHeader, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { create, parseCreationOptionsFromJSON } from '@github/webauthn-json/browser-ponyfill';
import s from './PasskeyPopup.module.css';
export const PasskeyPopup = ({ passkeyForEdit, onClose, target }) => {
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const { openSnackbar } = useSnackBar();
    const { requestTwoFa, resolveTwoFa } = useTwoFaContext();
    const { control, handleSubmit, formState: { isSubmitting } } = useForm({
        defaultValues: { name: passkeyForEdit?.extra?.passkey?.name ?? '' }
    });
    const [errorMessage, setErrorMessage] = useState('');
    const onSubmit = handleSubmit(async ({ name }) => {
        try {
            if (!passkeyForEdit) {
                const publicKeyParams = await createPasskeyParams(userId, target);
                const credential = await create(parseCreationOptionsFromJSON(publicKeyParams));
                const twoFaCode = await requestTwoFa('create-passkey');
                await dispatch(createPasskeyIdentity({ name, credential, target, twoFaCode })).unwrap();
                openSnackbar({ type: 'success', message: 'Passkey created' });
            }
            else {
                await dispatch(updatePasskeyIdentity({ passkey: passkeyForEdit.value, updatedData: { name } }));
                openSnackbar({ type: 'success', message: 'Passkey updated' });
            }
            onClose();
        }
        catch (error) {
            const { code, message } = getErrorData(error);
            // error code equals 0 if user prevented operation, pressed cancel in native browser prompt for example
            if (code === 0) {
                onClose();
                return;
            }
            openSnackbar({ type: 'error', message });
            setErrorMessage(message);
        }
        finally {
            resolveTwoFa();
        }
    });
    if (errorMessage) {
        return (_jsx(Popup, { onClose: onClose, children: _jsxs("div", { className: s.errorContainer, children: [_jsx("div", { className: s.errorIcon, children: _jsx(IconReject, {}) }), _jsx("div", { className: s.errorTitle, children: "Something went wrong" }), _jsx("div", { className: s.errorMessage, children: errorMessage }), _jsx(Button, { onClick: onClose, size: "l", text: "Done", fullWidth: true })] }) }));
    }
    return (_jsx(Popup, { onClose: onClose, children: _jsxs("form", { onSubmit: onSubmit, children: [_jsx(PopupHeader, { title: `${passkeyForEdit ? 'Edit' : 'Add'} passkey` }), _jsx(PopupContent, { children: _jsxs(FormControl, { children: [_jsxs(FormLabel, { children: ["Name", !passkeyForEdit && ' your passkey'] }), _jsx(Controller, { control: control, name: "name", render: ({ field }) => (_jsx(Input, { autoFocus: true, value: field.value, size: "l", onChange: field.onChange })) })] }) }), _jsx(PopupFooter, { rightSection: _jsxs("div", { className: s.buttons, children: [passkeyForEdit && _jsx(Button, { onClick: onClose, view: "secondary", text: "Cancel" }), _jsx(Button, { loading: isSubmitting, type: "submit", text: passkeyForEdit ? 'Save' : 'Continue' })] }) })] }) }));
};
