import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormResultFrame, FormResultHeading } from '@copper/components/FormResultFrame';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { Alert } from '@copper/ui-kit';
import s from './Result.module.css';
export const Result = ({ errorMessage, settlementInstruction, onDone }) => {
    const awaitingApproval = ['waiting-approve', 'co-sign-require'].includes(settlementInstruction?.status || '');
    const getStatus = () => {
        if (errorMessage)
            return {
                title: 'Instructions failed',
                status: 'Failure',
                value: 'failure',
                text: 'Failed to send instructions'
            };
        if (settlementInstruction?.status === 'waiting-counterparty-approve')
            return {
                title: 'Instructions sent',
                status: 'Pending review',
                value: 'success',
                text: 'The settlement instructions have been sent to your counterparty for review.'
            };
        if (awaitingApproval)
            return {
                title: 'Awaiting approval',
                status: 'Approval required',
                value: 'pending',
                text: `We've notified approvers of your transaction request.`
            };
        return {
            title: 'Pending',
            status: 'pending',
            value: 'pending',
            text: 'Pending approval'
        };
    };
    const { title, status, value, text } = getStatus();
    return (_jsxs(FormResultFrame, { status: value, onDone: onDone, children: [_jsx(FormResultHeading, { status: value, children: title }), errorMessage ? (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) })) : (_jsxs("div", { className: s.content, children: [_jsx("div", { className: s.statusTitle, children: status }), _jsx("div", { className: s.statusDescription, children: text }), settlementInstruction && (_jsx(CancelOrder, { forceShow: true, showSuccessMessage: true, order: settlementInstruction }))] }))] }));
};
