import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Breadcrumb } from '@copper/components/Breadcrumbs/Breadcrumb';
import { selectAllPortfolioEntities } from '@copper/entities/portfolios/portfolios-selector';
export const PortfoliosChainBreadcrumb = ({ portfolioId = '', pathname = '' }) => {
    const portfolioEntities = useSelector(selectAllPortfolioEntities);
    const basePortfolio = portfolioEntities[portfolioId];
    if (!basePortfolio) {
        return _jsx(_Fragment, {});
    }
    const getPortfoliosChain = (portfolio, acc = []) => {
        const parentPortfolio = portfolioEntities[portfolio?.extra?.parentPortfolioId ?? ''];
        if (parentPortfolio) {
            return getPortfoliosChain(parentPortfolio, acc.concat(portfolio));
        }
        return acc.concat(portfolio).reverse();
    };
    return (_jsx(_Fragment, { children: getPortfoliosChain(basePortfolio).map((portfolio) => (_jsx(Breadcrumb, { pathname: pathname.replace(portfolioId, portfolio.portfolioId), children: portfolio.portfolioName }, `breadcrumb-${portfolio.portfolioId}`))) }));
};
