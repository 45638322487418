import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const BLD = {
    title: 'BLD',
    id: 'bld',
    qa: [
        {
            question: 'How do I stake BLD?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake BLD?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 21-day unbonding period." })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for BLD?',
            answer: _jsx("p", { children: "No, you can choose up to 100% of your available balance." })
        },
        {
            question: 'How often will I earn BLD staking rewards?',
            answer: (_jsx("p", { children: "You can check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }))
        },
        {
            question: 'How can I claim my BLD staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your BLD rewards anytime by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your BLD staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm.." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "The funds will be made available in your staking vault right away." })] }))
        }
    ]
};
