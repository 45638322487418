import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserImageId, selectInitials } from '@copper/entities/user/user-selector';
import { getImageSrcById } from '@copper/helpers';
import { Avatar } from '@copper/ui-kit';
const ProfilePhoto = ({ size = 'm', image = '', forceInitials = false }) => {
    const userInitials = useSelector(selectInitials);
    const userImageId = useSelector(selectCurrentUserImageId);
    const userPhoto = userImageId ? getImageSrcById(userImageId) : '';
    if (forceInitials) {
        return _jsx(Avatar, { initials: userInitials, size: size });
    }
    if (image) {
        return _jsx(Avatar, { image: image, initials: userInitials, size: size });
    }
    return _jsx(Avatar, { image: userPhoto, initials: userInitials, size: size });
};
export { ProfilePhoto };
