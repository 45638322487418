import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { InfoList, InfoRow } from '@copper/components';
import { useOrderContext } from '@copper/components/OrderPopup/context-hook';
import { isReceivingCurrencyFiat } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/helpers';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { fiatSettlementFundsReceived, fiatSettlementFundsSent } from '@copper/entities/orders/orders-actions';
import { selectUserId } from '@copper/entities/user/user-selector';
import { getQuoteAmount, isCurrencyFiat } from '@copper/helpers';
import { useDateTime } from '@copper/hooks';
import { Alert, Button, FormLabel, Input, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './FiatSettlementInfo.module.css';
export const FiatSettlementInfo = ({ order }) => {
    const [reference, setReference] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const { getDateAndTimeAsObject } = useDateTime();
    const { openSnackbar } = useSnackBar();
    const counterparties = useSelector(selectCounterpartiesEntities);
    const currencyEntities = useSelector(selectCurrenciesEntities);
    const userId = useSelector(selectUserId);
    const { counterpartyName = 'Counterparty' } = counterparties[order?.extra?.fromCounterpartyId ?? ''] || {};
    const { popupContext: { orderChanges } } = useOrderContext();
    const settlingAmount = isCurrencyFiat(currencyEntities, order.quoteCurrency)
        ? getQuoteAmount(order)
        : order.amount;
    const settlingCurrency = isCurrencyFiat(currencyEntities, order.quoteCurrency)
        ? order.quoteCurrency
        : order.baseCurrency;
    const isReceivingFiat = isReceivingCurrencyFiat(order, currencyEntities);
    const paymentTime = getDateAndTimeAsObject(orderChanges?.find((change) => change.changeType === 'funds-sent-captured')?.changedAt || '');
    const paymentConfirmedTime = getDateAndTimeAsObject(orderChanges?.find((change) => change.changeType === 'funds-received-captured')?.changedAt || '');
    const paymentReceived = order.extra?.externalFundsReceived;
    const paymentSent = order.extra?.externalFundsSent;
    const handleSendPayment = async () => {
        try {
            setIsBusy(true);
            const data = {
                orderId: order.orderId,
                userId,
                fundsSentReference: reference
            };
            await fiatSettlementFundsSent(data);
            openSnackbar({ type: 'success', message: 'Payment sent' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsBusy(false);
        }
    };
    const handleReceivePayment = async () => {
        try {
            setIsBusy(true);
            const data = {
                orderId: order.orderId,
                userId
            };
            await fiatSettlementFundsReceived(data);
            openSnackbar({ type: 'success', message: 'Payment received' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsBusy(false);
        }
    };
    const getDescription = () => {
        if (!paymentSent)
            return `To complete your leg of the settlement, please deliver ${settlingAmount} ${settlingCurrency}. Once the payment has been made, select ‘Payment sent’.`;
        return paymentReceived
            ? 'Your counterparty has confirmed that they have received the payment.'
            : 'Your counterparty must now confirm that they have received the payment.';
    };
    if (isReceivingFiat) {
        return (_jsxs(_Fragment, { children: [_jsx("div", { children: `${counterpartyName} has been instructed to complete their leg of the settlement. Once you have received the funds, please confirm by selecting ‘Payment received’.` }), _jsx("div", { className: s.fiatAlert, children: _jsx(Alert, { message: "Please contact your counterparty to provide them with the necessary bank details.", type: "warning" }) }), _jsx("p", { className: s.amountTitle, children: "Expected amount" }), _jsxs("p", { className: s.amountValue, children: [settlingAmount, " ", settlingCurrency] }), _jsxs("div", { className: s.paymentInfo, children: [_jsxs(InfoList, { view: "primary-rare", children: [order.extra?.externalFundsReference && (_jsx(InfoRow, { title: "Reference", children: order.extra?.externalFundsReference })), _jsx(InfoRow, { title: "Status", children: _jsx("p", { className: cn(s.paymentStatus, {
                                            [s.paymentConfirmed]: paymentReceived
                                        }), children: paymentReceived ? 'Confirmed' : 'Awaiting confirmation' }) })] }), !paymentReceived && paymentSent && (_jsx("div", { className: s.paymentButton, children: _jsx(Button, { text: "Payment received", loading: isBusy, onClick: handleReceivePayment }) }))] })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: getDescription() }), paymentSent ? (_jsx(_Fragment, { children: _jsx("div", { className: s.paymentInfo, children: _jsxs(InfoList, { view: "primary-rare", children: [_jsxs(InfoRow, { title: "Payment amount", children: [settlingAmount, " ", settlingCurrency] }), _jsxs(InfoRow, { title: "Sent", children: [paymentTime.date, " at ", paymentTime.time, " ", paymentTime.timezone] }), _jsx(InfoRow, { title: "Reference", children: order.extra?.externalFundsReference || '-' }), _jsx(InfoRow, { title: "Status", children: _jsx("p", { className: cn(s.paymentStatus, {
                                        [s.paymentConfirmed]: paymentReceived
                                    }), children: paymentReceived ? 'Confirmed' : 'Awaiting confirmation' }) }), paymentReceived && (_jsxs(InfoRow, { title: "Confirmed", children: [paymentConfirmedTime.date, " at ", paymentConfirmedTime.time, ' ', paymentConfirmedTime.timezone] }))] }) }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: s.fiatAlert, children: _jsx(Alert, { message: "Please contact your counterparty to ask for their bank account details.", type: "warning" }) }), _jsx("p", { className: s.amountTitle, children: "Amount to pay" }), _jsxs("p", { className: s.amountValue, children: [settlingAmount, " ", settlingCurrency] }), _jsxs("div", { className: s.referenceInput, children: [_jsx(FormLabel, { tooltipInfo: "It is recommended that you provide a reference to help your counterparty identify the payment.", children: "Reference (optional)" }), _jsx(Input, { size: "l", value: reference, onChange: (e) => setReference(e.target.value) })] }), _jsx("div", { className: s.paymentButton, children: _jsx(Button, { text: "Payment sent", loading: isBusy, onClick: handleSendPayment }) })] }))] }));
};
