import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { TransferAmount } from 'shared/components/TransferAmount';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { EmptyFallback, InfoAccount, InfoList, InfoRow } from '@copper/components';
import { CounterpartyNetworkCard } from '@copper/components/Counterparty/CounterpartyNetworkCard';
import { reportingCurrency } from '@copper/constants/currency';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectPortfolioEntities } from '@copper/entities/portfolios/portfolios-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Alert, Button } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { formatCurrency } from '@copper/utils';
import s from './Preview.module.css';
const SettlementDryOrderPreview = () => {
    const { getDecimals } = useCurrenciesInfo();
    const { modalOrderData, setModalContext, onDelete } = useBulkOrder();
    const portfolioEntities = useSelector(selectPortfolioEntities);
    const counterpartiesEntities = useSelector(selectCounterpartiesEntities);
    const rates = useSelector(selectRates);
    const { orderId, portfolioId, toCounterpartyId, baseCurrency, amount, priceLimit, quoteCurrency, orderType, description } = modalOrderData;
    const portfolio = useMemo(() => portfolioEntities[modalOrderData?.portfolioId ?? ''], [portfolioId]);
    const counterparty = useMemo(() => counterpartiesEntities[toCounterpartyId ?? ''], [toCounterpartyId]);
    const orderTotal = useMemo(() => {
        if (!amount || !priceLimit) {
            return '';
        }
        return new BigNumber(amount || '0').multipliedBy(priceLimit || '0').toFixed();
    }, [amount, priceLimit]);
    const totalInReporting = useMemo(() => {
        if (!orderTotal || !quoteCurrency) {
            return '';
        }
        const rateInReporting = rates[quoteCurrency];
        return rateInReporting
            ? formatCurrency(new BigNumber(orderTotal).multipliedBy(rateInReporting).toFixed(), {
                decimalPlaces: 2
            })
            : '';
    }, [orderTotal, baseCurrency]);
    return (_jsxs(PopupContent, { children: [_jsx(TransferAmount, { order: modalOrderData ?? {} }), modalOrderData?.statusDescription && (_jsx("div", { className: s.alertContainer, children: _jsx(Alert, { type: "error", message: modalOrderData?.status === 'error'
                        ? modalOrderData.statusDescription
                        : 'Please edit this instruction and provide all required information or delete it.' }) })), _jsxs("div", { className: s.controls, children: [_jsx(Button, { text: "Edit", view: "secondary", onClick: () => setModalContext({ orderId: orderId ?? '', mode: 'edit' }) }), _jsx(Button, { text: "Delete", view: "secondary", onClick: () => onDelete([orderId]) })] }), _jsx("div", { className: s.infoSection, children: _jsxs(InfoList, { children: [_jsx(InfoRow, { title: "From", children: portfolio ? _jsx(InfoAccount, { account: portfolio }) : _jsx(EmptyFallback, {}) }), _jsx(InfoRow, { title: "Counterparty", contentUnderTitle: Boolean(counterparty), children: counterparty ? (_jsx(CounterpartyNetworkCard, { form: "preview", counterparty: counterparty })) : (_jsx(EmptyFallback, {})) })] }) }), _jsx("div", { className: s.infoSection, children: _jsxs(InfoList, { title: "Settlement", children: [_jsxs(InfoRow, { title: orderType ? `You ${orderType}` : '–', children: [_jsx(EmptyFallback, { children: amount && formatCurrency(amount) }), ' ', _jsx(EmptyFallback, { children: baseCurrency })] }), _jsxs(InfoRow, { title: "Price", children: [_jsx(EmptyFallback, { children: priceLimit && formatCurrency(priceLimit) }), ' ', _jsx(EmptyFallback, { children: quoteCurrency })] }), _jsxs(InfoRow, { title: "Total", children: [_jsx(EmptyFallback, { children: orderTotal &&
                                        formatCurrency(orderTotal, {
                                            decimalPlaces: getDecimals(quoteCurrency)
                                        }) }), ' ', _jsx(EmptyFallback, { children: quoteCurrency })] }), _jsx(InfoRow, { title: "Settlement date", children: "T+0" }), _jsxs(InfoRow, { title: "Estimated value", children: [_jsx(EmptyFallback, { children: totalInReporting }), ' ', _jsx(EmptyFallback, { children: reportingCurrency })] })] }) }), description && (_jsx(InfoList, { title: "Details", children: _jsx(InfoRow, { title: "Note", contentUnderTitle: true, children: description }) }))] }));
};
export { SettlementDryOrderPreview };
