import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { TransferFundsForm } from '@copper/client/components/TransferFunds/Form';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { selectTravelRuleVerificationRequired } from '@copper/entities/organizations/organizations-selector';
const formId = 'single-transfer-form';
const SingleTransferForm = ({ form, onSubmit, unverifiedAddress }) => {
    const travelRuleVerificationRequired = useSelector(selectTravelRuleVerificationRequired);
    return (_jsxs(_Fragment, { children: [_jsx(TransferFundsForm, { form: form, formId: formId, onSubmit: onSubmit, unverifiedAddress: unverifiedAddress }), (!unverifiedAddress || (unverifiedAddress && !travelRuleVerificationRequired)) && (_jsx(SwitchStateRow, { type: "submit", form: formId, testId: "transfer-form-submit" }))] }));
};
export { SingleTransferForm };
