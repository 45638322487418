export const getPortfolioSelectTypes = (exchange, omnibusTreasury) => {
    const exchangePortfolioTypes = exchange?.exchangeAccounts[0].portfolioTypes;
    const res = [];
    if (!exchangePortfolioTypes) {
        return res.concat(['trading-vault', 'trading-clearloop']);
    }
    if (exchangePortfolioTypes.includes('trading-vault')) {
        res.push('trading-vault');
    }
    if (exchangePortfolioTypes.includes('trading')) {
        res.push(omnibusTreasury ? 'trading-clearloop' : 'trading-default');
    }
    return res;
};
