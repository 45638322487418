import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Menu } from '@copper/client/components/Toolbar/Menu';
import { Panel } from '@copper/client/components/Toolbar/Panel';
import { SkeletonLoader } from '@copper/client/components/Toolbar/SkeletonLoader';
import { CustodyUpdatePopup } from '@copper/components/CustodyUpdatePopup';
import { ProfileMenu } from '@copper/components/ProfileMenu';
import { ProfilePhoto } from '@copper/components/ProfilePhoto';
import { useLayoutState } from '@copper/contexts/layout';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { ButtonIcon, IconZendesk } from '@copper/ui-kit';
import { FixedOverlay } from '@copper/ui-kit/Popup/overlay';
import { setCssProperty } from '@copper/utils/styles';
import { ProfileMenuContent } from './ProfileMenuContent';
import { ZendeskMenu } from './ZendeskMenu';
import s from './RightMenu.module.css';
export const Toolbar = () => {
    const { loadingLayout } = useLayoutState();
    const { route, setRouteContext } = useRoutingContext();
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [currentView, setCurrentView] = useState();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showZendeskMenu, setShowZendeskMenu] = useState(false);
    const onProfileClose = () => setShowProfileMenu(false);
    const onClose = () => {
        setCurrentView(undefined);
        setRouteContext(undefined);
    };
    const onMenuOpenHandler = (value) => setCurrentView(value);
    const onZendeskMenuToggleHandler = () => setShowZendeskMenu(!showZendeskMenu);
    const showNewApps = currentOrganization.organizationType === 'lite' || !window.envConfig?.disabledFeatures?.newCU || currentOrganization.extra?.newCuEnabled;
    const showLegacyApps = currentOrganization.organizationType !== 'lite';
    useEffect(() => {
        if (currentView) {
            setCssProperty('--modal-offset', '540px');
        }
        else {
            setCssProperty('--modal-offset', '0px');
        }
    }, [currentView]);
    useEffect(() => {
        if (route?.name) {
            if (route.name === 'closed') {
                onClose();
            }
            else {
                setCurrentView(route?.name);
            }
        }
    }, [route]);
    useEffect(() => {
        if (loadingLayout) {
            setCurrentView(undefined);
        }
    }, [loadingLayout]);
    return (_jsxs(_Fragment, { children: [showProfileMenu && _jsx(FixedOverlay, { onClose: onProfileClose, superior: true }), showZendeskMenu && _jsx(FixedOverlay, { onClose: onZendeskMenuToggleHandler, superior: true }), _jsxs("div", { className: cn(s.container, {
                    [s.isOpen]: currentView
                }), children: [showNewApps && _jsx(CustodyUpdatePopup, { isNewApp: true }), showLegacyApps && _jsx(CustodyUpdatePopup, {}), showProfileMenu && (_jsx(ProfileMenu, { onClose: onProfileClose, children: _jsx(ProfileMenuContent, { onClose: onProfileClose }) })), showZendeskMenu && _jsx(ZendeskMenu, { onClose: onZendeskMenuToggleHandler }), _jsx("div", { className: s.wrapper, children: !loadingLayout ? (_jsxs(_Fragment, { children: [_jsx("button", { type: "button", className: s.avatar, onClick: () => setShowProfileMenu(!showProfileMenu), children: _jsx(ProfilePhoto, {}) }), _jsx(Menu, { currentItem: currentView, onClick: onMenuOpenHandler }), (currentOrganization?.extra?.zendeskOrganizationId ||
                                    currentOrganization?.organizationType === 'lite') && (_jsx(ButtonIcon, { view: "ghost", className: s.zendeskIcon, onClick: onZendeskMenuToggleHandler, children: _jsx(IconZendesk, {}) }))] })) : (_jsx(SkeletonLoader, {})) }), _jsx(Panel, { currentView: currentView, onCloseClick: onClose })] })] }));
};
