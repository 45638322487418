import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ContactActionPopup } from '@copper/components';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectAuthorizedActions } from '@copper/entities/permissions/permissions-selector';
import { selectPortfolioIds } from '@copper/entities/portfolios/portfolios-selector';
import { isSomeActionAvailable } from '@copper/utils';
export const CheckPermissionsContext = createContext({});
export const CheckPermissionsProvider = ({ children }) => {
    const { organizationId } = useSelector(selectCurrentOrganization);
    const authorizedActions = useSelector(selectAuthorizedActions);
    const portfolioIds = useSelector(selectPortfolioIds);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const checkPermissions = (actionsToCheck = [], portfolioIdsToCheck) => isSomeActionAvailable({
        authorizedActions,
        actions: typeof actionsToCheck === 'string' ? [actionsToCheck] : actionsToCheck,
        portfolioIds: typeof portfolioIdsToCheck === 'string' ? [portfolioIdsToCheck] : portfolioIdsToCheck
    });
    const isAllowedInAnyPortfolio = useCallback((actionsToCheck = []) => isSomeActionAvailable({
        authorizedActions,
        actions: typeof actionsToCheck === 'string' ? [actionsToCheck] : actionsToCheck
    }), [organizationId, authorizedActions, portfolioIds]);
    const requirePermissions = ({ actionsToCheck = [], portfolioIdsToCheck = [], onSuccess }) => {
        if (!checkPermissions(actionsToCheck, portfolioIdsToCheck)) {
            setIsModalOpen(true);
        }
        else {
            onSuccess();
        }
    };
    const handleContactActionPopupClose = () => setIsModalOpen(false);
    return (_jsxs(_Fragment, { children: [_jsx(CheckPermissionsContext.Provider, { value: {
                    checkPermissions,
                    isAllowedInAnyPortfolio,
                    requirePermissions
                }, children: children }), isModalOpen && (_jsx(ContactActionPopup, { onClose: handleContactActionPopupClose, title: "You don\u2019t have permission to access this", description: "Contact your team administrator for access.", image: "permission" }))] }));
};
export const useCheckPermissionsContext = () => useContext(CheckPermissionsContext);
