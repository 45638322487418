import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import QRCode from 'qrcode';
import { Popup } from '@copper/ui-kit';
import s from './FullScreenPopup.module.css';
const FullScreenPopup = ({ address, onClose }) => {
    const [qrCodeImage, setQrCodeImage] = useState();
    useMemo(() => {
        QRCode.toDataURL(address, { width: 1000, margin: 1 }, (_error, dataUrl) => {
            setQrCodeImage(dataUrl);
        });
    }, [address]);
    return (_jsx(Popup, { onClose: onClose, children: _jsx("div", { className: s.container, children: _jsx("img", { src: qrCodeImage, width: "630", alt: "QRCode" }) }) }));
};
export { FullScreenPopup };
