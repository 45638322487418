import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as announcementsApi from '@copper/api/announcements';
const initialState = {
    entities: {}
};
export const createAnnouncement = createAsyncThunk('announcements/createAnnouncement', async (payload, thunkApi) => {
    try {
        const { data } = await announcementsApi.createAnnouncement(payload);
        return data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateAnnouncement = createAsyncThunk('announcements/updateAnnouncement', async (payload, thunkApi) => {
    try {
        const { data } = await announcementsApi.updateAnnouncement(payload.announcementId, payload.data);
        return data;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const fetchAnnouncements = createAsyncThunk('announcements/getAnnouncements', async (params, thunkApi) => {
    try {
        const { data } = await announcementsApi.getAnnouncements(params);
        return data.announcements;
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState,
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchAnnouncements.fulfilled, (state, { payload }) => {
            state.entities = payload.reduce((acc, announcement) => ({
                ...acc,
                [announcement.announcementId]: announcement
            }), {});
        });
        addMatcher(isAnyOf(createAnnouncement.fulfilled, updateAnnouncement.fulfilled), (state, { payload }) => {
            state.entities = { ...state.entities, [payload.announcementId]: payload };
        });
    }
});
export const announcements = announcementsSlice.reducer;
