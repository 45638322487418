import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { Tooltip } from '@copper/ui-kit';
import s from './ButtonIcon.module.css';
const ButtonIcon = React.forwardRef((props, ref) => {
    const { children, className, size, state, tabIndex, tooltipLabel, tooltipType, onClick, view = 'default', disabled = false, loading = false, testId, ...otherProps } = props;
    const buttonComponent = () => (_jsx("button", { ...otherProps, ref: ref, type: "button", disabled: disabled || loading, tabIndex: tabIndex, className: cn(s.button, className, s[`state_${state}`], s[`size_${size}`], s[`view_${view}`], { [s.disabled]: disabled, [s.inactive]: disabled || loading }), onClick: onClick, "data-testid": testId, children: children }));
    if (tooltipLabel && !disabled) {
        return (_jsx(Tooltip, { type: tooltipType, label: tooltipLabel, children: buttonComponent() }));
    }
    return buttonComponent();
});
ButtonIcon.displayName = 'ButtonIcon';
export { ButtonIcon };
