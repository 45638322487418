import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { QrCode } from '@copper/components/QrCode';
import { updateDeferredLegacyAppVersion, updateDeferredNewAppVersion, updateUserCustodyVersion } from '@copper/entities/custody/custody-reducer';
import { selectLegacyAppVersion, selectNewAppVersion } from '@copper/entities/custody/custody-selector';
import { selectUserId } from '@copper/entities/user/user-selector';
import { Button, Dropdown, IconExternalLink, IconKeyboardArrowDown } from '@copper/ui-kit';
import { detectOS } from '@copper/utils/os';
import { DESKTOP_APP_MENU_ITEMS, ENABLED_OS_LIST, legacyCustodyAppLinks, legacyRecoveryAppLinks, newCustodyAppLinks, newRecoveryAppLinks } from './helpers';
import s from './AppDownloadButton.module.css';
const getLinksForDownload = (type) => {
    switch (type) {
        case 'legacyCustody':
            return legacyCustodyAppLinks;
        case 'newCustody':
            return newCustodyAppLinks;
        case 'legacyRecovery':
            return legacyRecoveryAppLinks;
        case 'newRecovery':
            return newRecoveryAppLinks;
    }
};
export const AppDownloadButton = ({ type, view = 'primary', size, indent, fullWidth }) => {
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const newAppVersion = useSelector(selectNewAppVersion);
    const legacyAppVersion = useSelector(selectLegacyAppVersion);
    const [listWidthValue, setListWidthValue] = useState(100);
    const [selectedItem, setSelectedItem] = useState(DESKTOP_APP_MENU_ITEMS[0]);
    const linksForDownload = useMemo(() => getLinksForDownload(type), [type]);
    const menuItems = useMemo(() => Object.keys(linksForDownload ?? []).includes('iOS')
        ? [...DESKTOP_APP_MENU_ITEMS, { title: 'iOS', id: 'iOS' }]
        : DESKTOP_APP_MENU_ITEMS, [linksForDownload]);
    useEffect(() => {
        (async () => {
            const os = await detectOS();
            if (os && ENABLED_OS_LIST.includes(os)) {
                setSelectedItem(menuItems.find((item) => item.id === os) ?? menuItems[0]);
            }
        })();
    }, [menuItems]);
    const dropdownArrowStyles = {
        width: size === 's' ? '18px' : '24px',
        height: size === 's' ? '18px' : '24px'
    };
    const onLinkDownloadHandler = (id) => {
        window.open(linksForDownload?.[id]);
        if (['legacyCustody', 'newCustody'].includes(type)) {
            dispatch(updateUserCustodyVersion({
                userId,
                data: { version: legacyAppVersion, newVersion: newAppVersion }
            }));
        }
        if (type === 'legacyCustody') {
            dispatch(updateDeferredLegacyAppVersion(''));
        }
        else if (type === 'newCustody') {
            dispatch(updateDeferredNewAppVersion(''));
        }
    };
    const onButtonClickHandler = (e) => {
        e.preventDefault();
        onLinkDownloadHandler(selectedItem.id);
    };
    const ArrowElement = () => (_jsx("div", { className: cn(s.arrowIconContainer, s[`arrow_${size}`], s[`arrow_${view}`]), children: _jsx(IconKeyboardArrowDown, { ...dropdownArrowStyles }) }));
    const getDropdownTitle = ({ id, title }) => {
        if (id !== 'iOS')
            return title;
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.qrTitle, children: [title, _jsx("span", { className: s.externalLink, children: _jsx(IconExternalLink, {}) })] }), _jsxs("div", { className: s.qrWrapper, children: [_jsx(QrCode, { qr: linksForDownload?.[id] ?? '', hideFullscreenIcon: true, size: "full", qrContainerClass: s.qrContainerClass, qrClass: s.qrClass }), _jsx("p", { className: s.qrInfo, children: "Scan to download app" })] })] }));
    };
    return (_jsxs("div", { className: s.buttonContainer, children: [_jsx(Button, { size: size, view: view, indent: indent, fullWidth: fullWidth, text: `Download for ${selectedItem.title}`, rightSection: _jsx("div", { className: s.buttonDummy }), onClick: onButtonClickHandler, ref: (item) => item && setListWidthValue(item?.offsetWidth) }), _jsx("div", { className: s.buttonRightSection, children: _jsx(Dropdown, { placement: "bottom-end", highlightSelectedItem: false, listWidth: listWidthValue, getKey: ({ id }) => id, selected: selectedItem, getTitle: getDropdownTitle, onChange: ({ id }) => onLinkDownloadHandler(id), items: menuItems, getToggle: ArrowElement, zIndex: 1200 }) })] }));
};
