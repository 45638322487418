import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetInfo, InfoList, InfoRow } from '@copper/components';
import { InfoText } from '@copper/components/InfoList/InfoText';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { calculateReportingValue } from '@copper/helpers';
import { splitKebabToCaptialWords } from '@copper/helpers/case-conversion';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { formatCurrency, formatFiat } from '@copper/utils';
import s from '../LoanModalContent.module.css';
const LoanFeeDetails = ({ loan }) => {
    const { getDecimals } = useCurrenciesInfo();
    const rates = useSelector(selectRates);
    const currencies = useSelector(selectCurrenciesEntities);
    const { terms } = loan;
    const { borrowFeeAmount, borrowFeePercentage, borrowFeeCurrency, borrowFeeCalculationMethod, valuationTimezone, dayCountConvention } = terms;
    const rate = rates[borrowFeeCurrency ?? ''] ?? '1';
    const isFiat = currencies[borrowFeeCurrency ?? '']?.fiat;
    const hasFees = !!(borrowFeeAmount || borrowFeePercentage);
    return hasFees ? (_jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [borrowFeeCalculationMethod && borrowFeeCalculationMethod !== 'none' && (_jsx(InfoRow, { title: "Calculation Method", titleColor: "secondary", children: _jsx("span", { children: splitKebabToCaptialWords(borrowFeeCalculationMethod) }) })), dayCountConvention && (_jsx(InfoRow, { title: "Day Count Convention", titleColor: "secondary", children: _jsx("span", { children: dayCountConvention }) })), valuationTimezone && (_jsx(InfoRow, { title: "Valuation Timezone", titleColor: "secondary", children: _jsx("span", { children: valuationTimezone }) })), borrowFeeCurrency && (_jsx(InfoRow, { title: "Asset", titleColor: "secondary", children: _jsx(AssetInfo, { align: "right", currency: borrowFeeCurrency }) })), borrowFeeAmount && (_jsxs(InfoRow, { title: "Amount", titleColor: "secondary", children: [_jsx("div", { className: s.assetValue, children: borrowFeeAmount
                                ? formatCurrency(borrowFeeAmount, {
                                    decimalPlaces: getDecimals(borrowFeeCurrency),
                                    zeroFormat: '0.0000'
                                })
                                : '-' }), !isFiat && (_jsx("div", { className: s.fiatValue, children: formatFiat(calculateReportingValue(borrowFeeAmount ?? '0', rate), { short: true }) }))] })), borrowFeePercentage && (_jsx(InfoRow, { title: "APR", titleColor: "secondary", children: _jsx("span", { children: `${borrowFeePercentage}%` }) }))] }) })) : (_jsx("div", { className: s.loanDetailsContainer, children: _jsx(InfoList, { className: s.loanDataList, children: _jsx(InfoText, { children: _jsx("span", { className: s.feesMissing, children: "Fees not configured" }) }) }) }));
};
export { LoanFeeDetails };
