import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import failureIcon from './img/Failure.svg';
import pendingIcon from './img/Pending.svg';
import signIcon from './img/Sign.svg';
import successIcon from './img/Success.svg';
import warnIcon from './img/Warn.svg';
import s from './FormResultFrame.module.css';
export const FormResultHeading = ({ children, status = 'success' }) => (_jsx("div", { className: cn(s.headline, s[`headline_${status}`]), children: children }));
export const FormResultAccent = ({ children }) => (_jsx("div", { className: s.accent, children: children }));
export const FormResultRegular = ({ children }) => (_jsx("div", { className: s.regular, children: children }));
export const FormResultFrame = ({ status = 'success', children, onDone }) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.upperSection, children: [_jsx("div", { className: cn(s.circle, s.circle_l, s[`circle_${status}`]) }), _jsx("div", { className: cn(s.circle, s.circle_m, s[`circle_${status}`]) }), _jsx("div", { className: cn(s.circle, s.circle_s, s[`circle_${status}`]) }), onDone && (_jsx("button", { className: s.doneButton, "data-testid": "done-button", onClick: onDone, children: "Done" }))] }), _jsxs("div", { className: s.bottomSection, children: [_jsxs("div", { className: s.statusIcon, children: [status === 'success' && _jsx("img", { src: successIcon, alt: "" }), status === 'pending' && _jsx("img", { src: pendingIcon, alt: "" }), status === 'warn' && _jsx("img", { src: warnIcon, alt: "" }), status === 'failure' && _jsx("img", { src: failureIcon, alt: "" }), status === 'signature' && _jsx("img", { src: signIcon, alt: "" })] }), children] })] }));
