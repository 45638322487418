import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { fetchSuggestedPools } from '@copper/entities/stake/stake-actions';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
export const useFetchStakePool = (currency, mainCurrency, portfolioId, enabled = true, hasPendingPoolCalculation = false) => {
    const { openSnackbar } = useSnackBar();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [isFetchingPool, setIsFetchingPool] = useState(true);
    const [predefinedPool, setPredefinedPool] = useState();
    const fetchPool = useCallback(async (currency, mainCurrency, portfolioId) => {
        if (!currency || !mainCurrency) {
            return;
        }
        setIsFetchingPool(true);
        try {
            const pools = await fetchSuggestedPools({
                organizationId,
                currency: currency,
                mainCurrency: mainCurrency,
                portfolioId: portfolioId
            });
            setPredefinedPool(pools[0]);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setIsFetchingPool(false);
        }
    }, [openSnackbar, organizationId]);
    useEffect(() => {
        if (enabled && currency) {
            fetchPool(currency, mainCurrency);
        }
        else {
            setIsFetchingPool(false);
        }
    }, [currency, enabled, fetchPool, mainCurrency]);
    useEffect(() => {
        if (enabled && portfolioId && hasPendingPoolCalculation) {
            fetchPool(currency, mainCurrency, portfolioId);
        }
    }, [currency, enabled, fetchPool, mainCurrency, portfolioId]);
    return { predefinedPool, isFetchingPool };
};
