import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { AssetIcon } from '@copper/components';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { Button, FormControl, FormLabel, Input, TextareaAutosize } from '@copper/ui-kit';
import s from '../../Generate.module.css';
export const AlgoGovernanceForm = ({ onNext }) => {
    const { register, control, formState: { errors, isLoading } } = useFormContext();
    return (_jsxs("form", { onSubmit: onNext, className: s.content, children: [_jsxs("div", { className: s.innerContent, children: [_jsxs("div", { className: s.titleWrapper, children: [_jsx(AssetIcon, { currency: "ALGO" }), _jsxs("div", { children: [_jsx("p", { className: s.title, children: "Governance" }), _jsx("p", { className: s.subtitle, children: "Vote on changes to the protocol and earn rewards" })] })] }), _jsx(PortfolioSelect, { name: "portfolio", label: "Account", control: control, types: ['custody'], currencyId: "ALGO", hasUnavailableToggle: true, errorMessage: errors.portfolio?.portfolioId?.message, testId: "algo-governance-portfolio-select" }), _jsxs(FormControl, { errorMessage: errors?.externalAddress?.message, children: [_jsx(FormLabel, { view: "tool", children: "To" }), _jsx(Input, { size: "l", view: "tool", placeholder: "Type address", ...register('externalAddress'), "data-testid": "algo-governance-external-address" })] }), _jsxs(FormControl, { errorMessage: errors.memo?.message, children: [_jsx(FormLabel, { view: "tool", children: "Memo" }), _jsx(Controller, { control: control, name: "memo", render: ({ field }) => (_jsx(TextareaAutosize, { size: "l", view: "tool", minHeight: 68, value: field.value, onChange: field.onChange, testId: "algo-governance-memo" })) })] })] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Next", type: "submit", loading: isLoading, testId: "algo-governance-next-button" }) })] }));
};
