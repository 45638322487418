import { useEffect } from 'react';
const useOutsideClick = (exception, handler) => {
    useEffect(() => {
        const listener = (event) => {
            const exceptions = Array.isArray(exception) ? exception : [exception];
            const isException = exceptions.some((element) => {
                if (!element) {
                    return;
                }
                const targetElement = 'current' in element ? element.current : element;
                return targetElement?.contains(event.target);
            });
            if (isException) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [exception, handler]);
};
export { useOutsideClick };
