import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { InfoList, InfoRow } from '@copper/components';
import { AuthSchemesList } from '@copper/components/Auth/Login/AuthSchemesList';
import { selectOrganizations } from '@copper/entities/organizations/organizations-selector';
import { selectUserEmail } from '@copper/entities/user/user-selector';
import { Popup, PopupContent, PopupHeader } from '@copper/ui-kit';
import s from './ExtendAuthPopup.module.css';
export const ExtendAuthPopup = ({ onClose, handleOrganizationSwitch, organizationId }) => {
    const organizations = useSelector(selectOrganizations);
    const email = useSelector(selectUserEmail);
    const { organizationName, availableAuthSchemes = [] } = organizations.find((organization) => organization.organizationId === organizationId) || {};
    const onSuccessAuthExtend = () => {
        handleOrganizationSwitch(organizationId, true);
        onClose();
    };
    if (!organizationName) {
        return null;
    }
    return (_jsxs(Popup, { onClose: onClose, children: [_jsx(PopupHeader, { title: "Authorisation required" }), _jsxs(PopupContent, { children: [_jsx("div", { className: s.info, children: _jsxs(InfoList, { children: [_jsxs(InfoRow, { titleColor: "secondary", title: "Organisation", children: [_jsx("div", { className: s.infoOrgName, children: organizationName }), _jsx("div", { className: s.infoOrgId, children: organizationId })] }), _jsx(InfoRow, { titleColor: "secondary", title: "Email", children: email })] }) }), _jsx(AuthSchemesList, { target: "client", schemes: availableAuthSchemes, mode: "extend", extendToOrganizationId: organizationId, onSuccess: onSuccessAuthExtend })] })] }));
};
