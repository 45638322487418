import * as loansApi from '@copper/api/loans';
export const approveDefault = (loanId) => loansApi.approveDefault(loanId);
export const createLoan = (newLoan) => loansApi.createLoan(newLoan);
export const setBorrowerPortfolio = (update) => loansApi.updateBorrowerPortfolio(update);
export const setLenderPortfolio = (update) => loansApi.updateLenderPortfolio(update);
export const cancelLoan = (loanId) => loansApi.cancelLoan(loanId);
export const writeOffLoan = (loanId) => loansApi.writeOffLoan(loanId);
export const defaultLoan = (loanId) => loansApi.defaultLoan(loanId);
export const cancelDefault = (loanId) => loansApi.cancelDefault(loanId);
export const marginCallLoan = (loanId) => loansApi.marginCallLoan(loanId);
export const rebalanceLoan = (loanId) => loansApi.rebalanceLoan(loanId);
export const repayLoan = (loanId) => loansApi.repayLoan(loanId);
