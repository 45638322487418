import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router';
import { PendingText } from '@copper/client/components/Generate/AlgoGovernance/Result/PendingText';
import { FormResultFrame } from '@copper/components';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { Button } from '@copper/ui-kit';
export const Result = ({ transaction, onDone }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleButtonClick = () => {
        navigate({
            pathname,
            search: `orderId=${transaction.orderId}`
        });
    };
    return (_jsxs(FormResultFrame, { status: "signature", onDone: onDone, children: [_jsx(PendingText, {}), _jsx(Button, { size: "l", text: "Sign", fullWidth: true, onClick: handleButtonClick }), _jsx(CancelOrder, { order: transaction })] }));
};
