import cuid from 'cuid';
import { getApiUrl } from '@copper/helpers/api-host';
const wsApiPrefix = '/platform';
const wsState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
};
let reduxStore;
let wsWorker;
let webSocketState = wsState.CLOSED;
const windowId = cuid();
const requests = {};
const subscriptions = {};
const subscriptionsInfo = {};
let queue = [];
const handleWsStateChange = (e) => {
    const oldWSState = webSocketState;
    webSocketState = e.data.state;
    // Connection lost
    if (oldWSState === wsState.OPEN && e.data.state !== wsState.OPEN) {
        Object.keys(requests).forEach((requestKey) => {
            requests[requestKey]({
                error: 'connection-closed-from-worker'
            });
            delete requests[requestKey];
        });
        Object.keys(subscriptions).forEach((subscriptionKey) => {
            subscriptions[subscriptionKey]({
                error: 'connection-closed-from-worker',
                correlationId: subscriptionKey
            });
            delete subscriptions[subscriptionKey];
            delete subscriptionsInfo[subscriptionKey];
        });
    }
    // Connection restored
    if (oldWSState !== wsState.OPEN && e.data.state === wsState.OPEN) {
        queue.forEach((request) => {
            switch (request.method) {
                case 'GET':
                    fetch({
                        uri: request.uri,
                        promiseCallback: request.promiseCallback
                    }).catch((e) => {
                        console.error('Unable to re-fetch on restored connection:', e);
                    });
                    break;
                case 'SUBSCRIBE':
                    subscribe({
                        uri: request.uri,
                        callback: request.callback,
                        promiseCallback: request.promiseCallback
                    }).catch((e) => {
                        console.error('Unable to resubscribe on restored connection:', e);
                    });
                    break;
            }
        });
        queue = [];
    }
};
const setupBroadcastChannel = () => {
    const broadcastChannel = new BroadcastChannel('WebSocketChannel');
    broadcastChannel.addEventListener('message', (e) => {
        switch (e.data.type) {
            case 'WSState':
                handleWsStateChange(e);
                break;
            case 'message':
                console.info('unhandled broadcast message:', e.data);
                break;
        }
    });
};
export const setupWebsocket = (store) => {
    if (!('SharedWorker' in window)) {
        return;
    }
    reduxStore = store;
    if ('BroadcastChannel' in window) {
        setupBroadcastChannel();
    }
    wsWorker = new SharedWorker(new URL('@copper/workers/websocket.shared.worker.ts', import.meta.url), { name: 'WebsocketWorker' });
    wsWorker.port.onmessage = (e) => {
        switch (e.data.type) {
            case 'ping':
                wsWorker.port.postMessage({
                    type: 'pong',
                    windowId
                });
                break;
            case 'WSState':
                handleWsStateChange(e);
                break;
            case 'message':
                if (requests[e.data.correlationId]) {
                    requests[e.data.correlationId]({
                        correlationId: e.data.correlationId,
                        data: e.data.body,
                        error: e.data.error || e.data.body?.error,
                        errorMessage: e.data.body?.error ? e.data.body.message : ''
                    });
                    delete requests[e.data.correlationId];
                }
                else if (subscriptions[e.data.correlationId]) {
                    subscriptions[e.data.correlationId]({
                        correlationId: e.data.correlationId,
                        data: e.data.body,
                        error: e.data.error || e.data.body?.error,
                        errorMessage: e.data.body?.error ? e.data.body.message : ''
                    });
                }
                break;
        }
    };
    wsWorker.port.start();
    wsWorker.port.postMessage({
        type: 'init',
        apiUrl: getApiUrl()
    });
};
const createFetchPromiseCallback = (resolve, reject) => ({ data, error, errorMessage }) => {
    data && !data.error
        ? resolve(data)
        : reject(errorMessage || error || data.error || 'Unexpected server response.');
};
export const fetch = ({ uri, promiseCallback }) => new Promise((resolve, reject) => {
    const correlationId = cuid();
    if (webSocketState === wsState.CONNECTING ||
        webSocketState === wsState.CLOSING ||
        webSocketState === wsState.CLOSED) {
        queue.push({
            method: 'GET',
            uri,
            promiseCallback: createFetchPromiseCallback(resolve, reject)
        });
    }
    else {
        wsWorker.port.postMessage({
            type: 'message',
            correlationId,
            windowId,
            data: {
                method: 'GET',
                uri: `${wsApiPrefix}${uri}`,
                headers: {
                    Authorization: `Bearer ${reduxStore.getState().auth?.token}`,
                    'Correlation-Id': correlationId
                }
            }
        });
        requests[correlationId] = promiseCallback
            ? promiseCallback
            : createFetchPromiseCallback(resolve, reject);
    }
});
export const unsubscribe = (subscription) => {
    console.info('ws unsubscribing from', subscription.correlationId);
    if (queue.find((request) => request.uri === subscription.uri)) {
        queue = queue.filter((request) => request.uri != subscription.uri);
    }
    else if (requests[subscription.correlationId] || subscriptions[subscription.correlationId]) {
        const correlationId = cuid();
        if (reduxStore.getState().auth?.token) {
            wsWorker.port.postMessage({
                type: 'message',
                windowId,
                correlationId,
                data: {
                    uri: `${wsApiPrefix}${subscription.uri}`,
                    method: 'UNSUBSCRIBE',
                    headers: {
                        Authorization: `Bearer ${reduxStore.getState().auth?.token}`,
                        'Correlation-Id': subscription.correlationId
                    }
                }
            });
        }
        delete requests[subscription.correlationId];
        delete subscriptions[subscription.correlationId];
        delete subscriptionsInfo[subscription.correlationId];
    }
};
// This function can be partially applied
// see all .bind() calls and boundCorrelationId variable
const createSubscribePromiseCallback = ({ correlationId = null, resolve, reject, callback, uri }) => {
    function promiseCallback(boundCorrelationId, { data, error, errorMessage }) {
        if (data && !data.error) {
            // Set individual callback function for the subscription
            subscriptions[boundCorrelationId] = ({ data: subscriptionData, error: subscriptionError, correlationId: subscriptionCorrelationId }) => {
                callback({
                    data: subscriptionData,
                    error: subscriptionError ? subscriptionError || 'Unexpected server response.' : undefined,
                    correlationId: subscriptionCorrelationId
                });
            };
            // Save meta information to detect duplicate subscription attempts
            subscriptionsInfo[boundCorrelationId] = {
                uri
            };
            resolve({
                unsubscribe: () => {
                    unsubscribe({
                        correlationId: boundCorrelationId,
                        uri
                    });
                },
                data
            });
        }
        else {
            reject(errorMessage || error || data.error || 'Unexpected server response.');
        }
    }
    return correlationId ? promiseCallback.bind(null, correlationId) : promiseCallback;
};
export const subscribe = ({ uri, callback, cancelRequest, promiseCallback }) => new Promise((resolve, reject) => {
    if (!reduxStore.getState().auth?.token) {
        reject('Auth token is absent');
    }
    if (webSocketState === wsState.CONNECTING ||
        webSocketState === wsState.CLOSING ||
        webSocketState === wsState.CLOSED) {
        if (queue.find((request) => request.uri === uri)) {
            reject('Subscription already in queue');
        }
        else {
            queue.push({
                method: 'SUBSCRIBE',
                uri,
                callback,
                promiseCallback: createSubscribePromiseCallback({
                    resolve,
                    reject,
                    callback,
                    uri
                })
            });
        }
    }
    else {
        if (Object.keys(subscriptionsInfo).find((subKey) => subscriptionsInfo[subKey].uri === uri)) {
            reject('Already subscribed to specified URI');
        }
        else {
            const correlationId = cuid();
            wsWorker.port.postMessage({
                type: 'message',
                correlationId,
                windowId,
                data: {
                    method: 'SUBSCRIBE',
                    uri: `${wsApiPrefix}${uri}`,
                    headers: {
                        Authorization: `Bearer ${reduxStore.getState().auth?.token}`,
                        'Correlation-Id': correlationId
                    }
                }
            });
            requests[correlationId] = promiseCallback
                ? promiseCallback.bind(null, correlationId)
                : createSubscribePromiseCallback({
                    correlationId,
                    resolve,
                    reject,
                    callback,
                    uri
                });
            if (cancelRequest) {
                cancelRequest(unsubscribe.bind(null, {
                    correlationId,
                    uri
                }));
            }
        }
    }
});
export const logoutWS = () => {
    if (wsWorker.port) {
        wsWorker.port.postMessage({
            type: 'logout'
        });
    }
};
export const newToken = (token) => {
    if (wsWorker.port) {
        wsWorker.port.postMessage({
            type: 'newToken',
            token
        });
    }
};
