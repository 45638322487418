import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { IconControlBack, IconControlForward } from '@copper/ui-kit';
import s from './HorizontalScroller.module.css';
export const HorizontalScroller = ({ children, view = 'tool' }) => {
    const scrollContainer = useRef(null);
    const [scrollX, setScrollX] = useState(0);
    const [scrollable, setScrollable] = useState(false);
    useLayoutEffect(() => checkScrollPosition());
    const checkScrollPosition = () => {
        if (!scrollContainer?.current) {
            return;
        }
        const { scrollWidth, scrollLeft, offsetWidth } = scrollContainer.current;
        if (Math.floor(scrollWidth - scrollLeft) <= offsetWidth) {
            setScrollable(false);
        }
        else {
            setScrollable(true);
        }
    };
    const slide = (shift) => {
        if (!scrollContainer?.current) {
            return;
        }
        scrollContainer.current.scrollLeft += shift;
        setScrollX(scrollX + shift);
        checkScrollPosition();
    };
    const handleScroll = () => {
        if (!scrollContainer?.current) {
            return;
        }
        setScrollX(scrollContainer.current.scrollLeft);
        checkScrollPosition();
    };
    return (_jsxs("div", { className: cn(s.container, s[`view_${view}`]), children: [_jsx("button", { className: cn(s.button, s.button_left, scrollX > 0 && s.button_visible), onClick: () => slide(-140), children: _jsx(IconControlBack, {}) }), _jsx("div", { onScroll: handleScroll, ref: scrollContainer, className: s.scrollContainer, children: children }), _jsx("button", { className: cn(s.button, s.button_right, scrollable && s.button_visible), onClick: () => slide(+140), children: _jsx(IconControlForward, {}) })] }));
};
