export const DEPOSIT_TARGETS_PER_PAGE = 5;
export const EXTERNAL_DEPOSIT_TARGETS_PER_PAGE = 10;
export const DESTINATION_TYPE_MAP = {
    destinationTag: 'tag',
    memo: 'memo'
};
export const TARGET_TYPE_MAP = {
    direct: {
        title: 'Deposit addresses',
        titleTooltip: 'When using a deposit address, you receive the full deposit amount.',
        addAddressTooltip: 'Add new deposit address',
        noAddresses: 'No deposit addresses yet'
    },
    proxy: {
        title: 'Proxy deposit addresses',
        titleTooltip: 'Network fees are subtracted from deposits made using proxy addresses. You’ll receive less than the amount that was sent.',
        addAddressTooltip: 'Add new proxy deposit address',
        noAddresses: 'No proxy deposit addresses yet'
    }
};
