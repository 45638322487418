import { IconNetwork } from '@copper/ui-kit';
export const TRANSFER_BY_OPTIONS = [
    { value: 'network', label: 'Network', icon: IconNetwork }
];
export const BULK_DROPDOWN_ROW_OPTIONS = [
    {
        title: 'Edit',
        id: 'edit'
    },
    {
        title: 'Delete',
        id: 'delete',
        dangerous: true
    }
];
