import { useMemo } from 'react';
export const useForkRef = (refs) => useMemo(() => {
    if (refs.length < 1) {
        return null;
    }
    return (value) => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                ref(value);
            }
            else if (ref) {
                ref.current = value;
            }
        }
    };
}, [refs]);
