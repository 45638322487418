import { calculateReportingValue } from '@copper/helpers';
import { formatCurrency, formatFiat } from '@copper/utils';
import { formatFeeEstimatedTime } from '../../helpers';
const baseFeesLevelsDict = [
    {
        label: 'Slow',
        value: 'low'
    },
    {
        label: 'Average',
        value: 'medium'
    },
    {
        label: 'Fast',
        value: 'high'
    }
];
const feesFieldsByCurrency = {
    BTC: [
        {
            key: 'feePerByte',
            label: 'Fee Per Byte',
            editable: true,
            unit: 'sat/B'
        }
    ],
    ETH: [
        {
            key: 'gasPriceGwei',
            label: 'Gas price',
            editable: true,
            unit: 'GWEI'
        },
        {
            key: 'gasLimit',
            label: 'Gas limit (units)'
        }
    ]
};
const commonFeesFields = [
    {
        key: 'estimatedTime',
        label: 'Estimated time'
    },
    {
        key: 'fee',
        label: 'Estimated fees'
    }
];
const getEstimatedFeesFields = (mainCurrency = '') => {
    const uniqForCurrency = feesFieldsByCurrency[mainCurrency] || [];
    return uniqForCurrency.concat(commonFeesFields);
};
export const composeEstimatedFeesData = (mainCurrency = '', data, rateInReporting = '0') => getEstimatedFeesFields(mainCurrency).map((field) => {
    const fieldValue = data?.[field.key] ?? '0';
    switch (field.key) {
        case 'estimatedTime': {
            return {
                ...field,
                value: formatFeeEstimatedTime(fieldValue)
            };
        }
        case 'fee': {
            return {
                ...field,
                label: field.label,
                value: `${formatCurrency(fieldValue, { decimalPlaces: 8 })} ${data ? data.feeCurrency ?? '' : mainCurrency ?? ''}`,
                totalInReporting: formatFiat(calculateReportingValue(fieldValue, rateInReporting))
            };
        }
        default:
            return {
                ...field,
                value: fieldValue
            };
    }
});
export const getFeesLevelOptions = (isEditable = false) => !isEditable
    ? baseFeesLevelsDict
    : [
        ...baseFeesLevelsDict,
        {
            label: 'Custom',
            value: 'custom'
        }
    ];
export const getDestinationType = (destination) => {
    if (destination && 'portfolioType' in destination) {
        return destination.portfolioType;
    }
    if (destination && ('cryptoAddressId' in destination || 'nonCustodialWalletId' in destination)) {
        return 'external';
    }
    if (destination && 'counterpartyId' in destination) {
        return 'counterparty';
    }
};
export const checkIsFeesApplyable = (origin, destination, isFiat = false, withdrawTargets) => {
    const destinationType = getDestinationType(destination);
    if (isFiat || !destinationType || !origin?.portfolioType) {
        return false;
    }
    if ([destinationType, origin?.portfolioType].includes('clearloop')) {
        return false;
    }
    if (destinationType === 'trading' && origin?.portfolioType === 'trading') {
        return false;
    }
    if (destination &&
        'portfolioId' in destination &&
        withdrawTargets?.find((target) => target.portfolioId === destination?.portfolioId)?.internal) {
        return false;
    }
    return true;
};
export const checkIsFeeIncludable = (currency = '', fromPortfolioType = '', feesCurrencies) => {
    if (fromPortfolioType === 'trading') {
        return true;
    }
    if (fromPortfolioType === 'exernal') {
        return false;
    }
    // 'ARB', 'OP', 'BSC' have another network
    return feesCurrencies.includes(currency);
};
