import BigNumber from 'bignumber.js';
import { isAfter, isValid, parse, subYears } from 'date-fns';
import * as Yup from 'yup';
import { isClearloopSubAccount } from '@copper/helpers/portfolios';
export const validateAmountField = (schema) => {
    const yupObject = schema ?? Yup.string();
    return yupObject
        .test('amount', 'Enter an amount.', (value) => new BigNumber(value ?? 0).gte(0))
        .test({
        name: 'amount',
        test: (value, { parent, createError }) => {
            const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
            return new BigNumber(parent?.availableBalance ?? 0).minus(value ?? 0).gte(0)
                ? true
                : createError({ message: `Insufficient ${currency} balance.` });
        }
    });
};
export const validateDateOfBirth = (schema) => {
    const yupObject = schema ?? Yup.string();
    return yupObject.test({
        name: 'date',
        test: (value, { createError }) => {
            const parsedDate = parse(value ?? '', 'dd/MM/yyyy', new Date());
            if (!isValid(parsedDate)) {
                return createError({
                    message: 'Enter a date in the format DD/MM/YYYY. For example, 20/03/1980.'
                });
            }
            if (isAfter(parsedDate, subYears(new Date(), 18))) {
                return createError({
                    message: 'We require users to be 18 years old or older.'
                });
            }
            return true;
        }
    });
};
export const checkRequiredNetwork = ({ origin, destination }) => {
    if (isClearloopSubAccount(origin) ||
        (destination && 'portfolioId' in destination && isClearloopSubAccount(destination))) {
        return false;
    }
    return true;
};
