import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { debounce } from 'lodash-es';
import { ButtonIcon, IconControlBack, IconControlForward, IconControlPause, IconControlPlay, IconFullScreen, IconFullScreenExit, Input, Tooltip } from '@copper/ui-kit';
import { FullScreenPopup } from './FullScreenPopup';
import { animationSpeed, renderQRCode } from './helpers';
import s from './QrCode.module.css';
export const QrCode = ({ qr, onClickExit, qrClass, size = 'default', hideFullscreenIcon = false, qrContainerClass }) => {
    const [isPause, setIsPause] = useState(false);
    const [currentQrNumber, setCurrentQrNumber] = useState(0);
    const [userEnteredQrNumber, setUserEnteredQrNumber] = useState();
    const [isSequenceAtBegin, setIsSequenceAtBegin] = useState(true);
    const [isInvalidQrNumber, setIsInvalidQrNumber] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const [openedFullscreenModal, setOpenedFullscreenModal] = useState(false);
    const canvasRef = useRef(null);
    useEffect(() => {
        if (Array.isArray(qr)) {
            renderQrSeries();
        }
        else {
            renderQRCode(canvasRef.current, qr, size);
        }
    }, [qr]);
    const renderQrSeries = async (ix = 0) => {
        // Progress bar animation sequence
        if (ix === 0) {
            await setIsSequenceAtBegin(true);
            setTimeout(() => {
                setIsSequenceAtBegin(false);
                setCurrentQrNumber(ix + 1);
            }, 30);
        }
        else {
            setCurrentQrNumber(ix + 1);
        }
        renderQRCode(canvasRef.current, qr[ix], size, () => {
            const timerId = setTimeout(() => {
                renderQrSeries(ix + 1 >= qr.length ? 0 : ix + 1);
            }, animationSpeed);
            setTimerId(timerId);
        });
    };
    const showFullScreen = () => {
        if (!isPause) {
            togglePlay();
        }
        if (onClickExit) {
            onClickExit();
        }
        else {
            setOpenedFullscreenModal(true);
        }
    };
    const togglePlay = () => {
        if (!isPause && timerId) {
            clearTimeout(timerId);
        }
        else {
            renderQrSeries(Number(currentQrNumber) - 1);
        }
        setIsPause(!isPause);
        setUserEnteredQrNumber(undefined);
        setIsInvalidQrNumber(false);
    };
    const setCurrentQR = (qrNumber) => {
        if (!isPause && timerId) {
            clearTimeout(timerId);
        }
        let validQrNumber = Number(qrNumber);
        if (validQrNumber <= 0) {
            validQrNumber = qr.length;
        }
        else if (validQrNumber > qr.length) {
            validQrNumber = 1;
        }
        if (!isPause) {
            setIsPause(true);
        }
        setCurrentQrNumber(validQrNumber);
        setUserEnteredQrNumber(undefined);
        setIsInvalidQrNumber(false);
        renderQRCode(canvasRef.current, qr[validQrNumber - 1], size);
    };
    const debouncedSetCurrentQR = debounce((value) => {
        const validQrNumber = Number(value);
        if (validQrNumber > 0 && validQrNumber <= qr.length) {
            setCurrentQrNumber(validQrNumber);
            setIsPause(true);
            setUserEnteredQrNumber(undefined);
            setIsInvalidQrNumber(false);
            renderQRCode(canvasRef.current, qr[validQrNumber - 1], size);
        }
        else {
            setIsInvalidQrNumber(true);
        }
    }, 100);
    const onInput = (value) => {
        setUserEnteredQrNumber(value);
        if (value) {
            debouncedSetCurrentQR(value);
        }
    };
    return (_jsxs("div", { className: cn(s.container, s[`size_${size}`]), children: [_jsxs("div", { className: cn(s.canvasBox, qrContainerClass), children: [_jsx("canvas", { className: cn(s.canvas, { [s.hide]: isInvalidQrNumber }, qrClass), ref: canvasRef }), isInvalidQrNumber && (_jsx("div", { className: s.incorrectQr, style: {
                            width: canvasRef.current?.width ? `${canvasRef.current.width}px` : 'auto',
                            height: canvasRef.current?.height ? `${canvasRef.current.height}px` : 'auto'
                        }, children: "Incorrect QR code number" })), !hideFullscreenIcon && (_jsx("div", { className: s.fullscreen, children: _jsx(ButtonIcon, { onClick: showFullScreen, tooltipLabel: size === 'large' ? 'Exit' : 'Full screen', children: size === 'large' ? _jsx(IconFullScreenExit, {}) : _jsx(IconFullScreen, {}) }) }))] }), Array.isArray(qr) && (_jsxs("div", { className: s.controls, children: [_jsx("div", { className: s.subTitle, children: "QR codes" }), _jsx(Input, { size: "l", disabled: !isPause, value: userEnteredQrNumber ?? String(currentQrNumber), onChange: (e) => {
                            onInput(e.target.value);
                        }, rightSection: _jsxs("div", { className: s.buttons, children: [_jsxs("div", { className: s.counter, children: ["of ", qr.length] }), _jsx(Tooltip, { label: "Previous QR code", children: _jsx("button", { className: cn(s.button, s.controlFirst), onClick: setCurrentQR.bind(this, Number(currentQrNumber) - 1), children: _jsx(IconControlBack, {}) }) }), _jsx(Tooltip, { label: isPause ? 'Play QR code scanner' : 'Pause QR code scanner', children: _jsx("button", { className: s.button, onClick: togglePlay, children: isPause ? _jsx(IconControlPlay, {}) : _jsx(IconControlPause, {}) }) }), _jsx(Tooltip, { label: "Next QR code", children: _jsx("button", { className: cn(s.button, s.controlLast), onClick: setCurrentQR.bind(this, Number(currentQrNumber) + 1), children: _jsx(IconControlForward, {}) }) })] }) }), _jsx("div", { className: cn(s.progress_bar, {
                            [s.animation_begin]: isSequenceAtBegin
                        }), style: {
                            width: (100 / qr.length) * Number(currentQrNumber) + '%'
                        } })] })), openedFullscreenModal && (_jsx(FullScreenPopup, { qr: qr, onClose: () => setOpenedFullscreenModal(false) }))] }));
};
