import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
const defaultContext = {
    popupContext: {},
    saveContext: () => null,
    setOrderChangesContext: () => undefined
};
export const Context = React.createContext(defaultContext);
export const OrderContextProvider = ({ children }) => {
    const [popupContext, setPopupContext] = useState(defaultContext.popupContext);
    const saveContext = ({ popupContext }) => {
        setPopupContext((context) => ({ orderChanges: context.orderChanges, ...popupContext }));
    };
    const setOrderChangesContext = (orderChanges) => {
        setPopupContext((context) => ({
            ...context,
            orderChanges
        }));
    };
    return (_jsx(Context.Provider, { value: { popupContext, saveContext, setOrderChangesContext }, children: children }));
};
