import * as authApi from '@copper/api/auth';
import { getAuthorizationHeader } from './auth-reducer';
export const fetchSessions = async () => await authApi.getSessions().then(({ data }) => data.tokensDetails);
export const killSession = async (data) => {
    const { tokenId } = data;
    return await authApi.killSession(tokenId).then(({ data }) => data);
};
export const killAllOtherSessions = async (tokenId) => await authApi.logoutFromOtherSessions(tokenId).then(({ data }) => data);
export const createSSOConfiguration = async (data) => await authApi.createSSOConfiguration(data).then(({ data }) => data);
export const updateSSOConfiguration = async (configurationId, data) => await authApi.updateSSOConfiguration(configurationId, data).then(({ data }) => data);
export const getSSOConfigurationsByOrgId = async (organizationId) => await authApi
    .getSSOConfigurationsByOrgId(organizationId)
    .then(({ data }) => data.configurations[0]);
export const createPasskeyChallenge = async (data, authParams) => {
    const reqConfig = authParams
        ? {
            headers: {
                Authorization: getAuthorizationHeader(authParams)
            }
        }
        : undefined;
    return await authApi.createPasskeyChallenge(data, reqConfig).then(({ data }) => data);
};
