import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { omit } from 'lodash-es';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { updateUISettings } from '@copper/entities/revault/revault-reducer';
import { selectOrganizationsWithFavoritesSelected, selectShowFavoritePortfolios } from '@copper/entities/revault/revault-selector';
import { useAppDispatch } from '@copper/store';
import { ButtonIcon, IconStar } from '@copper/ui-kit';
import s from './FavoriteButton.module.css';
export const FavoriteButton = () => {
    const dispatch = useAppDispatch();
    const showFavorites = useSelector(selectShowFavoritePortfolios);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const organizationsWithFavoritesSelected = useSelector(selectOrganizationsWithFavoritesSelected);
    const toggleFilterFavorites = () => {
        if (showFavorites) {
            dispatch(updateUISettings({
                data: {
                    organizationsWithFavoritesSelected: omit(organizationsWithFavoritesSelected, currentOrganization.organizationId)
                }
            }));
        }
        else {
            dispatch(updateUISettings({
                data: {
                    organizationsWithFavoritesSelected: {
                        ...organizationsWithFavoritesSelected,
                        [currentOrganization.organizationId]: true
                    }
                }
            }));
        }
    };
    return (_jsx(ButtonIcon, { tooltipLabel: "Starred accounts", onClick: toggleFilterFavorites, className: cn({
            [s.starIconBrand]: showFavorites,
            [s.starIconOutlined]: !showFavorites
        }), children: _jsx(IconStar, {}) }));
};
