import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const CORE = {
    title: 'CORE',
    id: 'core',
    qa: [
        {
            question: 'How do I stake CORE?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar and then select Core (CORE)." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 1 CORE)." }), _jsxs("li", { children: ["Choose a validator and enter the address provided on", ' ', _jsx("a", { href: "https://scan.coredao.org/validators", target: "_blank", rel: "noreferrer", children: "Core Scan" }), ' ', "in the validator address field."] }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }) }))
        },
        {
            question: 'How do I unstake CORE?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." })] }), _jsx("p", { children: "Unstaked assets will be released into your staking vault right away." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for CORE?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 1 CORE, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "1 CORE" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn CORE staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "CORE rewards are issued daily. These rewards will accumulate until you claim them." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my CORE staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your CORE rewards at any time by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, go to the Outstanding tab." }), _jsx("li", { children: "Locate the row showing your CORE staking details and select Claim." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." })] }), _jsx("p", { children: "Your claimed assets will be released into your staking vault right away." })] }))
        }
    ]
};
