import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectCollateralManagementLendingEnabled, selectCurrentOrganization, selectIsAgencyLendingLenderOrganization, selectIsAgencyOrganization, selectIsBilateralLenderOrganization } from '@copper/entities/organizations/organizations-selector';
import { IconClearloop, IconGradientGenerate, IconGradientLending, IconGradientOxygen, IconGradientPlus, IconGradientSettle, IconGradientStake, IconGradientTransfer } from '@copper/ui-kit';
import s from './Menu.module.css';
export const Menu = ({ currentItem, onClick }) => {
    const currentOrganization = useSelector(selectCurrentOrganization);
    const { isAllowedInAnyPortfolio } = useCheckPermissionsContext();
    const isLendingEnabled = useSelector(selectCollateralManagementLendingEnabled);
    const isAgencyLendingLenderOrganization = useSelector(selectIsAgencyLendingLenderOrganization);
    const isAgencyOrganization = useSelector(selectIsAgencyOrganization);
    const isBilateralLenderOrganization = useSelector(selectIsBilateralLenderOrganization);
    const handleClick = (itemId) => {
        if (itemId === currentItem) {
            onClick();
        }
        else {
            onClick(itemId);
        }
    };
    const menuItems = useMemo(() => {
        const items = [];
        if (currentOrganization.organizationType === 'oxygen') {
            items.push({
                id: 'oxygen-withdraw',
                icon: IconGradientTransfer,
                title: 'Withdraw'
            });
        }
        else {
            items.push({
                id: 'transfer',
                icon: IconGradientTransfer,
                title: 'Transfer'
            });
        }
        items.push({
            id: 'deposit',
            icon: IconGradientPlus,
            title: 'Deposit'
        });
        if (isAllowedInAnyPortfolio('create-oxygen-pledge')) {
            items.push({
                id: 'oxygen-borrow-dct',
                icon: IconGradientOxygen,
                title: 'Pledge'
            });
        }
        if (isAllowedInAnyPortfolio('create-clearloop-transfer')) {
            items.push({
                id: 'clearloop',
                icon: IconClearloop,
                title: 'ClearLoop'
            });
        }
        if (isAllowedInAnyPortfolio('create-otc-trade')) {
            items.push({
                id: 'settle',
                icon: IconGradientSettle,
                title: 'Settle'
            });
        }
        if (isAllowedInAnyPortfolio('approve-stake-agreement')) {
            items.push({
                id: 'stake',
                icon: IconGradientStake,
                title: 'Stake'
            });
        }
        if ((isLendingEnabled && isAllowedInAnyPortfolio('create-loan')) ||
            (isAgencyLendingLenderOrganization && isAllowedInAnyPortfolio('create-parent-loan')) ||
            (isAgencyOrganization && isAllowedInAnyPortfolio('create-child-loan')) ||
            (isBilateralLenderOrganization && isAllowedInAnyPortfolio('create-loan'))) {
            items.push({
                id: 'lend',
                icon: IconGradientLending,
                title: 'Lend'
            });
        }
        if (isAllowedInAnyPortfolio('approve-stake-agreement')) {
            items.push({
                id: 'generate',
                icon: IconGradientGenerate,
                title: 'Generate'
            });
        }
        return items;
    }, [isAllowedInAnyPortfolio, isLendingEnabled]);
    return (_jsx("div", { children: menuItems.map((item) => {
            const ComponentIcon = item.icon;
            return (_jsxs("button", { className: cn(s.menuItem, { [s.active]: currentItem === item.id }), onClick: () => handleClick(item.id), children: [_jsx(ComponentIcon, {}), _jsx("span", { className: s.title, children: item.title })] }, item.id));
        }) }));
};
