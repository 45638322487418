import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControl, FormLabel, Input } from '@copper/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './helpers';
import s from './EditAddressForm.module.css';
const mapViewToButtonView = {
    primary: 'secondary',
    tool: 'tool'
};
const EditAddressForm = ({ depositTarget, isSubmitting, onCancel, onSubmit, view = 'primary' }) => {
    const { depositTargetId, name = '' } = depositTarget;
    const { control, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            depositTargetName: name
        },
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });
    const handleOnSubmit = ({ depositTargetName }) => {
        onSubmit?.(depositTargetId, depositTargetName);
    };
    return (_jsxs("form", { onSubmit: handleSubmit(handleOnSubmit), children: [_jsxs(FormControl, { errorMessage: errors?.depositTargetName?.message, children: [_jsx(FormLabel, { children: "Address name" }), _jsx(Controller, { control: control, name: "depositTargetName", render: ({ field }) => _jsx(Input, { size: "l", view: view, ...field }) })] }), _jsxs("div", { className: s.addressFormButtons, children: [_jsx(Button, { text: "Cancel", view: mapViewToButtonView[view], onClick: onCancel }), _jsx(Button, { loading: isSubmitting, text: "Save", type: "submit", view: "primary" })] })] }));
};
export { EditAddressForm };
