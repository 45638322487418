import { request } from '@copper/utils';
export const getPledgeConnections = (organizationId) => request
    .get(`/loan-connections?organizationId=${organizationId}`)
    .then((res) => res.data.connections);
export const createPledgeConnection = (data) => request.post('/loan-connections', data).then((res) => res.data);
export const reconnectPledgeConnection = (connectionId) => request
    .patch(`/loan-connections/${connectionId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.activate+json'
    }
})
    .then((res) => res.data);
export const disconnectPledgeConnection = (connectionId) => request.delete(`/loan-connections/${connectionId}`).then((res) => res.data);
