import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import s from './Tabs.module.css';
const Tabs = ({ items, onChange, value, view = 'default', altActiveView, getItemIcon, getTitle, getRightSection, testId = 'tab' }) => (_jsx("div", { className: s.tabs, children: items.map((item) => {
        const icon = getItemIcon && getItemIcon(item);
        const rightSection = getRightSection && getRightSection(item);
        return (_jsxs("button", { type: "button", className: cn(s.tab, s[`view_${view}`], {
                [s[`active_${altActiveView}`]]: Boolean(altActiveView),
                [s.activeTab]: value === item.value,
                [s.hasSubtitle]: Boolean(item.subTitle)
            }), onClick: () => onChange(item), "data-testid": `${item.title}-${testId}`, children: [_jsxs("div", { className: s.titleRow, children: [icon && _jsx("div", { className: s.iconContainer, children: icon }), _jsx("div", { className: cn(s.title, { [s.secondaryTitle]: Boolean(item.subTitle) }), children: getTitle ? getTitle(item) : item.title }), rightSection && _jsx("div", { className: s.sectionContainer, children: rightSection })] }), item.subTitle && _jsx("div", { className: s.subTitle, children: item.subTitle })] }, item.value));
    }) }));
export { Tabs };
