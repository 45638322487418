import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { getLoanStatusAsObject, isBorrowerOrganization } from '../helpers';
import s from './LoanStatus.module.css';
const LoanStatus = ({ loan, view = 'primary' }) => {
    const organization = useSelector(selectCurrentOrganization);
    const isBorrower = isBorrowerOrganization(loan, organization);
    const { text, status, color, secondColor } = getLoanStatusAsObject({
        loanStatus: loan.loanStatus,
        closedReason: loan.closedReason,
        actions: loan.pendingUserActions || [],
        isBorrower,
        terms: loan.terms
    });
    const finalColor = view === 'primary' ? secondColor || color : color;
    return (_jsx(_Fragment, { children: view === 'primary' ? (_jsx("div", { className: cn(s.activeStatus, s[`color_${finalColor}`]), children: text || status })) : (_jsx("div", { className: cn(s.activeStatus, s[`color_${finalColor}`]), children: status })) }));
};
export { LoanStatus };
