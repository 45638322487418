import { jsx as _jsx } from "react/jsx-runtime";
import { IconGradientOrganisation, IconLogo } from '@copper/ui-kit';
import { LOAN_TYPES_MAP } from './constants';
export const isAgencyLendingType = (loanType) => LOAN_TYPES_MAP[loanType]?.lendingType === 'Agency lending';
export const isAgencyChildLoanType = (loanType) => LOAN_TYPES_MAP[loanType]?.isAgencyChildLoan;
export const agencyChildLoanLabel = (loanType) => LOAN_TYPES_MAP[loanType]?.label;
export const isBilateralLoanType = (loanType) => LOAN_TYPES_MAP[loanType]?.isBilateral;
export const getLendingTypeIcon = (lendingType, size) => {
    if (!lendingType)
        return null;
    switch (lendingType) {
        case 'Agency lending':
            return _jsx(IconLogo, { width: size, height: size, viewBox: "0 0 32 32" });
        case 'Bilateral lending':
            return _jsx(IconGradientOrganisation, { width: size, height: size });
        default:
            return null;
    }
};
