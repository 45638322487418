import * as Yup from 'yup';
export const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};
export const validationSchema = Yup.object()
    .shape({
    oldPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string()
        .required('Password is required')
        .min(8, 'Enter at least 8 characters.')
        .notOneOf([Yup.ref('oldPassword'), null], 'Your old and new passwords are the same')
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*(\W|_)).{8,}$/, 'Enter at least 8 characters using a mix of lowercase and uppercase letters, numbers and symbols.'),
    confirmNewPassword: Yup.string()
        .required('Password is required')
        .oneOf([Yup.ref('newPassword')], 'Passwords do not match. Please try again.')
})
    .required();
