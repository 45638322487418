import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as treasuryApi from '@copper/api/treasury';
import { normalize } from '@copper/entities/treasury/treasury-normalize';
import { subscribe } from '@copper/helpers/websocket';
import { serializeParams } from '@copper/utils';
export const fetchTreasuries = createAsyncThunk('treasury/getTreasuries', async (_, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuries();
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchTreasuryAdjustments = createAsyncThunk('treasury/getTreasuryAdjustments', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuryAdjustments(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchTreasuryBalances = createAsyncThunk('treasury/getTreasuryBalances', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuryBalances(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchTreasuryFees = createAsyncThunk('treasury/getTreasuryFees', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuryFees(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchTreasuryRequests = createAsyncThunk('treasury/getTreasuryRequests', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuryRequests(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const subscribeRequests = createAsyncThunk('treasury/subscribeToRequests', async (params, thunkAPI) => {
    const { onUpdate, cancelRequest, ...requestQueryObject } = params;
    const serializedPayload = serializeParams(requestQueryObject);
    try {
        return await subscribe({
            uri: `/treasury/requests${serializedPayload}`,
            callback: onUpdate,
            cancelRequest
        });
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchTreasuryTransactions = createAsyncThunk('treasury/getTreasuryTransactions', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuryTransactions(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchTreasuryWallets = createAsyncThunk('treasury/getTreasuryWallets', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.getTreasuryWallets(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createTreasuryRequest = createAsyncThunk('treasury/createTreasuryRequest', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.createTreasuryRequest(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelTreasuryRequest = createAsyncThunk('treasury/cancelTreasuryRequest', async (treasuryRequestId, thunkAPI) => {
    try {
        const response = await treasuryApi.cancelTreasuryRequest(treasuryRequestId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createTreasuryWallet = createAsyncThunk('treasury/createTreasuryWallet', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.createTreasuryWallet(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteTreasuryWallet = createAsyncThunk('treasury/deleteTreasuryWallet', async (walletId, thunkAPI) => {
    try {
        const response = await treasuryApi.deleteTreasuryWallet(walletId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const uploadTreasuryWalletShard = createAsyncThunk('treasury/uploadTreasuryWalletShard', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.uploadTreasuryWalletShard(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const markTreasuryRequestAsWorking = createAsyncThunk('treasury/markTreasuryRequestAsWorking', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.markAsWorking(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const markTreasuryRequestAsPartSigned = createAsyncThunk('treasury/markTreasuryRequestAsPartSigned', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.markAsPartSigned(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const markTreasuryRequestAsFullSigned = createAsyncThunk('treasury/markTreasuryRequestAsFullSigned', async (data, thunkAPI) => {
    try {
        const response = await treasuryApi.markAsFullSigned(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const treasurySlice = createSlice({
    name: 'treasury',
    initialState: {
        treasuries: [],
        adjustments: [],
        balances: [],
        fees: [],
        requests: [],
        transactions: [],
        wallets: []
    },
    reducers: {
        updateRequests(state, { payload }) {
            const oldRequestsEmbeds = state.requests.reduce((obj, item) => Object.assign(obj, { [item.treasuryRequestId]: item._embedded }), {});
            const updatedRequests = payload;
            state.requests = [
                ...new Map([...state.requests, ...updatedRequests].map((item) => [
                    item.treasuryRequestId,
                    {
                        ...item,
                        _embedded: {
                            ...oldRequestsEmbeds[item.treasuryRequestId],
                            ...item._embedded
                        }
                    }
                ])).values()
            ];
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchTreasuries.fulfilled, (state, { payload }) => {
            state.treasuries = normalize(payload.treasuries).sort((treasuryA, treasuryB) => Number(treasuryA.createdAt) - Number(treasuryB.createdAt));
        });
        addCase(fetchTreasuryAdjustments.fulfilled, (state, { payload }) => {
            state.adjustments = payload.adjustments;
        });
        addCase(fetchTreasuryBalances.fulfilled, (state, { payload }) => {
            state.balances = payload.aggregatedBalances;
        });
        addCase(fetchTreasuryFees.fulfilled, (state, { payload }) => {
            state.fees = payload.feesRecords;
        });
        addCase(subscribeRequests.fulfilled, (state, { payload: { data } }) => {
            const { treasuryWallets = [] } = data?._embedded ?? {};
            state.wallets = [
                ...new Map([...state.wallets, ...treasuryWallets].map((item) => {
                    const wallet = state.wallets.find((wallet) => item.walletId === wallet.walletId);
                    return [
                        item.walletId,
                        {
                            ...item,
                            _embedded: {
                                ...wallet?._embedded
                            }
                        }
                    ];
                })).values()
            ];
            state.requests = data.requests;
        });
        addCase(fetchTreasuryRequests.fulfilled, (state, { payload }) => {
            state.requests = payload.requests;
        });
        addCase(fetchTreasuryTransactions.fulfilled, (state, { payload }) => {
            state.transactions = payload.transactions;
        });
        addCase(fetchTreasuryWallets.fulfilled, (state, { payload }) => {
            state.wallets = payload.wallets.sort((walletA, walletB) => walletA.name.localeCompare(walletB.name));
        });
        addCase(markTreasuryRequestAsWorking.fulfilled, (state, { payload }) => {
            state.requests = state.requests.map((request) => request.treasuryRequestId === payload.treasuryRequestId ? payload : request);
        });
        addCase(markTreasuryRequestAsPartSigned.fulfilled, (state, { payload }) => {
            state.requests = state.requests.map((request) => request.treasuryRequestId === payload.treasuryRequestId ? payload : request);
        });
        addCase(markTreasuryRequestAsFullSigned.fulfilled, (state, { payload }) => {
            state.requests = state.requests.map((request) => request.treasuryRequestId === payload.treasuryRequestId ? payload : request);
        });
    }
});
const { reducer } = treasurySlice;
export const { updateRequests } = treasurySlice.actions;
export const treasury = reducer;
