import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
export const validatePledgeCalculation = (isZeroValid = false) => Yup.object().shape({
    ratio: Yup.string()
        .required()
        .test('ratio', (value, { parent, createError }) => {
        const { minRatio } = parent ?? {};
        const valueNumber = Number(value);
        const minRatioNumber = Number(minRatio);
        if (isZeroValid && valueNumber === 0) {
            return true;
        }
        return valueNumber >= minRatioNumber
            ? true
            : createError({
                message: `Insufficient collateral. The ratio must be no lower than ${(minRatioNumber * 100).toFixed()}%.`
            });
    })
});
export const validateDCTAmount = () => Yup.string()
    .required('Enter an amount.')
    .test('amount', 'Amount must be greater than zero', (value) => new BigNumber(value ?? 0).gt(0))
    .test({
    name: 'maxDebt',
    test: (value, { parent, createError }) => {
        const maxDebt = parent?.availableMaxDebt ?? parent?.pledgeCalculation?.maxDebt;
        if (!maxDebt) {
            return true;
        }
        if (parent?.availableDCT &&
            BigNumber(parent?.availableDCT ?? 0)
                .minus(value ?? 0)
                .lt(0)) {
            return createError({
                message: 'Insufficient DCT balance.'
            });
        }
        return new BigNumber(maxDebt).minus(value ?? 0).gte(0)
            ? true
            : createError({
                message: `The maximum amount is ${new BigNumber(maxDebt).toFixed(2, BigNumber.ROUND_DOWN)} DCT.`
            });
    }
});
export const validateNewCollaterals = () => Yup.array().of(Yup.object().shape({
    amount: Yup.string()
        .test('collateral', '', (value, { parent, createError }) => {
        if (!value && !parent?.currency) {
            return createError({
                message: 'Select an asset and enter an amount to pledge.'
            });
        }
        else if (!value) {
            return createError({
                message: 'Enter an amount to pledge.'
            });
        }
        return true;
    })
        .test({
        name: 'amount',
        test: (value, { parent, from, createError }) => {
            const currency = parent?.currency?.currency;
            if (!currency) {
                return true;
            }
            const balance = from?.[1].value.collateralAvailableBalances?.[currency] ?? 0;
            return new BigNumber(balance).minus(value ?? 0).gte(0)
                ? true
                : createError({ message: `Insufficient ${currency} balance.` });
        }
    }),
    currency: Yup.object().required('Select an asset to pledge.')
}));
