import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { InfoRow } from '@copper/components';
import { Button } from '@copper/ui-kit';
import { truncateStr } from '@copper/utils';
import { CurrencyRow } from './CurrencyRow';
import s from '../../Generate.module.css';
export const Summary = ({ onSubmit }) => {
    const { formState: { isSubmitting }, getValues } = useFormContext();
    const { currency, validatorRegistration, signatures, voter, operator, commissionPercentage, amount } = getValues();
    return (_jsxs("div", { className: s.summaryContent, children: [_jsxs("div", { className: s.innerContent, children: [_jsx("h2", { className: s.summaryTitle, children: "Summary" }), currency && _jsx(CurrencyRow, { currency: currency }), validatorRegistration && (_jsx(InfoRow, { title: "Validator registration transaction", titleColor: "ghost", children: truncateStr(validatorRegistration, 7, 7) })), currency.currency === 'ADA' && signatures?.length && (_jsx(InfoRow, { title: "Signatures", titleColor: "ghost", children: signatures.map((signature, index) => (_jsx("div", { children: truncateStr(signature.signature, 7, 7) }, index))) })), amount && (_jsxs(InfoRow, { title: "Amount", titleColor: "ghost", children: [amount, " ", currency.currency] })), voter && (_jsx(InfoRow, { title: "Voter", titleColor: "ghost", children: voter })), operator && (_jsx(InfoRow, { title: "Operator", titleColor: "ghost", children: operator })), commissionPercentage && (_jsx(InfoRow, { title: "Commission percentage", titleColor: "ghost", children: commissionPercentage }))] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "validator-node-submit-button" }) })] }));
};
