import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const ROSE = {
    title: 'ROSE',
    id: 'rose',
    qa: [
        {
            question: 'How do I stake ROSE?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 100 ROSE)." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake ROSE?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 14-day unbonding period." })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for ROSE?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 100 ROSE, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "100 ROSE" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn ROSE staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "ROSE rewards are issued daily. You can check the reward frequency applicable at the time of staking. Or in your staking details, by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my ROSE staking rewards?',
            answer: (_jsx("p", { children: "Rewards for ROSE are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }))
        }
    ]
};
