import { debounce } from 'lodash-es';
import { resetAuth, updateToken } from '@copper/entities/auth/auth-reducer';
import { getApiUrl } from '@copper/helpers/api-host';
import { saveAuthData } from '@copper/utils/localstorage';
import { clearAuthData, setAuthorizationHeader } from '@copper/utils/request';
let currentToken;
let worker;
const setupBroadcastChannel = (store) => {
    const broadcastChannel = new BroadcastChannel('AuthChannel');
    broadcastChannel.addEventListener('message', (e) => {
        switch (e.data.type) {
            case 'tokenUpdate':
                if (e.data.token !== currentToken) {
                    currentToken = e.data.token;
                    setAuthorizationHeader(e.data.token);
                    saveAuthData({
                        token: e.data.token,
                        expiredAt: e.data.expiredAt,
                        twoFaTypes: store.getState().auth?.twoFaTypes,
                        target: store.getState().auth?.target
                    });
                    store.dispatch(updateToken({
                        token: e.data.token,
                        expiredAt: e.data.expiredAt
                    }));
                }
                break;
            case 'logoutOnIdle':
                console.info('logout on idle');
                setAuthorizationHeader(null);
                clearAuthData();
                store.dispatch(resetAuth());
                break;
            default:
                break;
        }
    });
};
export const registerTokenWorker = (store, targetPlatform) => {
    if (!('SharedWorker' in window)) {
        return;
    }
    worker = new SharedWorker(new URL('@copper/workers/token.shared.worker.ts', import.meta.url), {
        name: 'TokenWorker'
    });
    worker.port.start();
    worker.port.postMessage({
        type: 'init',
        apiUrl: getApiUrl()
    });
    if ('BroadcastChannel' in window) {
        setupBroadcastChannel(store);
    }
    store.subscribe(() => {
        if (store.getState().auth?.token !== currentToken) {
            currentToken = store.getState().auth?.token;
            worker.port.postMessage({
                type: 'newToken',
                newToken: currentToken,
                expiredAt: store.getState().auth?.expiredAt,
                targetPlatform
            });
        }
    });
};
export const idleReset = debounce(() => {
    if (worker) {
        worker.port.postMessage({
            type: 'resetIdle'
        });
    }
}, 5000);
