import { request } from '@copper/utils';
const normalizeInvoicesParams = ({ statuses, ...params }) => ({
    ...params,
    status: statuses?.join(',')
});
export const getInvoices = (params) => request.get('/invoices', {
    params: normalizeInvoicesParams(params)
});
export const getInvoicesByOrganizationId = (organizationId) => request.get(`/invoices?organizationId=${organizationId}`);
export const getInvoiceById = (invoiceId) => request.get(`/invoices/${invoiceId}?includeDepositTargets=true`);
export const createInvoice = (data) => request.post('/invoices', data);
export const updateInvoice = (data) => request.patch(`/invoices/${data.invoiceId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.edit-invoice+json'
    }
});
export const updateInvoiceStatus = (data) => request.patch(`invoices/${data.invoiceId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.change-invoice-status+json'
    }
});
export const getInvoicePayments = (invoiceId) => request.get(`/invoices/${invoiceId}/payments`);
export const createInvoicePayment = (data) => request.post(`/invoices/${data.invoiceId}/payments`, data);
export const recalculateInvoiceFees = (data) => request.patch(`/invoices/${data.invoiceId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.recalculate-invoice+json'
    }
});
export const removeInvoicePayments = (data) => request.patch(`/invoices/${data.invoiceId}/payments`, data, {
    headers: {
        'Content-Type': 'application/vnd.remove-invoice-payments+json'
    }
});
export const createManualAdjustments = (data) => request.patch(`invoices/${data.invoiceId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.add-manual-adjustments+json'
    }
});
