import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { AssetInfo, InfoRow } from '@copper/components';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { formatCurrency, formatFiat, formatShortCurrency } from '@copper/utils';
import s from './AssetList.module.css';
export const AssetList = ({ assets, status }) => {
    const { getDecimals } = useCurrenciesInfo();
    const [expanded, setExpanded] = useState(false);
    const isSuccess = status === 'success';
    const isError = status === 'error';
    const handleOnShowMore = () => {
        setExpanded((previous) => !previous);
    };
    const getTitle = () => {
        if (isSuccess)
            return 'Successfully sent';
        if (isError)
            return 'Failed to send';
        return 'Assets on exchange';
    };
    return (_jsxs("div", { className: s.assetsWrapper, children: [_jsxs("div", { className: cn(s.assetList, { [s.expanded]: expanded }), children: [_jsx("p", { className: cn(s.label, { [s.success]: isSuccess }, { [s.error]: isError }), children: getTitle() }), assets.map((asset) => (_jsx(InfoRow, { title: _jsx(AssetInfo, { currency: asset.currency }), titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [_jsx("span", { children: new BigNumber(asset.balance).isGreaterThan(0)
                                        ? formatCurrency(asset.totalBalance, {
                                            decimalPlaces: getDecimals(asset.currency)
                                        })
                                        : formatShortCurrency(asset.totalBalance) }), _jsx("span", { className: s.ticker, children: formatFiat(asset.balanceReporting, { short: true }) })] }) }, asset.currency)))] }), assets.length > 3 && (_jsx("button", { className: s.showMore, type: "button", onClick: handleOnShowMore, children: expanded ? 'Show less' : `Show more (+${assets.length - 3})` }))] }));
};
