import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAssignableGroups, getAuthorizedActions } from '@copper/api/permissions';
import { normalizeAuthorizedActions } from './permissions-normalize';
const initialState = {
    authorizedActions: {},
    clientAssignableGroups: []
};
export const fetchAuthorizedActions = createAsyncThunk('permissions/fetchAuthorizedActions', async (params, { rejectWithValue }) => {
    try {
        const { data } = await getAuthorizedActions(params);
        return data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const fetchClientAssignableGroups = createAsyncThunk('permissions/fetchClientAssignableGroups', async (organizationId, { rejectWithValue }) => {
    try {
        const { data } = await getAssignableGroups({
            organizationId,
            userTypes: ['user', 'service-user']
        });
        return data.groupActions;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const fetchAdminAssignableGroups = createAsyncThunk('permissions/fetchAdminAssignableGroups', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getAssignableGroups({
            userTypes: ['admin']
        });
        return data.groupActions;
    }
    catch (error) {
        return rejectWithValue(error);
    }
});
export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchAuthorizedActions.fulfilled, (state, { payload }) => {
            state.authorizedActions = normalizeAuthorizedActions(payload);
        });
        addCase(fetchClientAssignableGroups.fulfilled, (state, { payload }) => {
            state.clientAssignableGroups = payload;
        });
        addCase(fetchAdminAssignableGroups.fulfilled, (state, { payload }) => {
            state.adminAssignableGroups = payload;
        });
    }
});
const { reducer } = permissionsSlice;
export const permissions = reducer;
