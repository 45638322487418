import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TransferPortfolio } from '@copper/components/Forms/TransferPortfolio';
import { ButtonIcon, IconAdd, IconDelete } from '@copper/ui-kit';
import { ConnectionLine } from '../ConnectionLine';
import s from './ConditionalTransfers.module.css';
const ConditionalTransfers = () => {
    const { control, formState: { errors }, setValue, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'conditionalTransfers'
    });
    const { currency, mainCurrency, origin, destination, conditionalTransfers } = watch();
    const handleAddClick = () => {
        append({});
        if (destination && ('cryptoAddressId' in destination || 'counterpartyId' in destination)) {
            setValue('destination', undefined);
        }
    };
    const currentConditionalTransfers = conditionalTransfers
        ?.map((item) => 'portfolioId' in item && item.portfolioId)
        .filter(Boolean) ?? [];
    const excludePortfolioIds = [
        origin?.portfolioId ?? '',
        destination && 'portfolioId' in destination ? destination.portfolioId : '',
        ...currentConditionalTransfers
    ];
    if (destination && !('portfolioId' in destination)) {
        return;
    }
    return (_jsxs(_Fragment, { children: [fields.map((_, index) => (_jsxs("div", { id: `destination${index}`, children: [_jsxs("div", { className: s.field, children: [_jsx(TransferPortfolio, { name: `conditionalTransfers.${index}`, label: "Then send to", type: "destination", control: control, currencyId: currency?.currency, mainCurrencyId: mainCurrency, excludePortfolioIds: excludePortfolioIds, errorMessage: 'portfolioType' in (errors?.conditionalTransfers?.[index] ?? {})
                                    ? errors?.conditionalTransfers?.[index]?.message
                                    : undefined, hasExternal: !conditionalTransfers?.find((hop, ix, hops) => hop.cryptoAddressId && ix < hops.length - 1) }), _jsx("div", { className: s.remove, children: _jsx(ButtonIcon, { onClick: () => remove(index), tooltipLabel: "Remove", children: _jsx(IconDelete, {}) }) })] }), _jsx(ConnectionLine, { errors: errors, startId: `destination${index - 1 >= 0 ? index - 1 : ''}`, stopId: `destination${index}` })] }, index))), !conditionalTransfers?.find((hop) => hop.cryptoAddressId) && (_jsxs("div", { className: s.add, children: [_jsx(ButtonIcon, { onClick: handleAddClick, children: _jsx(IconAdd, {}) }), _jsx("button", { type: "button", className: s.addText, onClick: handleAddClick, children: "Add condition" })] }))] }));
};
export { ConditionalTransfers };
