import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CoSignControls } from '@copper/components/OrderPopup/ui/CoSignControls';
import { StepItem } from '@copper/ui-kit';
import s from './OfferReview.module.css';
const AwaitingCoSign = ({ order, status, disabled, index, viewByAdmin = false, testId }) => {
    const isCoSignable = Boolean(order?._embedded?.isCoSignable);
    return (_jsxs(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: isCoSignable && !viewByAdmin ? 'You approve the settlement' : 'Pending approval', disabled: disabled, children: ["The settlement instruction is pending internal approval.", status === 'processing' && !viewByAdmin && (_jsx("div", { className: s.buttonsContainer, children: _jsx(CoSignControls, { order: order, size: "m" }) }))] }));
};
export { AwaitingCoSign };
