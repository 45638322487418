import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as oxygenApi from '@copper/api/oxygen';
import { normalizePledges } from '@copper/entities/oxygen-pledges/pledges-normalize';
export const fetchPledges = createAsyncThunk('oxygen-pledge/get', async (params, thunkAPI) => {
    try {
        const response = await oxygenApi.getPledges(params);
        return response.data.pledges;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createPledge = createAsyncThunk('oxygen-pledge/create', async (params, thunkAPI) => {
    try {
        const response = await oxygenApi.createPledge(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updatePledgeMeta = createAsyncThunk('oxygen-pledge/updateMeta', async ({ pledgeId, data }, thunkAPI) => {
    try {
        const response = await oxygenApi.updatePledgeMeta(pledgeId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addPledgeCollateral = createAsyncThunk('oxygen-pledge/addCollateral', async ({ pledgeId, data }, thunkAPI) => {
    try {
        const response = await oxygenApi.addPledgeCollateral(pledgeId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createMoreDct = createAsyncThunk('oxygen-pledge/createMoreDct', async ({ pledgeId, data }, thunkAPI) => {
    try {
        const response = await oxygenApi.createMoreDct(pledgeId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const repayPledge = createAsyncThunk('oxygen-pledge/repay', async ({ pledgeId, data }, thunkAPI) => {
    try {
        const response = await oxygenApi.repayPledge(pledgeId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const withdrawPledgeCollateral = createAsyncThunk('oxygen-pledge/withdrawCollateral', async ({ pledgeId, data }, thunkAPI) => {
    try {
        const response = await oxygenApi.withdrawPledgeCollateral(pledgeId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const oxygenPledgeSlice = createSlice({
    name: 'oxygen-pledges',
    initialState: {
        entities: {}
    },
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchPledges.fulfilled, (state, { payload }) => {
            state.entities = normalizePledges(payload);
        });
        addMatcher(isAnyOf(createPledge.fulfilled, updatePledgeMeta.fulfilled, repayPledge.fulfilled, createMoreDct.fulfilled, addPledgeCollateral.fulfilled, withdrawPledgeCollateral.fulfilled), (state, { payload }) => {
            state.entities[payload.pledgeId] = payload;
        });
    }
});
const { reducer } = oxygenPledgeSlice;
export const oxygenPledges = reducer;
