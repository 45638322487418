export const isVaultPortfolio = (portfolioType) => portfolioType === 'custody' ||
    portfolioType === 'trading-vault' ||
    portfolioType === 'fees-vault' ||
    portfolioType === 'oxygen-vault';
export const isTradingPortfolio = (portfolioType) => portfolioType === 'trading';
export const isFeesVault = (portfolioType) => portfolioType === 'fees-vault';
export const isTradingClearloopPortfolio = (portfolio) => portfolio?.portfolioType === 'trading' &&
    portfolio.extra?.treasuryId &&
    portfolio.extra?.treasuryId !== 'default';
export const isExternalPortfolio = (portfolioType) => portfolioType === 'external';
export const isClearloopPortfolio = (portfolioType) => portfolioType === 'clearloop';
export const isOxygenPortfolio = (portfolioType) => portfolioType === 'oxygen-vault';
export const isThirdPartyWalletPortfolio = (portfolioType) => portfolioType === 'external-wallets';
// Find ClearLoop account of CL provider for portfolio participating in CL provided by another Copper platform client
export const getSelfClearloopProviderAccount = (clearloopExchanges, portfolio) => {
    if (!portfolio)
        return;
    const provider = clearloopExchanges.find((exchange) => exchange.exchangeAccounts.find((account) => account.delegatedOrganizationId === portfolio?.extra?.delegatedOrganizationId));
    return provider?.exchangeAccounts?.find((account) => account.delegatedOrganizationId === portfolio?.extra?.delegatedOrganizationId &&
        account.accountType === 'clearloop' &&
        account.unlockDisabledFor);
};
export const isClearloopSubAccount = (portfolio) => portfolio?.portfolioType === 'external' && Boolean(portfolio?.extra?.clearLoopPortfolioId);
export const isExternalCustodianClearloop = (portfolio) => isClearloopPortfolio(portfolio?.portfolioType) && !portfolio?.extra?.parentPortfolioId; // Komainu
export const isMultiCustodySettlementPortfolio = (portfolioType) => portfolioType === 'multi-custody-settlement'; // Bitgo
export const isMultiChainVault = (portfolio) => portfolio?.extra?.isMultiChain || false;
export const getPortfolioLabel = (portfolioType, exchangeName) => {
    switch (portfolioType) {
        case 'custody':
            return 'Copper vault account';
        case 'oxygen-vault':
        case 'trading-vault':
            return 'Copper trading vault account';
        case 'trading':
            return 'Copper trading account';
        case 'external':
            return `${exchangeName || 'Exchange'} account`;
        case 'clearloop':
            return `Copper ClearLoop ${exchangeName || ''} account`;
        case 'external-address':
            return 'Out of Copper ecosystem';
        default:
            return '';
    }
};
