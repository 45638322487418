import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ButtonIcon, IconClose, IconCross, IconSearch, Input } from '@copper/ui-kit';
import s from './SearchField.module.css';
const SearchField = ({ autoFocus = false, value, placeholder, onChange, view = 'round', size = 's', hasFocus = true, testId, showSearchField, onShowSearchField, searchButtonRef, disabled, ...restProps }) => {
    const isHiddenField = typeof showSearchField !== 'undefined';
    const rightSection = () => {
        if ((!value.length && !onShowSearchField) || disabled) {
            return null;
        }
        const handleClickButton = () => {
            onChange('');
            onShowSearchField?.(false);
        };
        return (_jsx("div", { className: s.closeButton, children: _jsx(ButtonIcon, { view: "ghost", onClick: handleClickButton, children: isHiddenField ? _jsx(IconClose, { width: "20", height: "20" }) : _jsx(IconCross, {}) }) }));
    };
    if (isHiddenField && !showSearchField) {
        return (_jsx(ButtonIcon, { ref: searchButtonRef, view: view === 'round' ? 'round' : 'default', tooltipLabel: "Search", onClick: () => onShowSearchField?.(true), testId: `icon-${testId}`, children: _jsx(IconSearch, {}) }));
    }
    return (_jsx(Input, { testId: testId, autoFocus: autoFocus, size: size, view: view, value: value, disabled: disabled, leftSection: _jsx(IconSearch, {}), rightSection: rightSection(), placeholder: placeholder, hasFocus: hasFocus, onChange: (event) => {
            onChange(event.target.value);
        }, ...restProps }));
};
export { SearchField };
