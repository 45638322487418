import { getUserZendeskWidgetToken } from '@copper/api/users';
let isUserIdentifiedToZendesk = false;
export const setupZendeskWidget = ({ email, user, organizationId }) => {
    try {
        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    suppress: false,
                    connectOnPageLoad: false,
                    tags: ['org-' + organizationId, 'jwt-auth'],
                    departments: {
                        select: organizationId
                    }
                },
                authenticate: {
                    chat: {
                        jwtFn: async (callback) => {
                            try {
                                const { data: { token } } = await getUserZendeskWidgetToken(user?.userId ?? '');
                                token && callback(token);
                            }
                            catch (err) {
                                console.error(err);
                            }
                        }
                    }
                }
            }
        });
        window.zE('webWidget', 'chat:reauthenticate');
        if (email && organizationId && !isUserIdentifiedToZendesk) {
            window.zE('webWidget', 'identify', {
                name: `${user.firstName} ${user?.lastName ?? ''}`,
                email,
                organization: organizationId
            });
            isUserIdentifiedToZendesk = true;
        }
    }
    catch (e) {
        console.error(e);
    }
};
export const hideZendeskWidget = () => {
    try {
        window.zE('webWidget', 'hide');
    }
    catch (e) {
        console.error(e);
    }
};
