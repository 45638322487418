import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router';
import { useRoutingContext } from '@copper/hooks';
import { Button, IconGradientNetwork } from '@copper/ui-kit';
import s from './ExploreNetworkMessage.module.css';
const ExploreNetworkMessage = () => {
    const { setRouteContext } = useRoutingContext();
    const navigate = useNavigate();
    const goToNetwork = () => {
        setRouteContext({ name: 'closed' });
        navigate('/network', { replace: true });
    };
    return (_jsxs("div", { className: s.container, children: [_jsx(IconGradientNetwork, { width: 40, height: 40 }), _jsxs("div", { className: s.textContainer, children: [_jsx("div", { className: s.title, children: "You don\u2019t have any connections yet" }), _jsx("div", { className: s.description, children: "Explore the Copper Network to discover, connect and transact with other participants." })] }), _jsx(Button, { text: "Explore", onClick: goToNetwork })] }));
};
export { ExploreNetworkMessage };
