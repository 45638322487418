import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@copper/ui-kit';
import s from './SignTransfer.module.css';
const SignTransfer = ({ loan }) => {
    const navigate = useNavigate();
    return (_jsxs("div", { className: s.signContainer, children: [_jsx("h3", { className: s.title, children: "Transferring funds" }), _jsx("p", { className: s.description, children: "Please sign this transaction to transfer the funds. You need to sign one transaction at a time. Once this transaction has been broadcast, you can move on to the next one." }), _jsx("div", { className: s.buttonContainer, children: _jsx(Button, { text: "Sign now", onClick: () => navigate(`/accounts/all/transactions?orderId=${loan._embedded?.order?.orderId}`) }) })] }));
};
export { SignTransfer };
