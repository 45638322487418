import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
import { canImportanceTransfer, canStakeDelegation, canStakeNomination, canStakeWithAmount, canStakeWithCycles, canStakeWithRewardAddress } from '@copper/helpers/stake';
import { validateAmountField } from '@copper/helpers/validation';
export const getPoolOperationList = (currency) => {
    const poolOperationList = [
        {
            label: 'Copper',
            value: 'selectPredefined'
        },
        {
            label: 'Set address',
            value: 'connect'
        }
    ];
    if (currency?.currency === 'SOL') {
        poolOperationList.push({
            label: 'Marinade Native',
            value: 'marinade'
        });
    }
    return poolOperationList;
};
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().shape({
        portfolioId: Yup.string().required('Select an account.')
    }),
    amount: Yup.string()
        .when('currency', {
        is: (currency) => canStakeWithAmount(currency),
        then: validateAmountField
    })
        .when('minStake', {
        is: (minStake) => minStake,
        then: (schema) => schema.test({
            name: 'amount',
            test: (value, { parent, createError }) => {
                const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
                const minStake = parent?.minStake;
                if (new BigNumber(value ?? 0).minus(minStake ?? 0).gte(0)) {
                    return true;
                }
                return createError({
                    message: `The minimum you can stake is ${minStake} ${currency}.`
                });
            }
        })
    })
        .when('maxStake', {
        is: (maxStake) => maxStake,
        then: (schema) => schema.test({
            name: 'amount',
            test: (value, { parent, createError }) => {
                const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
                const maxStake = parent?.maxStake;
                if (new BigNumber(value ?? 0).minus(maxStake ?? 0).lte(0)) {
                    return true;
                }
                return createError({
                    message: `The maximum you can stake is ${maxStake} ${currency}.`
                });
            }
        })
    })
        .test('amountIncrementValidation', 'Amount must be multiple of 32 ETH.', (value, { parent }) => {
        const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
        if (currency === 'ETH' && new BigNumber(value ?? 0).mod(32).toNumber()) {
            return false;
        }
        return true;
    }),
    poolId: Yup.string().when(['currency', 'predefinedPool'], {
        is: (currency, predefinedPool) => canStakeDelegation(currency) &&
            !canStakeNomination(currency) &&
            !predefinedPool &&
            currency?.currency !== 'ETH',
        then: (schema) => schema.required('Enter a validator address.')
    }),
    remoteAccountPublicKey: Yup.string().when('currency', {
        is: (currency) => canImportanceTransfer(currency),
        then: (schema) => schema.required('Enter a remote account public key.')
    }),
    period: Yup.number().when('currency', {
        is: (currency) => canStakeWithCycles(currency),
        then: (schema) => schema
            .required('Enter a number of cycles.')
            .min(1, 'Value must be greater or equal to 1')
            .max(12, 'Value must be less or equal to 12')
    }),
    rewardAddress: Yup.string().when('currency', {
        is: (currency) => canStakeWithRewardAddress(currency),
        then: (schema) => schema.required('Enter a reward address.')
    })
});
export const getBlockchainTransactionType = (currency, poolOperation) => {
    if (canImportanceTransfer(currency))
        return 'importance-transfer';
    if (poolOperation === 'marinade')
        return 'stake-nomination';
    return 'stake-delegation';
};
const rewardCurrency = {
    BTC: 'CORE',
    STX: 'BTC'
};
export const getRewardCurrency = (currency) => {
    return rewardCurrency[currency.currency] ?? currency.mainCurrency;
};
