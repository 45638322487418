import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountsByOrganizationId } from '@copper/entities/accounts/accounts-reducer';
import { fetchClearloopExchanges } from '@copper/entities/dictionaries/dictionaries-reducer';
import { fetchOrganization, fetchOrganizationFees, fetchOrganizationInfo, fetchOrganizationRestrictedResources } from '@copper/entities/organizations/organizations-reducer';
import { fetchClientAssignableGroups } from '@copper/entities/permissions/permissions-reducer';
import { fetchPortfolios } from '@copper/entities/portfolios/portfolios-reducer';
import { fetchFavoritePortfolios } from '@copper/entities/revault/revault-reducer';
import { selectUser } from '@copper/entities/user/user-selector';
import { fetchWallets, resetWallets } from '@copper/entities/wallets/wallets-reducer';
import { setKeyValue } from '@copper/utils/localstorage';
export const useFetchOrganization = () => {
    const dispatch = useDispatch();
    const { userId, isAdmin } = useSelector(selectUser);
    return async (organizationId) => {
        try {
            dispatch(resetWallets());
            await Promise.all([
                dispatch(fetchAccountsByOrganizationId(organizationId)).unwrap(),
                dispatch(fetchOrganization({ organizationId })).unwrap(),
                dispatch(fetchPortfolios({ organizationId })).unwrap(),
                dispatch(fetchWallets({ organizationId, nonEmpty: true })).unwrap(),
                dispatch(fetchClearloopExchanges(organizationId)).unwrap(),
                dispatch(fetchOrganizationFees(organizationId)).unwrap(),
                dispatch(fetchOrganizationInfo(organizationId)).unwrap(),
                dispatch(fetchClientAssignableGroups(organizationId)).unwrap(),
                dispatch(fetchOrganizationRestrictedResources(organizationId)).unwrap()
            ]);
            if (!isAdmin) {
                setKeyValue(`organization_${userId}`, { organizationId });
                dispatch(fetchFavoritePortfolios(organizationId)).unwrap();
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    };
};
