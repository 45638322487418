import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { formatInTimeZone } from 'date-fns-tz';
import { InfoList, InfoRow } from '@copper/components';
import { selectUnpaidInvoicesByOrganisation, selectUnpaidInvoicesTotalBalance } from '@copper/entities/invoices/invoices-selector';
import { Button, Popup, PopupHeader } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { formatFiat } from '@copper/utils';
import s from './InvoiceListPopup.module.css';
const InvoiceListPopup = ({ onClose, onSelect }) => {
    const unpaidInvoices = useSelector(selectUnpaidInvoicesByOrganisation);
    const unpaidTotalBalance = useSelector(selectUnpaidInvoicesTotalBalance);
    return (_jsxs(Popup, { onClose: onClose, closeOnBackdropClick: true, children: [_jsx(PopupHeader, { title: "Invoice payment" }), _jsxs(PopupContent, { children: [_jsx("p", { className: s.title, children: "Total to pay (USD)" }), _jsx("p", { className: s.total, children: formatFiat(unpaidTotalBalance) }), _jsx("div", { className: s.infoList, children: unpaidInvoices.map(({ alreadyPaid, invoice }) => {
                            const { amount, invoiceId, period } = invoice;
                            const outstandingAmount = new BigNumber(amount).minus(alreadyPaid).toFixed();
                            return (_jsx(InfoList, { title: formatInTimeZone(parseInt(period), 'UTC', 'MMMM yyyy'), children: _jsx(InfoRow, { title: _jsxs(_Fragment, { children: [_jsx("div", { className: s.infoTitle, children: "Total amount" }), _jsx("div", { className: s.infoAmount, children: formatFiat(outstandingAmount) })] }), children: _jsx(Button, { size: "s", text: "View details", view: "primary", onClick: () => onSelect(invoice) }) }) }, invoiceId));
                        }) })] })] }));
};
export { InvoiceListPopup };
