import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { AppDownloadButton } from '@copper/components/AppDownloadButton';
import { updateDeferredLegacyAppVersion, updateDeferredNewAppVersion } from '@copper/entities/custody/custody-reducer';
import { selectLegacyAppVersion, selectNewAppVersion, selectShowLegacyAppNotification, selectShowNewAppNotification } from '@copper/entities/custody/custody-selector';
import { ButtonLink } from '@copper/ui-kit';
import legacyAppIcon from './img/legacy-app.svg';
import newAppIcon from './img/new-app.svg';
import s from './CustodyUpdatePopup.module.css';
export const CustodyUpdatePopup = ({ isNewApp }) => {
    const dispatch = useDispatch();
    const newAppVersion = useSelector(selectNewAppVersion);
    const legacyAppVersion = useSelector(selectLegacyAppVersion);
    const showNewAppNotification = useSelector(selectShowNewAppNotification);
    const showLegacyAppNotification = useSelector(selectShowLegacyAppNotification);
    if ((isNewApp && !showNewAppNotification) || (!isNewApp && !showLegacyAppNotification)) {
        return null;
    }
    const onLinkClick = () => {
        if (isNewApp) {
            dispatch(updateDeferredNewAppVersion(newAppVersion));
        }
        else {
            dispatch(updateDeferredLegacyAppVersion(legacyAppVersion));
        }
    };
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.arrow }), _jsx("img", { src: isNewApp ? newAppIcon : legacyAppIcon, className: s.cuLogo, alt: "" }), _jsxs("h2", { className: s.header, children: ["Your version of Copper Unlimited ", isNewApp ? 'New' : '', " is out of date"] }), _jsxs("p", { className: s.description, children: ["A new and improved version of Copper Unlimited ", isNewApp ? 'New' : '', " is ready for download. Please install the latest version."] }), _jsx("div", { className: s.downloadContainer, children: _jsx(AppDownloadButton, { fullWidth: true, type: isNewApp ? 'newCustody' : 'legacyCustody' }) }), _jsx(ButtonLink, { size: "s", text: "Ask me later", onClick: onLinkClick })] }));
};
