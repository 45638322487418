import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Network } from '@copper/components';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { hasAdditionalFields } from '@copper/helpers';
import { Button, FormControl, FormLabel, Input } from '@copper/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './helpers';
import s from './AddExchangeAddressForm.module.css';
const mapViewToButtonView = {
    primary: 'secondary',
    tool: 'tool'
};
const AddExchangeAddressForm = ({ currency, isSubmitting, view = 'primary', onCancel, mainCurrencyId, onSubmit, portfolioType }) => {
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const { control, formState: { errors }, handleSubmit, watch, trigger } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            address: '',
            mainCurrency: mainCurrencyId,
            portfolioType
        }
    });
    const { mainCurrency } = watch();
    const mainCurrencies = useMemo(() => currenciesEntities[currency ?? '']?.mainCurrencies || [], [currency, currenciesEntities]);
    useEffect(() => {
        if (mainCurrencies.length === 1) {
            trigger('mainCurrency');
        }
    }, [mainCurrencies, trigger]);
    const handleOnSubmit = (values) => {
        const { mainCurrency, ...addressDetails } = values;
        onSubmit({
            ...(addressDetails.address && { depositAddresses: { [mainCurrency]: addressDetails } })
        });
    };
    return (_jsxs("form", { className: s.container, onSubmit: handleSubmit(handleOnSubmit), children: [!mainCurrencyId && (_jsx(Network, { view: view, control: control, currency: currency, errorMessage: errors?.mainCurrency?.message, name: "mainCurrency", required: true })), _jsxs(FormControl, { errorMessage: errors?.address?.message, children: [_jsxs(FormLabel, { children: [currency, mainCurrency && mainCurrency !== currency ? `-${mainCurrency}` : '', " address"] }), _jsx(Controller, { control: control, name: "address", render: ({ field }) => _jsx(Input, { size: "l", view: view, ...field }) })] }), hasAdditionalFields('memo', tokens, currency, mainCurrency) && (_jsxs(FormControl, { errorMessage: errors?.memo?.message, children: [_jsx(FormLabel, { children: "Memo" }), _jsx(Controller, { control: control, name: "memo", render: ({ field }) => _jsx(Input, { size: "l", view: view, ...field }) })] })), hasAdditionalFields('destinationTag', tokens, currency, mainCurrency) && (_jsxs(FormControl, { errorMessage: errors?.destinationTag?.message, children: [_jsx(FormLabel, { children: "Destination tag" }), _jsx(Controller, { control: control, name: "destinationTag", render: ({ field }) => _jsx(Input, { size: "l", view: view, ...field }) })] })), _jsxs("div", { className: s.buttons, children: [_jsx(Button, { text: "Cancel", onClick: onCancel, view: mapViewToButtonView[view] }), _jsx(Button, { text: "Save", type: "submit", view: "primary", loading: isSubmitting })] })] }));
};
export { AddExchangeAddressForm };
