import { jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import cn from 'classnames';
import s from './Ratio.module.css';
export const Ratio = ({ value, minValue = 1.05, size = 'm', isZeroValid = false }) => {
    const ratio = Number(value);
    const minRatio = Number(minValue);
    const ratioStatus = useMemo(() => {
        if (!value || (isZeroValid && ratio === 0)) {
            return 'neutral';
        }
        if (ratio >= minRatio) {
            return 'healthy';
        }
        if (ratio < minRatio && ratio >= minRatio - 0.05) {
            return 'warn';
        }
        return 'unhealthy';
    }, [minRatio, ratio, value]);
    return (_jsxs("span", { className: cn(s[`ratio_${ratioStatus}`], s[`size_${size}`]), children: [value ? (ratio * 100).toFixed(2) : '0.00', "%"] }));
};
