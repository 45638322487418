import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account to fund the loan'),
    amount: Yup.string()
        .required('Enter a loan amount.')
        .test('amount', (value = '0', { parent, createError }) => {
        const { availableBalance = '0', currency } = parent;
        return new BigNumber(availableBalance).minus(value).gte(0)
            ? true
            : createError({
                message: `Insufficient ${currency?.currency ?? ''} balance.`
            });
    }),
    counterparty: Yup.object().required('Select a loan broker'),
    currency: Yup.object().required('Select an asset'),
    mainCurrency: Yup.string().required('Select a network'),
    openTerm: Yup.boolean(),
    maturityDate: Yup.string().when('openTerm', {
        is: (openTerm) => !openTerm,
        then: (schema) => schema.required('Select a maturity date')
    })
});
