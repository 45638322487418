import { request } from '@copper/utils';
export const getWebhookSubscriptions = ({ organizationId, ...params }) => request.get(`/organizations/${organizationId}/webhooks`, { params });
export const createWebhookSubscription = ({ organizationId, ...data }) => request.post(`/organizations/${organizationId}/webhooks`, data, {
    headers: {
        'Content-Type': 'application/vnd.create-webhook-subscription+json'
    }
});
export const deleteWebhookSubscription = ({ organizationId, subscriptionId }) => request.delete(`/organizations/${organizationId}/webhooks/${subscriptionId}`);
export const pauseWebhookSubscription = ({ organizationId, subscriptionId, ...data }) => request.patch(`/organizations/${organizationId}/webhooks/${subscriptionId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.pause-webhook-subscription+json'
    }
});
export const updateWebhookSubscription = ({ organizationId, subscriptionId, ...data }) => request.patch(`/organizations/${organizationId}/webhooks/${subscriptionId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-webhook-subscription+json'
    }
});
export const getWebhookEvents = ({ organizationId, subscriptionId, ...params }) => request.get(`/organizations/${organizationId}/webhooks/${subscriptionId}/events`, {
    params
});
export const sendWebhookEvents = ({ organizationId, subscriptionId, ...data }) => request.patch(`/organizations/${organizationId}/webhooks/${subscriptionId}/events`, data, {
    headers: {
        'Content-Type': 'application/vnd.send-webhook-events+json'
    }
});
