import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { AssetInfo } from '@copper/components/AssetInfo';
import { EmptyFallback } from '@copper/components/EmptyFallback';
import { Popup, PopupHeader } from '@copper/ui-kit';
import { TransferDryOrderEditForm } from './EditForm';
import { TransferDryOrderPreview } from './Preview';
import s from './Popup.module.css';
const BulkTransferPopup = () => {
    const { modalContext, setModalContext, modalOrderData } = useBulkOrder();
    return (_jsxs(Popup, { onClose: () => setModalContext(), children: [_jsxs(PopupHeader, { rightSection: modalContext?.mode === 'read' && (_jsx(AssetInfo, { currency: modalOrderData?.baseCurrency ?? '' })), children: [modalContext?.mode === 'read' && (_jsxs("div", { children: [_jsx("div", { className: s.headerTitle, children: _jsx(EmptyFallback, { children: modalOrderData?.orderType }) }), _jsx("div", { className: s.headerSubTitle, children: "Transfer" })] })), modalContext?.mode === 'edit' && _jsx("div", { className: s.headerTitle, children: "Edit transfer" })] }), modalOrderData && modalContext?.mode === 'read' && _jsx(TransferDryOrderPreview, {}), modalOrderData && modalContext?.mode === 'edit' && _jsx(TransferDryOrderEditForm, {})] }));
};
export { BulkTransferPopup };
