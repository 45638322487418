import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NOT_ACTIVATED } from '@copper/components/Forms/TransferPortfolio/constants';
import { getAvailableBalance } from '@copper/components/Forms/TransferPortfolio/helpers';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
export const useInactiveAccountCheck = (portfolio, currencyId, mainCurrencyId) => {
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    return useMemo(() => portfolio &&
        getAvailableBalance({
            walletsByPortfolioId,
            currenciesEntities,
            tokens,
            portfolio,
            currencyId,
            mainCurrencyId
        }) === NOT_ACTIVATED, [portfolio, walletsByPortfolioId, currenciesEntities, currencyId, mainCurrencyId, tokens]);
};
