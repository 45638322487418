import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as legalEntitiesApi from '@copper/api/legalEntities';
import { normalizeLegalEntities } from '@copper/entities/legalEntities/legalEntities-normalize';
export const fetchLegalEntities = createAsyncThunk('legalEntities/fetchLegalEntities', async (_, thunkAPI) => {
    try {
        const response = await legalEntitiesApi.getLegalEntities();
        return response.data.legalEntities;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const legalEntitiesSlice = createSlice({
    name: 'legalEntities',
    initialState: {
        entities: {}
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchLegalEntities.fulfilled, (state, { payload }) => {
            const { entities } = normalizeLegalEntities(payload);
            state.entities = entities;
        });
    }
});
export const legalEntities = legalEntitiesSlice.reducer;
