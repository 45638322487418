import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { ButtonIcon, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import s from './InfoRow.module.css';
const InfoRowCopy = ({ title, className, contentUnderTitle = false, titleColor = 'ghost', content, children }) => {
    const { openSnackbar } = useSnackBar();
    if (!content)
        return null;
    return (_jsxs("div", { className: cn(s.row, contentUnderTitle && s.rowContentUnder, className), children: [_jsx("div", { className: cn(s.title, s[`title_color_${titleColor}`]), children: title }), _jsxs("div", { className: s.copyContent, children: [children || content, _jsx(ButtonIcon, { onClick: (e) => {
                            e.stopPropagation();
                            copy(content, () => openSnackbar({ type: 'success', message: 'Copied' }));
                        }, tooltipLabel: "Copy to clipboard", children: _jsx(_Fragment, { children: _jsx(IconClipboard, {}) }) })] })] }));
};
export { InfoRowCopy };
