import { fuzzySearch } from '@copper/ui-kit';
export const searchAddressOwners = (owners = [], searchQuery) => {
    if (searchQuery === '')
        return owners;
    return fuzzySearch({
        items: owners,
        keys: ['legalName'],
        searchQuery
    });
};
export const searchFiatAddresses = (addresses = [], searchQuery) => {
    if (searchQuery === '')
        return addresses;
    return fuzzySearch({
        items: addresses,
        keys: ['bankAccountName'],
        searchQuery
    });
};
export const isWithdrawalAddress = (address) => address.addressFolders?.find((folder) => ['external', 'withdraw', 'default'].includes(folder.addressFolderId));
export const isDepositAddress = (address) => address.addressFolders?.find((folder) => folder.addressFolderId === 'deposit');
export const getOwnerAddress = (owner) => {
    let address = '';
    if (owner.street)
        address = owner.street;
    if (owner.buildingNumber)
        address = address + `, ${owner.buildingNumber}`;
    if (owner.city)
        address = address + `, ${owner.city}`;
    if (owner.postalCode)
        address = address + `, ${owner.postalCode}`;
    return address;
};
export const isVisualProofRequired = (address) => address.ownershipVerificationStatus === 'processing' &&
    address.travelRuleInfo?.ownershipVerificationMethod === 'visual-proof';
export const isComplianceReviewRequired = (address) => address.addressInvestigationStatus === 'alerting';
export const getAddressActionColors = ({ address, canUpdate }) => {
    if (address.pendingApproval) {
        if (canUpdate) {
            return { awaitingTextColor: 'oxide', gradientColor: 'oxide' };
        }
        return { awaitingTextColor: 'orange', gradientColor: 'orange' };
    }
    return { awaitingTextColor: 'orange', gradientColor: 'base' };
};
export const getFiltersLength = (filters) => Object.values(filters).reduce((acc, filter) => {
    if (Array.isArray(filter) && !filter.length)
        return acc;
    if (filter)
        return acc + 1;
    return acc;
}, 0);
export const getAcceptTokensProtocol = (asset, network) => {
    if (!asset)
        return;
    const currency = typeof asset === 'string' ? asset : asset.currency;
    if (currency === 'BNB' && network === 'BSC')
        return 'BEP-20';
    if (network === 'ARB' && currency === 'ETH')
        return 'ERC-20';
    if (network === 'OP' && currency === 'ETH')
        return 'OP-20';
    if (currency !== network)
        return;
    switch (network) {
        case 'ETH':
            return 'ERC-20';
        case 'MATIC':
            return 'ERC-20';
        case 'AVAXC':
            return 'ARC-20';
        case 'FTM':
            return 'FRC-20';
        case 'TRX':
            return 'TRC-20';
        case 'POL':
            return 'ERC-20';
    }
};
export const getAdminAddressStatusColor = (address) => {
    switch (address?.verificationStatus) {
        case 'verified':
            return {
                status: 'Verified',
                color: 'green'
            };
        case 'processing':
        case 'action-required':
            return {
                status: 'Processing',
                color: 'orange'
            };
        case 'rejected':
            return {
                status: 'Rejected',
                color: 'red'
            };
        case 'unverified':
            return {
                status: 'Not verified',
                color: 'red'
            };
        default:
            return {
                status: address?.verificationStatus?.split('-').join(' '),
                color: 'base'
            };
    }
};
export const AVAILABLE_VERIFICATION_STATUSES = [
    'not-required',
    'unverified',
    'processing',
    'action-required',
    'verified'
];
export const getIsWhitelisted = (address) => {
    return address.pendingApproval
        ? typeof address.extra?.previousIsWhitelist === 'boolean'
            ? address.extra.previousIsWhitelist
            : address.isWhitelist
        : address.isWhitelist;
};
