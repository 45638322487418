import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetInfo, EmptyFallback } from '@copper/components';
import { LoanStatus } from '@copper/components/Loans';
import { LoanLtv } from '@copper/components/Loans/LoanLtv';
import { displayMaturity, displayTimeToMaturity, getCounterparty } from '@copper/components/Loans/helpers';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { calculateReportingValue } from '@copper/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Td, TdDoubleRowContent, Tr } from '@copper/ui-kit';
import { formatCurrency, formatFiat } from '@copper/utils';
import s from '@copper/components/Loans/LoanList/LoanList.module.css';
const ActiveLoanListItem = ({ loan, onClick, view }) => {
    const { getDecimals } = useCurrenciesInfo();
    const rates = useSelector(selectRates);
    const organization = useSelector(selectCurrentOrganization);
    const rate = rates[loan.terms.loanCurrency];
    const collateralRate = loan.terms.collateralCurrency && rates[loan.terms.collateralCurrency];
    const collateralValue = loan.securedCollateralAmount || '0';
    return (_jsxs(Tr, { className: s.row, onClick: onClick, children: [_jsx(Td, { children: _jsx(AssetInfo, { currency: loan.terms.loanCurrency }) }), _jsx(Td, { align: "right", children: _jsx(TdDoubleRowContent, { text: formatCurrency(loan.terms.amount, {
                        decimalPlaces: getDecimals(loan.terms.loanCurrency),
                        zeroFormat: '0.0000'
                    }), subText: rate
                        ? formatFiat(calculateReportingValue(loan.terms.amount ?? '0', rate), { short: true })
                        : '-' }) }), _jsx(Td, { align: "right", children: _jsx(EmptyFallback, { children: collateralValue !== '0' && (_jsx(TdDoubleRowContent, { text: formatCurrency(collateralValue, {
                            decimalPlaces: getDecimals(loan.terms.collateralCurrency),
                            symbol: ` ${loan.terms.collateralCurrency}`,
                            zeroFormat: '0.0000'
                        }), subText: collateralRate
                            ? formatFiat(calculateReportingValue(collateralValue, collateralRate), {
                                short: true
                            })
                            : '-' })) }) }), _jsx(Td, { children: loan.terms.termType === 'open' ? ('Open') : (_jsx(TdDoubleRowContent, { text: displayMaturity(loan), subText: displayTimeToMaturity(loan) })) }), _jsx(Td, { children: getCounterparty(organization?.organizationId, loan) }), _jsx(Td, { align: "right", children: _jsx(LoanLtv, { loan: loan }) }), _jsx(Td, { align: "right", children: _jsx(LoanStatus, { loan: loan, view: view }) })] }, loan.loanId));
};
export { ActiveLoanListItem };
