import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoRow } from '@copper/components';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName, getCurrencyProtocol } from '@copper/helpers';
import { convertTypeToDisplay } from '@copper/helpers/loans';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Button } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import s from './Summary.module.css';
export const Summary = ({ onSubmit }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { tokens } = useSelector(selectCurrencies);
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const { loanCurrency, loanNetworkCurrency, amount, counterparty, collateralNetworkCurrency, collateralAmount, collateralCurrency, marginCallPercentage, rebalancePercentage, defaultPercentage } = getValues();
    const loanCurrencyProtocol = getCurrencyProtocol(loanCurrency.currency || '', loanNetworkCurrency || '', tokens);
    const collateralCurrencyProtocol = getCurrencyProtocol(collateralCurrency.currency || '', collateralNetworkCurrency || '', tokens);
    return (_jsxs("div", { className: s.mainWrapper, children: [_jsxs("div", { className: s.summaryContent, children: [_jsx("h2", { className: s.summaryTitle, children: "Summary" }), _jsx(InfoRow, { title: "Loan type", titleColor: "ghost", children: convertTypeToDisplay('secured-pledged') }), _jsx(InfoRow, { title: "Asset to lend", titleColor: "ghost", children: _jsxs("div", { className: s.assetRow, children: [_jsx("p", { children: loanCurrency.name }), _jsx("p", { className: s.assetRowCurrency, children: loanCurrency.currency }), _jsx(AssetIcon, { currency: loanCurrency.currency })] }) }), loanNetworkCurrency && (_jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsxs("div", { className: s.assetRow, children: [_jsx("p", { children: loanNetworkCurrency }), _jsxs("p", { className: s.assetRowCurrency, children: [getCurrencyName(loanNetworkCurrency || '', currenciesEntities), ' ', loanCurrencyProtocol && `(${loanCurrencyProtocol?.toUpperCase()})`] }), _jsx(AssetIcon, { currency: loanNetworkCurrency })] }) })), _jsxs(InfoRow, { title: "Loan amount", titleColor: "ghost", children: [formatCurrency(amount || '0', {
                                decimalPlaces: getDecimals(loanCurrency.currency, { useDictionaryDecimal: true })
                            }), ' ', loanCurrency.currency] }), _jsx(InfoRow, { title: "Borrowing counterparty", titleColor: "ghost", children: counterparty.organizationName }), _jsx(InfoRow, { title: "Collateral", titleColor: "ghost", children: _jsxs("div", { className: s.assetRow, children: [_jsx("p", { children: collateralCurrency.name }), _jsx("p", { className: s.assetRowCurrency, children: collateralCurrency.currency }), _jsx(AssetIcon, { currency: collateralCurrency.currency })] }) }), collateralNetworkCurrency && (_jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsxs("div", { className: s.assetRow, children: [_jsx("p", { children: collateralNetworkCurrency }), _jsxs("p", { className: s.assetRowCurrency, children: [getCurrencyName(collateralNetworkCurrency || '', currenciesEntities), ' ', collateralCurrencyProtocol && `(${collateralCurrencyProtocol?.toUpperCase()})`] }), _jsx(AssetIcon, { currency: collateralNetworkCurrency })] }) })), _jsxs(InfoRow, { title: "Collateral amount", titleColor: "ghost", children: [formatCurrency(collateralAmount || '0', {
                                decimalPlaces: getDecimals(collateralCurrency.currency, { useDictionaryDecimal: true })
                            }), ' ', collateralCurrency.currency] }), _jsxs(InfoRow, { title: "Rebalance", titleColor: "ghost", children: [rebalancePercentage, "%"] }), _jsxs(InfoRow, { title: "Margin call", titleColor: "ghost", children: [marginCallPercentage, "%"] }), _jsxs(InfoRow, { title: "Default", titleColor: "ghost", children: [defaultPercentage, "%"] })] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "new-loan-submit-button" }) })] }));
};
