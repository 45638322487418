import { omit } from 'lodash-es';
import { getInitiatorFiatStep, getLiteClientFiatStep } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/Flows/Fiat/helpers';
import { getInitiatorFopStep, getLiteClientFopStep } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/Flows/Fop/helpers';
import { getInitiatorPvpStep, getLitePvpFlowStep } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/Flows/Pvp/helpers';
import { AwaitingAddressReview } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/AwaitingAddressReview';
import { AwaitingCoSign } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/AwaitingCoSign';
import { AwaitingCounterpartyAddress } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/AwaitingCounterpartyAddress';
import { AwaitingCounterpartyDeposit } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/AwaitingCounterpartyDeposit';
import { AwaitingInitiatorDeposit } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/AwaitingInitiatorDeposit';
import { AwaitingOfferReview } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/AwaitingOfferReview';
import { CounterpartyDeposit } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/CounterpartyDeposit';
import { OfferReview } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/OfferReview';
import { Processing } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/Processing';
import { ReviewCounterpartyAddress } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/ReviewCounterpartyAddress';
import { SetReceivingAddress } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/SetReceivingAddress';
import { SetReturningAddress } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/SetReturningAddress';
import { StartSettlement } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/StartSettlement';
import { StartSign } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/steps/StartSign';
import { isCurrencyFiat } from '@copper/helpers';
export const getDeliveryTypeOptions = (isFiatSettlement = false) => {
    const options = [
        {
            value: 'free-of-payment',
            label: 'Free of payment',
            tooltipInfo: 'When you select Free of Payment (FoP), payment or receipt of an asset is not linked to a corresponding transfer of funds. This settlement method does not require funding to accept or initiate settlement.',
            tooltipPlacement: 'bottom'
        }
    ];
    if (!isFiatSettlement)
        options.push({
            value: 'payment-vs-payment',
            label: 'Payment versus payment',
            tooltipInfo: 'Payment versus Payment (PvP) guarantees the simultaneous transfer of assets.',
            tooltipPlacement: 'top'
        });
    return options;
};
export const isFiatSettlement = ({ currencyEntities, baseCurrency = '', quoteCurrency = '' }) => isCurrencyFiat(currencyEntities, baseCurrency) || isCurrencyFiat(currencyEntities, quoteCurrency);
export const getBaseStepStatus = (currentStepIndex, index) => {
    if (currentStepIndex > index) {
        return 'completed';
    }
    if (currentStepIndex === index) {
        return 'processing';
    }
    return 'awaiting';
};
export const isReceivingCurrencyFiat = (order, currencyEntities) => (order.orderType === 'sell' && isCurrencyFiat(currencyEntities, order.quoteCurrency)) ||
    (order.orderType === 'buy' && isCurrencyFiat(currencyEntities, order.baseCurrency));
const getStepsToSkip = (order, isReceivingFiat, isFiatSettlement) => {
    const stepsToSkip = {
        ...(isFiatSettlement ? { counterpartyDeposit: true } : { awaitingCounterpartyDeposit: true }),
        ...(isFiatSettlement && !isReceivingFiat && { addressReview: true }),
        ...(!Boolean(order?.extra?.coSignersNumber) && { awaitingCoSign: true }),
        ...(order.portfolioType !== 'trading-vault' && { sign: true }),
        ...(order.extra?.deliveryType !== 'free-of-payment' && { startSettlement: true })
    };
    return Object.keys(stepsToSkip);
};
const getLiteStepsToSkip = (isReceivingFiat, isFiatSettlement) => {
    const stepsToSkip = isFiatSettlement && {
        ...(!isReceivingFiat && { setReturningAddress: true }),
        ...(isReceivingFiat && { setReceivingAddress: true })
    };
    return Object.keys(stepsToSkip);
};
export const getFlowType = (order, isFiatSettlement) => {
    if (isFiatSettlement)
        return 'fiat';
    return order.extra?.deliveryType || 'payment-vs-payment';
};
export const getFlowComponents = (order, isFiatSettlement, isReceivingFiat) => {
    if (order.portfolioType === 'settlement-lite') {
        return omit({
            offerReview: OfferReview,
            setReceivingAddress: SetReceivingAddress,
            setReturningAddress: SetReturningAddress,
            addressReview: AwaitingAddressReview,
            counterpartyDeposit: CounterpartyDeposit,
            awaitingDeposit: AwaitingInitiatorDeposit,
            processing: Processing,
            completed: null
        }, getLiteStepsToSkip(isReceivingFiat, isFiatSettlement));
    }
    return omit({
        awaitingCoSign: AwaitingCoSign,
        offerReview: AwaitingOfferReview,
        awaitingAddresses: AwaitingCounterpartyAddress,
        addressReview: ReviewCounterpartyAddress,
        startSettlement: StartSettlement,
        counterpartyDeposit: AwaitingCounterpartyDeposit,
        awaitingCounterpartyDeposit: AwaitingCounterpartyDeposit,
        sign: StartSign,
        processing: Processing,
        completed: null
    }, getStepsToSkip(order, isReceivingFiat, isFiatSettlement));
};
export const getLiteClientFlowStep = (order, currencyEntities, flowType) => {
    const status = order?.extra?.previousStatus || order.status;
    if (flowType === 'fiat')
        return getLiteClientFiatStep(order, currencyEntities, status);
    if (flowType === 'free-of-payment')
        return getLiteClientFopStep(order, status);
    return getLitePvpFlowStep(order, status);
};
export const getInitiatorFlowStep = (order, flowType) => {
    const status = order?.extra?.previousStatus || order.status;
    if (flowType === 'fiat')
        return getInitiatorFiatStep(order, status);
    if (flowType === 'free-of-payment')
        return getInitiatorFopStep(order, status);
    return getInitiatorPvpStep(order, status);
};
