import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import cn from 'classnames';
import { PortfolioIcon } from '@copper/components';
import { EditExchangeForm } from '@copper/components/EditExchangeForm';
import { Button } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
import s from './SubAccountList.module.css';
const SubAccountList = ({ portfolios }) => {
    const [openedPortfolio, setOpenedPortfolio] = useState();
    return (_jsxs(_Fragment, { children: [portfolios.map(({ item: portfolio, nestingLevel, next }) => {
                const autodiscoveredAccountStatus = portfolio?.extra?.autodiscoveredAccountStatus;
                return (_jsxs("div", { className: cn(s.portfolio, s[`portfolioLevel_${nestingLevel}`]), children: [_jsxs("div", { className: s.portfolioWrapper, children: [_jsxs("div", { className: s.portfolioLeft, children: [_jsx("div", { className: s.portfolioIcon, children: _jsx(PortfolioIcon, { portfolio: portfolio }) }), _jsxs("div", { className: s.nameWrapper, children: [_jsx("div", { className: s.name, children: portfolio.portfolioName }), _jsx("div", { className: s.balance, children: formatFiat(portfolio.balance, { short: true }) })] })] }), _jsx("div", { className: s.portfolioRight, children: _jsxs("div", { className: s.status, children: [autodiscoveredAccountStatus == 'completed' && (_jsx("div", { className: s.success, children: "Connected" })), autodiscoveredAccountStatus === 'information-required' && (_jsxs(_Fragment, { children: [_jsx("div", { className: s.error, children: "Info required" }), _jsx("div", { className: s.infoButton, children: _jsx(Button, { size: "s", text: "Edit", view: "secondary", onClick: () => setOpenedPortfolio(portfolio) }) })] }))] }) })] }), next && next.length > 0 && _jsx(SubAccountList, { portfolios: next })] }, portfolio.portfolioId));
            }), openedPortfolio && (_jsx(EditExchangeForm, { portfolio: openedPortfolio, onClose: () => setOpenedPortfolio(undefined) }))] }));
};
export { SubAccountList };
