import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as loanApi from '@copper/api/loans';
import { normalizeLoans } from '@copper/entities/loans/loans-normalize';
import { subscribe } from '@copper/helpers/websocket';
import { serializeParams } from '@copper/utils';
const initialState = {
    entities: {},
    summary: {},
    valuations: {
        page: '0',
        pageSize: '0',
        totalPages: '0',
        totalSize: '0',
        valuations: []
    }
};
export const fetchLoansCollection = createAsyncThunk('loans/getLoansCollection', async (organizationId, thunkAPI) => {
    try {
        return await loanApi.getLoansCollection(organizationId);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const subscribeLoans = createAsyncThunk('loans/subscribe', async (params, thunkAPI) => {
    const { onUpdate, cancelRequest, command } = params;
    const serializedPayload = serializeParams(command);
    try {
        return await subscribe({
            uri: `/loans${serializedPayload}`,
            callback: onUpdate,
            cancelRequest
        });
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchLoanValuations = createAsyncThunk('loans/getLoanValuations', async (query, thunkAPI) => {
    try {
        return await loanApi.getLoanValuations(query);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const loansSlice = createSlice({
    name: 'loans',
    initialState,
    reducers: {
        updateLoans(state, { payload }) {
            const newLoans = normalizeLoans(payload);
            state.summary = newLoans.summary;
            state.entities = { ...state.entities, ...newLoans.entities };
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchLoansCollection.fulfilled, (state, { payload }) => {
            const { entities, summary } = normalizeLoans(payload);
            state.entities = entities;
            state.summary = summary;
        });
        addCase(subscribeLoans.fulfilled, (state, { payload }) => {
            const { entities, summary } = normalizeLoans(payload.data);
            state.entities = entities;
            state.summary = summary;
        });
        addCase(fetchLoanValuations.fulfilled, (state, { payload }) => {
            state.valuations = payload;
        });
    }
});
export const { updateLoans } = loansSlice.actions;
export const loans = loansSlice.reducer;
