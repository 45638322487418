import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import s from './Resizer.module.css';
export const Resizer = ({ onResize, onResizeEnd }) => {
    const resizerRef = useRef(null);
    const [startX, setStartX] = useState();
    const [mouseDown, setMouseDown] = useState(false);
    useEffect(() => {
        const handleMouseMove = (e) => {
            const resizer = resizerRef.current;
            if (!startX || !resizer) {
                return;
            }
            const delta = startX - e.clientX;
            onResize(delta);
        };
        if (mouseDown) {
            window.addEventListener('mousemove', handleMouseMove);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [startX, mouseDown, onResize]);
    useEffect(() => {
        const handleMouseUp = () => {
            if (mouseDown) {
                onResizeEnd();
                setMouseDown(false);
                setStartX(undefined);
            }
        };
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [mouseDown, onResizeEnd]);
    const handleMouseDown = (event) => {
        event.preventDefault();
        setStartX(event.clientX);
        setMouseDown(true);
    };
    return (_jsx("div", { role: "presentation", ref: resizerRef, className: s.resizer, onMouseDown: handleMouseDown }));
};
