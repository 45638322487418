import { getInitiatorPvpStep } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/Flows/Pvp/helpers';
import { getBaseStepStatus } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/helpers';
export const getLiteClientFopStep = (order, status) => {
    switch (status) {
        case 'co-sign-require':
            return 'awaitingCoSign';
        case 'require-counterparty-approve':
            return 'offerReview';
        case 'waiting-addresses': {
            if (order.extra?.receivingAddressId && order.extra.returningAddressId)
                return 'addressReview';
            if (order.extra?.receivingAddressId)
                return 'setReturningAddress';
            return 'setReceivingAddress';
        }
        case 'waiting-funds':
        case 'ready-for-settlement':
            return 'counterpartyDeposit';
        case 'processing':
            return 'processing';
        case 'settled':
            return 'completed';
        case 'executed':
            return 'completed';
    }
};
export const getInitiatorFopStep = (order, status) => {
    if (status === 'settled')
        return 'processing';
    return getInitiatorPvpStep(order, status);
};
export const isFopStepDisabled = ({ currentStepIndex, index, order, stepName }) => {
    const isLite = order.portfolioType === 'settlement-lite';
    const isClient = order.portfolioType === 'trading';
    const isCurrentStep = index === currentStepIndex;
    if (stepName === 'stepName' && isClient && order.status === 'canceled' && isCurrentStep)
        return true;
    if ((stepName === 'awaitingDeposit' && isLite) ||
        (stepName === 'counterpartyDeposit' && isClient && isCurrentStep)) {
        if ([
            'settled',
            'executed',
            'processing',
            'waiting-counterparty-settlement',
            'ready-for-settlement',
            'waiting-funds'
        ].includes(order.status) ||
            order.extra?.correlatedOrderSettled)
            return false;
    }
    if (stepName === 'processing') {
        return true;
    }
    return currentStepIndex < index;
};
export const getFopStepStatus = ({ order, currentStepIndex, index, stepName }) => {
    const isCanceled = ['error', 'canceled', 'rejected'].includes(order.status);
    const isLite = order.portfolioType === 'settlement-lite';
    const isClient = order.portfolioType === 'trading';
    const completedStatuses = ['settled', 'executed'];
    const baseStepStatus = getBaseStepStatus(currentStepIndex, index);
    const processingStatuses = ['ready-for-settlement', 'processing', 'working', 'waiting-funds'];
    const correlatedOrderSettled = order.extra?.correlatedOrderSettled;
    if (isCanceled && currentStepIndex <= index) {
        return 'canceled';
    }
    if (stepName === 'counterpartyDeposit') {
        if (isClient) {
            if (order.extra?.correlatedOrderSettled)
                return 'completed';
            if ([...processingStatuses, 'settled'].includes(order.status) && !correlatedOrderSettled)
                return 'processing';
            return 'awaiting';
        }
        if (isLite) {
            if (completedStatuses.includes(order.status))
                return 'completed';
            if (processingStatuses.includes(order.status))
                return 'processing';
            return 'awaiting';
        }
    }
    if (stepName === 'awaitingDeposit' && isLite) {
        if (order.extra?.correlatedOrderSettled)
            return 'completed';
        if ([...processingStatuses, 'settled'].includes(order.status) && !correlatedOrderSettled) {
            return 'processing';
        }
        return 'awaiting';
    }
    if (stepName === 'startSettlement' && isClient) {
        if (completedStatuses.includes(order.status))
            return 'completed';
        if (processingStatuses.includes(order.status))
            return 'processing';
        return 'awaiting';
    }
    if (stepName === 'processing') {
        if (['settled', 'executed'].includes(order.status)) {
            return order.extra?.correlatedOrderSettled ? 'completed' : 'awaiting';
        }
        return 'awaiting';
    }
    return baseStepStatus;
};
