import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { FormControl, FormLabel, Input } from '@copper/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './helpers';
import s from './WithdrawalPassword.module.css';
const mapContextToView = {
    order: 'primary',
    summary: 'tool',
    'confirm-step': 'tool'
};
export const WithdrawalPassword = ({ formId, submitWithdrawalPassword, context = 'summary' }) => {
    const { register, formState, handleSubmit } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    });
    const { errors } = formState;
    const confirmWithdrawalPassword = ({ password }) => {
        submitWithdrawalPassword(password);
    };
    return (_jsx("form", { id: formId, onSubmit: handleSubmit(confirmWithdrawalPassword), children: _jsxs("div", { className: cn(s.container, s[`container_${context}`]), children: [context !== 'order' && _jsx("div", { className: s.title, children: "Enter withdrawal password" }), _jsx("div", { className: s.description, children: "To confirm this withdrawal, enter your organization\u2018s withdrawal password." }), _jsxs(FormControl, { errorMessage: errors.password?.message, indent: "none", children: [_jsx(FormLabel, { children: "Withdrawal password" }), _jsx(Input, { view: mapContextToView[context], type: "password", size: "l", ...register('password', { required: true }) })] }), _jsx("div", { className: s.helpText, children: "Need help? Please contact Copper's Middle Office." })] }) }));
};
