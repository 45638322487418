import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const AXL = {
    title: 'AXL',
    id: 'axl',
    qa: [
        {
            question: 'How do I stake AXL?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake AXL?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 21-day unbonding period." })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for AXL?',
            answer: _jsx("p", { children: "No, you can choose up to 100% of your available balance." })
        },
        {
            question: 'How often will I earn AXL staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Rewards for AXL are distributed each block \u2013 approximately every 6 seconds. You can check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 6 seconds" })] }) }) })] }))
        },
        {
            question: 'How can I claim my AXL staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your AXL rewards anytime by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, click the row showing your AXL staking details." }), _jsx("li", { children: "Under Rewards info, select Get rewards." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "The funds will be made available in your staking vault right away." })] }))
        }
    ]
};
