import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import s from './Slider.module.css';
const clampValue = (value, min, max) => Math.min(Math.max(value, min), max);
const Slider = ({ value = 0, min = 0, max = 100, marks = {}, step = 1, disabled, onChange, view = 'tool' }) => {
    const [grab, setGrab] = useState(0);
    const [maxPosition, setMaxPosition] = useState(0);
    const thumbRef = useRef(null);
    const sliderRef = useRef(null);
    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(handleUpdate);
        sliderRef.current && resizeObserver.observe(sliderRef.current);
        handleUpdate();
        return () => {
            sliderRef.current && resizeObserver.unobserve(sliderRef.current);
        };
    }, []);
    const handleUpdate = () => {
        if (!sliderRef.current || !thumbRef.current) {
            return;
        }
        const sliderPos = sliderRef.current['offsetWidth'];
        const handlePos = thumbRef.current['offsetWidth'];
        setMaxPosition(sliderPos - handlePos);
        setGrab(handlePos / 2);
    };
    const getValueFromPosition = useCallback((pos) => {
        const percentage = clampValue(pos, 0, maxPosition) / (maxPosition || 1);
        const baseVal = step * Math.round((percentage * (max - min)) / step);
        const value = baseVal + min;
        return clampValue(value, min, max);
    }, [maxPosition, max, min, step]);
    const getValueFromPointer = useCallback((event) => {
        const node = sliderRef.current;
        if (!node) {
            return 0;
        }
        const direction = node.getBoundingClientRect().left;
        const pos = event.clientX - direction - grab;
        return getValueFromPosition(pos);
    }, [getValueFromPosition, grab]);
    const getPositionFromValue = useCallback((value) => {
        const diffMaxMin = max - min;
        const diffValMin = value - min;
        const percentage = diffValMin / diffMaxMin;
        return Math.round(percentage * maxPosition) + grab;
    }, [grab, maxPosition, max, min]);
    const handleMouseDown = useCallback((event) => {
        if (disabled) {
            return;
        }
        onChange(getValueFromPointer(event));
    }, [disabled, getValueFromPointer, onChange]);
    const handleStart = useCallback(() => {
        if (disabled) {
            return;
        }
        const mouseMove = (event) => {
            onChange(getValueFromPointer(event));
        };
        const mouseUp = () => {
            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
        };
        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', mouseUp);
    }, [disabled, getValueFromPointer, onChange]);
    const handleKeyDown = useCallback((event) => {
        if (disabled) {
            return;
        }
        switch (event.key) {
            case 'ArrowRight':
            case 'ArrowUp':
                event.preventDefault();
                onChange(value + step > max ? max : value + step);
                break;
            case 'ArrowLeft':
            case 'ArrowDown':
                event.preventDefault();
                onChange(value - step < min ? min : value - step);
                break;
            default:
        }
    }, [disabled, onChange, value, step, max, min]);
    const handleLabelClick = useCallback((value) => () => {
        if (disabled) {
            return;
        }
        onChange(value);
    }, [disabled, onChange]);
    const labels = useMemo(() => Object.keys(marks)
        .map(parseFloat)
        .sort((a, b) => a - b)
        .map((point, index, items) => {
        let markStyle = { left: `${getPositionFromValue(point)}px` };
        if (index === 0) {
            markStyle = { left: 0 };
        }
        if (index === items.length - 1) {
            markStyle = { right: 0 };
        }
        return (_jsxs("span", { style: markStyle, className: s.label, children: [_jsx("div", { className: cn(s.mark, { [s.activeMark]: value >= point }) }), _jsx("button", { type: "button", "data-label-value": point, className: s.labelButton, onClick: handleLabelClick(point), tabIndex: -1, children: marks[point] })] }, point));
    }), [getPositionFromValue, handleLabelClick, marks, value]);
    const position = useMemo(() => getPositionFromValue(value), [getPositionFromValue, value]);
    return (_jsxs("div", { className: cn(s.slider, s[`view_${view}`]), children: [_jsxs("div", { className: s.track, role: "presentation", ref: sliderRef, onMouseDown: handleMouseDown, children: [_jsx("div", { className: s.trackLine, children: _jsx("div", { className: s.trackFilled, style: { width: `${position}px` } }) }), _jsx("div", { ref: thumbRef, style: { left: `${position}px` }, "aria-disabled": disabled, className: s.thumb, onMouseDown: handleStart, onKeyDown: handleKeyDown, role: "slider", tabIndex: 0, "aria-valuenow": value, "aria-valuemin": min, "aria-valuemax": min })] }), _jsx("div", { className: s.marks, children: labels })] }));
};
export { Slider };
