import BigNumber from 'bignumber.js';
import { isCurrencyFiat } from '@copper/helpers';
import { isClearloopPortfolio, isTradingPortfolio, isVaultPortfolio } from '@copper/helpers/portfolios';
export const getAvailableBalance = (args) => {
    const { walletsByPortfolioId, currenciesEntities, portfolio, currencyId, mainCurrencyId } = args;
    if (!portfolio || !currencyId) {
        return;
    }
    const portfolioWallets = walletsByPortfolioId[portfolio.portfolioId] ?? [];
    const wallet = portfolioWallets.find((wallet) => {
        if (mainCurrencyId &&
            (isVaultPortfolio(portfolio?.portfolioType) ||
                isTradingPortfolio(portfolio?.portfolioType)) &&
            !isCurrencyFiat(currenciesEntities, currencyId)) {
            return wallet.currency === currencyId && wallet.mainCurrency === mainCurrencyId;
        }
        return wallet.currency === currencyId;
    });
    const transferableAmount = wallet?._embedded?.transferableAmount;
    if (wallet?.portfolioType === 'external' && transferableAmount) {
        return transferableAmount;
    }
    if (isClearloopPortfolio(portfolio?.portfolioType) && wallet) {
        return new BigNumber(wallet.availableToUndelegate ?? 0).toFixed();
    }
    return wallet?.available;
};
export const getSliderPercentagePosition = (value, balance) => {
    const percent = new BigNumber(value)
        .times(100)
        .div(balance || 1)
        .toFixed(0);
    return Number(percent) > 100 ? 0 : Number(percent);
};
