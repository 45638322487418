import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import useStateMachine from '@cassiozen/usestatemachine';
import { EditExchangeForm } from '@copper/components/EditExchangeForm';
import { AddChildPortfolioPopup } from '@copper/components/PortfolioInfoPopup/AddChild';
import { AddClearloopPortfolioPopup } from '@copper/components/PortfolioInfoPopup/AddClearloop';
import { DisconnectClearLoopPopup } from '@copper/components/PortfolioInfoPopup/DisconnectClearLoop';
import { EditPortfolioNamePopup } from '@copper/components/PortfolioInfoPopup/EditName';
import { PortfolioInfo } from '@copper/components/PortfolioInfoPopup/PortfolioInfo';
const PortfolioInfoPopup = ({ onClose, portfolio }) => {
    const [unbindEligibilityData, setUnbindEligibilityData] = useState();
    const [state, send] = useStateMachine({
        initial: 'portfolioInfo',
        states: {
            portfolioInfo: {
                on: {
                    EDIT_NAME: 'editPortfolioName',
                    ADD_CHILD: 'addChildPortfolio',
                    ADD_CLEARLOOP: 'addClearloopPortfolio',
                    EDIT_EXCHANGE: 'editExchange',
                    DISCONNECT_CLEARLOOP: 'disconnectClearLoop'
                }
            },
            editPortfolioName: {
                on: { BACK: 'portfolioInfo' }
            },
            addChildPortfolio: {
                on: { BACK: 'portfolioInfo' }
            },
            addClearloopPortfolio: {
                on: { BACK: 'portfolioInfo' }
            },
            editExchange: {
                on: { BACK: 'portfolioInfo' }
            },
            disconnectClearLoop: {
                on: { BACK: 'portfolioInfo' }
            }
        }
    });
    return (_jsxs(_Fragment, { children: [state.value === 'portfolioInfo' && (_jsx(PortfolioInfo, { portfolio: portfolio, onClose: onClose, onEdit: () => send('EDIT_NAME'), addClearloop: () => send('ADD_CLEARLOOP'), addChildPortfolio: () => send('ADD_CHILD'), editExchange: () => send('EDIT_EXCHANGE'), disconnectClearLoop: () => send('DISCONNECT_CLEARLOOP'), onUnbindEligibility: setUnbindEligibilityData })), state.value === 'editPortfolioName' && (_jsx(EditPortfolioNamePopup, { portfolio: portfolio, onBack: () => send('BACK') })), state.value === 'addChildPortfolio' && portfolio && (_jsx(AddChildPortfolioPopup, { onBack: () => send('BACK'), parentPortfolio: portfolio })), state.value === 'addClearloopPortfolio' && portfolio && (_jsx(AddClearloopPortfolioPopup, { onBack: () => send('BACK'), parentPortfolio: portfolio })), state.value === 'editExchange' && portfolio && (_jsx(EditExchangeForm, { portfolio: portfolio, onClose: () => send('BACK') })), state.value === 'disconnectClearLoop' && (_jsx(DisconnectClearLoopPopup, { onClose: onClose, portfolio: portfolio, onBack: () => send('BACK'), unbindEligibilityData: unbindEligibilityData }))] }));
};
export { PortfolioInfoPopup };
