import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isBorrowerOrganization } from '@copper/components/Loans/helpers';
import { SelectAccountForm } from '@copper/components/Loans/LoanModalContent/LoanManagementAction/SelectAccount/SelectAccountForm';
import { validationSchema } from '@copper/components/Loans/LoanModalContent/LoanManagementAction/SelectAccount/SelectAccountForm/helpers';
import { setBorrowerPortfolio, setLenderPortfolio } from '@copper/entities/loans/loans-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectUserId } from '@copper/entities/user/user-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import s from './SelectAccount.module.css';
const SelectAccount = ({ loan }) => {
    const organization = useSelector(selectCurrentOrganization);
    const { openSnackbar } = useSnackBar();
    const userId = useSelector(selectUserId);
    const isBorrower = isBorrowerOrganization(loan, organization);
    const lenderText = 'Choose an account to fund the loan from, receive the repayment in and receive any default in. This should support both loan and collateral currencies.';
    const borrowerText = 'Choose an account to receive the loan funds in and to repay the loan from. This should support the loan currency.';
    const form = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema)
    });
    const handleFormSubmit = async (formValues) => {
        const setPortfolio = isBorrower ? setBorrowerPortfolio : setLenderPortfolio;
        try {
            await setPortfolio({
                updatedBy: userId,
                portfolioId: formValues.portfolio.portfolioId,
                loanId: loan.loanId,
                loanArranger: loan.loanArranger
            });
            openSnackbar({ type: 'success', message: 'Account updated' });
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
    };
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.content, children: [_jsx("h2", { children: "Select account" }), _jsx("span", { className: s.description, children: isBorrower ? borrowerText : lenderText })] }), _jsx("div", { className: s.content, children: _jsx(SelectAccountForm, { onSubmit: handleFormSubmit, form: form }) })] }));
};
export { SelectAccount };
