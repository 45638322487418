import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetInfo, InfoList, InfoRow } from '@copper/components';
import { LoanLtv } from '@copper/components/Loans/LoanLtv';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { calculateReportingValue } from '@copper/helpers';
import { useDateTime } from '@copper/hooks';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { IconInformation, Tooltip } from '@copper/ui-kit';
import { formatCurrency, formatFiat } from '@copper/utils';
import s from '../LoanModalContent.module.css';
const CollateralDetails = ({ loan }) => {
    const { getDecimals } = useCurrenciesInfo();
    const rates = useSelector(selectRates);
    const { getFormattedDateAndTime } = useDateTime();
    const { terms, securedCollateralAmount, valuation } = loan;
    const { loanCurrency, collateralCurrency, collateralNetworkCurrency, marginCallPercentage, defaultPercentage, rebalancePercentage } = terms;
    const collateralRate = rates[collateralCurrency ?? ''] ?? '1';
    const { price, valuedAt } = valuation ?? {};
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [_jsx(InfoRow, { title: "Asset", titleColor: "secondary", children: _jsx(AssetInfo, { align: "right", currency: collateralCurrency }) }), _jsx(InfoRow, { title: "Network", titleColor: "secondary", children: _jsx(AssetInfo, { align: "right", currency: collateralNetworkCurrency }) })] }) }), _jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { title: "LTV", className: s.loanDataList, titleIcon: _jsx(Tooltip, { label: _jsxs(_Fragment, { children: [_jsx("span", { children: "The Loan-to-Value (LTV) ratio is a lending risk assessment ratio, which expresses the ratio of a loan to the value of the collateral." }), _jsx("br", {}), _jsx("br", {}), _jsx("span", { children: "In other words, it compares the loan amount to the market price of the assets that have been put up to secure the loan." })] }), placement: "top-start", type: "hint", wrapperClassName: s.tooltip, children: _jsx(IconInformation, { className: s.tooltipIcon, width: 12, height: 12 }) }), children: [securedCollateralAmount && securedCollateralAmount !== '0' && (_jsxs(InfoRow, { title: "Pledged", titleColor: "secondary", children: [_jsx("div", { className: s.assetValue, children: formatCurrency(securedCollateralAmount, {
                                        decimalPlaces: getDecimals(collateralCurrency),
                                        zeroFormat: '0.0000',
                                        symbol: ` ${collateralCurrency}`
                                    }) }), _jsx("div", { className: s.fiatValue, children: formatFiat(calculateReportingValue(securedCollateralAmount ?? '0', collateralRate), { short: true }) })] })), _jsx(InfoRow, { title: "LTV", children: _jsx(LoanLtv, { loan: loan, prefix: "" }) }), price && (_jsxs(InfoRow, { titleColor: "secondary", title: "Valuation", children: [_jsx("div", { className: s.assetValue, children: formatCurrency(price, {
                                        symbol: ` ${collateralCurrency} `,
                                        decimalPlaces: getDecimals(collateralCurrency),
                                        zeroFormat: '0.0000'
                                    }) }), _jsx("div", { className: s.fiatValue, children: formatCurrency(1, {
                                        symbol: ` ${loanCurrency} `,
                                        decimalPlaces: getDecimals(loanCurrency),
                                        zeroFormat: '0.0000'
                                    }) })] })), valuedAt && (_jsx(InfoRow, { titleColor: "secondary", title: "Valued At", children: getFormattedDateAndTime(valuedAt) }))] }) }), _jsx("div", { className: s.loanDetailsContainer, children: _jsxs(InfoList, { className: s.loanDataList, children: [_jsx(InfoRow, { title: "Rebalance", titleColor: "secondary", children: rebalancePercentage ? `${rebalancePercentage}%` : '-' }), _jsx(InfoRow, { title: "Margin Call", titleColor: "secondary", children: marginCallPercentage ? `${marginCallPercentage}%` : '-' }), _jsx(InfoRow, { title: "Default", titleColor: "secondary", children: defaultPercentage ? `${defaultPercentage}%` : '-' })] }) })] }));
};
export { CollateralDetails };
