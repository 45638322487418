import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectUserId } from '../user/user-selector';
export const selectApiKeys = (store) => store.apiKeys.apiKeys;
export const selectApiKeysForCurrentOrg = createSelector(selectApiKeys, selectCurrentOrganization, (apiKeys, currentOrganization) => apiKeys.filter((identity) => identity.extra?.allowedOrganizationId === currentOrganization.organizationId));
export const selectUserApiKeys = createSelector(selectApiKeys, selectCurrentOrganization, selectUserId, (apiKeys, currentOrganization, userId) => apiKeys.filter((identity) => identity.extra?.allowedOrganizationId === currentOrganization.organizationId &&
    identity._embedded?.apiKeyOwner?.userId === userId));
export const selectOtherUsersApiKeys = createSelector(selectApiKeys, selectCurrentOrganization, selectUserId, (apiKeys, currentOrganization, userId) => apiKeys.filter((identity) => identity.extra?.allowedOrganizationId === currentOrganization.organizationId &&
    identity._embedded?.apiKeyOwner?.userId !== userId &&
    identity._embedded?.apiKeyOwner?.userType !== 'service-user'));
export const selectServiceAccountsApiKeys = createSelector(selectApiKeys, selectCurrentOrganization, (apiKeys, currentOrganization) => apiKeys.filter((identity) => identity.extra?.allowedOrganizationId === currentOrganization.organizationId &&
    identity._embedded?.apiKeyOwner?.userType === 'service-user'));
export const selectUserApiKeysForCC = (store) => store.apiKeys.apiKeys.filter((identity) => identity.extra?.permissions?.includes('cc'));
