import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContactActionPopup } from '@copper/components/ContactActionPopup';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectGroupedPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { Button, IconGradientSettle } from '@copper/ui-kit';
import { useState } from 'react';
import { useSelector } from 'react-redux';
const ClearloopDelegateController = ({ counterparty, view = 'secondary', size = 'm', readOnly }) => {
    const { setRouteContext } = useRoutingContext();
    const { isAllowedInAnyPortfolio } = useCheckPermissionsContext();
    const { clearloopPortfolios } = useSelector(selectGroupedPortfolios);
    const [isOpenContactPopup, setIsOpenContactPopup] = useState(false);
    const connectedClearLoopExchanges = clearloopPortfolios.reduce((list, portfolio) => {
        if (portfolio.extra?.exchange && !list.includes(portfolio.extra?.exchange)) {
            return [...list, portfolio.extra?.exchange];
        }
        return list;
    }, []);
    const { counterpartyType } = counterparty;
    const isClearloopExchange = counterpartyType === 'clearloop-exchange';
    const handleOnDelegate = (event) => {
        event.stopPropagation();
        if (readOnly)
            return;
        if (connectedClearLoopExchanges.length &&
            !connectedClearLoopExchanges.includes(counterparty.extra?.exchangeId ?? '')) {
            setIsOpenContactPopup(true);
            return;
        }
        setRouteContext({
            name: 'clearloop',
            data: {
                clearLoopType: 'lock',
                exchangeId: counterparty.extra?.exchangeId
            }
        });
    };
    if (!isClearloopExchange || !isAllowedInAnyPortfolio('create-clearloop-transfer')) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [isOpenContactPopup && _jsx(ContactActionPopup, { onClose: () => setIsOpenContactPopup(false) }), isClearloopExchange && (_jsx(Button, { testId: "button-delegate-open-form", leftSection: _jsx(IconGradientSettle, { height: 16, width: 16 }), size: size, text: "Delegate", view: view, disabled: readOnly, onClick: handleOnDelegate }))] }));
};
export { ClearloopDelegateController };
