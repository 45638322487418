import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { formatMantissa } from '@copper/helpers/formatMantissa';
import { ButtonIcon, IconInformation, Tooltip } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
import s from './Balance.module.css';
const Balance = ({ balance, title, children, hint = '', hintPlacement = 'bottom', size = 'm', showSign }) => {
    const isNegative = showSign && new BigNumber(balance).isNegative();
    const isPositive = showSign && new BigNumber(balance).isGreaterThan(0);
    return (_jsxs("div", { className: s.balance, children: [_jsxs("h6", { className: s.title, children: [title, Boolean(hint) && (_jsx("div", { className: s.icon, children: _jsx(Tooltip, { type: "hint", label: hint, placement: hintPlacement, children: _jsx(ButtonIcon, { children: _jsx(IconInformation, { width: "16px", height: "16px" }) }) }) }))] }), _jsxs("div", { className: cn(s.value, s[`value_size_${size}`], {
                    [s.negativeBalance]: isNegative,
                    [s.positiveBalance]: isPositive
                }), children: [isPositive && '+', formatMantissa(formatFiat(balance), isNegative || isPositive ? s.mantissaInherit : s.mantissa)] }), children] }));
};
export { Balance };
