import { request } from '@copper/utils';
export const create = (data) => request.post('/wallets', data);
export const get = (params) => request.get('/wallets', { params });
export const addAsset = (data) => request.post('/wallets/assets', data);
// WG deposit addresses
export const updateDepositAddresses = (walletId, depositAddresses) => request.patch(`/wallets/${walletId}`, { depositAddresses }, {
    headers: {
        'Content-Type': 'application/vnd.update-deposit-addresses+json'
    }
});
export const approveDepositAddress = (walletId, mainCurrency) => request.patch(`/wallets/${walletId}`, { mainCurrency }, {
    headers: {
        'Content-Type': 'application/vnd.approve-deposit-address+json'
    }
});
export const declineDepositAddress = (walletId, mainCurrency) => request.patch(`/wallets/${walletId}`, { mainCurrency }, {
    headers: {
        'Content-Type': 'application/vnd.decline-deposit-address+json'
    }
});
export const removeDepositAddress = (walletId, mainCurrency) => request.patch(`/wallets/${walletId}`, { mainCurrency }, {
    headers: {
        'Content-Type': 'application/vnd.remove-deposit-address+json'
    }
});
export const uploadWalletShardKey = ({ walletId, walletShardPassword, walletShard }) => request.patch(`/wallets/${walletId}`, { walletShardPassword, walletShard }, {
    headers: {
        'Content-Type': 'application/vnd.upload-wallet-shard-key+json'
    }
});
