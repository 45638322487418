export const normalizeCurrencies = (currencies) => {
    const result = {
        entities: {},
        tokens: {}
    };
    currencies.forEach((currency) => {
        const { mainCurrency, name, fiat, priority } = currency;
        result.tokens[`${currency.currency}-${currency.mainCurrency}`] = currency;
        if (!result.entities[currency.currency]) {
            result.entities[currency.currency] = {
                currency: currency.currency,
                name,
                fiat: Boolean(fiat),
                priority,
                mainCurrencies: [mainCurrency]
            };
        }
        else {
            result.entities[currency.currency].mainCurrencies.push(mainCurrency);
        }
    });
    return result;
};
