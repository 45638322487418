import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import s from './EmptyList.module.css';
export const EmptyList = ({ type = 'data', emptyDataString, emptySearchString }) => {
    if (type === 'search') {
        return (_jsx("div", { className: s.empty, children: emptySearchString && _jsx("h5", { className: s.title, children: emptySearchString }) }));
    }
    if (typeof emptyDataString === 'string') {
        return (_jsx("div", { className: s.empty, children: emptyDataString && _jsx("h5", { className: s.title, children: emptyDataString }) }));
    }
    return _jsx("div", { className: s.empty, children: emptyDataString && emptyDataString });
};
