import { emptyObject } from '@copper/utils/empty';
import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentOrganization } from '../organizations/organizations-selector';
export const selectRevault = (store) => store.revault;
export const selectUISettings = createSelector(selectRevault, (revault) => revault.uiSettings);
export const selectShowZeroBalances = createSelector(selectUISettings, (uiSettings) => uiSettings?.showZeroBalances ?? false);
export const selectHidePasskeyPromoPopup = createSelector(selectUISettings, (uiSettings) => uiSettings?.hidePasskeyPromoPopup ?? false);
export const selectOrganizationsWithFavoritesSelected = createSelector(selectUISettings, (uiSettings) => uiSettings?.organizationsWithFavoritesSelected ?? emptyObject());
export const selectSearchPreferences = createSelector(selectUISettings, (uiSettings) => uiSettings?.searchPreferences ?? 'weak');
export const selectTransactionFlowSummaryPeriod = createSelector(selectUISettings, (uiSettings) => uiSettings?.transactionFlowSummaryPeriod ?? '1m');
export const selectTimeFormat = createSelector(selectUISettings, (uiSettings) => uiSettings?.timeFormat ?? '24-hour');
export const selectPaginationSettings = createSelector(selectUISettings, (revault) => revault?.paginationSettings ?? emptyObject());
export const selectFavoriteOrganizations = createSelector(selectRevault, (revault) => revault?.favoriteOrganizations ?? []);
export const selectShowFavoritePortfolios = createSelector(selectOrganizationsWithFavoritesSelected, selectCurrentOrganization, (organizationsWithFavoritesSelected, currentOrganization) => organizationsWithFavoritesSelected[currentOrganization.organizationId]);
export const selectFavoritePortfolios = createSelector(selectRevault, (revault) => revault?.favoritePortfolios ?? emptyObject());
