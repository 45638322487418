import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { REGISTRATION_TYPE_OPTIONS } from '@copper/client/components/Generate/GenerateAlgo/AlgoVotingForm';
import { ChoiceGroup, FormControl, FormLabel } from '@copper/ui-kit';
export const RegistrationType = () => {
    const { control } = useFormContext();
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { view: "tool", children: "Registration type" }), _jsx(Controller, { control: control, name: "blockchainTransactionType", render: ({ field }) => (_jsx(ChoiceGroup, { items: REGISTRATION_TYPE_OPTIONS, name: field.name, getItemKey: (item) => item.value, getItemLabel: (item) => item.label, value: field.value, onChange: ({ newValue }) => {
                        field.onChange(newValue.value);
                    }, view: "tool", testId: "algo-registration-blockchain-type" })) })] }));
};
