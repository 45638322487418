import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as portfoliosApi from '@copper/api/portfolios';
import { normalizePortfolios } from '@copper/entities/portfolios/portfolios-normalize';
import { removeWalletsByPortfolioId } from '@copper/entities/wallets/wallets-reducer';
export const createPortfolio = createAsyncThunk('portfolio/create', async (data, thunkAPI) => {
    try {
        const response = await portfoliosApi.create(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchPortfolios = createAsyncThunk('portfolios/fetch', async ({ organizationId }, thunkAPI) => {
    try {
        const response = await portfoliosApi.getPortfolios({
            organizationId,
            includeShadowPortfolios: true
        });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchArchivedPortfolios = createAsyncThunk('portfoliosArchived/fetch', async ({ organizationId }, thunkAPI) => {
    try {
        const response = await portfoliosApi.getPortfolios({ organizationId, isArchived: true });
        return response.data.portfolios;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchCounterPartyPortfolio = createAsyncThunk('portfolios/fetchCounterPartyPortfolio', async ({ organizationId, counterpartyId }, thunkAPI) => {
    try {
        const response = await portfoliosApi.getPortfolios({
            organizationId,
            owningCounterpartyId: counterpartyId
        });
        return response.data.portfolios[0];
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchPortfolio = createAsyncThunk('portfolios/fetchPortfolio', async ({ portfolioId }, thunkAPI) => {
    try {
        const response = await portfoliosApi.getPortfolio(portfolioId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateExternalPortfolio = createAsyncThunk('portfolio/updateExternal', async (params, thunkAPI) => {
    try {
        const { data } = await portfoliosApi.updateExternalPortfolio(params);
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updatePortfolio = createAsyncThunk('portfolio/updatePortfolio', async (params, thunkAPI) => {
    try {
        const { data } = await portfoliosApi.updatePortfolio(params);
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const archivePortfolio = createAsyncThunk('portfolio/archive', async ({ portfolioId }, thunkAPI) => {
    try {
        await portfoliosApi.archivePortfolio(portfolioId);
        thunkAPI.dispatch(removeWalletsByPortfolioId(portfolioId));
        return portfolioId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const unarchivePortfolio = createAsyncThunk('portfolio/unarchive', async (portfolioId, thunkAPI) => {
    try {
        await portfoliosApi.unarchivePortfolio(portfolioId);
        return portfolioId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateFoPPortfolioSettings = createAsyncThunk('portfolio/updatePortfolio', async (data, thunkAPI) => {
    try {
        const response = await portfoliosApi.updateFoPPortfolioSettings(data.portfolioId, data.params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const portfoliosSlice = createSlice({
    name: 'accounts',
    initialState: {
        portfolios: {},
        archivedPortfolios: {},
        shadowPortfolios: {}
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(createPortfolio.fulfilled, (state, action) => {
            const { portfolioId } = action.payload;
            state.portfolios = {
                ...state.portfolios,
                [portfolioId]: {
                    ...action.payload
                }
            };
        });
        addCase(fetchPortfolios.fulfilled, (state, { payload }) => {
            state.portfolios = normalizePortfolios(payload.portfolios);
            state.shadowPortfolios = normalizePortfolios(payload?._embedded?.shadowPortfolios ?? [], true);
        });
        addCase(fetchArchivedPortfolios.fulfilled, (state, { payload }) => {
            state.archivedPortfolios = normalizePortfolios(payload);
        });
        addCase(unarchivePortfolio.fulfilled, (state, { payload }) => {
            delete state.archivedPortfolios[payload];
        });
        addCase(updateFoPPortfolioSettings.fulfilled, (state, action) => {
            const { portfolioId } = action.payload;
            state.portfolios = {
                ...state.portfolios,
                [portfolioId]: {
                    ...action.payload
                }
            };
        });
        addCase(archivePortfolio.fulfilled, (state, { payload }) => {
            delete state.portfolios[payload];
        });
    }
});
export const portfolios = portfoliosSlice.reducer;
