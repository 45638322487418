import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCryptoAddress } from '@copper/entities/addressBook/addressBook-actions';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
export const useDestination = (data) => {
    const [address, setAddress] = useState(null);
    const portfolios = useSelector(selectPortfolios);
    const organization = useSelector(selectCurrentOrganization);
    const counterpartiesEntities = useSelector(selectCounterpartiesEntities);
    useEffect(() => {
        if (data?.toCryptoAddressId) {
            getCryptoAddress(data?.toCryptoAddressId).then((res) => setAddress(res));
        }
    }, [data?.toCryptoAddressId]);
    if (!data) {
        return;
    }
    if (data.toPortfolioId) {
        return portfolios[data.toPortfolioId ?? ''];
    }
    if (data.toCounterpartyId) {
        return counterpartiesEntities[data.toCounterpartyId || ''];
    }
    if (data.toCryptoAddressId && address) {
        return address;
    }
    if (data.toAddress && !data.toCryptoAddressId) {
        return { address: data.toAddress, memo: data.memo };
    }
    if (data.toBankAccountId) {
        return organization.bankAccounts?.find(({ bankAccountId }) => bankAccountId === data.toBankAccountId);
    }
};
