import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { TransferFundsForm } from '@copper/client/components/TransferFunds/Form';
import { validationSchema } from '@copper/client/components/TransferFunds/helpers';
import { useDestination } from '@copper/client/components/TransferFunds/useDestination';
import { useTransferPortfolio } from '@copper/components/Forms/TransferPortfolio/helpers';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { Button } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { PopupFooter } from '@copper/ui-kit/Popup/PopupFooter';
import { yupResolver } from '@hookform/resolvers/yup';
import s from './EditForm.module.css';
const FORM_ID = 'bulk-transfer-edit-form';
const defaultValues = {};
const TransferDryOrderEditForm = () => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const getSelectedPortfolio = useTransferPortfolio();
    const { setModalContext, modalOrderData, onUpdate, showIncompletedOnly, onUnselect } = useBulkOrder();
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    });
    const destination = useDestination(modalOrderData);
    useEffect(() => {
        const formValues = {
            origin: getSelectedPortfolio(modalOrderData?.portfolioId ?? ''),
            destination: destination,
            amount: modalOrderData?.amount,
            currency: currenciesEntities[modalOrderData?.baseCurrency ?? ''],
            mainCurrency: modalOrderData?.mainCurrency,
            description: modalOrderData?.description,
            feeLevel: modalOrderData?.feeLevel,
            includeFeeInWithdraw: modalOrderData?.includeFeeInWithdraw
        };
        form.reset({ ...defaultValues, ...formValues });
    }, [modalOrderData]);
    const closePopup = () => setModalContext();
    const onSave = (formValues) => {
        if (!modalOrderData?.orderId) {
            return;
        }
        const { destination } = formValues;
        const { orderId, statusDescription } = modalOrderData;
        const updatedData = {
            orderId,
            orderType: modalOrderData.orderType,
            portfolioId: formValues?.origin?.portfolioId ?? '',
            amount: formValues?.amount,
            baseCurrency: formValues?.currency?.currency ?? '',
            mainCurrency: formValues?.mainCurrency,
            description: formValues?.description,
            toPortfolioId: destination && 'portfolioId' in destination ? destination?.portfolioId : undefined,
            toCryptoAddressId: destination && 'cryptoAddressId' in destination ? destination?.cryptoAddressId : undefined,
            toCounterpartyId: destination && 'counterpartyId' in destination ? destination?.counterpartyId : undefined,
            toAddress: destination && 'address' in destination && !('cryptoAddressId' in destination)
                ? destination?.address
                : undefined,
            memo: destination && 'memo' in destination && !('cryptoAddressId' in destination)
                ? destination?.memo
                : undefined,
            feeLevel: formValues.feeLevel !== 'custom' ? formValues.feeLevel : undefined,
            includeFeeInWithdraw: formValues.includeFeeInWithdraw,
            requestedNetworkFees: formValues.requestedNetworkFees
        };
        if (showIncompletedOnly && statusDescription) {
            onUnselect([orderId]);
        }
        onUpdate(updatedData);
        closePopup();
    };
    return (_jsxs(_Fragment, { children: [_jsx(PopupContent, { noPadding: true, children: _jsx(TransferFundsForm, { formId: FORM_ID, form: form, onSubmit: onSave, view: "primary", withConditional: false }) }), _jsx(PopupFooter, { rightSection: _jsxs("div", { className: s.buttons, children: [_jsx(Button, { text: "Cancel", view: "secondary", onClick: closePopup }), _jsx(Button, { type: "submit", form: FORM_ID, text: "Save" })] }) })] }));
};
export { TransferDryOrderEditForm };
