import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import QRCode from 'qrcode';
import { useOutsideClick } from '@copper/hooks';
import { ButtonIcon, ButtonLink, IconQrCode } from '@copper/ui-kit';
import { FullScreenPopup } from './FullScreenPopup';
import s from './QrCodeImage.module.css';
const QrCodeImage = ({ address, view = 'primary' }) => {
    const [opened, setOpen] = useState(false);
    const [openedModal, setOpenedModal] = useState(false);
    const [qrCodeImage, setQrCodeImage] = useState();
    const ref = useRef(null);
    useOutsideClick(ref, useCallback(() => {
        setOpen(false);
    }, []));
    useMemo(() => {
        QRCode.toDataURL(address, { width: 320, margin: 1.5 }, (_error, dataUrl) => {
            setQrCodeImage(dataUrl);
        });
    }, [address]);
    return (_jsxs("div", { ref: ref, className: s.container, children: [opened && (_jsxs("div", { className: cn(s.qrWrapper, s[`view_${view}`]), children: [_jsx("img", { src: qrCodeImage, className: s.qrCodeImg, alt: "QRCode" }), _jsx(ButtonLink, { text: "Full screen", size: "s", onClick: () => setOpenedModal(true) })] })), _jsx(ButtonIcon, { tooltipLabel: "Scan QR code", state: opened ? 'active' : undefined, onClick: () => {
                    setOpen(!opened);
                }, children: _jsx(IconQrCode, {}) }), openedModal && _jsx(FullScreenPopup, { address: address, onClose: () => setOpenedModal(false) })] }));
};
export { QrCodeImage };
