import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { isBorrowerOrganization } from '@copper/components/Loans/helpers';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useRoutingContext } from '@copper/hooks';
import { Button, IconGradientPlus, IconGradientTransfer } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import s from './TopUp.module.css';
const TopUpAccount = ({ loan }) => {
    const { setRouteContext } = useRoutingContext();
    const organization = useSelector(selectCurrentOrganization);
    const { terms, borrowerPortfolioId, lenderPortfolioId, borrowerCollateralPortfolioId } = loan;
    const { loanCurrency, loanNetworkCurrency, collateralCurrency, collateralNetworkCurrency, amount, collateralAmount } = terms;
    const isBorrower = isBorrowerOrganization(loan, organization);
    const isLoanFunds = ['disbursing-loan', 'repaying-loan'].includes(loan.loanStatus);
    const targetAmount = isLoanFunds ? amount : collateralAmount;
    const currencyId = isLoanFunds ? loanCurrency : collateralCurrency;
    const mainCurrencyId = isLoanFunds ? loanNetworkCurrency : collateralNetworkCurrency;
    const title = isLoanFunds ? 'Transferring funds' : 'Pledging collateral';
    const openDeposit = (event) => {
        event.stopPropagation();
        const portfolioId = isLoanFunds
            ? isBorrower
                ? borrowerPortfolioId
                : lenderPortfolioId
            : borrowerCollateralPortfolioId;
        setRouteContext({
            name: 'deposit',
            data: {
                currencyId,
                portfolioId,
                mainCurrencyId
            }
        });
    };
    const openTransfer = (event) => {
        event.stopPropagation();
        const isLoanFunds = ['disbursing-loan', 'repaying-loan'].includes(loan.loanStatus);
        const toPortfolioId = isLoanFunds
            ? isBorrower
                ? borrowerPortfolioId
                : lenderPortfolioId
            : borrowerCollateralPortfolioId;
        setRouteContext({
            name: 'transfer',
            data: {
                currencyId,
                toPortfolioId,
                mainCurrencyId,
                amount: targetAmount
            }
        });
    };
    return (_jsxs("div", { className: s.signContainer, children: [_jsx("h3", { className: s.title, children: title }), _jsx("p", { className: s.description, children: `There are not enough funds in your account, please ensure your account has at least ${formatCurrency(targetAmount, {
                    decimalPlaces: 4,
                    symbol: ` ${currencyId}`,
                    short: true,
                    roundingMode: BigNumber.ROUND_UP
                })} available.` }), _jsxs("div", { className: s.buttonContainer, children: [_jsx(Button, { className: s.button, view: "secondary", text: "Deposit", leftSection: _jsx(IconGradientPlus, {}), onClick: openDeposit }), _jsx(Button, { className: s.button, view: "secondary", text: "Transfer", leftSection: _jsx(IconGradientTransfer, {}), onClick: openTransfer })] })] }));
};
export { TopUpAccount };
