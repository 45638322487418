import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetInfo } from '@copper/components';
import { LoanStatus } from '@copper/components/Loans';
import { displayMaturity, getCounterparty } from '@copper/components/Loans/helpers';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Td, Tr } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import s from '@copper/components/Loans/LoanList/LoanList.module.css';
const HistoricLoanListItem = ({ loan, onClick }) => {
    const { getDecimals } = useCurrenciesInfo();
    const organization = useSelector(selectCurrentOrganization);
    return (_jsxs(Tr, { className: s.row, onClick: onClick, children: [_jsx(Td, { children: _jsx(AssetInfo, { currency: loan.terms.loanCurrency }) }), _jsx(Td, { align: "right", children: formatCurrency(loan.terms.amount, {
                    decimalPlaces: getDecimals(loan.terms.loanCurrency),
                    zeroFormat: '0.0000'
                }) }), _jsx(Td, { children: displayMaturity(loan) }), _jsx(Td, { children: getCounterparty(organization?.organizationId, loan) }), _jsx(Td, { align: "right", children: _jsx(LoanStatus, { loan: loan }) })] }, loan.loanId));
};
export { HistoricLoanListItem };
