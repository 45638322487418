import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { ResultSignInfo } from '@copper/components/Forms/ResultSignInfo';
import { Alert } from '@copper/ui-kit';
import s from './Result.module.css';
const statusDescriptionMap = {
    'co-sign-require': 'Pending approval',
    executed: 'Transfer completed',
    processing: 'Processing',
    new: 'Transfer completed',
    'waiting-approve': 'Pending signature by Copper'
};
const Result = ({ transaction, errorMessage, formData, onDone }) => {
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        if (transaction?.status === 'processing' ||
            (transaction?.status !== 'executed' && transaction?.status !== 'new')) {
            return 'pending';
        }
        return 'success';
    };
    const getHeadingText = () => {
        if (errorMessage) {
            return 'Transfer failed';
        }
        return statusDescriptionMap[transaction?.status ?? ''] ?? 'Pending signature';
    };
    const getDestinationText = () => {
        const { destination } = formData;
        if (!destination) {
            return 'Unknown';
        }
        if ('portfolioId' in destination) {
            return (_jsx(NavLink, { to: `/accounts/${destination?.portfolioId}`, children: destination?.portfolioName }));
        }
        if ('cryptoAddressId' in destination) {
            return 'External address';
        }
        if ('counterpartyId' in destination) {
            return destination.counterpartyName;
        }
        if ('bankAccountName' in destination) {
            return destination.bankAccountName;
        }
    };
    const status = getResultStatus();
    const { origin } = formData;
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: getHeadingText() }), _jsxs(FormResultAccent, { children: [formData.amount, " ", formData.currency?.currency] }), _jsx(FormResultRegular, { children: _jsxs(_Fragment, { children: ["From ", _jsx(NavLink, { to: `/accounts/${origin?.portfolioId}`, children: origin?.portfolioName }), " to", ' ', getDestinationText()] }) }), errorMessage ? (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) })) : (_jsx(ResultSignInfo, { currencyId: formData.currency?.currency, transaction: transaction, origin: formData.origin }))] }));
};
export { Result };
