import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCounterpartiesEntities } from '@copper/entities/counterparties/counterparties-selector';
import { StepItem } from '@copper/ui-kit';
const AwaitingCounterpartyAddress = ({ order, status, disabled, index, viewByAdmin = false, testId }) => {
    const counterparties = useSelector(selectCounterpartiesEntities);
    const { counterpartyName = 'Counterparty' } = counterparties[order?.extra?.toCounterpartyId ?? ''] ?? {};
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin ? `${counterpartyName} sets addresses` : 'Counterparty sets addresses'}`, disabled: disabled, children: "The counterparty specifies address information" }));
};
export { AwaitingCounterpartyAddress };
