import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconGradientChat, IconGradientFolders } from '@copper/ui-kit';
import s from './ZendeskMenu.module.css';
const ZendeskMenu = ({ onClose }) => (_jsxs("div", { className: s.container, children: [_jsxs("button", { className: s.item, onClick: () => {
                onClose();
                window.zE('webWidget', 'show');
                window.zE('webWidget', 'open');
            }, children: [_jsx(IconGradientChat, {}), _jsx("div", { children: "Contact us" })] }), _jsxs("button", { className: s.item, onClick: () => {
                onClose();
                window.open('https://copper.zendesk.com/hc/en-gb/requests', '_blank');
            }, children: [_jsx(IconGradientFolders, {}), _jsx("div", { children: "View your requests" })] })] }));
export { ZendeskMenu };
