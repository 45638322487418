import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { AssetIcon, InfoRow } from '@copper/components';
import { CounterpartyInfo } from '@copper/components/Counterparty/CounterpartyInfo';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Button, IconInformation, Tooltip } from '@copper/ui-kit';
import { formatCurrency, formatFiat } from '@copper/utils';
import { MATURITY_DATE_DEFINITION } from '../../constants';
import s from './Summary.module.css';
export const Summary = ({ onSubmit }) => {
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const currenciesRates = useSelector(selectRates);
    const { currency, mainCurrency, amount, maturityDate, collateralAmount, collateralCurrency, counterparty, marginCallPercent, defaultPercent, rebalancePercent } = getValues();
    const amountMarketValue = BigNumber(amount)
        .times(currenciesRates[currency?.currency ?? ''] ?? 0)
        .toFixed();
    const collateralAmountMarketValue = collateralAmount &&
        BigNumber(collateralAmount)
            .times(currenciesRates[collateralCurrency?.currency ?? ''] ?? 0)
            .toFixed();
    return (_jsxs("div", { className: s.mainWrapper, children: [_jsxs("div", { className: s.summaryContent, children: [_jsx("h2", { className: s.summaryTitle, children: "Summary" }), _jsx(InfoRow, { title: "Loan type", titleColor: "ghost", children: _jsx("div", { className: s.loanType, children: 'Title transfer bilateral loan' }) }), _jsx(InfoRow, { title: "Borrower", titleColor: "ghost", children: _jsx(CounterpartyInfo, { counterparty: counterparty, reverseView: true, size: "3xs" }) }), _jsx(InfoRow, { title: "Loan amount", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsxs("div", { className: s.assetWrapper, children: [_jsxs("div", { className: s.asset, children: [formatCurrency(amount || '0', {
                                                    decimalPlaces: getDecimals(currency.currency, { useDictionaryDecimal: true })
                                                }), _jsx("span", { children: currency.currency })] }), _jsx("div", { className: s.assetValue, children: formatFiat(amountMarketValue) })] }), _jsx(AssetIcon, { size: "s", currency: currency.currency })] }) }), _jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsx("div", { className: s.asset, children: mainCurrency }), _jsx(AssetIcon, { size: "s", currency: mainCurrency })] }) }), collateralAmount && collateralAmountMarketValue && (_jsxs(_Fragment, { children: [_jsx(InfoRow, { title: "Collateral amount", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsxs("div", { className: s.assetWrapper, children: [_jsxs("div", { className: s.asset, children: [formatCurrency(collateralAmount || '0', {
                                                            decimalPlaces: getDecimals(collateralCurrency?.currency, {
                                                                useDictionaryDecimal: true
                                                            })
                                                        }), _jsx("span", { children: collateralCurrency?.currency })] }), _jsx("div", { className: s.assetValue, children: formatFiat(collateralAmountMarketValue) })] }), _jsx(AssetIcon, { size: "s", currency: collateralCurrency?.currency })] }) }), _jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsx("div", { className: s.asset, children: collateralCurrency?.currency }), _jsx(AssetIcon, { size: "s", currency: collateralCurrency?.currency })] }) })] })), _jsx(InfoRow, { titleColor: "ghost", title: _jsxs("div", { className: s.dateLabel, children: ["Maturity date", maturityDate && (_jsx(Tooltip, { view: "tool", label: MATURITY_DATE_DEFINITION, placement: "top", children: _jsx(IconInformation, { width: 12 }) }))] }), children: maturityDate ? format(maturityDate, `dd MMM yyyy`) : 'Open term' }), rebalancePercent && (_jsxs(InfoRow, { title: "Rebalance", titleColor: "ghost", children: [rebalancePercent, "%"] })), marginCallPercent && (_jsxs(InfoRow, { title: "Margin call", titleColor: "ghost", children: [marginCallPercent, "%"] })), defaultPercent && (_jsxs(InfoRow, { title: "Default", titleColor: "ghost", children: [defaultPercent, "%"] }))] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "new-loan-submit-button" }) })] }));
};
