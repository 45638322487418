import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { ContactActionPopup } from '@copper/components';
import { CounterpartyLogo } from '@copper/components/Counterparty/CounterpartyNetworkCard/CounterpartyLogo';
import { ExtendAuthPopup } from '@copper/components/ExtendAuthPopup';
import { useLayoutState } from '@copper/contexts/layout';
import { updateBearerToken } from '@copper/entities/auth/auth-reducer';
import { selectAuth } from '@copper/entities/auth/auth-selector';
import { fetchOrganizations } from '@copper/entities/organizations/organizations-reducer';
import { selectCurrentOrganization, selectOrganizations } from '@copper/entities/organizations/organizations-selector';
import { fetchAuthorizedActions } from '@copper/entities/permissions/permissions-reducer';
import { fetchFavoritePortfolios } from '@copper/entities/revault/revault-reducer';
import { useOutsideClick } from '@copper/hooks';
import { useFetchOrganization } from '@copper/hooks/useFetchOrganization';
import { useAppDispatch } from '@copper/store';
import { Badge, ButtonIcon, IconAdd, IconKeyboardArrowDown, SearchField, Skeleton, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { CurrentOrganization } from './CurrentOrganization';
import { getOrganizationsToDisplay } from './helpers';
import s from './OrganizationSwitch.module.css';
const OrganizationSwitch = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fetchOrganization = useFetchOrganization();
    const { loadingLayout, setLoadingLayout } = useLayoutState();
    const { openSnackbar } = useSnackBar();
    const currentOrganization = useSelector(selectCurrentOrganization);
    const organizations = useSelector(selectOrganizations);
    const { token } = useSelector(selectAuth);
    const [extendAuth, setExtendAuth] = useState();
    const [isContactPopupOpen, setIsContactPopupOpen] = useState(false);
    const [isSwitchPanelOpen, setIsSwitchPanelOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchParams] = useSearchParams();
    const dropdownRef = useRef(null);
    const configurationId = searchParams.get('configurationId');
    const nextOrganizationId = searchParams.get('organizationId');
    const ssoSessionToken = searchParams.get('ssoSessionToken');
    const handleExtendSessionViaSSO = async ({ configurationId, organizationId, ssoSessionToken }) => {
        try {
            setLoadingLayout(true);
            await dispatch(updateBearerToken({
                configurationId,
                organizationId,
                ssoSessionToken,
                target: 'client',
                token
            })).unwrap();
            await fetchOrganization(organizationId);
            await dispatch(fetchAuthorizedActions({ organizationId })).unwrap();
            await dispatch(fetchFavoritePortfolios(organizationId)).unwrap();
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            searchParams.delete('configurationId');
            searchParams.delete('organizationId');
            searchParams.delete('ssoSessionToken');
            setLoadingLayout(false);
            navigate('accounts/all', { replace: true });
        }
    };
    const handleOrganizationAdd = () => {
        setIsContactPopupOpen(true);
        setIsSwitchPanelOpen(false);
    };
    const handleOrganizationSettings = () => {
        navigate('/settings');
        setIsSwitchPanelOpen(false);
    };
    const handleOrganizationSwitch = async (organizationId, forceOrganizationsRefetch = false) => {
        try {
            setLoadingLayout(true);
            setIsSwitchPanelOpen(false);
            await fetchOrganization(organizationId);
            await dispatch(fetchAuthorizedActions({ organizationId })).unwrap();
            forceOrganizationsRefetch && (await dispatch(fetchOrganizations()).unwrap());
            await dispatch(fetchFavoritePortfolios(organizationId)).unwrap();
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setLoadingLayout(false);
            navigate('accounts/all', { replace: true });
        }
    };
    const handleOrganizationChange = async (id, isAuthenticated) => {
        if (!isAuthenticated) {
            return setExtendAuth(id);
        }
        await handleOrganizationSwitch(id);
    };
    const handleSearchChange = (value) => {
        setSearchQuery(value);
    };
    const organizationsToDisplay = useMemo(() => getOrganizationsToDisplay(organizations, searchQuery), [organizations, searchQuery]);
    useEffect(() => {
        if (configurationId && nextOrganizationId && ssoSessionToken) {
            handleExtendSessionViaSSO({
                configurationId,
                organizationId: nextOrganizationId,
                ssoSessionToken
            });
        }
    }, [configurationId, nextOrganizationId, ssoSessionToken]);
    useOutsideClick(dropdownRef, useCallback(() => {
        setIsSwitchPanelOpen(false);
    }, []));
    return (_jsxs("div", { className: s.container, children: [loadingLayout ? (_jsxs("div", { className: s.buttonWrapper, children: [_jsx(Skeleton, { height: 24, width: 24 }), _jsx(Skeleton, { height: 24, width: 120 })] })) : (_jsxs(ButtonIcon, { className: cn(s.buttonWrapper, isSwitchPanelOpen && s.active), testId: "switch-organization", view: "ghost", onClick: () => setIsSwitchPanelOpen(true), children: [_jsx("div", { className: s.buttonColumn, children: _jsx(CounterpartyLogo, { counterpartyName: currentOrganization?.organizationName, imageId: currentOrganization?.counterparty?.imageId, size: "3xs" }) }), _jsxs("div", { className: s.buttonColumn, children: [_jsx("div", { className: s.buttonTitle, children: currentOrganization?.organizationName }), _jsx("div", { className: s.buttonSubtitle, children: currentOrganization?.organizationId })] }), _jsx("div", { className: s.buttonColumn, children: _jsx(IconKeyboardArrowDown, { className: cn(s.buttonArrow, isSwitchPanelOpen && s.isOpen) }) })] })), isSwitchPanelOpen && (_jsxs("div", { className: s.dropdownWrapper, ref: dropdownRef, children: [_jsx(CurrentOrganization, { onClick: handleOrganizationSettings }), _jsx("div", { className: s.searchWrapper, children: _jsx(SearchField, { fullWidth: true, placeholder: "Search other organisations", testId: "search_organisation", value: searchQuery, view: "secondary", onChange: handleSearchChange }) }), organizationsToDisplay.map(({ isAuthenticated, organizationId, organizationName }) => {
                        if (currentOrganization?.organizationId === organizationId)
                            return null;
                        return (_jsx(ButtonIcon, { className: s.organizationButton, testId: "change_organisation", view: "ghost", onClick: () => handleOrganizationChange(organizationId, Boolean(isAuthenticated)), children: _jsxs("div", { children: [_jsx("div", { className: s.organizationTitle, children: organizationName }), _jsxs("div", { className: s.organizationSubtitle, children: [organizationId, ' ', !isAuthenticated && (_jsx(Badge, { altView: true, content: "Authorise", textForm: "regular", view: "secondary" }))] })] }) }, organizationId));
                    }), _jsxs(ButtonIcon, { className: s.organizationButton, testId: "add_organisation", view: "ghost", onClick: handleOrganizationAdd, children: [_jsx(IconAdd, { className: s.iconOffset }), " Add another organisation"] })] })), extendAuth && (_jsx(ExtendAuthPopup, { onClose: () => setExtendAuth(undefined), handleOrganizationSwitch: handleOrganizationSwitch, organizationId: extendAuth })), isContactPopupOpen && _jsx(ContactActionPopup, { onClose: () => setIsContactPopupOpen(false) })] }));
};
export { OrganizationSwitch };
