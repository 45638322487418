import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { setCssProperty } from '@copper/utils/styles';
import s from './PopupFooter.module.css';
export const PopupFooter = ({ rightSection, leftSection, height }) => {
    useEffect(() => {
        setCssProperty('--popup-footer-height', height ? `${height}px` : '65px');
        return () => setCssProperty('--popup-footer-height', '0px');
    }, [height]);
    return (_jsxs("div", { className: s.footer, children: [leftSection && _jsx("div", { className: s.leftSection, children: leftSection }), rightSection && _jsx("div", { className: s.rightSection, children: rightSection })] }));
};
