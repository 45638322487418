import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonIcon, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import s from './AccountDataToCopy.module.css';
export const AccountDataToCopy = ({ data }) => {
    const { openSnackbar } = useSnackBar();
    if (!data) {
        return;
    }
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.copyButton, children: _jsx(ButtonIcon, { onClick: (e) => {
                        e.stopPropagation();
                        copy(data, () => openSnackbar({ type: 'success', message: 'Copied' }));
                    }, tooltipLabel: "Copy address", children: _jsx(IconClipboard, { height: 16, width: 16 }) }) }), data] }));
};
