import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ChoiceCard, ContactActionPopup, PortfolioIcon } from '@copper/components';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectCurrentOrganization, selectWalledGardenManager } from '@copper/entities/organizations/organizations-selector';
import { selectUser } from '@copper/entities/user/user-selector';
import { IconArrowBack, IconClearloop, IconGradientExternal } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import s from './Initial.module.css';
const Initial = ({ onNext }) => {
    const walledGardenManager = useSelector(selectWalledGardenManager);
    const { organizationType } = useSelector(selectCurrentOrganization);
    const { checkPermissions } = useCheckPermissionsContext();
    const { isAdmin } = useSelector(selectUser);
    const [isOpenContactPopup, setIsOpenContactPopup] = useState(false);
    const isClientCanAddExchange = !isAdmin && walledGardenManager === 'client' && checkPermissions(['create-external-portfolio']);
    const isAdminCanAddExchange = isAdmin && walledGardenManager === 'admin' && checkPermissions(['create-external-portfolio']);
    const handleExchangeClick = () => {
        if (isClientCanAddExchange || isAdminCanAddExchange) {
            onNext('EXCHANGE_FORM');
        }
        else if (!isClientCanAddExchange) {
            setIsOpenContactPopup(true);
        }
    };
    const handleTradingClick = () => {
        if (checkPermissions(['create-trading-portfolio'])) {
            onNext('TRADING_FORM');
        }
        else {
            setIsOpenContactPopup(true);
        }
    };
    const handleThirdPartyClick = () => {
        if (checkPermissions(['create-external-wallets-portfolio'])) {
            onNext('THIRD_PARTY_FORM');
        }
        else {
            setIsOpenContactPopup(true);
        }
    };
    return (_jsxs(PopupContent, { children: [_jsx("div", { className: s.description, children: "You can create new accounts and connect existing exchange accounts." }), checkPermissions(['create-custody-portfolio']) && (_jsx(ChoiceCard, { onClick: () => onNext('VAULT_TYPES'), title: "Vault", description: "Hold, trade and interact with DeFi apps", leftSection: _jsx(PortfolioIcon, { portfolio: "custody" }), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), organizationType === 'standard' && (_jsx(ChoiceCard, { onClick: handleTradingClick, title: "Trading", description: "Live omnibus account for instant trading", leftSection: _jsx(PortfolioIcon, { portfolio: "trading" }), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), (!isAdmin || isAdminCanAddExchange) && (_jsx(ChoiceCard, { onClick: handleExchangeClick, title: "Exchange", description: "Connect to exchange accounts", leftSection: _jsx(IconGradientExternal, {}), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), checkPermissions(['create-clearloop-portfolio']) && (_jsx(ChoiceCard, { onClick: () => onNext('CLEARLOOP_FORM'), title: "ClearLoop", description: _jsxs(_Fragment, { children: ["On-exchange trading from MPC custody", _jsx("div", { className: s.notice, children: "Most secure trading method" })] }), leftSection: _jsx(IconClearloop, {}), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), organizationType === 'standard' &&
                checkPermissions(['create-external-wallets-portfolio']) && (_jsx(ChoiceCard, { onClick: handleThirdPartyClick, title: "Third-party", description: "Connect third-party wallet addresses", leftSection: _jsx(PortfolioIcon, { portfolio: "external-wallets" }), rightSection: _jsx("div", { className: s.icon, children: _jsx(IconArrowBack, {}) }) })), isOpenContactPopup && (_jsx(ContactActionPopup, { title: "Please contact us to open this account", description: "Your Relationship Manager will be happy to help you set it up.", onClose: () => setIsOpenContactPopup(false) }))] }));
};
export { Initial };
