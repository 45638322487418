import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useStateMachine from '@cassiozen/usestatemachine';
import { Form } from '@copper/client/components/Lend/BilateralLoanPledge/Form';
import { Success } from '@copper/client/components/Lend/BilateralLoanPledge/Success';
import { Summary } from '@copper/client/components/Lend/BilateralLoanPledge/Summary';
import { validationSchema } from '@copper/client/components/Lend/BilateralLoanPledge/helpers';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { createLoan } from '@copper/entities/loans/loans-actions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { fetchPledgeConnections } from '@copper/entities/pledge/pledge-reducer';
import { selectUserId } from '@copper/entities/user/user-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import s from './BilateralLoan.module.css';
export const BilateralLoanPledge = ({ onBack }) => {
    const [newLoan, setNewLoan] = useState(null);
    const { setTopPanel } = useTopPanel();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const userId = useSelector(selectUserId);
    const lendingForm = useForm({
        defaultValues: {},
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });
    const { handleSubmit, reset } = lendingForm;
    const [state, send] = useStateMachine({
        initial: 'lendingForm',
        states: {
            lendingForm: {
                on: {
                    NEXT: 'summary'
                }
            },
            summary: {
                on: { BACK: 'lendingForm', SUCCESS: 'success' }
            },
            success: {
                on: {
                    DONE: 'lendingForm'
                }
            }
        }
    });
    useEffect(() => {
        setTopPanel({
            title: 'Lend',
            onBack: state.value === 'summary' ? () => send('BACK') : onBack
        });
    }, [setTopPanel, send, state]);
    const getConnections = useCallback(async () => {
        try {
            await dispatch(fetchPledgeConnections(organizationId));
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    }, [organizationId]);
    useEffect(() => {
        getConnections();
    }, []);
    const handleCreateLoan = handleSubmit(async (formValues) => {
        try {
            const data = {
                loanType: 'secured-pledged',
                pricingSource: 'copper',
                createUserId: userId,
                lenderOrganizationId: organizationId,
                borrowerOrganizationId: formValues.counterparty.organizationId,
                terms: {
                    termType: 'open',
                    borrowFeeCalculationMethod: 'none',
                    startDate: new Date().getTime().toString(),
                    requireNoticeOfExclusiveControl: true,
                    collateralAmount: formValues.collateralAmount,
                    amount: formValues.amount,
                    loanCurrency: formValues.loanCurrency.currency,
                    loanNetworkCurrency: formValues.loanNetworkCurrency || formValues.loanCurrency.currency,
                    collateralCurrency: formValues.collateralCurrency.currency,
                    collateralNetworkCurrency: formValues.collateralNetworkCurrency || formValues.collateralCurrency.currency,
                    marginCallPercentage: formValues.marginCallPercentage,
                    rebalancePercentage: formValues.rebalancePercentage,
                    defaultPercentage: formValues.defaultPercentage
                }
            };
            const res = await createLoan(data);
            setNewLoan(res);
            send('SUCCESS');
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    });
    const handleDone = () => {
        send('DONE');
        setNewLoan(null);
        reset();
    };
    return (_jsx("div", { className: s.mainContainer, children: _jsxs(FormProvider, { ...lendingForm, children: [state.value === 'lendingForm' && _jsx(Form, { onNext: handleSubmit(() => send('NEXT')) }), state.value === 'summary' && _jsx(Summary, { onSubmit: handleCreateLoan }), state.value === 'success' && newLoan && (_jsx(Success, { onDone: handleDone, loanId: newLoan.loanId }))] }) }));
};
