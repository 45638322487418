import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { InfoRow } from '@copper/components';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { formatFiat } from '@copper/utils';
import { AssetList } from '../AssetList';
import s from './Form.module.css';
const formId = 'migrate-funds-form';
export const MigrateFundsForm = ({ onSubmit, form, assets, total }) => {
    const { formState: { errors }, handleSubmit, control, watch, setValue } = form;
    const { origin, destination } = watch();
    useEffect(() => {
        if (origin && destination && origin?.portfolioId === destination?.portfolioId) {
            setValue('destination', undefined);
        }
    }, [origin, destination, setValue]);
    return (_jsxs(_Fragment, { children: [_jsx(FormProvider, { ...form, children: _jsxs("form", { id: formId, className: s.formContainer, onSubmit: handleSubmit(onSubmit), children: [_jsx("h2", { className: s.title, children: "Move all assets from exchange" }), _jsx("p", { className: s.subTitle, children: "To move all assets from an exchange account, the assets must be supported by the recipient account." }), _jsxs("div", { children: [_jsx(PortfolioSelect, { view: "tool", name: "origin", label: "From", control: control, types: ['external'], testId: "migrate-form-origin-portfolio", errorMessage: errors?.origin?.portfolioType?.message }), _jsx(PortfolioSelect, { view: "tool", name: "destination", label: "To", control: control, types: ['custody', 'trading', 'external', 'fees-vault'], testId: "migrate-form-destination-portfolio", errorMessage: errors?.destination?.message })] }), assets && assets.length > 0 ? _jsx(AssetList, { assets: assets }) : '', total && total !== '0' && (_jsx("div", { className: s.totalWrapper, children: _jsx(InfoRow, { title: "Total", titleColor: "ghost", children: _jsx("span", { className: s.total, children: formatFiat(total) }) }) }))] }) }), _jsx(SwitchStateRow, { type: "submit", form: formId, testId: "migrate-form-submit" })] }));
};
