import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { selectExchangesEntities } from '@copper/entities/exchanges/exchanges-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { hasExchangeAutoActivation } from '@copper/helpers/exchanges';
import { Button, IconComplete } from '@copper/ui-kit';
import { SubAccounts } from '../SubAccounts';
import s from './ExchangeResult.module.css';
const ExchangeResult = ({ portfolioId, onSuccess }) => {
    const exchangesEntities = useSelector(selectExchangesEntities);
    const portfolios = useSelector(selectPortfolios);
    // portfolio from store to get autodiscovery status after updating sub-accounts
    const portfolio = portfolios[portfolioId ?? ''];
    const currentExchange = exchangesEntities[portfolio?.extra?.exchange ?? ''];
    if (!portfolio) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx("div", { className: s.iconContainer, children: _jsx(IconComplete, {}) }), _jsxs("div", { className: s.content, children: [_jsx("h3", { className: s.title, children: "Account connected" }), _jsxs("p", { className: s.description, children: [portfolio?.portfolioName, " is now available in your Open Garden. We\u2019ve automatically connected all wallets or sub-accounts."] }), _jsx(Button, { view: "secondary", fullWidth: true, onClick: onSuccess, text: "Done" })] }), (currentExchange?.exchangeAccounts?.length || 0) > 1 && (_jsx(SubAccounts, { portfolio: portfolio, autoActivation: hasExchangeAutoActivation(currentExchange) }))] }));
};
export { ExchangeResult };
