import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormResultAccent, FormResultFrame, FormResultHeading } from '@copper/components/FormResultFrame';
export const Result = ({ resetForm }) => {
    const status = 'success';
    const onDone = () => {
        resetForm();
    };
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: status === 'success' ? 'Success' : 'Attention' }), _jsx(FormResultAccent, { children: "Rule created" })] }));
};
