import * as invoiceApi from '@copper/api/invoices';
export const getInvoices = (params) => invoiceApi.getInvoices(params).then((response) => response.data);
export const getInvoicesByOrganizationId = (organizationId) => invoiceApi.getInvoicesByOrganizationId(organizationId).then((response) => response.data);
export const getInvoiceById = (invoiceId) => invoiceApi.getInvoiceById(invoiceId).then((response) => response.data);
export const createInvoice = (data) => invoiceApi.createInvoice(data).then((response) => response.data);
export const updateInvoice = (data) => invoiceApi.updateInvoice(data).then((response) => response.data);
export const updateInvoiceStatus = (data) => invoiceApi.updateInvoiceStatus(data).then((response) => response.data);
export const getInvoicePayments = (invoiceId) => invoiceApi.getInvoicePayments(invoiceId).then((response) => response.data);
export const createInvoicePayment = (data) => invoiceApi.createInvoicePayment(data).then((response) => response.data);
export const recalculateInvoiceFees = (data) => invoiceApi.recalculateInvoiceFees(data).then((response) => response.data);
export const removeInvoicePayments = (data) => invoiceApi.removeInvoicePayments(data).then((response) => response.data);
export const createManualAdjustments = (data) => invoiceApi.createManualAdjustments(data).then((response) => response.data);
