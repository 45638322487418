import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useOrderContext } from '@copper/components/OrderPopup/context-hook';
import s from './SetNewAddressButton.module.css';
export const SetNewAddress = ({ operation }) => {
    const { popupContext, saveContext } = useOrderContext();
    const handleAddNewClick = () => {
        saveContext({
            popupContext: {
                ...popupContext,
                operation,
                header: {
                    title: 'Add new address',
                    hideAssetInfo: true,
                    returnCallback: () => {
                        saveContext({
                            popupContext: {
                                operation: undefined,
                                header: undefined,
                                content: undefined
                            }
                        });
                    }
                },
                content: {
                    noPaddings: true
                }
            }
        });
    };
    return (_jsx("button", { type: "button", className: s.buttonLink, onClick: handleAddNewClick, children: "Set new address" }));
};
