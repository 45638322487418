import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { FormControlContext } from '@copper/ui-kit/FormControl/FormControlContext';
import s from './FormControl.module.css';
const FormControl = ({ children, errorMessage, indent = 'm' }) => {
    const childContext = {
        state: Boolean(errorMessage) ? 'error' : undefined
    };
    return (_jsx(FormControlContext.Provider, { value: childContext, children: _jsxs("div", { className: cn(s[`indent_${indent}`]), children: [children, errorMessage && _jsx("div", { className: s.errorMessage, children: errorMessage })] }) }));
};
export { FormControl };
