import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Controller } from 'react-hook-form';
import { AssetIcon, InfoAccount, InfoRow, PortfolioIcon } from '@copper/components';
import { FormControl, FormLabel, NumberInput } from '@copper/ui-kit';
import { formatRuleAmount, getCurrencyCode } from './helpers';
import s from './Rules.module.css';
export const UntilTheBalanceReachesForm = ({ control, values, errors, watch }) => {
    const { portfolio, amountThreshold, balanceType, currency, transferPortfolio, transferMinBalance, amountBy } = values;
    const ruleType = watch('ruleType');
    const currencyCode = getCurrencyCode(currency, amountBy);
    const formattedAmountThreshold = formatRuleAmount(amountThreshold, currencyCode);
    const formattedTransferMinBalance = formatRuleAmount(transferMinBalance, currencyCode);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.formData, children: [_jsx(InfoRow, { title: `If the ${currencyCode === 'USD' ? currency?.currency : ''} balance for`, titleColor: "ghost", children: portfolio && _jsx(InfoAccount, { account: portfolio }) }), _jsx(InfoRow, { title: ruleType === 'below' ? 'Is below' : 'Is above', titleColor: "ghost", children: _jsx("div", { className: s.rowData, children: _jsxs("div", { children: [_jsx("div", { className: s.subTitle, children: `${formattedAmountThreshold} ${currencyCode}` }), _jsx("div", { className: s.subTitleUnder, children: balanceType === 'available' ? 'Available balance' : 'Total balance' })] }) }) }), _jsx(InfoRow, { title: "Transfer assets from", titleColor: "ghost", children: transferPortfolio && _jsx(InfoAccount, { account: transferPortfolio }) }), ruleType === 'below' && (_jsx(InfoRow, { title: "Leaving a min balance of", titleColor: "ghost", children: _jsx("div", { className: s.rowData, children: _jsx("div", { children: _jsx("div", { className: s.subTitle, children: `${formattedTransferMinBalance} ${currencyCode}` }) }) }) }))] }), _jsxs("h6", { className: s.formTitle, children: ["Until your", _jsxs("div", { className: s.portfolioRow, children: [_jsx(PortfolioIcon, { portfolio: portfolio ? portfolio : 'all' }), _jsx("div", { className: s.subTitle, children: portfolio?.portfolioName || 'All accounts' })] }), "balance reaches"] }), _jsx("div", { children: _jsxs(FormControl, { errorMessage: errors.targetBalanceLimit?.message, children: [_jsx(FormLabel, { view: "tool", children: "Amount" }), _jsx(Controller, { control: control, name: "targetBalanceLimit", render: ({ field }) => (_jsx(NumberInput, { size: "l", view: "tool", value: field.value, onValueChange: ({ value }) => field.onChange(value), decimalScale: 8, testId: "target-balance-limit", rightSection: currencyCode, leftSection: currency?.currency && _jsx(AssetIcon, { size: "s", currency: currencyCode }) })) })] }) })] }));
};
