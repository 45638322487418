import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { subscribeWallets, updateWallets } from '@copper/entities/wallets/wallets-reducer';
const useBalanceSubscription = () => {
    const dispatch = useDispatch();
    const [balanceSubscription, setBalanceSubscription] = useState({
        unsubscribe: () => null
    });
    const balanceQueue = useRef({});
    useEffect(() => {
        const interval = setInterval(() => {
            const wallets = Object.values(balanceQueue.current);
            if (wallets.length) {
                dispatch(updateWallets(wallets));
                balanceQueue.current = {};
            }
        }, 1000);
        return () => {
            balanceQueue.current = {};
            clearInterval(interval);
        };
    }, []);
    useEffect(() => () => {
        balanceSubscription.unsubscribe?.();
    }, [balanceSubscription]);
    const updateBalanceQueue = (wallets) => {
        wallets.forEach((wallet) => {
            const balance = balanceQueue.current[wallet.walletId];
            if (!balance || (balance?.updatedAt ?? 0) < (wallet?.updatedAt ?? 0)) {
                balanceQueue.current[wallet.walletId] = wallet;
            }
        });
    };
    const subscribeToBalances = async (orgId) => {
        try {
            const result = await dispatch(subscribeWallets({
                params: {
                    organizationId: orgId
                },
                onUpdate: (onUpdateResponse) => {
                    if (!onUpdateResponse.error) {
                        updateBalanceQueue(onUpdateResponse.data.wallets);
                    }
                    else if (onUpdateResponse.error === 'connection-closed-from-worker') {
                        subscribeToBalances(orgId);
                    }
                }
            })).unwrap();
            setBalanceSubscription({
                unsubscribe: result.unsubscribe
            });
        }
        catch (e) {
            Promise.reject(e);
        }
    };
    return subscribeToBalances;
};
export { useBalanceSubscription };
