import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { groupBy } from 'lodash-es';
import { ALL_PORTFOLIOS_CATEGORY } from '@copper/constants/portfolio';
import { selectGroupedPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectClearloopWallets, selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { fuzzySearch } from '@copper/ui-kit';
const generateAssetsEntities = (wallets, currenciesEntities) => {
    const walletsByCurrency = groupBy(wallets, 'currency');
    return Object.entries(walletsByCurrency).reduce((acc, [currency, wallets]) => {
        const totalByCurrency = wallets.reduce((balancesAcc, wallet) => {
            if (!Number(wallet.totalBalance)) {
                return balancesAcc;
            }
            return {
                ...balancesAcc,
                balance: balancesAcc.balance.plus(wallet.balance ?? 0),
                totalBalance: balancesAcc.totalBalance.plus(wallet.totalBalance),
                available: balancesAcc.available.plus(wallet.available ?? 0),
                reserve: balancesAcc.reserve.plus(wallet.reserve),
                stakeBalance: balancesAcc.stakeBalance.plus(wallet.stakeBalance ?? 0),
                balanceReporting: balancesAcc.balanceReporting.plus(wallet.balanceReporting ?? 0),
                availableReporting: balancesAcc.availableReporting.plus(wallet.availableReporting ?? 0),
                reserveReporting: balancesAcc.reserveReporting.plus(wallet.reserveReporting),
                stakeBalanceReporting: balancesAcc.stakeBalanceReporting.plus(wallet.stakeBalanceReporting ?? 0),
                totalBalanceReporting: balancesAcc.totalBalanceReporting.plus(wallet.totalBalanceReporting)
            };
        }, {
            balance: new BigNumber('0'),
            totalBalance: new BigNumber('0'),
            available: new BigNumber('0'),
            reserve: new BigNumber('0'),
            stakeBalance: new BigNumber('0'),
            balanceReporting: new BigNumber('0'),
            availableReporting: new BigNumber('0'),
            reserveReporting: new BigNumber('0'),
            stakeBalanceReporting: new BigNumber('0'),
            totalBalanceReporting: new BigNumber('0')
        });
        return {
            ...acc,
            [currency]: {
                balance: totalByCurrency.balance.toFixed(),
                totalBalance: totalByCurrency.totalBalance.toFixed(),
                available: totalByCurrency.available.toFixed(),
                reserve: totalByCurrency.reserve.toFixed(),
                stakeBalance: totalByCurrency.stakeBalance.toFixed(),
                balanceReporting: totalByCurrency.balanceReporting.toFixed(),
                availableReporting: totalByCurrency.availableReporting.toFixed(),
                reserveReporting: totalByCurrency.reserveReporting.toFixed(),
                stakeBalanceReporting: totalByCurrency.stakeBalanceReporting.toFixed(),
                totalBalanceReporting: totalByCurrency.totalBalanceReporting.toFixed(),
                currency,
                name: currenciesEntities[currency]?.name || ''
            }
        };
    }, {});
};
const sortAssetsByBalance = (assets) => assets.sort((a, b) => {
    // Show negative available values before zero values
    if (new BigNumber(b.totalBalanceReporting).isZero() &&
        new BigNumber(a.totalBalanceReporting).isZero() &&
        new BigNumber(b.availableReporting).isZero() &&
        new BigNumber(a.availableReporting).isNegative()) {
        return -1;
    }
    // Show negative values before zero values
    if (new BigNumber(b.totalBalanceReporting).isZero() &&
        new BigNumber(a.totalBalanceReporting).isNegative()) {
        return -1;
    }
    if (new BigNumber(a.totalBalanceReporting).isZero() &&
        new BigNumber(b.totalBalanceReporting).isNegative()) {
        return 1;
    }
    return new BigNumber(b.balanceReporting).minus(a.balanceReporting).toNumber();
});
export const getAssets = (wallets, currenciesEntities) => {
    const assetsEntities = generateAssetsEntities(wallets, currenciesEntities);
    return sortAssetsByBalance(Object.values(assetsEntities));
};
export const filterAssets = (items, searchQuery, showZeroBalances) => {
    if (!searchQuery) {
        if (showZeroBalances)
            return items;
        return items.filter((item) => !new BigNumber('amount' in item ? item.amount : item.totalBalance).isZero());
    }
    return fuzzySearch({
        searchQuery,
        keys: ['name', 'currency'],
        items
    });
};
export const useClearloopBalances = (portfolioId) => {
    const clearloopWallets = useSelector(selectClearloopWallets);
    const groupedPortfolios = useSelector(selectGroupedPortfolios);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const portfolios = getClearloopPortfoliosByParentId(groupedPortfolios.clearloopPortfolios, portfolioId);
    const clearloopWalletsByPortfolio = portfolios.reduce((acc, p) => {
        acc = acc.concat(walletsByPortfolioId[p.portfolioId] ?? []);
        return acc;
    }, []);
    const wallets = portfolioId === ALL_PORTFOLIOS_CATEGORY ? clearloopWallets : clearloopWalletsByPortfolio;
    return useMemo(() => wallets.reduce((acc, wallet) => {
        if (acc[wallet.currency]) {
            acc[wallet.currency] = {
                clearloopBalance: new BigNumber(acc[wallet.currency].clearloopBalance)
                    .plus(wallet.balance)
                    .toFixed(),
                clearloopBalanceReporting: new BigNumber(acc[wallet.currency].clearloopBalanceReporting)
                    .plus(wallet.balanceReporting)
                    .toFixed()
            };
        }
        else {
            acc[wallet.currency] = {
                clearloopBalance: wallet.balance,
                clearloopBalanceReporting: wallet.balanceReporting
            };
        }
        return acc;
    }, {}), [wallets]);
};
export const getClearloopPortfoliosByParentId = (clearloopPortfolios, portfolioId) => clearloopPortfolios.filter((portfolio) => portfolio.extra?.parentPortfolioId === portfolioId);
export const getConfirmationModalDetails = (type) => {
    if (!type)
        return;
    const details = {
        confirm: {
            title: 'Approved',
            message: 'Your approval has been recorded successfully.'
        },
        reject: {
            title: 'Rejected',
            message: 'Your rejection has been recorded successfully.'
        }
    };
    return details[type];
};
