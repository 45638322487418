import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const BTC = {
    title: 'BTC',
    id: 'btc',
    qa: [
        {
            question: 'How do I stake BTC with Core Chain?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can stake BTC to participate in Core Chain\u2019s consensus mechanism and earn CORE tokens by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar and then select Bitcoin (BTC)." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount (minimum: 0.01 BTC)." }), _jsxs("li", { children: ["Choose a validator and enter the address provided on", ' ', _jsx("a", { href: "https://scan.coredao.org/validators", target: "_blank", rel: "noreferrer", children: "Core Scan" }), ' ', "in the validator address field."] }), _jsx("li", { children: "Select the end date of your staking term (minimum duration: 10 days)." }), _jsx(Alert, { message: "Your assets will be locked up for the duration of this staking term.", type: "info" }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] })] }))
        },
        {
            question: 'How do I unstake BTC?',
            answer: (_jsx(_Fragment, { children: _jsx("p", { children: "Your staked BTC will be unlocked automatically at the end of your chosen staking term. You don\u2019t need to take any action. The funds will be available to use in your staking vault immediately." }) }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for BTC?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 0.01 BTC, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "0.01 BTC" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "When you stake BTC with Core Chain, rewards are issued in the form of CORE tokens daily." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can claim your CORE rewards earned from staking BTC at any time by following these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the staking dashboard, go to the Outstanding tab." }), _jsx("li", { children: "Locate the row showing your CORE staking details and select Claim." }), _jsx("li", { children: "Review your claimable rewards and click Confirm." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." })] }), _jsx("p", { children: "Your assets will be released into your staking vault right away." })] }))
        }
    ]
};
