import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const SUI = {
    title: 'SUI',
    id: 'sui',
    qa: [
        {
            question: 'How do I stake SUI?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount." }), _jsx("li", { children: "Choose a validator to stake with." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }))
        },
        {
            question: 'How do I unstake SUI?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." })] }), _jsx("p", { children: "Once signed, unstaked assets will be made available in your staking vault right away." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for SUI?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Yes, the minimum staking amount is 1 SUI, but there is no maximum amount." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "1 SUI" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn SUI staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Rewards are issued every epoch (around 24 hours). You will start earning rewards at the end of the first epoch after you start staking." }), _jsx("p", { children: "Rewards on the SUI network are automatically restaked \u2013 in other words, they are auto-compounding. Therefore, if you would like to transfer rewards, unstaking must first occur." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "First payout" }) }), _jsx("td", { className: s.td, children: "After one epoch" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 24 hours" })] })] }) })] }))
        },
        {
            question: 'How can I claim my SUI staking rewards?',
            answer: (_jsx("p", { children: "Rewards for SUI are automatically restaked. You will need to unstake assets to claim rewards. See how to unstake SUI." }))
        }
    ]
};
