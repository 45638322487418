import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const ETH = {
    title: 'ETH',
    id: 'eth',
    qa: [
        {
            question: 'How do I stake ETH?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select an account to stake from. Note, there is a fixed stake amount of 32 ETH." }), _jsx("li", { children: "Enter your validator public key, withdrawal credentials, signature and deposit data root." }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the bonding period." }), _jsx(Alert, { type: "info", message: "This can take anywhere between 2 hours to 3 weeks as ETH uses a queue-based system. You will not earn rewards during this period." })] }))
        },
        {
            question: 'How do I unstake ETH?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, navigate to your staking vault, where you can view a list of all the ETH validators you are staking to." }), _jsx("li", { children: "Select the validator you would like to unstake from, and then select Unstake. Note, transaction signing is not required to unstake ETH." }), _jsx("li", { children: "Wait out the unbonding period." })] }), _jsxs("p", { children: ["Unbonding times on ETH are variable and depend on the total number of validators unbonding on the Ethereum network at any given time. For full information on the ETH unbonding process, please refer to this documentation:", ' ', _jsx("a", { target: "_blank", href: "https://ethereum.org/en/staking/withdrawals/", rel: "noreferrer", children: "Staking withdrawals | ethereum.org." })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for ETH?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "There is a fixed stake amount of 32 ETH. " }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Fixed amount" }) }), _jsx("td", { className: s.td, children: "32 ETH" })] }) }) })] }))
        },
        {
            question: 'How often will I earn ETH staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "Rewards for ETH are issued every few days. You can check the reward frequency applicable at the time of staking, or in your staking details by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every few days" })] }) }) })] }))
        },
        {
            question: 'How can I claim my ETH staking rewards?',
            answer: (_jsx("p", { children: "When you first set up your staking deposit, you\u2019ll be asked to provide a withdrawal address. Your ETH staking rewards will be automatically deposited at this withdrawal address." }))
        }
    ]
};
